import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { config } from '../config.js'
import {
  Box,
  Button,
  Grid,
  Hidden,
  Icon,
  Paper,
  Tabs,
  Typography,
} from '@material-ui/core'
import {
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import InfiniteScroll from 'react-infinite-scroll-component'
import AppTopBar from '../components/AppTopBar'
import DestinationList from '../components/lists/DestinationList'
import SearchPanel from '../components/SearchPanel'
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const API = config.api.TRAVEL_URL
let abortController = new AbortController()

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

const ThumbComponent = (props) => {
  return (
    <span {...props}>
       👍
    </span>
  );
}

class Trips extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      hasMore: false,
      page: 0,
      destination_groups: [],
      category: props.category || 'upcoming',
    }
  }

  componentDidMount() {
    this.loadList()
  }

  componentDidUpdate(prevProps) {
    if (prevProps.user.is_registered !== this.props.user.is_registered) {
      this.loadList()
    }
  }

  handleReloadResults = () => {
    this.setState({
      destination_groups: [],
      isLoading: true,
    }, this.loadList)
  }

  handleCategorySwitch = (category) => {
    this.props.history.push(`/trips/${category}`)
    this.setState({
      category: category,
      page: 0,
      destination_groups: [],
    }, this.loadList)
  }

  loadList = () => {
    let path = ''

    if (this.props.user.is_registered) {
      this.setState({isLoading: true})

      // abort previous fetch
      abortController.abort()
      abortController = new AbortController();
      const signal = abortController.signal

      switch(this.state.category) {
        case 'upcoming':
          path = `/trips?page=${this.state.page + 1}`
          break;
        case 'past':
          path = `/trips?category=past&page=${this.state.page + 1}`
          break;
      }

      fetch(API + path,
      {
        headers: {
          'Authorization': `Bearer ${cookies.get('jwt')}`
        },
        signal: signal,
      })
      .then(response => response.json())
      .then(data => {
        let group = data.destination_groups[0]

        if (group && group.key === this.state.category) {
          this.setState(prevState => ({
            isLoading: false,
            destination_groups: prevState.page === 0 ? data.destination_groups : [...prevState.destination_groups, ...data.destination_groups],
            hasMore: group.has_next_page,
            page: prevState.page + 1,
          }))
        }
      })
      .catch(e => {});
    }
  }

  render() {
    const { classes, user } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>{`Trips`}</title>
          <link rel="canonical" href={`https://www${config.api.DOMAIN}/trips`} />
        </Helmet>
        <AppTopBar
          hideMenu={false}
          // showBackButton={true}
          // backButtonPath={`/trips/${this.state.category}`}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onCurrencyChange={this.handleReloadResults}
          onChange={this.props.onFilterChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'trips'}
          openedSigninDialog={this.state.signinDialogOpened}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations explore" style={{paddingBottom: 70}}>
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 0, backgroundColor: '#fafafa'}}>
              <h2 className={classes.headline}>
                <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonBook} ${classes.buttonRequestTrip}`} onClick={() => this.props.history.push({pathname: '/planner', state: {step: 'occasion'}})}>
                  <Icon className="material-icons-outlined notranslate" style={{marginRight: 10, marginLeft: -10, marginTop: -5}}>auto_awesome</Icon>
                  Start a trip
                </Button>
                <strong className="main-title" style={{fontWeight: 800}}>Trips<br/></strong>
                <small className={classes.subtitle}></small>

                <div className={classes.categoriesContainer} style={{paddingTop: 0, marginLeft: -5, marginTop: 0, marginBottom: 0}}>
                  <Tabs
                    value={false}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable auto"
                  >
                      <CustomTab key={-1} className={classes.categorySelector}>
                        <ToggleButtonGroup size="small">
                          <ToggleButton value="upcoming" disableRipple selected={this.state.category === 'upcoming'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={this.handleCategorySwitch.bind(this, "upcoming")}>Upcoming</ToggleButton>
                          <ToggleButton value="past" disableRipple selected={this.state.category === 'past'} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={this.handleCategorySwitch.bind(this, "past")}>Archived</ToggleButton>
                        </ToggleButtonGroup>
                      </CustomTab>
                  </Tabs>
                </div>
              </h2>


            </Paper>
          </div>


          <div className="destinations-container" style={{overflow: 'hidden'}}>
            {((this.state.destination_groups.length < 1 || this.state.destination_groups.length > 0 && this.state.destination_groups[0].trips.length < 1) && !this.state.isLoading) && <Grid item xs={12} className={classes.showcaseDestinations}>
              <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, fontSize: "100%", textAlign: 'center', fontWeight: 400, display: 'block' }}>
                <h2 className={classes.showcaseHeadline}>
                  <Typography className={classes.showcaseHeadline}>No trips</Typography>
                  <small className={classes.showcaseSubtitle} style={{fontSize: "80%"}}>Start an adventure</small>
                  <br/>

                  <Hidden mdUp>
                    <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonBook}`} onClick={() => this.props.history.push({pathname: '/planner', state: {step: 'occasion'}})} style={{margin: 0}}>
                      <Icon className="material-icons-outlined notranslate" style={{marginRight: 10, marginLeft: -10, marginTop: -5}}>auto_awesome</Icon>
                      Start a trip
                    </Button>
                  </Hidden>
                </h2>
              </Typography>
            </Grid>}

            <InfiniteScroll
              dataLength={this.state.destination_groups.length} //This is important field to render the next data
              next={this.loadList}
              hasMore={this.state.hasMore}
              loader={<div className={classes.buttonLoading}>Loading...</div>}
              style={{display: 'contents'}}
              scrollThreshold={0.4}
            >
              {<Grid container spacing={2} className={classes.gridFilters}>
                {((this.state.isLoading && this.state.destination_groups.length === 0) ? Array.from(new Array(1)) : this.state.destination_groups).map((group, i) => {
                  return <DestinationList key={group ? `${group.key}-${i}` : 'loading'}
                          search_params={this.state.search_params}
                          next_page_url={group && API + group.next_page_url}
                          destinations={group && group.trips}
                          title={group && group.title}
                          description={group && group.description}
                          has_next_page={false}
                          link_to_all={group && group.link_to_all}
                          page={group && group.page}
                          pageSize={3}
                          group_name={group && group.key}
                          members={this.state.members}
                          member_id={this.state.member_id}
                          showHeader={false}
                          isLoading={this.state.isLoading}
                          onSelected={this.props.onSearch}
                          address={this.props.address}
                          addressType={this.props.addressType}
                          countryCode={this.props.countryCode}
                          locationFrom={(this.state.from_address ? this.state.from_address : 'nearby')}
                          infiniteScroll={false}
                        />
                })}
              </Grid>}
            </InfiniteScroll>
          </div>

        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {
    fontWeight: 800,
    fontSize: 20,
    margin: '30px 0px 10px 0px',
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    margin: '-100px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    // minHeight: '60vh',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 4,
    },
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '30px',
    padding: '120px 20px 20px 20px',

    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      padding: '99px 20px 10px 20px',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 14,
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1142,
    margin: '20px auto 0px auto',
    width: 'calc(100% - 0px)',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  icon: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: 0,
    marginRight: 0,
    marginLeft: -5,
    padding: '8px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  buttonAddMemberSmall: {
    height: 32,
    fontSize: '13px',
    fontWeight: 900,
    backgroundColor: 'transparent',
    color: '#02c39a',
    border: '2px solid #02c39a',
    borderRadius: 45,
    padding: '18px 10px',
    minWidth: 10,
    verticalAlign: 'super',

    [theme.breakpoints.down('xs')]: {
      padding: '18px 6px',
    },

    '&:hover': {
      color: '#fff',
      border: '2px solid transparent',
    }
  },
  buttonBookOutlined: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    border: '2px solid #02c39a',
    textTransform: 'none',
    borderRadius: 45,
    fontWeight: 800,
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',
    // minWidth: 155,

    '&:hover': {
      border: '2px solid transparent',
      boxShadow: 'none',
    }
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 45,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  categoriesContainer: {
    width: '100%',
    margin: '10px auto -20px auto',
    maxWidth: 1137,
    paddingRight: 0,
    top: -1,
    whiteSpace: 'nowrap',
    overflowX: 'auto',
    // backgroundColor: '#fff',
    zIndex: 100,
  },
  categorySelector: {
    // [theme.breakpoints.down('sm')]: {
    //   textAlign: 'center',
    //   margin: 'auto',
    // },
  },
  travelStyleContainer: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '40px',
    padding: '0px 20px',

    [theme.breakpoints.down('xs')]: {
      padding: '0px 10px',
    },
  },
  travelStylePaper: {
    border: '1px solid #e5e5e5',
    padding: '20px',
    marginTop: 0,
    marginBottom: 0,
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0px -4px',

    [theme.breakpoints.down('xs')]: {
      backgroundPosition: '0px -1px',
      margin: '20px auto 0px auto',
    },
  },
  travelStyleIcon: {
    verticalAlign: 'middle',
    float: 'left',
    padding: '14px',
    margin: '0px 20px 0px 10px',
    backgroundColor: '#fff',
    color: '#02c39a',
    borderRadius: 25,
    display: 'block',

    ["@media (max-width:704px)"]: {
      display: 'none !important',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  travelStyleTestBtn: {
    margin: '0px 10px 0px 0px !important',
    float: 'left',
    color: '#fff',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',

    [theme.breakpoints.down('xs')]: {
      margin: '10px auto 0px auto !important',
      float: 'left',
    },
  },
  avatarSmall: {
    width: 36,
    height: 36,
    fontSize: '15px',
    fontWeight: 800,
    backgroundColor: '#fff',
    color: '#02c39a',
    borderColor: '#02c39a',
  },
  avatarGroup: {
    float: 'left',
    marginRight: 5,
  },
  showcaseDestinations: {
    margin: '100px 0px 40px 0px',

    [theme.breakpoints.down('xs')]: {
      margin: '100px 0px 30px 0px',
    },
  },
  showcaseHeadline: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000',
    textDecoration: 'none',

    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
      marginBottom: -5,
    },
  },
  showcaseSubtitle: {
    fontWeight: 600,
    fontSize: '70% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
  buttonOutlined: {
    // color: '#1bb746',
    float: 'left',
    // marginLeft: 10,
    padding: '10px',
    borderRadius: 25,
    fontWeight: 500,
    border: '1px solid #f5f5f5',
    color: '#333',
    marginTop: 6,
    minWidth: 110,
    backgroundColor: '#f5f5f5',

    '& .material-icons': {
      marginRight: 5,
      color: '#777',
      fontWeight: 500,
      padding: 0,
    },

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: '1px solid transparent',
    },

    '&:not(:first-child)': {
      marginLeft: 0,
    },

    '&:hover .material-icons': {
      color: '#333',
    },
  },
  buttonOutlinedSelected: {
    backgroundColor: '#f5f5f5 !important',
    fontWeight: 800,
    color: '#333 !important',
    padding: '5px 7px',
    minWidth: 110,

    '& .material-icons': {
      color: '#02c39a !important',
    },

    '&:hover': {
      color: '#333',
    },

    '&:hover .material-icons': {
      color: '#333',
    },

    '& > .MuiToggleButton-label': {
      borderRadius: 25,
      backgroundColor: '#fff',
      padding: '4px 10px',
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  buttonLoading: {
    color: '#02c39a',
    textDecoration: 'none',
    margin: '27px auto',
    display: 'block',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 600,
    padding: '13px 20px 13px 27px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '2px solid #02c39a',
    width: 65,
  },
  buttonRequestTrip: {
    float: 'right',
    marginTop: 55,
    marginBottom: -70,
    zIndex: 10,

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  }
})

export default withRouter(withStyles(styles)(Trips))
