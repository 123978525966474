import React, { Component, Fragment } from 'react'
// import { APIProvider, Map } from '@vis.gl/react-google-maps';
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { config } from '../config.js'
import {
  Grid,
  Paper,
  Typography,
  CardMedia,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { startCase } from 'lodash'
import ReactPixel from 'react-facebook-pixel'
import AppTopBar from '../components/AppTopBar.js'
import SearchPanel from '../components/SearchPanel.js'
import Itinerary from '../components/itinerary/Itinerary.js'
import TripItinerary from '../components/trip/TripItinerary.js'

const GOOGLE_MAPS_API_KEY = config.ui.GOOGLE_MAPS_API_KEY

class Assistant extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openedSigninDialog: false,
      openedSigninDialogType: 'signup',
      showHeader: true,
    }
  }

  componentDidMount() {
    window.gtag('event', 'assistant');
    ReactPixel.pageView()
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }


  render() {
    const { classes, user } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>{`Assistant`}</title>
          <link rel="canonical" href={`https://www${config.api.DOMAIN}/pricing`} />
        </Helmet>
        <AppTopBar
          hideMenu={true}
          hideBottomMenu={true}
          hideCurrency={true}
          showBackButton={true}
          backButtonPath={`/`}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onCurrencyChange={this.handleReloadResults}
          onChange={this.props.onFilterChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'assistant'}
          openedSigninDialog={this.state.signinDialogOpened}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div style={{backgroundColor: '#fafafa'}}>
          <div className={classes.searchPanel} style={{marginBottom: 10, padding: '50px 10px 0px 10px'}}>

            {/* {this.state.showHeader && <Paper square={true} className={classes.headerPaper}>
              <h1 className={classes.headerTitle}>
                <CardMedia
                  component="img"
                  className={classes.headerMedia}
                  image={'/hero-desktop.png'}
                  srcSet={'/hero-desktop.png 1x, /hero-desktop@2x.png 2x, /hero-desktop@3x.png 3x'}
                  title="Discover Weekly"
                  width="1000"
                  height="578"
                />
                <div style={{maxWidth: 450, fontWeight: 800}}>
                  <span className={classes.green} style={{whiteSpace: 'nowrap'}}>
                    Create unique trip,
                  </span> tours and activities instantly
                </div>
              </h1>
            </Paper>} */}

          </div>
        </div>

        <Grid container className={classes.grid} alignContent="flex-start" alignItems="flex-start">
          <Grid item md={7} xs={12}>
            <Grid item xs={12} >
              <SearchPanel
                planner={false}
                center={false}
                hideFieldTo={true}
                hideFieldDates={true}
                hideSearchButton={true}
                fieldFromMultiple={true}
                show_description={false}
                // expandable={this.isSize('xs')}
                search_params={this.props.search_params}
                onSearch={this.props.onSearch}
              />
            </Grid>
            <Grid item xs={12} style={{marginTop: -85, marginBottom: -20, paddingTop: 0, paddingBottom: 0}}>
              <Itinerary
                // editable={this.state.editable}
                // editable={true}
                showAiAssistant={true}
                itinerary={[]}
                itineraryType={"Trip"}
                itineraryId={null}
                itineraryLoaded={true}
                user={this.props.user}
                search_params={this.props.search_params}
                onSignin={this.props.onSignin}
                onSearch={this.props.onSearch}
              />
            </Grid>
          </Grid>
        </Grid>

      </Fragment>
    )
  }
}

const styles = theme => ({
  grid: {
    textAlign: 'left',
    maxWidth: 1130,
    padding: '0px 10px',
    margin: '0px auto',
    minHeight: '100vh',
  },
  searchPanel: {
    padding: '0px 0px 0px 0px',
    margin: '0px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    maxWidth: 1130,
    position: 'relative',
    boxShadow: 'none',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 4,
    },
  },
  headerPaper: {
    padding: '0px 0px 175px 0px',
    margin: '-124px auto 0px auto',
    color: '#000',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    backgroundColor: '#fafafa',
    display: 'block',
  },
  headerTitle: {
    maxWidth: 1119,
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: '40px',
    fontWeight: 800,
    textAlign: 'left',
    marginTop: '90px',
    padding: '70px 30px 0px 40px',

    [theme.breakpoints.down('xs')]: {
      padding: '90px 20px 0px 20px',
      fontSize: '30px',
      marginTop: '70px',
    },
  },
  headerMedia: {
    // maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',
    width: '50vw',
    minWidth: '1000px',
    maxWidth: '1000px',
    position: 'absolute',
    left: '500px',
    top: '-112px',
    // top: '-152px',
    // top: '-62px',
    display: 'block',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
      minWidth: 600,
      left: '-70px',
      top: '-120px',
    },
  },
})

export default withRouter(withStyles(styles)(withWidth()(Assistant)))
