import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  CardMedia,
  Icon,
  Grid,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
// import GetStartedField from './GetStartedField'

class SignupBanner extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      location: '',
      campaign: 'discover-weekly',
      submitted: false,
    };
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  render() {
    const { classes } = this.props

    return (
      <Grid className={classes.discoverSignup} container alignItems={'center'} justify={'center'} >
        <Grid item xs={12} sm={8} md={6}>
          <h2 className={classes.discoverHeadline} style={{margin: '5px 10px 4px 0px'}}>
            <strong className="main-title" style={{fontSize: '2.02rem', margin: '0px', display: 'block', fontWeight: 800}}>
              Would you like to see all destinations?
            </strong>
            <br/>
            <small className={classes.subtitle} style={{color: 'rgba(130, 130, 130, 0.9)', margin:'0px', display: 'block', lineHeight: 2.2}}>
              <Grid container spacing={2}>
                <Grid item xs={1} className={classes.discoverIcon}><Icon>done</Icon> </Grid> <Grid item xs={11} className={classes.discoverText}> <span>Browse all matching destinations for free</span></Grid>
                <Grid item xs={1} className={classes.discoverIcon}><Icon>done</Icon> </Grid> <Grid item xs={11} className={classes.discoverText}> <span>Access self guided itineraries before travel</span></Grid>
                <Grid item xs={1} className={classes.discoverIcon}><Icon>done</Icon> </Grid> <Grid item xs={11} className={classes.discoverText}> <span>Discover weekly new destinations, like on Spotify</span></Grid>

              </Grid>
            </small>
            <br/>
            {/* <GetStartedField
              locationFrom={(this.props.locationFrom ? this.props.locationFrom.replace(/,/g, ', ').replace(/-/g, ' ') : 'Current Location')}
              campaign={'discover-weekly'}
              align={'center'}
            ></GetStartedField> */}
            <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={this.props.onOpenSigninDialog.bind(this, true, 'signup')} style={{margin: '0px 5px 0px 0px'}}>Signup</Button>
            <Button variant="text" color="primary" size="small" disableElevation className={classes.buttonCancel} onClick={this.props.onOpenSigninDialog.bind(this, true, 'signin')}>Sign in</Button>
          </h2>
        </Grid>
        <Grid item xs={12} sm={4} md={6} className={classes.discoverImageContainer}>
          <CardMedia
            component="img"
            className={classes.discoverImage}
            image={'/discover-weekly-image.png'}
            // srcSet="/discover-weekly-image@2x.png 2x,
            //         /discover-weekly-image@3x.png 3x"
            title="Discover Weekly"
          />
        </Grid>
      </Grid>
    )
  }
}

const styles = theme => ({
  discoverHeadline: {
    padding: '40px 60px 60px 100px',
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      padding: '40px 60px 60px 40px',
      marginBottom: 80,
    },

    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px 40px 20px',
      marginBottom: 80,
    },
  },
  discoverIcon: {
    padding: '3px 8px 3px 0px !important',

    '& .material-icons': {
      verticalAlign: 'middle',
      color: '#02c39a',
      marginRight: 8,
      padding: '3px 8px'
    }
  },
  discoverText: {
    padding: '3px 8px 3px 18px !important',
  },
  discoverSignup: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '140px auto',
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 25,
    marginBottom: 140,
    // display: 'none',

    [theme.breakpoints.down('sm')]: {
      marginBottom: 70,
      marginTop: 70,
    },
  },
  discoverImageContainer: {
    [theme.breakpoints.down('sm')]: {
      padding: 10,
    },
  },
  discoverImage: {
    position: 'relative',
    right: '-47px',
    top: '-65px',
    display: 'block',
    marginBottom: -125,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 450,
      marginLeft: 'auto',
      marginRight: 'auto',
      top: 0,
      right: 0,
      marginBottom: 0,
      paddingBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      top: 0,
      right: 0,
      marginBottom: 0,
      paddingBottom: 10,
      display: 'none',
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '59% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
  btnSignup: {
    color: '#fff',
    borderRadius: 25,
    margin: '7px 5px 0px 0px',
    padding: '13px 35px',
    zIndex: 1,
    fontWeight: 600,

    [theme.breakpoints.down('sm')]: {
      // padding: '13px 25px',
      // margin: '0px auto',
      // display: 'block',
    },

    [theme.breakpoints.down('xs')]: {
      // padding: '10px 17px',
      margin: '0px auto',
      display: 'block',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonCancel: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,
    // backgroundColor: '#6b6b6b08',

    '&:hover': {
      backgroundColor: '#e2e2e2',
      border: 'none',
    }
  },
})

export default withRouter(withStyles(styles)(withWidth()(SignupBanner)))
