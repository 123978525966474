import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { config } from '../../config.js'
import {
  Grid,
  Paper,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar.js'
import PricingTable from '../../components/PricingTable.js'

class Pricing extends Component {
  constructor(props) {
    super(props)

    this.state = {
      openedSigninDialog: false,
      openedSigninDialogType: 'signup',
    }
  }

  componentDidMount() {
    window.gtag('event', 'upgrade_pricing_table');
  }

  componentDidUpdate(prevProps) {
  }


  render() {
    const { classes, user } = this.props

    return (
      <Fragment>
        <Helmet>
          <title>{`Pricing`}</title>
          <link rel="canonical" href={`https://www${config.api.DOMAIN}/pricing`} />
        </Helmet>
        <AppTopBar
          hideMenu={true}
          hideBottomMenu={true}
          hideCurrency={true}
          showBackButton={true}
          // backButtonPath={`/trips/${this.state.category}`}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onCurrencyChange={this.handleReloadResults}
          onChange={this.props.onFilterChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'home'}
          openedSigninDialog={this.state.signinDialogOpened}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations-container" style={{minHeight: "100vh"}}>
          <div className={classes.showcaseDestinations}>
            <Grid container spacing={2} className={classes.gridFilters} style={{width: "100%"}}>
              <Grid item xs={12}>
                <Typography gutterBottom variant="inherit" style={{ paddingBottom: 30, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block', textAlign: 'center' }}>
                  <h2 className={classes.showcaseHeadline}>
                    Upgrade your plan
                    <small className={classes.showcaseSubtitle}>Start planning trips with AI assistant</small>
                  </h2>
                </Typography>
              </Grid>
              <Grid item xs={12}><PricingTable user={this.props.user}/></Grid>
            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {
    fontWeight: 800,
    fontSize: 20,
    margin: '30px 0px 10px 0px',
  },
  showcaseHeadline: {
    fontSize: '30px',
    fontWeight: 800,
    color: '#000',
    textDecoration: 'none',
    marginBottom: 0,
    marginTop: 50,

    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      marginBottom: -20,
    },
  },
  showcaseSubtitle: {
    fontWeight: 600,
    fontSize: '50% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
})

export default withRouter(withStyles(styles)(Pricing))
