import React, { Component, Fragment } from 'react';
import { airlineNames } from '../../../utils/helpers';
import {
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';

const styles = theme => ({
  heading: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 700,
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    color: '#555',
    fontWeight: 600,
  },
  column: {
    flexBasis: '33.33%',
  },
  divider: {
    width: '90%',
    backgroundColor: '#f2f2f2',
    margin: '10px 10px 10px 10px',
  },
  airline: {
    whiteSpace: 'nowrap',
    display: 'block',
    overflowX: 'hidden'
  }
});

class FlightRow extends Component {

  render() {
    const { classes } = this.props;

    return(
      <Fragment>
        <Grid item zeroMinWidth xs={2} className={classes.airline}>
          <Typography noWrap className={classes.secondaryHeading}>
            <img className="airline-logo" style={{maxWidth: 32, maxHeight: 32}} alt="Departure Airline Logo" src={"https://images.kiwi.com/airlines/32x32/" + this.props.departure_airline +  ".png"} srcSet={"https://images.kiwi.com/airlines/64x64/" + this.props.departure_airline +  ".png 2x"}/>
            {(airlineNames(this.props.departure_airline) || {})["name"]}
          </Typography>
        </Grid>
        <Grid item xs={6}>
          <div className={classes.column}>
            <Typography className={classes.heading}>
            {this.props.flight_city_from} -> {this.props.flight_city_to}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {moment.utc(this.props.departure_time).format('ddd')}, {moment.utc(this.props.departure_time).format('MMM DD')} - {moment.utc(this.props.departure_time).format('hh:mm A')}
              {/* {moment.utc(this.props.departure_time).format('hh:mm A')} */}
            </Typography>
          </div>
          {/* <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {moment.unix(this.props.departure_time).utc().format('ddd hh:mm A')} - {moment.unix(this.props.arrival_time).utc().format('ddd hh:mm A')}
            </Typography>
          </div> */}
        </Grid>
        {/* <Grid item xs={2}>
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {this.props.flight_duration}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>

            </Typography>
          </div>
        </Grid> */}
        <Grid item xs={4}>
          <div className={classes.column}>
            <Typography className={classes.heading}>
              {this.props.flight_stops === 0 ? 'nonstop' : (this.props.flight_stops > 1 ? this.props.flight_stops + ' stops': this.props.flight_stops + ' stop')}
            </Typography>
          </div>
          <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {this.props.flight_duration}
            </Typography>
          </div>
          {/* <div className={classes.column}>
            <Typography className={classes.secondaryHeading}>
              {this.props.flight_city_from} -> {this.props.flight_city_to}
            </Typography>
          </div> */}
        </Grid>
        {this.props.divider === true && <Divider className={classes.divider}/>}
      </Fragment>
    )
  }
}


export default withStyles(styles)(FlightRow);
