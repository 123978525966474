import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  IconButton,
  Button,
  Box,
  Icon,
  Grid,
  TextareaAutosize,
} from '@material-ui/core'
import {
  Alert,
  ToggleButton,
  ToggleButtonGroup,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import DatesFilter from '../../components/filters/DatesFilter.js'
import TravelersFilter from '../../components/filters/TravelersFilter.js'
import PlaceField from '../../components/forms/PlaceField.js'
import BudgetFilter from '../../components/filters/BudgetFilter.js'
import { compact, merge } from 'lodash'

const date_defaults = {
  dates_type: 'cheapest',
  start_date: null,
  end_date: null,
  nights: [2, 4],
}
// const to_defaults = {}

class PlannerTravel extends Component {
  constructor(props) {
    super(props)

    this.state = props.travel || {
      travelers: {
        adults: 1,
        children: 0,
        infants: 0,
      },
      dates: Array.from({length: (props.multiple ? 2 : 1)}, () => date_defaults),
      from: [],
      to: Array.from({length: (props.multiple ? 2 : 1)}, () => ({id: Math.random().toString(36).substr(2, 9)})),
      budget: 500,
      currency: props.user && props.user.currency || 'USD',
      number_of_destinations: props.multiple ? 2 : 1,
    }
  }

  handleChange = (event, index = false) => {
    if (index !== false) {
      let state = this.state[event.target.name]
      state[index] = merge(event.target.value, {id: state[index].id})
      this.setState({[event.target.name]: state});
    } else {
      this.setState({[event.target.name]: event.target.value});
    }
  }

  addDestination = () => {
    let dates = this.state.dates
    let to = this.state.to
    dates.push(date_defaults)
    to.push({id: Math.random().toString(36).substr(2, 9)})

    this.setState({dates: dates, to: to, number_of_destinations: this.state.number_of_destinations + 1});
  }

  removeDestination = (index) => {
    let dates = this.state.dates
    let to = this.state.to
    dates.splice(index, 1);
    to.splice(index, 1);

    this.setState({dates: dates, to: to, number_of_destinations: this.state.number_of_destinations - 1});
  }

  submitRequest = () => {
    let state = this.state
    // state.to = state.to.map((to) => {
    //   delete to.id;
    //   return to;
    // })
    // state.to = state.to.filter((object) => object.description !== undefined)
    // console.log(state)
    this.props.onUpdated({travel: state})
  }

  render() {
    const { classes, user, multiple, compact } = this.props
    const { travelers, dates, from, to, budget, number_of_destinations, isSubmitting } = this.state

    return (
      <Fragment>
        <Grid item xs={12} md={8} style={{marginBottom: compact ? 50 : 140, marginTop: 10, maxWidth: 600}}>
          <Grid container className={classes.searchField}>
            <Grid item xs={8} md={8} style={{paddingTop: 5}}>
              <PlaceField
                name="from"
                label="Where from? *"
                autofocus={true}
                placeholder={from && from.length > 0 ? "Where from?" : " "}
                limitTags={3}
                single={false}
                standalone={true}
                defaultValues={from}
                onChange={(event) => this.handleChange(event)}
                classes={{iconLabel: classes.iconLabel}}
              />
            </Grid>
            <Grid item xs={4} md={4} style={{padding: '5px 5px 5px 5px', borderLeft: '1px solid #ccc'}}>
              <TravelersFilter
                name="travelers"
                adults={travelers && travelers.adults}
                children={travelers && travelers.children}
                infants={travelers && travelers.infants}
                onChange={(event) => this.handleChange(event)}
                classes={{iconLabel: classes.iconLabel}}
              >
              </TravelersFilter>
            </Grid>
          </Grid>
          {/* <Grid item xs={12} className={classes.subtitle} style={{textAlign: 'center'}}>
            <ToggleButtonGroup size="small" style={{margin: 'auto'}}>
              <ToggleButton value="planning" disableRipple selected={true} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => {}}>Enter destination</ToggleButton>
              <ToggleButton value="upcoming" disableRipple disabled selected={false} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} onClick={() => {}} >Flexible (soon)</ToggleButton>
            </ToggleButtonGroup>
          </Grid> */}

          <Box className={number_of_destinations > 1 ? classes.boxFieldTo : ''}>
          {to && to.map((el, index) => {
            return <Grid container key={el.id}>
              <Grid item xs={number_of_destinations > 1 ? 11 : 12} md={number_of_destinations > 1 ? 11 : 12} style={{paddingTop: 5}}>
                <Grid container className={classes.searchField}>
                  <Grid item xs={8} md={8} style={{paddingTop: 5}}>
                    <PlaceField
                      name={`to`}
                      label="Where to? *"
                      placeholder={" "}
                      defaultValues={to[index] && to[index].place_id ? [to[index]] : null}
                      limitTags={3}
                      single={true}
                      standalone={true}
                      onChange={(event) => this.handleChange(event, index)}
                      classes={{iconLabel: classes.iconLabel}}
                    />
                  </Grid>
                  <Grid item xs={4} md={4} style={{padding: '5px 5px 5px 5px', borderLeft: '1px solid #ccc'}}>
                    <DatesFilter
                      name={`dates`}
                      dates_type = {dates[index] && dates[index].dates_type}
                      start_date = {dates[index] && dates[index].start_date}
                      end_date = {dates[index] && dates[index].end_date}
                      nights={dates[index] && dates[index].nights}
                      onChange={(event) => this.handleChange(event, index)}>
                    </DatesFilter>
                  </Grid>
                </Grid>
              </Grid>
              {number_of_destinations > 1 && <Grid item xs={1} md={1} style={{margin: 'auto'}}>
                <IconButton style={{marginLeft: 5, padding: 5}} onClick={() => this.removeDestination(index)}><Icon className={`material-icons-outlined notranslate ${classes.iconDelete}`}>delete</Icon></IconButton>
              </Grid>}
            </Grid>
            })
          }
          {multiple && number_of_destinations < 10 &&<Button variant="contained" color="primary" size="small" disableElevation className={classes.buttonPrimary} onClick={this.addDestination} style={{marginRight: '10px'}}>
            <Icon className={`material-icons-outlined notranslate`}>add</Icon> Add destination
          </Button>}
          </Box>

          {/* <Grid item xs={12} className={classes.searchField} style={{paddingTop: 50, textAlign: 'center', border: 'none', fontWeight: 500, color: '#777', marginBottom: 20}}>
            Set your travel style (optional)
          </Grid>
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa'}}>
            <BudgetFilter name="budget" max={5000} label={'Budget per person: '} budget={this.state.budget} currency={this.state.currency} user={this.props.user} onChange={this.handleChange.bind(this)}/>
          </Grid>
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa'}}>
            <div style={{padding: '6px 5px 5px 5px'}}>
              <div className={classes.iconLabel}>
                <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>attractions</Icon>
                What would you like to do there ?
              </div>
              <TextareaAutosize
                id="trip_description"
                aria-label="trip_description textarea"
                placeholder="E.g. Visit top attractions and unique coffee"
                variant="outlined"
                name="trip_description"
                minRows={3}
                className={classes.textfieldArea}
              />
            </div>
          </Grid>
          <Grid item xs={12} className={classes.searchField} style={{paddingTop: 5, border: 'none', backgroundColor: '#fafafa', marginBottom: 100}}>
            <div style={{padding: '6px 5px 5px 5px'}}>
              <div className={classes.iconLabel}>
                <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>thumb_down_alt</Icon>
                I'm not interested in
              </div>
              <TextareaAutosize
                id="trip_description"
                aria-label="trip_description textarea"
                placeholder="E.g. Sport, Museums, Nightlife"
                variant="outlined"
                name="trip_description"
                minRows={3}
                className={classes.textfieldArea}
              />
            </div>
          </Grid> */}
        </Grid>

        <Grid item xs={12} md={8} className={classes.bottomBar}>
          {to && from && <Button disabled={!(from.map(item => item.description).some(location => location !== undefined)) || !(to.map(item => item.description).some(location => location !== undefined)) || isSubmitting} onClick={this.submitRequest} variant="contained" color="primary" size="large" className={classes.buttonBook}>
            {isSubmitting ?
              <Fragment>Sending...</Fragment>
              :
              <Fragment>Continue<Icon className="material-icons-outlined notranslate" style={{marginLeft: 10, marginBottom: -7}}>east</Icon></Fragment>
            }
          </Button>}
        </Grid>
      </Fragment>
    )
  }
}

const styles = theme => ({
  boxFieldTo: {
    padding: 10,
    backgroundColor: "#f7f7f7",
    borderRadius: 25
  },
  buttonPrimary: {
    color: '#fff',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '7px 20px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  searchField: {
    border: '1px solid #ccc',
    borderRadius: 20,
    marginBottom: 10,
    backgroundColor: '#fff',
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconLabel: {
    color: '#333',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: -4,
    marginLeft: 5,
  },
  iconDelete: {
    fontSize: 20,
    fontWeight: 400,
    verticalAlign: 'middle',
  },
  textfieldArea: {
    width: 'calc(100% - 30px)',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '15px 10px 0px 5px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 10px',
    border: '1px solid #ddd',
    fontFamily: 'inherit',
    minHeight: 20,
    maxWidth: '100%',
    minWidth: '88%',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(PlannerTravel))
