import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Chip,
  Grid,
  Icon,
  Tabs,
  Typography,
} from '@material-ui/core';

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class SelectorOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      values: [],
      selected: props.selected || [],
      disabled: false,
    };
  }

  handleToggle = (value) => {
    if (this.props.multiple === true) {
      const currentIndex = this.state.selected.indexOf(value);
      const newChecked = [...this.state.selected];

      if (currentIndex === -1) {
        newChecked.push(value);
      } else {
        newChecked.splice(currentIndex, 1);
      }

      this.setState(state => ({ selected: newChecked }));

    } else {
      const newChecked = [value]
      this.setState({ selected: newChecked, disabled: true}, this.handleSubmit);
    }
  }

  handleSubmit = () => {
    const value = { [this.props.name]: this.state.selected }
    const { triggerNextStep } = this.props;

    this.setState({ disabled: true});

    triggerNextStep({ value });
  }

  render() {
    const { selected, disabled } = this.state;
    const { classes, multiple, showImages, maxSelected, list } = this.props;

    return(
      <div role="tab" className={classes.root}>
        {showImages !== true ?
          <Grid container className={classes.chipsContainer}>
            {list.map((item, i) => {
              return <CustomTab key={i} style={{display: 'flex'}}>
                <Chip
                  key={i}
                  disabled={disabled || ((maxSelected !== undefined && !selected.includes(item.value)) ? selected.length > (maxSelected - 1) : false)}
                  classes={{root: classes.chipRoot, avatar: classes.chipAvatar, icon: classes.chipIcon, colorPrimary: classes.chipSelected}}
                  label={item.title}
                  clickable={!disabled || ((maxSelected !== undefined && !selected.includes(item.value)) ? selected.length < (maxSelected + 1) : true)}
                  color={selected.includes(item.value) ? "primary" : "default"}
                  onClick={(event) => this.handleToggle(item.value)}
                  onDelete={(event) => this.handleToggle(item.value)}
                  deleteIcon={(multiple === true ? (selected.includes(item.value) ?
                    <Icon className={`${classes.icon} material-icons-outlined notranslate`}>check</Icon>
                    :
                    <Icon className={`${classes.checkIcon} material-icons-filled notranslate`}>check_box_outline_blank</Icon>
                    )
                    : <Icon className={`${classes.blankIcon} material-icons-filled notranslate`}>blank</Icon>)
                  }
                  icon={item.icon ? <Icon className={`${classes.icon} material-icons-outlined notranslate`}>{item.icon}</Icon> : false}
                />
              </CustomTab>
            })}
          </Grid>
        :
          <Grid item xs={12} style={{display: 'flex'}}>
            <Tabs
              value={false}
              variant="scrollable"
              aria-label="scrollable"
              disabled={disabled}
              style={{display: 'contents'}}
            >
              {list.map((item, i) => {
                return <CustomTab key={i}>
                  <Card className={selected.includes(item.value) ? classes.cardSelected : classes.card}
                        elevation={0}
                        style={{ opacity: (disabled || ((maxSelected !== undefined && !selected.includes(item.value)) ? selected.length > (maxSelected - 1) : false) ? 0.3 : 1) }}
                        disabled={disabled || ((maxSelected !== undefined && !selected.includes(item.value)) ? selected.length > (maxSelected - 1) : false)}
                  >
                    <CardActionArea
                      component='span'
                      disabled={disabled || ((maxSelected !== undefined && !selected.includes(item.value)) ? selected.length > (maxSelected - 1) : false)}
                      onClick={(event) => this.handleToggle(item.value)}
                      disableRipple>
                        {/* <Box style={{ display: 'flex' }}> */}
                          <CardMedia
                            component="img"
                            image={item.imageSrc}
                            style={{ width: '100%', height: 120 }}
                          />
                          <CardContent style={{padding: '5px 15px 5px 15px' }}>
                            <Typography className={classes.cardName}>
                              {item.icon ? <Icon className={`${classes.cardIcon} material-icons-outlined notranslate`}>{item.icon}</Icon> : ''}
                              {item.title}
                              {(multiple === true ? (selected.includes(item.value) ?
                                  <Icon className={`${classes.withImageIcon} material-icons-outlined notranslate`}>check</Icon>
                                :
                                  <Icon className={`${classes.withImageCheckIcon} material-icons-filled notranslate`}>check_box_outline_blank</Icon>)
                                :
                                  <Icon className={`${classes.blankIcon} material-icons-filled notranslate`}>blank</Icon>)
                              }
                            </Typography>
                          </CardContent>
                        {/* </Box> */}
                    </CardActionArea>
                  </Card>
                </CustomTab>
              })}
            </Tabs>
          </Grid>
        }

        {multiple === true && disabled === false && <Grid item xs={12}>
          <Button disabled={selected.length < 1} variant="contained" color="primary" size="large" disableElevation className={classes.buttonOk} onClick={this.handleSubmit} style={{margin: '10px 5px 0px 0px', float: 'right'}}>Next</Button>
          {maxSelected !== undefined && <Box style={{float: 'right', padding: 21}}>Select up to {maxSelected}</Box>}
        </Grid>}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    // marginRight: 10,
    marginTop: 0,
    textAlign: 'left',
    width: '100%',
  },
  chipsContainer: {
    [theme.breakpoints.up('sm')]: {
      padding: '0px 0px 0px 40px',
    },
  },
  icon: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: 0,
    marginRight: -9,
    marginLeft: -5,
    padding: '10px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  cardIcon: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: 0,
    marginRight: 0,
    marginLeft: -5,
    padding: '10px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  checkIcon: {
    fontSize: 17,
    fontWeight: 400,
    marginTop: 4,
    marginRight: -13,
    marginLeft: -5,
    padding: '12px !important',
    verticalAlign: 'middle',
    color: '#ddd',
  },
  blankIcon: {
    width: 1,
  },
  withImageIcon: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: -2,
    marginRight: -13,
    marginLeft: 0,
    marginBottom: 1,
    padding: '10px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  withImageCheckIcon: {
    fontSize: 17,
    fontWeight: 400,
    marginTop: -1,
    marginRight: -13,
    marginLeft: 0,
    padding: '12px !important',
    verticalAlign: 'middle',
    color: '#ddd',
  },
  chipSelected: {
    backgroundColor: 'rgba(7, 195, 153, 0.26) !important',
    boxShadow: 'none !important',
    border: '2px solid rgba(7, 195, 153, 0.26) !important',
  },
  chipRoot: {
    padding: '18px 7px 18px 7px',
    borderRadius: 50,
    margin: '5px 10px 5px 0px',
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    fontWeight: 600,
    fontSize: 13,
    border: '2px solid #e0dddd',
    backgroundColor: '#fff',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  chipAvatar: {
    width: '44px !important',
    height: '44px !important',
    marginRight: '4px !important',
  },
  buttonOk: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  card: {
    borderRadius: 20,
    border: '1px solid #f2f2f2',
    minHeight: 86,
    minWidth: 130,
    marginBottom: 8,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    boxShadow: 'none',
    marginRight: 10,

    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  cardName: {
    marginBottom: 0,
    color: '#000',
    textDecoration: 'none',
    fontSize: 13,
    fontWeight: 600,
  },
  cardSelected: {
    backgroundColor: 'rgba(7, 195, 153, 0.26) !important',
    border: '1px solid rgba(7, 195, 153, 0.26) !important',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    borderRadius: 20,
    minHeight: 86,
    minWidth: 130,
    marginBottom: 8,
    marginRight: 5,
  },
});

export default withStyles(styles)(SelectorOption);