import React, { Component } from 'react'
import { config } from '../config.js'

class PricingTable extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      // Paste the stripe-pricing-table snippet in your React component
      <stripe-pricing-table
        pricing-table-id={`${config.stripe.PRICING_TABLE_ID}`}
        publishable-key={`${config.stripe.API_KEY}`}
        client-reference-id={`${this.props.user && this.props.user.client_reference_id}`}
        customer-email={`${this.props.user && this.props.user.email}`}
      >
      </stripe-pricing-table>
    );
  }
}

export default PricingTable;