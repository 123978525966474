import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { config } from '../../config.js'
import {
  Avatar,
  Divider,
  Grid,
  Icon,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar'
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const API = config.api.TRAVEL_URL

class UserMenu extends Component {
  constructor(props) {
    super(props)

    this.state = { }
  }

  handleHomeRedirect = () => {
    this.props.history.push('/')
  }

  handleSignOut = () => {
    fetch(config.api.TRAVEL_URL + '/users/sign_out', {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
      },
    })

    cookies.remove('jwt', { path: '/', domain: config.api.DOMAIN });
    this.setState({
      notification: "You've been signed out",
    })

    if (this.props.onSignin !== undefined) {
      this.props.onSignin({user: { is_registered: false}})
      this.handleHomeRedirect()
    }
  };

  render() {
    const { classes, user } = this.props

    return (
      <Fragment>
        <AppTopBar
          hideMenu={true}
          hideCurrency={true}
          showBackButton={false}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onCurrencyChange={this.handleReloadResults}
          onChange={this.props.onFilterChange}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'explore'}
          openedSigninDialog={this.state.openedSigninDialog}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations explore">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 16, backgroundColor: '#fafafa'}}>
              <h2 className={classes.headline}>
                <Avatar alt="Profile" src={this.props.user.image} width={48} height={48} className={classes.avatar}/>
                <div>
                  <strong className="main-title" style={{fontWeight: 800}}>
                    {user && user.first_name ? `Hey ${user.first_name}` : 'Hey there'}
                    <br/>
                  </strong>
                  <small className={classes.subtitle}>
                    {user && user.email}
                  </small>
                </div>
              </h2>
            </Paper>
          </div>

          <div className={classes.container}>
            <Grid container spacing={2} className={classes.gridFilters}>
              <List className={classes.menu}>
                <ListItem button component="a" key={'personalize'} href="/user/preferences">
                  <ListItemIcon><Icon className="material-icons-outlined notranslate">tune</Icon></ListItemIcon>
                  <ListItemText primary={'Travel style'} secondary={'Update your preferences'} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
                </ListItem>
                {/* <ListItem button component="a" key={'discover-weekly'} href={`/discover-weekly`}>
                  <ListItemIcon><Icon className="material-icons-outlined notranslate">next_week</Icon></ListItemIcon>
                  <ListItemText primary={'Discover weekly'} disableTypography className={classes.listItemText} />
                </ListItem> */}
                <Divider style={{margin: '20px 0px'}} />
                <ListItem button component="a" key={'signout'} onClick={this.handleSignOut}>
                  <ListItemIcon><Icon className="material-icons-outlined notranslate">logout</Icon></ListItemIcon>
                  <ListItemText primary={'Sign out'} disableTypography className={classes.listItemText} />
                </ListItem>
              </List>
            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  avatar: {
    width: 48,
    height: 48,
    marginRight: 15,
    marginTop: -11,
    float: 'left',

    [theme.breakpoints.up('sm')]: {
      marginTop: 10,
    },
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    margin: '-100px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    // minHeight: '60vh',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto 20px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '30px',
    padding: '140px 20px 20px 20px',

    [theme.breakpoints.down('sm')]: {
      fontSize: 25,
      padding: '126px 20px 0px 20px',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 10,
  },
  container: {
    padding: '10px 5px',
    textAlign: 'left',
    minHeight: 'calc(100vh - 167px)',
    backgroundColor: '#fff',
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1142,
    margin: '20px auto 0px auto',
    width: 'calc(100% - 0px)',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 45,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  menu: {
    padding: '30px 20px',
    width: '100%',

    '& > a': {
      padding: 16,
    },
  },
  listItemText: {
    fontSize: 15,
    fontWeight: 700,
  },
})

export default withRouter(withStyles(styles)(UserMenu))
