import { createMuiTheme } from "@material-ui/core/styles"

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#02c39a'
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
    useNextVariants: true,
    fontFamily: [
      'Manrope',
      'Helvetica Neue',
      'Helvetica',
      '-apple-system',
      'BlinkMacSystemFont',
      'Segoe UI',
      'Arial',
      'sans-serif'
    ].join(','),
  },
  overrides: {
    MuiPaper: {
      rounded: {
        borderRadius: 15,
      }
    },
    MuiAccordion: {
      rounded: {
        '&:first-child': {
          borderTopLeftRadius: 15,
          borderTopRightRadius: 15,
        },
        '&:last-child': {
          borderBottomLeftRadius: 15,
          borderBottomRightRadius: 15,
        }
      },
      root: {
        '&:before': {
          backgroundColor: '#f2f2f2',
        }
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 25,
      }
    },
    MuiIconButton: {
      label: {
        fontFamily: [
          'Manrope',
          'Helvetica Neue',
          'Helvetica',
          '-apple-system',
          'BlinkMacSystemFont',
          'Segoe UI',
          'Arial',
          'sans-serif'
        ].join(','),
      },
    }
  },
})
