import React, { Component } from 'react';
import { airlineNames } from '../../../utils/helpers';
import {
  Icon,
} from '@material-ui/core';
import moment from 'moment';


class FlightLegDetail extends Component {

  render() {
    const route = this.props.route;

    return(<div>
        <div>
          <Icon  className="notranslate">
            {route.vehicle_type === "aircraft" && 'flight_takeoff'}
            {route.vehicle_type === "bus" && 'directions_bus'}
            {route.vehicle_type === "train" && 'train'}
          </Icon>
          {moment.utc(route.local_departure).format('hh:mm A')} &bull; {route.vehicle_type === "aircraft" ? `${route.cityFrom} ${route.flyFrom}` : route.stationFrom}
        </div>
        <div className="travel-time">
          <Icon  className="notranslate">more_vert</Icon>
          <small className="second-heading">Travel time: {Math.floor((moment(route.local_arrival).diff(moment(route.local_departure), 'minutes') % 1440) / 60)}h {Math.floor((moment(route.local_arrival).diff(moment(route.local_departure), 'minutes') % 1440) % 60)}m </small>
          <span className="airline">
            <small className="second-heading">{(airlineNames(this.props.departure_airline) || {})["name"]}</small>
            <img className="airline-logo" alt="Departure Airline Logo" src={"//images.kiwi.com/airlines/32x32/" + route.airline +  ".png"} srcSet={"//images.kiwi.com/airlines/64x64/" + route.airline +  ".png 2x"}/>
          </span>
        </div>
        <div>
          <Icon  className="notranslate">
            {route.vehicle_type === "aircraft" && 'flight_land'}
            {route.vehicle_type === "bus" && 'directions_bus'}
            {route.vehicle_type === "train" && 'train'}
          </Icon>
          {moment.utc(route.local_arrival).format('hh:mm A')} &bull; {route.vehicle_type === "aircraft" ? `${route.cityTo} ${route.flyTo}` : route.stationTo}
        </div>
        {this.props.layover && (
          <div className="layover-time">
            <small><b>Layover: {this.props.layover} </b></small>
          </div>
        )}
      </div>)
  }
}


export default FlightLegDetail;