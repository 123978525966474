import React, { Component, Fragment } from 'react'
import Cookies from 'universal-cookie'
import { withRouter } from 'react-router-dom'
import { startCase, kebabCase } from 'lodash'
import moment from 'moment'
import {
  Avatar,
  Button,
  CardMedia,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Hidden,
  Icon,
  IconButton,
  InputAdornment,
  LinearProgress,
  Link,
  ListItemText,
  Menu,
  MenuItem,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Paper,
  Slide,
  Stepper,
  Step,
  StepButton,
  StepLabel,
  StepConnector,
  Tabs,
  Tab,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { AvatarGroup, Alert, AlertTitle, Skeleton } from '@material-ui/lab';
import Score from './Score.js'
import { stringToColor } from '../../utils/helpers.js'
import { HashLink } from 'react-router-hash-link'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();


const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const LinkTab = ({ to, smooth, className, children }) => {
  return (
    <HashLink
      to={to}
      className={className}
      smooth={smooth}
    >
      {children}
    </HashLink>
  );
}

class TripHeader extends Component {
  constructor(props) {
    super(props)
    this.steps = ['Explore', 'Transportation', 'Accommodation', 'Trip Overview']
    this.state = {
      activeStep: props.activeStep || 1,
      completed: ['Explore Destination'],
      saved: this.props.saved || false,
      openedShareDialog: false,
      openedGallery: false,

      isMenuOpened: false,
      anchorEl: null,
    }
  }

  saveInteraction = (action) => {
    this.setState(prevState => ({
      [action]: !prevState[action]
    }))

    let path = this.props.link_to_save ? this.props.link_to_save : `/users/${action === 'saved' ? 'save_destination' : 'visited_destination'}`;

    fetch(API + path, {
      method: 'POST',
      dataType: 'json',
      body: JSON.stringify({
        slug: this.props.destination ? this.props.destination.slug : '',
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid')
      }
    })
  }

  componentDidUpdate(prevProps) {
    if (prevProps.saved !== this.props.saved) {
      this.setState({
        saved: this.props.saved,
      })
    }
  }

  getGridListCols = () => {
    if (isWidthUp('xl', this.props.width)) {
      return 1;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 1;
    }

    if (isWidthUp('md', this.props.width)) {
      return 1;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 2;
    }

    return 2;
  }

  handleStep(i) {
    this.setState({activeStep: i})
  }

  handleClickBack = () => {
    this.props.history.push('/')
  }

  showFullscreen = () => {
    if (isWidthUp('sm', this.props.width)) {
      return false;
    } else {
      return true;
    }
  }

  shareTrip = () => {
    window.gtag('event', 'share', {link: this.props.sharableLink});
    this.props.history.replace(this.props.sharableLink)
    this.setState({openedShareDialog: true})
  }

  handleClose = () => {
    this.setState({openedShareDialog: false})
  }

  handleCloseGallery = () => {
    this.setState({openedGallery: false})
  }

  handleOpenGallery = () => {
    this.setState({openedGallery: true})
  }

  handleMenuClose = (event) => {
    this.setState({isMenuOpened: false, anchorEl: null})
  }

  handleMenuOpen = (event) => {
    this.setState({isMenuOpened: true, anchorEl: event.currentTarget})
  }

  handleLeaveTrip = () => {
    const user_jwt_token = cookies.get('jwt')

    if (this.props.user.is_registered && user_jwt_token !== undefined) {
      fetch(config.api.TRAVEL_URL + '/travel_plans/' + this.props.travel_plan_id + '/leave', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_jwt_token}`,
        },
        dataType: 'json',
      })
      .then(response => response.json())
      .then(data => {
        this.props.history.push('/trips')
      })
    }
  }

  render () {

    const {
      classes,
      searchParams,
      address,
      hideGallery,
    } = this.props

    let {
      to,
      from,
      startDate,
      endDate,
      nights,
      adults,
      children,
      infants,
      flightPrice,
      hotelPrice,
      destination,
      score,
      title,
    } = this.props

    nights = nights || searchParams.nights
    adults = adults || searchParams.adults
    children = children || searchParams.children
    infants = infants || searchParams.infants
    to = to || address

    const totalPrice = (flightPrice || 0) + (hotelPrice || 0)
    const rand = destination && (destination.images && ((destination.images.length - 1) >= 0 ? Math.floor(Math.random() * (destination.images.length - 1)) : 0));
    const link = `${config.env !== 'development' ? 'https://www' + config.api.DOMAIN : 'http://localhost:5200'}${this.props.sharableLink}?ref=share`
    const subject = `Check out this trip on Travelities!`
    const shareMessage = `Check out this trip on Travelities! ${link}`

    const connector = (
      <StepConnector
        classes={{
          line: classes.connectorLine,
        }}
      />
    );

    const shareDialog = <Dialog
      fullWidth={true}
      fullScreen={this.showFullscreen()}
      maxWidth={'sm'}
      open={this.state.openedShareDialog}
      onClose={this.handleClose}
      aria-labelledby="signup-dialog"
      classes={{paper: classes.dialogRoot}}
      TransitionComponent={Transition}
      scroll={'body'}
    >
      <DialogTitle id="signup-dialog-title" className={classes.dialogTitle}>
        <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={this.handleClose}>
          <Icon  className="notranslate">close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContentText}>
          <Grid container spacing={1} direction="row" justify="center" alignItems="center">

            <Grid item xs={12} style={{minHeight: 440}}>
                <Fragment>
                  <h2 className={classes.dialogHeadline}>
                    <p style={{marginBottom: 30}}>Share this trip<br/>with friends and family</p>
                    {destination && <Grid container spacing={3} justify={'center'}>
                      <Grid item xs={4} sm={3}>
                        <CardMedia
                          style={{borderRadius: 15, height: 100, width: 100}}
                          component="img"
                          image={destination.images && destination.images[rand] ? destination.images[rand].sizes.medium.url.replace('http://', '//') : '/art-image.jpg'}
                          title={to ? startCase(to.split(',')[0]) + ', ' + startCase(to.split(',').slice(-1)[0]) : ''}
                          alt={to ? startCase(to.split(',')[0]) + ', ' + startCase(to.split(',').slice(-1)[0]) : ''}
                        />
                      </Grid>
                      <Grid item xs={8} sm={9}>
                        <p style={{marginTop: 20, fontSize: 20, paddingLeft: 10}}>
                          {to ? startCase(to.split(',')[0]) + ', ' + startCase(to.split(',').slice(-1)[0]) : ''}
                          {nights > 0 && <small className="trip-summary-dates">
                            Personalized itinerary &bull; {nights} nights
                          </small>}
                        </p>
                      </Grid>
                    </Grid>}
                  </h2>

                  <Grid container spacing={1} style={{marginTop: 40}} justify={'flex-start'}>
                    <Grid item xs={12}><Typography className={classes.dialogShareGroup} variant="h6" component="h6">Social media</Typography></Grid>
                    <Grid item xs={6}><Button size="large" variant="text" className={classes.dialogShareLink} component="a" target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=${link}&quote=${subject}`} rel="noopener">
                      <img src={'/icon-facebook.svg'} width={36} height={36}/> Facebook</Button>
                    </Grid>
                    <Grid item xs={6}><Button size="large" variant="text" className={classes.dialogShareLink} component="a" target="_blank" href={`https://twitter.com/intent/tweet?text=${shareMessage}`} rel="noopener">
                      <img src={'/icon-twitter.svg'} width={36} height={36}/> Twitter</Button>
                    </Grid>



                    <Grid item xs={12}><Typography className={classes.dialogShareGroup} variant="h6" component="h6">Message</Typography></Grid>
                    <Grid item xs={6}><Button size="large" variant="text" className={classes.dialogShareLink} component="a" target="_blank" href={`mailto:?to=&subject=${subject}&body=${shareMessage}`} rel="noopener">
                      <Icon className="notranslate material-icons-outlined" style={{fontSize: 36}}>email</Icon> Email</Button>
                    </Grid>
                    <Grid item xs={6}><Button size="large" variant="text" className={classes.dialogShareLink} component="a" target="_blank" href={`http://www.facebook.com/dialog/send?app_id=261087682197713&link=${link}&redirect_uri=${link}`} rel="noopener">
                      <img src={'/icon-messenger.svg'} width={36} height={36}/> Messenger</Button>
                    </Grid>

                    <Grid item xs={12}><Typography className={classes.dialogShareGroup} variant="h6" component="h6">Link</Typography></Grid>
                    <Grid item xs={12}>
                      <TextField classes={{root: classes.dialogCodeField}} label={false} value={link} variant="outlined"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <Button size="small" variant="contained" className={classes.dialogButtonBook}
                                onClick={() => {
                                  navigator.clipboard.writeText(link)
                                }}>
                                Copy
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                </Fragment>
            </Grid>

          </Grid>
      </DialogContent>
    </Dialog>

    const gallery = <Dialog
      fullWidth={true}
      fullScreen={true}
      open={this.state.openedGallery}
      onClose={this.handleCloseGallery}
      aria-labelledby="signup-dialog"
      classes={{paper: classes.dialogRoot}}
      TransitionComponent={Transition}
      scroll={'body'}
      >
      <DialogTitle id="signup-dialog-title" className={classes.dialogTitle}>
        <IconButton aria-label="close" className={classes.dialogCloseButton} onClick={this.handleCloseGallery} style={{position: 'fixed', backgroundColor: '#fff', top: 25, right: 25, borderRadius: 50}}>
          <Icon  className="notranslate">close</Icon>
        </IconButton>
      </DialogTitle>
      <DialogContent className={classes.dialogContentText}>
        <Fragment>
          {destination && Array.isArray(destination.images) && destination.images.length > 1 &&
            <GridList className={classes.gridList} spacing={6} cols={2}>
              {destination.images.map((image, i) => (
                <GridListTile
                  classes={{
                    root: classes.gridTileRoot,
                    tile: classes.gridTile,
                  }}
                  rows={ (i===0 ? 3 : 2) }
                  cols={ (i===0 ? 2 : this.getGridListCols()) }
                  key={`${image.sizes.medium.url.replace('http://', '//')}_${i}`}>
                  <img src={image.sizes.medium.url.replace('http://', '//')} alt={image.caption} />
                  <GridListTileBar
                    title={
                      <Fragment>
                        <Link href={image.attribution.attribution_link} rel="noreferrer" target="_blank" style={{color: '#fff', textAlign: 'right', display: 'block'}}>
                          <Icon className="material-icons-outlined notranslate" style={{fontSize: '17px', margin: '-2px 5px 0px 0px', verticalAlign: 'middle'}}>camera_alt</Icon>
                          {image.attribution.attribution_text.replace('This Photo was taken by', '').split('. ')[0]}
                        </Link>
                      </Fragment>
                    }
                    classes={{
                      root: classes.titleBar,
                      title: classes.title,
                    }}
                  />
                </GridListTile>
              ))}
          </GridList>}
        </Fragment>
      </DialogContent>
    </Dialog>

    return (
      <Fragment>
        {!hideGallery && destination && destination.images && destination.images.length > 0 &&
          <Fragment>
            <Grid container spacing={1}>
              {destination.images.length > 2 ?
                <Fragment>
                  <Grid item xs={12} sm={8}>
                    <CardMedia
                      className={classes.heroPhoto}
                      image={destination.images[0].sizes.medium.url.replace('http://', '//')}
                    />
                  </Grid>
                  <Grid item sm={4}>
                    <CardMedia
                      className={classes.heroPhotoTop}
                      image={destination.images[1].sizes.medium.url.replace('http://', '//')}
                    />
                    <CardMedia
                      className={classes.heroPhotoBottom}
                      image={destination.images[2].sizes.medium.url.replace('http://', '//')}
                    />
                    <Button className={classes.buttonGallery} onClick={this.handleOpenGallery}>
                      <Icon className="notranslate material-icons-outlined">collections</Icon>
                      View photos
                    </Button>
                  </Grid>
                </Fragment>
              :
                <Grid item xs={12}>
                  <CardMedia
                    className={classes.heroPhotoFull}
                    image={destination.images[0].sizes.medium.url.replace('http://', '//')}
                  />
                </Grid>
              }
            </Grid>
          </Fragment>
        }

        <Grid
          container
          alignItems={'center'}
          justify={'center'}
          style={{maxWidth: 1130, margin: '0px auto 40px auto', textAlign: 'left'}}
        >
          <Paper className={classes.paperHeader} style={{borderBottomLeftRadius: 0, borderBottomRightRadius: 0, background: 'transparent'}}>
            <Grid item xs={12} className={classes.header}>

              {/* Header for group members */}
              {/* {destination && <Fragment>
                <Typography gutterBottom variant="h6" component="h1" className={classes.headerRight}>
                  <IconButton
                    edge="end"
                    aria-label="account of current user"
                    aria-controls={'tripMenuId'}
                    aria-haspopup="true"
                    onClick={this.handleMenuOpen}
                    onClose={this.handleMenuClose}
                    className={classes.menuBox}
                    color="inherit">
                      <Icon className={`${classes.menuIcon} material-icons-outlined notranslate`}>more_vert</Icon>
                  </IconButton>


                  <AvatarGroup classes={{avatar: classes.avatarSmall, root: classes.avatarGroup}} max={3}>
                    <Avatar key={0} className={classes.avatarSmall}/>
                    <Avatar key={1} className={classes.avatarSmall}/>
                    <Avatar key={2} className={classes.avatarSmall}/>
                    <Avatar key={3} className={classes.avatarSmall}/>
                    <Avatar key={4} className={classes.avatarSmall}/>
                  </AvatarGroup>

                </Typography>
              </Fragment>} */}

              {/* Title */}
              <Typography gutterBottom variant="h6" component="h1" className={classes.tripDestination}>
                  {title &&
                    <span style={{maxWidth: '100%', display: 'inline-flex', marginBottom: 10}}>
                      {title}
                    </span>
                  }
                  {!title && to &&
                    <span style={{maxWidth: '80%', display: 'inline-flex', marginBottom: 10}}>
                      {to}
                    </span>
                  }


                {to && <small className="trip-summary-dates">
                  {/* <img src={`/flags/${kebabCase(to.split(',').slice(-1)[0]) || 'blank'}.svg`} onError={(e) => (e.target.onerror = null, e.target.src = '/flags/blank.svg')} className={classes.flag}  alt={`flag of ${startCase(to.split(',').slice(-1)[0])}`} width="28" height="28"/> */}
                  {to} &bull; <Button variant="text" onClick={this.shareTrip} className={classes.shareButton}><Icon className={`notranslate`}>ios_share</Icon> Share</Button>
                </small>}

                {/* {destination && destination.intro && <Typography style={{fontWeight: 500, fontSize: 14, lineHeight: '24px', marginTop: 10}}>
                  {destination.intro}
                </Typography>} */}

                {false && (startDate > 0 && nights > 0 && adults > 0) && <small className="trip-summary-dates">
                  {!startDate && "Anytime"}
                  {nights > 0 &&
                    <Fragment>
                       &nbsp;&bull;
                      {this.getGridListCols() > 1 && <Fragment>Personalized itinerary &bull;</Fragment> }
                      {nights} nights &bull; <Button variant="text" onClick={this.shareTrip} className={classes.shareButton}><Icon className={`notranslate`}>ios_share</Icon> Share</Button>
                    </Fragment>}
                </small>}

                {this.props.isLoading && <div style={{textAlign: 'center', padding: '0px 0px 9px 0px'}}>
                  <div style={{paddingLeft: 0, paddingBottom: 10}}>
                    {!this.props.hideGallery && <Skeleton variant="rect" height={100} width={'auto'} className={classes.skeleton} style={{marginTop: 0, borderRadius: 15, marginBottom: 20}}/>}
                    <Skeleton variant="rect" height={20} width={'300px'} className={classes.skeleton} style={{marginTop: 10, borderRadius: 10, marginLeft: 0}}/>
                    <Skeleton variant="rect" height={15} width={'150px'} className={classes.skeleton} style={{marginTop: 10, borderRadius: 10, marginLeft: 0}}/>
                  </div>
                </div>}
              </Typography>
            </Grid>
          </Paper>
        </Grid>

        <Menu
          anchorEl={this.state.anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          id={'tripMenuId'}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={this.state.isMenuOpened}
          onClose={this.handleMenuClose}
          classes={{paper: classes.menuPaper}}
        >
          <MenuItem disabled={(this.props.plan && this.props.plan.members && this.props.plan.members.length < 1) || (this.props.plan && this.props.plan.is_organizer && this.props.plan.members.length > 1)} onClick={this.handleLeaveTrip}>
            <ListItemText primary={'Leave the trip'} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
          </MenuItem>
        </Menu>

        {shareDialog}
        {gallery}
      </Fragment>
    )
  }
}

const styles = theme => ({
  tripDestination: {
    marginLeft: 0,
    marginBottom: 0,
    lineHeight: '30px',
    padding: '15px 10px 10px 10px',
    fontWeight: 900,
    borderBottom: '1px solid #f5f5f5',
    minHeight: 28,

    [theme.breakpoints.down('xs')]: {
      padding: '5px 10px 10px 10px',
    },
  },
  tripPrice: {
    margin: '22px 0px 0px 0px',
    lineHeight: '20px',
    padding: '10px 20px',
    float: 'right',
    textAlign: 'right',
    fontWeight: 800,
    right: 0,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      right: -10,
    },
  },
  progressPrimary: {
    backgroundColor: '#f2f2f2',
    height: '2px'
  },
  progressBarColor: {
    backgroundColor: '#02c39a'
  },
  progress: {
    flexGrow: 1,
    width: '100%',
    // float: 'left',
    // clear: 'both',
    margin: '0px 0px 0px 0px',
    // position: 'relative',
    position: 'absolute',
    // bottom: 0,
    left: 0,
  },
  paperHeader: {
    width: '100%',
    // border: ' 1px solid #eee',
    marginBottom: -14,
    // boxShadow: '0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)',
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    boxShadow: 'none',
    position: 'relative',
  },
  stepperRoot: {
    padding: '13px 24px 14px 13px !important',
    width: '100%',
  },
  stepButtonRoot: {
    padding: '12px 16px',
  },
  stepperActive: {
    fontWeight: '600 !important',
  },
  stepperCompleted: {
    fontWeight: '400 !important',
    color: '#0000008a !important',
  },
  iconActive: {
    fontWeight: 600,
    color: '#4bb745 !important',
  },
  iconDisabled: {
    fontWeight: 600,
    color: '#ddd',
  },
  iconCompleted: {
    fontWeight: 600,
    color: '#4bb745 !important',
  },
  connectorLine: {
    borderColor: '#ddd',
    borderTopStyle: 'dotted',
  },
  buttonBack: {
    marginLeft: '-3px',
    color: '#45a1ff',
    fontSize: 12,
    fontWeight: 600,
    display: 'block',
    float: 'none',
    padding: '3px',
    marginBottom: '4px',

    '& .material-icons': {
      padding: 0,
      fontSize: 18,
      marginBottom: 2,
      verticalAlign: 'middle',
    },
  },
  buttonGallery: {
    backgroundColor: '#fff !important',
    fontWeight: 600,
    fontSize: 13,
    color: '#333 !important',
    padding: '5px 7px',
    float: 'right',
    marginTop: -45,
    marginRight: 10,
    borderRadius: 10,
    border: '1px solid #555',

    [theme.breakpoints.down('xs')]: {
      marginRight: 10,
      position: 'absolute',
      marginTop: -55,
      right: 0,
    },

    '& .material-icons': {
      // color: '#02c39a !important',
      marginRight: 5,
    },

    '&:hover': {
      color: '#333',
    },

    '&:hover .material-icons': {
      color: '#333',
    },

    '& > .MuiToggleButton-label': {
      borderRadius: 25,
      backgroundColor: '#fff',
      padding: '4px 10px',
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  shareButton: {
    fontWeight: 600,
    color: '#0000008a',
    fontSize: 13,
    textDecoration: 'underline',

    '& .material-icons': {
      fontSize: 20,
      marginTop: -6,
      marginRight: 3,
      marginLeft: -5,
      display: 'block',
    },
  },
  loadingInfo: {
    margin: '17px 24px 16px 30px !important',
    fontSize: '14px',
    color: '#333',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: 600,
  },
  gridList: {
    // flexWrap: 'nowrap',
    // Promote the list into his own layer on Chrome. This cost memory but helps keeping high FPS.
    transform: 'translateZ(0)',
    maxWidth: '1130px',
    margin: '-3px auto !important',
  },
  gridTileRoot: {
    // height: '35vh !important',
    // minHeight: 275,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
    overflow: 'hidden',

    '&:first-child': {
      paddingLeft: '0px !important',
    },
    '&:last-child': {
      paddingRight: '0px !important',
    },
  },
  gridTile: {
    borderRadius: '0px !important',
    minWidth: '100%',
  },
  title: {
    fontSize: '10px',
  },
  titleBar: {
    marginBottom: '-5px',
    fontWeight: 600,
    background:
      'linear-gradient(to top, rgba(0,0,0,0.7) 0%, rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
  },
  destinationIcon: {
    padding: '0px 10px',
    marginBottom: '-5px',
  },
  tabs: {
    margin: '0px',
    padding: '0px 20px',

    '& button': {
      padding: '17px 40px',
      display: 'block',
      textDecoration: 'none',
      fontWeight: 600,
      fontSize: 14,
      color: '#303030',
      opacity: 1,
      minWidth: 'auto',

      [theme.breakpoints.down('xs')]: {
        padding: '17px 16px',
      },
    }
  },
  tabsIndicator: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    height: 4,
  },
  skeleton: {
    borderRadius: 10,
  },
  skeletonImg: {
    height: '34.25vh !important',
    minHeight: 275,
    borderRadius: 0,
  },
  menuLink: {
    padding: '17px 40px',
    display: 'block',
    textDecoration: 'none',
    fontWeight: 600,
    fontSize: 14,
    color: '#303030',

    [theme.breakpoints.down('xs')]: {
      padding: '17px 16px',
    },
  },
  favoriteIcon: {
    borderRadius: 25,
    background: '#fff',
    padding: '9px 4px 7px 10px',
    color: '#25082f',
    margin: '-3px 0px 0px 5px',
    fontSize: '14px',
    fontWeight: 500,
    position: 'relative',

    '& .material-icons': {
      marginRight: 5,
    },
    '&:hover': {
      background: '#fafafa',
    },
  },
  favoriteIconSelected: {
    borderRadius: 25,
    background: '#fff',
    padding: '9px 4px 7px 10px',
    color: 'rgba(255, 107, 107, 0.9)',
    margin: '-3px 0px 0px 5px',
    fontSize: '14px',
    fontWeight: 500,
    position: 'relative',

    '& .material-icons': {
      marginRight: 5,
    },
    '&:hover': {
      background: '#fafafa',
    },
  },
  dialogTitle: {
    float: 'left',
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 'calc(100% - 48px)',
    zIndex: 2,
  },
  dialogContentText: {
    padding: 40,
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      overflowX: 'hidden',
    },
  },
  dialogCloseButton: {
    float: 'right',
  },
  dialogHeadline: {
    textAlign: 'left',
    lineHeight: 1.67,
    color: '#303030',
    fontSize: '26px',
    fontWeight: 700,

    [theme.breakpoints.up('md')]: {
      margin: '31px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-20px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-25px 0px 30px 0px',
    },
  },
  dialogCodeField: {
    width: '100%',

    '& .MuiOutlinedInput-root': {
      fontSize: 14,

      '& fieldset': {
        borderRadius: 15,
      },
    },
  },
  dialogButtonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  dialogShareGroup: {
    fontSize: 15,
    fontWeight: 700,
    color: '#000'
  },
  dialogShareLink: {
    width: '100%',
    textAlign: 'left',
    justifyContent: 'left',
    marginTop: 5,
    marginBottom: 10,

    '& img': {
      marginRight: 10,
    },
    '& .material-icons': {
      marginRight: 10,
    },
  },
  flag: {
    width: 18,
    marginRight: 5,
    marginTop: -3,
    verticalAlign: 'middle',
  },
  heroPhotoFull: {
    paddingTop: '40%',
    borderRadius: 15,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px -10px',
      paddingTop: '30%',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px -10px',
      paddingTop: '60%',
    },
  },
  heroPhoto: {
    paddingTop: '50.8%',
    borderTopLeftRadius: 15,
    borderBottomLeftRadius: 15,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px -10px',
      paddingTop: '40%',
    },
    [theme.breakpoints.down('xs')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px -10px',
      paddingTop: '60%',
    },
  },
  heroPhotoTop: {
    paddingTop: '50%',
    borderTopRightRadius: 15,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px 6px',
      paddingTop: '40%',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  heroPhotoBottom: {
    paddingTop: '50%',
    borderBottomRightRadius: 15,
    marginTop: 8,

    [theme.breakpoints.down('sm')]: {
      borderRadius: 0,
      margin: '-90px -10px 0px 6px',
      paddingTop: '39.7%',
      marginTop: '4px !important',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none'
    },
  },
  headerRight: {
    lineHeight: '20px',
    padding: '10px',
    marginTop: 15,
    float: 'right',
    textAlign: 'right',
    fontWeight: 800,
    right: 0,
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      marginTop: 5,
      right: -10,
    },
  },
  menuBox: {
    float: 'right',
    // border: '1px solid #ddd',
    marginRight: 0,
    marginLeft: 5,
    padding: 10,
  },
  menuIcon: {
    fontSize: 20,
    color: '#999',
  },
  menuPaper: {
    margin: '10px 0px',
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    border: '1px solid #f2f2f2',
  },
  listItemText: {
    fontSize: 14,
    fontWeight: 600,
  },
  listItemTextSecondary: {
    fontSize: 11,
    fontWeight: 500,
    color: '#999',
  },
})

export default withStyles(styles)(withRouter(withWidth()(TripHeader)))