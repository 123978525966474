import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import {
  Button,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  GridList,
  GridListTile,
  Icon,
  Chip,
  Avatar,
} from '@material-ui/core'
import { THINGS_TO_DO } from '../../../utils/lists'
// import { config } from '../config.js';

// const API = config.api.TRAVEL_URL + '/hotels';

class HotelCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      hotel_details: props.hotel,
      isLoading: false,
      leftScrollBtn: false,
      rightScrollBtn: true,
    }
  }


  toRad(x) {
    return x * Math.PI / 180;
  }

  haversineDistance(coords1, coords2, isMiles) {

    var lon1 = coords1[0];
    var lat1 = coords1[1];

    var lon2 = coords2[0];
    var lat2 = coords2[1];

    var R = 6371; // km

    var x1 = lat2 - lat1;
    var dLat = this.toRad(x1);
    var x2 = lon2 - lon1;
    var dLon = this.toRad(x2)
    var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(this.toRad(lat1)) * Math.cos(this.toRad(lat2)) *
      Math.sin(dLon / 2) * Math.sin(dLon / 2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;

    if(isMiles) d /= 1.60934;

    return Math.round(d * 10) / 10;
  }

  star_rating(star_rating) {
    const rating = (Math.round(star_rating * 2)/2);

    return (
      <Fragment>
        {/* Render full stars */}
        {[...Array(Math.floor(rating))].map((e, i) => <Icon className="notranslate" key={i}>star</Icon>)}

        {/* Render half star */}
        {Math.floor(rating) !== Math.round(rating) && (
          <Icon className="notranslate">star_half</Icon>
        )}

        {/* Render empty stars up to rating 5 */}
        {/* {5 - (Math.ceil(rating)) > 0 && [...Array(5 - Math.ceil(rating))].map((e, i) => <Icon key={i}>star_border</Icon>)} */}
      </Fragment>
    )
  }

  // fetchHotel(event, expanded) {
  //   if (expanded === true && !this.state.hotel_details) {
  //     this.setState({ isLoading: true })

  //     fetch(API + '/' + this.props.hotel.listingId, {
  //       headers: {
  //         'X-USER': this.props.user_id,
  //         'X-TRIP': this.props.trip_id
  //       }
  //     })
  //     .then(response => response.json())
  //     .then(response => {
  //       this.setState(previousState => ({
  //         hotel_details: response,
  //         isLoading: false
  //       }));
  //     })
  //     .catch((err) => this.setState({ isLoading: false, error: true }))
  //   }
  // }

  saveHotel() {
    let hotel = this.props.hotel;
    hotel.details = this.state.hotel_details;

    this.props.onSelected(hotel);
  }

  getGridListCols = () => {
    if (isWidthUp('xl', this.props.width)) {
      return 3.5;
    }

    if (isWidthUp('lg', this.props.width)) {
      return 2.5;
    }

    if (isWidthUp('md', this.props.width)) {
      return 2.5;
    }

    if (isWidthUp('sm', this.props.width)) {
      return 1.5;
    }

    return 1.5;
  }

  scrollCarousel(direction) {
    let width = this.carouselEl.offsetWidth

    if (direction === 'left') {
      width = width * -1
    }

    this.setState({
      leftScrollBtn: (this.carouselEl.scrollLeft + width) > 0 ? true : false,
      rightScrollBtn: (this.carouselEl.scrollLeft + width) < this.carouselEl.scrollWidth - this.carouselEl.offsetWidth ? true : false,
    })

    this.carouselEl.scrollBy({
      left: width,
      behavior: 'smooth'
    });
  }

  render() {
    const { classes, hotel, compact, smallImages, adults, children, startDate, endDate } = this.props;

    return (
      <Accordion
        expanded={false}
        classes={{
          // expanded: classes.expanded,
          root: classes.root,
        }}
        elevation={0}
      >
        <AccordionSummary classes={{content: classes.summaryContent, root: classes.summaryRoot}}>
          <Grid container spacing={1}>

          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={compact ? 12 : 5} md={compact ? 12 : 5} className={classes.images} style={smallImages ? {maxHeight: 200, minHeight: 200} : {}}>
              {hotel.photos && hotel.photos.length > 1 && this.state.leftScrollBtn && <div className={classes.carousel_arrow_left} onClick={this.scrollCarousel.bind(this, 'left')}>
                <Icon className="notranslate">keyboard_arrow_left</Icon>
              </div>}
              <GridList className={classes.gridList} cols={1} onClick={this.scrollCarousel.bind(this, 'right')} ref={carousel => {this.carouselEl = carousel}}>
                {hotel.photos && hotel.photos.map((photo_url, i) => (
                  <GridListTile classes={{tile: classes.gridListTile}} style={smallImages ? {maxHeight: 190, minHeight: 190} : {}} key={`${photo_url.replace('http://', '//').replace('?s=312x', '?s=624x')}_${i}`} rows={1.5} cols={hotel.photos.length > 1 ? 1 : 1}>
                    <img src={photo_url.replace('http://', '//').replace('?s=312x', '?s=624x')} alt={"hotel gallery image " + i} />
                  </GridListTile>
                ))}
              </GridList>
              {hotel.photos && hotel.photos.length > 1 && this.state.rightScrollBtn && <div className={classes.carousel_arrow_right} onClick={this.scrollCarousel.bind(this, 'right')}>
                <Icon  className="notranslate">keyboard_arrow_right</Icon>
              </div>}
            </Grid>
            <Grid item xs={12} sm={compact ? 12 : 7} md={compact ? 12 : 7} className={classes.overview}>

              {/* {<Button className={classes.headline} style={{width: '80%', margin: '-10px 0px 0px -10px'}} variant='text' component='a' target="_blank" href={hotel.booking_url + (hotel.booking_url.includes("?") ? "&" : "?") + "checkIn=" + (startDate || '') + "&checkOut=" + (endDate || '') + "&adults=" + (adults || 1) + "&children=" + (children || 0)}>
                <Typography gutterBottom className={classes.headline}>
                  {hotel.hotel_name} <small className="accommodation-rating">{this.star_rating(hotel.star_rating)}</small>
                </Typography>
              </Button>} */}
              <div style={{fontSize: 16, fontWeight: 800, marginTop: 5}}>
                <Typography className={classes.rating}>
                  {hotel.rating_average} <Icon className={`${classes.iconStar} notranslate`}>star</Icon>
                </Typography>
                <Typography className={classes.secondaryHeading}>
                  ({hotel.number_of_reviews} reviews)
                </Typography>
              </div>
              {<Typography variant="caption" className={classes.location}>
                {this.props.destination && this.props.destination.to_longitude &&
                  <Chip
                    label={`${hotel.city_name} • ` + this.haversineDistance(
                          [this.props.destination.to_longitude, this.props.destination.to_latitude],
                          [hotel.longitude, hotel.latitude],
                          false) + ' km to your destination'}
                    className={classes.chip}
                    avatar={
                      <Avatar className={classes.avatar}>
                        <Icon className="material-icons-outlined notranslate">location_on</Icon>
                      </Avatar>
                    } />
                }

                {/* ({hotel.city_name && hotel.city_name + ', '}{hotel.country_name}) */}
              </Typography>}
              <Typography className={classes.description}>
                {hotel.overview}
              </Typography>
              {(hotel.price_per_night || hotel.daily_rate || hotel.rates_from) && <Typography gutterBottom className={classes.headline} style={{marginTop: 10}}>
                {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.props.currency || 'USD'}).format((hotel.price_per_night ? Math.floor(hotel.price_per_night) : Math.floor(hotel.daily_rate || hotel.rates_from)))}
                <small> / night</small>
              </Typography>}
              {/* {hotel.matching_things_to_do && <Typography component="div" className={classes.description}>
                {hotel.matching_things_to_do && THINGS_TO_DO.map((item, i) => {
                  return hotel.matching_things_to_do.includes(item.value) && <Chip
                    key={i}
                    classes={{root: classes.chipRoot, avatar: classes.chipAvatar, icon: classes.chipIcon, colorPrimary: classes.chipSelected}}
                    color="primary"
                    label={item.title}
                    component="p"
                    icon={item.icon ? <Icon className={`${classes.icon} material-icons-filled notranslate`}>{item.icon}</Icon> : false}
                  />
                })}
              </Typography>} */}
              {/* <Typography className={classes.description}>
                {hotel.matching_vibe && hotel.matching_vibe.join(', ')}
              </Typography> */}

              {this.props.travelers && <Grid item xs={12} className={classes.summary}>
                <Button onClick={this.props.onSelected && this.props.onSelected.bind(this, this.props.hotel)} variant="contained" color="primary" size="large" className={classes.buttonBook} style={{float: 'right'}} rel="noopener noreferrer">
                  Select
                </Button>

                <Typography className={`${classes.heading} ${classes.price}`}>
                  <b>{new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.props.currency || 'USD'}).format(Math.round(hotel.price_per_night))}</b>
                  <small style={{fontWeight: 500, fontSize: 12, color: '#777'}}> / night</small>
                </Typography>
              </Grid>}
            </Grid>
          </Grid>

        </AccordionSummary>
      </Accordion>)
  }
}

const styles = theme => ({
  root: {
    // width: '100%',
    // boxShadow: 'none',
  },
  expanded: {
    marginTop: 0,
    marginBottom: 0,
  },
  media: {
    height: '30px',
    paddingTop: '56.25%', // 16:9
    margin: '-6px 0px -6px -18px',
  },
  content: {
    padding: '16px',
    paddingBottom: '16px !important'
  },
  title: {
    maxHeight: '42px',
    overflow: 'hidden',
    lineHeight: '1.5em',
    fontWeight: 800,
  },
  button: {
    width: '100%',
    boxShadow: 'none',
    margin: '0px 8px',
    backgroundColor: '#00a1ff',

    '&:hover': {
      backgroundColor: '#303f9f',
    }
  },
  details: {
    alignItems: 'center',
    backgroundColor: '#fafafa',
    padding: '8px 25px 24px',
  },
  heading: {
    fontSize: theme.typography.pxToRem(18),
    padding: '5px 0px',
  },

  price: {
    float: 'right',

    [theme.breakpoints.down('xs')]: {
      float: 'left',
    },
  },
  images: {
    marginTop: 4,
    // marginLeft: -4,
    position: 'relative',
    minHeight: 250,

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      marginLeft: 0,
      padding: '0px !important',
    },
  },
  overview: {
    paddingTop: '10px !important',

    [theme.breakpoints.down('xs')]: {
      // padding: '0px !important',
    },
  },
  chip: {
    height: '25px',
    fontWeight: 600,
    backgroundColor: '#fff',
    marginLeft: '-14px',
    marginTop: '-4px',
    verticalAlign: 'middle',
    '& span': {
      color: '#828282 !important',
      padding: '0px 10px',
      fontWeight: 600,
    },
    '& .material-icons': {
      verticalAlign: 'middle',
      margin: '10px -30px 0px -26px',
      fontSize: '16px !important'
    }
  },
  chipSelected: {
    boxShadow: 'none !important',
  },
  chipRoot: {
    padding: '10px 0px 10px 0px',
    borderRadius: 50,
    margin: '5px 10px 5px 0px',
    fontWeight: 600,
    fontSize: 13,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    color: '#777',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  chipAvatar: {
    width: '44px !important',
    height: '44px !important',
    marginRight: '4px !important',
  },
  icon: {
    fontSize: 17,
    fontWeight: 600,
    marginTop: 0,
    marginRight: -15,
    marginLeft: 0,
    padding: '10px !important',
    verticalAlign: 'middle',
    color: '#777',
  },
  iconStar: {
    fontSize: 19,
    color: '#d4c500',
    padding: '2px 0px 0px 0px',
    verticalAlign: 'top',
  },
  progressPrimary: {
    backgroundColor: '#e0e0e0',
    height: '3px'
  },
  progressBarColor: {
    backgroundColor: '#00a1ff'
  },
  progress: {
    margin: '-12px -24px 0px -24px',
    flexGrow: 1
  },
  avatar: {
    marginLeft: '5px',
    margin: '-11px -5px 0px -16px',
    color: '#616161',
    backgroundColor: 'transparent',
    height: '40px',
    width: '36px',
  },
  headline: {
    fontWeight: '800 !important',
    fontSize: '16px',
    justifyContent: 'flex-start',
    maxHeight: 70,
    overflow: 'hidden',
  },
  location: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#777',
    marginTop: '0px',
    display: 'block'
  },
  description: {
    marginTop: 10,
    fontSize: '14px',
    fontWeight: 500,
    maxHeight: 75,
    overflow: 'hidden',
    lineHeight: '24px',
  },
  gridList: {
    flexWrap: 'nowrap',
    overflow: 'hidden',
  },
  gridListTile: {
    borderRadius: 15,
  },
  summaryContent: {
    display: 'block',
    cursor: 'default',

    [theme.breakpoints.up('sm')]: {
      margin: 0,
    },
  },
  summaryRoot: {
    overflow: 'hidden',
    display: 'block',
    padding: 0,
    // margin: 10,
    // backgroundColor: '#fff',
    width: 'auto',
    // boxShadow: '0 0px 10px 0 rgb(0 0 0 / 5%)',
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    // borderRadius: '15px !important',
    // border: '1px solid #eee',

    [theme.breakpoints.down('xs')]: {
      padding: '0px 8px',
      margin: 0,
    }
  },
  columnRight: {
    float: 'right',
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '0px 5px 0px 20px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  summary: {
    textAlign: 'left',
    verticalAlign: 'middle',
    display: 'block',
    padding: '3px 0px 5px 5px !important',
    marginBottom: '11px',
    position: 'absolute',
    bottom: 0,
    right: 10,

    [theme.breakpoints.down('xs')]: {
      margin: '15px 0px',
      position: 'relative',
      right: 0,
      padding: 0,
    },
  },
  carousel_arrow_left: {
    position: 'absolute',
    left: 12,
    top: '43%',
    zIndex: 1,
    backgroundColor: '#ffffffc2',
    padding: '6px 7px 0px 7px',
    borderRadius: 20,
    cursor: 'pointer',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    display: 'none',

    '.MuiButtonBase-root:hover &': {
      display: 'block',
    },
  },
  carousel_arrow_right: {
    position: 'absolute',
    right: 13,
    top: '43%',
    zIndex: 1,
    backgroundColor: '#ffffffc2',
    padding: '6px 7px 0px 7px',
    borderRadius: 20,
    cursor: 'pointer',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
    display: 'none',

    '.MuiButtonBase-root:hover &': {
      display: 'block',
    },

    '& > .material-icons': {
      marginBottom: -1,
    },
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: 600,
    textAlign: 'left',
    // color: 'rgba(0, 0, 0, 0.44)',
    display: 'inline-block',
    paddingLeft: 5,
  },
  rating: {
    fontSize: 16,
    fontWeight: 800,
    display: 'inline-block',
  },
});

export default withStyles(styles)(withWidth()(HotelCard));