import { airlines } from './airlines'

const isObject = val => {
  return typeof val === 'object' && val !== null;
};

export const classnames = (...args) => {
  const classes = [];
  args.forEach(arg => {
    if (typeof arg === 'string') {
      classes.push(arg);
    } else if (isObject(arg)) {
      Object.keys(arg).forEach(key => {
        if (arg[key]) {
          classes.push(key);
        }
      });
    } else {
      throw new Error(
        '`classnames` only accepts string or object as arguments'
      );
    }
  });

  return classes.join(' ');
};

export const airlineNames = (airline_code) => {
  return airlines.find(airline => airline["id"] === airline_code);
}

export const transportationIcon = (transporation_codes) => {
  let types = transporation_codes.map(code => (code && airlineNames(code) && airlineNames(code)["type"]))
  if (types.indexOf('airline') > -1) {
    return 'flight'
  } else if  (types.indexOf('train') > -1) {
    return 'train'
  } else {
    return 'directions_bus'
  }
}

export const routeStopIcon = (route_stop_type) => {
  if (route_stop_type === 'aircraft') {
    return 'local_airport'
  } else if (route_stop_type === 'train') {
    return 'directions_railway'
  } else if (route_stop_type === 'bus') {
    return 'directions_bus'
  } else if (route_stop_type === 'location') {
    return 'place'
  } else if (route_stop_type === 'hotel') {
    return 'place'
  }
}

export const removeDuplicates = (myArr, prop) => {
    return myArr.filter((obj, pos, arr) => {
        return arr.map(mapObj => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
};

export const humanize = (input) => {
  input = input.toLowerCase().replace(/[_-]+/g, ' ').replace(/\s{2,}/g, ' ').trim();
  input = input.charAt(0).toUpperCase() + input.slice(1);

  return input;
};

export const count_stops = (routes, return_fly=0) => {
  let flights = [];

  routes.forEach(function(route) {
    if(route.return === return_fly) {
      flights.push(route)
    }
  });

  return flights;
};

export const arrayToObject = (array, keyField) =>
  array.reduce((obj, item) => {
    obj[item[keyField]] = item
    return obj
  }, {})


export const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.substr(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export const toRadians = (degrees) => {
  var pi = Math.PI;
  return degrees * (pi / 180);
}
export const haversine_distance = (origin, destination) => {
  let [lat1, lon1] = origin;
  let [lat2, lon2] = destination;
  let radius = 6371; // earth radius in km

  let dlat = toRadians(lat2 - lat1);
  let dlon = toRadians(lon2 - lon1);
  let a =
    Math.sin(dlat / 2) * Math.sin(dlat / 2) +
    Math.cos(toRadians(lat1)) *
      Math.cos(toRadians(lat2)) *
      Math.sin(dlon / 2) *
      Math.sin(dlon / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = radius * c;

  return Math.round(d * 1.4);
}

export const distance_in_minutes = (origin, destination) => {
  let air_distance = haversine_distance(origin, destination)

  return Math.round(air_distance * 1.4 / (air_distance < 10 ? 70 : 90) * 60)
}