import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Divider,
  Icon,
  Grid,
  Paper,
  Tabs,
  Box,
  Typography,
  TextareaAutosize,
} from '@material-ui/core'
import moment from 'moment'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { startCase, kebabCase } from 'lodash'
// import SearchBar from './SearchBar'
import DatesFilter from './filters/DatesFilter'
import TravelersFilter from './filters/TravelersFilter'
import PlaceField from './forms/PlaceField'
// import Uploader from './Uploader'

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class SearchPanel extends Component {
  constructor(props) {
    super(props)
    this.state = {
      travelers: props.search_params.travelers || null,
      dates: props.search_params.dates || null,
      from: props.search_params.from || null,
      to: props.search_params.to || null,
      expanded: !props.expandable || false,
      show_description: props.show_description || false,
    }

    // console.log(props)
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.expandable) !== JSON.stringify(this.props.expandable)) {
      this.setState({
        expanded: this.props.expandable || false,
      })
    }
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value});
  }

  handleClose = event => {
    event.stopPropagation();
    event.preventDefault();

    this.setState((prev) => ({ expanded:  (!this.props.expandable || false) }))
  }

  handleSearch = (event) => {
    event.stopPropagation();
    event.preventDefault();

    let search_params = {}

    search_params.from = this.state.from ? (this.state.from.location ? [this.state.from.location] : this.state.from) : []
    search_params.to = this.state.to && this.state.to.length > 0 ? (this.state.to[0].location ? this.state.to.map(item => item.location) : this.state.to) : []

    if (this.state.dates !== null) {
      search_params.dates = this.state.dates
    }
    if (this.state.travelers !== null) {
      search_params.travelers = this.state.travelers
    }
    if (this.props.group !== null) {
      search_params.interests = this.props.group
    }

    // console.log(search_params)
    this.setState((prev) => ({ ...search_params, expanded: (!this.props.expandable || false) }))
    this.props.onSearch(search_params)
  }

  renderDatesLabel = () => {
    const { dates } = this.state

    if (dates && dates.dates_type === "specific") {
      let start_date = !dates.start_date ? 'Start Date' : moment(dates.start_date).format('MMM DD');
      let end_date = !dates.end_date ? 'End Date' : moment(dates.end_date).format('MMM DD');

      return (!dates.start_date || !dates.end_date) ?  'Anytime' : start_date + ' - ' + end_date

    } else if (dates && dates.dates_type === "weekends") {
      return 'Weekend'

    } else if (dates && dates.dates_type === "cheapest") {
      return `${dates.nights[0]}${dates.nights[0] === dates.nights[1] ? '' : '-' + dates.nights[1]} night${(dates.nights[1] > 1 ? 's' : '')}`

    } else {
      return 'Anytime'
    }
  }

  render() {
    const { classes, search_params } = this.props
    const travelers = search_params && search_params.travelers && (search_params.travelers.adults + search_params.travelers.children + search_params.travelers.infants)

    return (
      <Box style={{ marginTop: -55 }} >
        <Grid container className={this.props.center ? classes.center : ''}>
          <Paper style={{maxWidth: (!this.props.hideFieldTo ? 800 : 720), minHeight: (!this.props.hideFieldTo ? 23 : 'inherit'), height: 'auto', margin: (isWidthUp('sm', this.props.width) ? 0 : '10px auto 0px auto')}} className={`${classes.header} ${classes.search} ${this.state.expanded ? (this.props.inline ? classes.paperBoxInline : classes.paperBox) : (this.props.inline ? classes.miniPaperBoxInline : classes.miniPaperBox)}`} onClick={ () => this.setState((prev) => ({ expanded: true })) }>
            {!this.state.expanded && <Box>
              <Box className={classes.boxSearchPart} style={{marginTop: 4, marginLeft: 5}}><Icon className="material-icons-outlined notranslate">place</Icon></Box>
              <Box className={classes.boxSearchContainer}>
                <Box className={classes.boxSearchPart}>
                  {(this.state.from && this.state.from.length > 0) ? (Array.isArray(this.state.from) ? this.state.from[0].description.split(',')[0] + (this.state.from.length > 1 ? ` +${this.state.from.length-1}` : '') : this.state.from.description.split(',')[0]) : (this.props.hideFieldTo ? 'Current Location' : '')}
                  {!this.props.hideFieldTo && (this.state.from && this.state.from.length > 0) && <Icon className={`material-icons-outlined notranslate ${classes.icon}`} style={{marginLeft: 5}}>arrow_forward</Icon>}
                  {!this.props.hideFieldTo && ((this.state.to && this.state.to.length > 0) ? (this.state.to.length > 1 ? `${this.state.to.length} places` : this.state.to[0].place) : 'Anywhere')}
                </Box>
                <Box className={classes.boxSearchPart}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>calendar_today</Icon> {this.renderDatesLabel()}</Box>
                <Box className={classes.boxSearchPart}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>people</Icon> {travelers}</Box>
              </Box>
            </Box>}

            {this.state.expanded && <Grid item xs={12}>
              {/* <Uploader onUnknownDestination={this.handleUnknownDestination}/> */}

              <Grid container spacing={1} alignItems="flex-start" className={classes.searchContainer}>
                <Grid item xs={12} sm={!this.props.hideFieldTo ? 3 : (this.props.hideFieldDates ? 9 : 5)} className={classes.searchField}>
                  <PlaceField
                    name="from"
                    label="From"
                    placeholder={this.state.from && this.state.from.length > 0 ? "Where from?" : "Current location"}
                    single={this.props.fieldFromMultiple ? !this.props.fieldFromMultiple : true}
                    defaultValues={this.state.from}
                    location={this.props.locationFrom}
                    onChange={this.handleChange.bind(this)}
                  />
                </Grid>
                {!this.props.hideFieldTo && <Fragment>
                  <Divider orientation="vertical" flexItem className={classes.divider} style={{display: (isWidthUp('sm', this.props.width) ? 'block' : 'none')}}/>
                  <Grid item xs={12} sm={4} className={classes.searchField}>
                    <PlaceField
                      name="to"
                      placeholder={this.state.to && this.state.to.length > 0 ? "Where to?" : "Anywhere"}
                      defaultValues={this.state.to}
                      limitTags={(isWidthUp('xs', this.props.width) && isWidthDown('xs', this.props.width) ? 3 : 1)}
                      onChange={this.handleChange.bind(this)}
                    />
                  </Grid>
                </Fragment>}
                {!this.props.hideFieldDates && <Fragment>
                  <Divider orientation="vertical" flexItem className={classes.divider} style={{display: (isWidthUp('sm', this.props.width) ? 'block' : 'none')}}/>
                  <Grid item xs={7} sm={!this.props.hideFieldTo ? 3 : 4}>
                    <CustomTab>
                      <DatesFilter
                        name="dates"
                        dates_type = {search_params && search_params.dates && search_params.dates.dates_type}
                        start_date = {search_params && search_params.dates && search_params.dates.start_date}
                        end_date = {search_params && search_params.dates && search_params.dates.end_date}
                        nights={search_params && search_params.dates && search_params.dates.nights}
                        onChange={this.handleChange}>
                      </DatesFilter>
                    </CustomTab>
                  </Grid>
                </Fragment>}
                <Divider orientation="vertical" flexItem className={classes.divider}  style={{display: (isWidthUp('sm', this.props.width) ? 'block' : (this.props.hideFieldDates ? 'none' : 'block'))}} />
                <Grid item xs={5}  sm={!this.props.hideFieldTo ? 2 : 3}>
                  <CustomTab>
                    <TravelersFilter
                      name="travelers"
                      adults={search_params && search_params.travelers && search_params.travelers.adults}
                      children={search_params && search_params.travelers && search_params.travelers.children}
                      infants={search_params && search_params.travelers && search_params.travelers.infants}
                      onChange={this.handleChange}>
                    </TravelersFilter>
                  </CustomTab>
                </Grid>
              </Grid>

              {/* <SearchBar
                onLocationChanged={this.props.onLocationChanged}
                onLocationCleared={this.props.onLocationCleared}
                address={startCase(this.props.address)}
                locationFrom={this.props.locationFrom ? `${kebabCase(this.props.locationFrom.split(',')[0])},${kebabCase(this.props.locationFrom.split(',')[1])}` : 'nearby'}
              /> */}
            </Grid>}

            {this.state.show_description && this.state.expanded && <Grid item xs={12} style={{marginTop: 10, borderTop: '1px solid #f2f2f2', paddingTop: 10}}>
              <Grid container spacing={1} alignItems="flex-start" style={{ maxWidth: 'calc(100% - 10px)' }}>
                <Grid item xs={12} className={classes.searchField}>
                  <div style={{padding: '6px 5px 0px 5px'}}>
                    <div className={classes.iconLabel}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>auto_awesome</Icon>Customize your trip</div>
                    <TextareaAutosize
                      id="trip_description"
                      aria-label="trip_description textarea"
                      placeholder="For example: Direct flight, cosy apartment, top 10 attractions"
                      variant="outlined"
                      name="trip_description"
                      minRows={3}
                      className={classes.textfieldArea}
                    />
                  </div>
                </Grid>
              </Grid>
            </Grid>}

            {!this.props.hideSearchButton && (this.state.expanded && <Grid item xs={12}>
              <Button variant="contained"
                color="primary"
                disableElevation
                className={classes.buttonSearch}
                component="a"
                // href={`/explore${this.props.locationFrom && this.props.locationFrom !== 'nearby' ? '/from/' + this.props.locationFrom : ''}`}
                alt="Search Button"
                aria-label="Search Button"
                onClick={this.handleSearch}
              >
                {!this.props.planner && <Icon className="notranslate">search</Icon>}
                {this.props.planner && <Icon className="notranslate">auto_fix_high</Icon>}
                <span style={{display: (isWidthUp('sm', this.props.width) ? 'none' : 'block'), fontSize: 12, fontWeight: 800, paddingLeft: 10, paddingRight: 5}}>
                {this.props.planner ? 'Start planning' : 'Search'}
                </span>
              </Button>
            </Grid>)}

          </Paper>
        </Grid>
      </Box>
    )
  }
}

const styles = theme => ({
  green:{
    color: '#02c39a',
  },
  paperBox: {
    width: '100%',
    maxWidth: 800,
    border: ' 1px solid #ddd',
    // borderColor: 'transparent',
    height: 'inherit',
    height: 63,
    marginBottom: '0px !important',
    zIndex: 10,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    borderRadius: 39,
    transition: 'all .2s linear',
    // transform: 'scale',
    // transformOrigin: 'top left',

    [theme.breakpoints.down('xs')]: {
      minHeight: 220,
      height: 'inherit',
      marginBottom: '0px !important',
      width: 'calc(100vw - 50px)',
    },
  },
  paperHeader: {
    width: 'auto',
    // border: ' 1px solid #eee',
    // marginBottom: 10,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    // top: 64,
    // margin: '0px auto',
    // maxWidth: 1130,
    maxWidth: '95%',
    borderBottomLeftRadius: 10,
    borderBottomRightRadius: 10,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    padding: '0px 5px 5px 22px',
    // borderTop: '1px solid #f5f5f5',
    // display: 'none',
  },
  search: {
    margin: 0,
    lineHeight: '20px',
    padding: '9px 15px 8px',
    fontWeight: 600,
    // borderBottom: '1px solid #f5f5f5',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  searchContainer: {
    maxWidth: 'calc(100% - 50px)',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 'inherit',
    },
  },
  searchField: {
    [theme.breakpoints.down('xs')]: {
      borderBottom: '1px solid #f2f2f2',
      marginBottom: 5,
    },
  },
  buttonSearch:{
    height: 54,
    width: 54,
    color: '#fff',
    borderRadius: 50,
    float: 'right',
    margin: '-3px 0px 0px 10px',
    padding: 10,
    zIndex: 1000,
    position: 'absolute',
    right: 14,
    top: 16,
    minWidth: 54,
    fontSize: 25,

    [theme.breakpoints.down('xs')]: {
      top: 'inherit',
      // bottom: -40,
      left: 0,
      marginTop: 15,
      position: 'relative',
      width: '100%',
      boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    },
  },
  tripOrigin: {
    color: '#828282',
    textTransform: 'capitalize',
    textAlign: 'left',
    border: '1px solid transparent',
    fontWeight: 600,
    // backgroundColor: '#eee',
    fontSize: 12,
    height: 40,
    verticalAlign: 'top',
    padding: '1px 8px 0px 8px',
    borderRadius: 25,
  },
  iconTripOrigin: {
    padding: '0 !important',
    fontSize: 18,
    marginTop: -3,
    marginRight: 5,
  },
  root: {
    fontSize: 12,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-flex',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    marginRight: 10,
    marginTop: 5,
    textAlign: 'left',
  },
  center: {
    maxWidth: 800,
    width: '100%',
    margin: '-50px auto 10px auto',

    [theme.breakpoints.down('sm')]: {
      margin: '-70px auto 10px auto',
      width: '100%',
    },

    [theme.breakpoints.down('xs')]: {
      margin: '25px auto 10px auto',
    },
  },
  divider: {
    height: 55,
    margin: '7px -1px 0px -10px',
    backgroundColor: '#f2f2f2',
  },
  miniPaperBox: {
    width: '100%',
    maxWidth: '500px !important',
    margin: '-70px auto 0px auto !important',
    border: ' 1px solid #ddd',
    zIndex: 10,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    borderRadius: 39,
    height: '23px !important',
    padding: '13px 15px 15px 15px',
    transition: 'all .2s linear',
    // transform: 'scale',
    // transformOrigin: 'top left',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '300px !important',
      margin: '-50px auto 0px auto !important',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '100% !important',
      margin: '0px auto 0px auto !important',
    },
  },
  miniSearch: {
    margin: '0px auto',
    lineHeight: '20px',
    padding: '8px 15px',
    fontWeight: 600,
    // borderBottom: '1px solid #f5f5f5',
    position: 'relative',

    [theme.breakpoints.down('xs')]: {
      padding: '15px',
    },
  },
  miniButtonSearch:{
    height: 54,
    width: 54,
    color: '#fff',
    borderRadius: 50,
    float: 'right',
    margin: '-3px 0px 0px 10px',
    padding: 10,
    zIndex: 1000,
    position: 'absolute',
    right: 14,
    top: 16,
    minWidth: 54,
    fontSize: 25,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -2,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconSeparator: {
    color: '#02c39a',
    fontSize: '6px',
    marginRight: -8,
    marginLeft: 3,
  },
  boxSearchContainer: {
    margin: '-3px auto 0px 0px',
    textAlign: 'center',
    padding: 5,
    display: 'flex',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    fontSize: 13,
    color: '#828282',
    left: 0,
    position: 'relative',
  },
  boxSearchPart: {
    float: 'left',
    marginRight: 10,
  },

  paperBoxInline: {
    width: '100%',
    maxWidth: 800,
    border: ' 1px solid #eee',
    // borderColor: 'transparent',
    height: 'inherit',
    height: 63,
    marginBottom: '0px !important',
    zIndex: 10,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    borderRadius: 39,
    transition: 'all .2s linear',
    // transform: 'scale',
    // transformOrigin: 'top left',

    [theme.breakpoints.down('xs')]: {
      minHeight: 220,
      height: 'inherit',
      // marginTop: '0px !important',
      marginBottom: '0px !important',
      width: 'calc(100vw - 50px)',
    },
  },
  miniPaperBoxInline: {
    width: '100%',
    // maxWidth: '500px !important',
    border: ' 1px solid #eee',
    zIndex: 10,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    borderRadius: 39,
    height: 23,
    padding: '13px 15px 15px 15px',
    transition: 'all .2s linear',
    // transform: 'scale',
    // transformOrigin: 'top left',
    cursor: 'pointer',

    [theme.breakpoints.down('sm')]: {
      maxWidth: '600px !important',
      margin: '0px auto 0px auto !important',
    },

    [theme.breakpoints.down('xs')]: {
      maxWidth: '600px !important',
      margin: '10px auto 0px auto !important',
    },
  },

  iconLabel: {
    color: '#333',
    fontSize: 12,
    fontWeight: 700,
    marginBottom: -4,
    marginLeft: 5,
  },
  textfieldArea: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '2px 0px 0px 0px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 5px',
    border: 'none',
    fontFamily: 'inherit',
    minHeight: 20,
    maxWidth: '100%',
    minWidth: '98%',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
})

export default withRouter(withStyles(styles)(withWidth()(SearchPanel)))