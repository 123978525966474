import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  ClickAwayListener,
  Divider,
  Icon,
  Popper,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
} from '@material-ui/core';
import { Fragment } from 'react';

const defaultProps = {
  adults: 1,
  children: 0,
  infants: 0,
}

const TRAVELERS_DEFAULT = 1

class TravelersFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adults: this.props.adults || TRAVELERS_DEFAULT,
      children: this.props.children || 0,
      infants: this.props.infants || 0,
      open: false
    };
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  }

  handleChange = () => {
    if (!this.props.readonly && (!this.props.updateAfterClose || (this.props.updateAfterClose === true && this.state.open === false))) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: {
            adults: this.state.adults,
            children: this.state.children,
            infants: this.state.infants,
          }
        }
      })
    }
  }

  handleClose = event => {
    this.setState({ open: false }, this.handleChange)
  }

  travelersCount() {
    return this.state.adults + this.state.children + this.state.infants
  }

  addCount(prop) {
    this.setState((prevState) => ({
      [prop] : (prevState[prop] + 1)
    }), this.handleChange)
  }

  reduceCount(prop) {
    this.setState((prevState) => ({
      [prop] : (prevState[prop] === defaultProps[prop] ? defaultProps[prop] : prevState[prop] - 1)
    }), this.handleChange)
  }

  renderButtonLabel() {
    return this.travelersCount() === 1 ? `1 ${this.props.compact ? 'traveler' : 'traveler'}` : `${this.travelersCount()} ${this.props.compact ? 'travelers' : 'travelers'}`
  }

  render() {
    const { classes } = this.props;
    const { open } = this.state;

    return(
      <div role="tab" className={classes.root} style={{width: '100%'}}>
        <Button
          buttonRef={node => { this.anchorEl = node; }}
          aria-owns={open ? 'travelers-list-grow' : null}
          aria-haspopup="true"
          name="Filter: Travelers"
          onClick={this.handleToggle}
          className={open ? classes.selected : classes.select}
          style={{padding: '7px 15px', display: 'block', width: '100%'}}
        >
          <div className={classes.iconLabel} style={this.props.compact ? {float: 'left', marginTop: 1, marginBottom: 0} : {marginLeft: 0, marginBottom: 0}}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>people</Icon>{!this.props.compact && ' Travelers'}</div>
          <div style={{maxWidth: (this.props.compact ? 100 : 'auto'), paddingLeft: (this.props.compact ? 22 : 0), color: (this.props.compact ? '#333' : '#828282'), whiteSpace: 'nowrap', fontSize: 13}}>
            {this.renderButtonLabel(this)}
          </div>
        </Button>
        <Popper open={this.state.open}
          anchorEl={this.anchorEl}
          className={classes.popper}
          placement="bottom-start"
          disablePortal={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'window',
            },
            arrow: {
              enabled: false,
              element: this.anchorEl,
            },
          }}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <List className={classes.list}>
              <ListItem style={{minHeight: 64}}>
                <ListItemText primary="Adults" secondary="" classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}/>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.reduceCount.bind(this, 'adults')}>remove_circle_outline</Icon>}
                <span className={classes.count}> {this.state.adults} </span>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.addCount.bind(this, 'adults')}>add_circle_outline</Icon>}
              </ListItem>
              <ListItem>
                <ListItemText primary="Children" secondary="Ages 2-12" classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}/>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.reduceCount.bind(this, 'children')}>remove_circle_outline</Icon>}
                <span className={classes.count}> {this.state.children} </span>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.addCount.bind(this, 'children')}>add_circle_outline</Icon>}
              </ListItem>
              <ListItem>
                <ListItemText primary="Infants" secondary="Under 2" classes={{ primary: classes.primaryText, secondary: classes.secondaryText }}/>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.reduceCount.bind(this, 'infants')}>remove_circle_outline</Icon>}
                <span className={classes.count}> {this.state.infants} </span>
                {!this.props.readonly && <Icon className={classes.iconPlus} onClick={this.addCount.bind(this, 'infants')}>add_circle_outline</Icon>}
              </ListItem>
              {!this.props.readonly && <Fragment>
                <Divider className={classes.divider}/>
                <ListItem style={{minHeight: 50}}>
                  <ListItemText primary=""/>
                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" size="medium" className={classes.buttonBook} onClick={this.handleClose}>
                      Apply
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </Fragment>}
            </List>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
}

const styles = theme => ({
  popper: {
    top: '4px !important',
    // left: '1px !important',
    // textAlign: 'left',
    zIndex: 100,
  },
  root: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'inline-flex',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    marginRight: 10,
    marginTop: 0,
    textAlign: 'left',
  },
  // root: {
  //   fontSize: 12,
  //   color: 'rgba(0, 0, 0, 0.87)',
  //   display: 'inline-flex',
  //   position: 'relative',
  //   fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
  //   lineHeight: '1.4em',
  //   textAlign: 'left',
  //   marginRight: 10,
  //   marginTop: 5,
  // },
  select: {
    color: '#828282',
    fontWeight: 600,
    // backgroundColor: '#eee',
    fontSize: 12,
    padding: '10px 15px 10px',
    borderRadius: 25,
    // border: '1px solid #eee',
    // height: 40,
    textTransform: 'none !important',
    textAlign: 'left',
    minWidth: 130,

    '&:focus': {
      borderRadius: 25,
    }
  },
  selected: {
    color: '#333',
    fontWeight: 600,
    fontSize: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: '10px 15px 10px',
    // border: '1px solid #eee',
    // height: 40,
    borderRadius: 25,
    textTransform: 'none !important',
    textAlign: 'left',
    minWidth: 130,

    '&:focus': {
      // backgroundColor: '#5ad076',
      borderRadius: 25,
    },
    '&~svg': {
      color: '#fff',
    },
    '&:hover': {
      // backgroundColor: '#5ad076',
      borderRadius: 25,
      color: '#333',
    },
  },
  list: {
    maxWidth: 360,
    minWidth: 220,
    // boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    // borderRadius: 3,
    backgroundColor: '#fff',
    padding: '5px 0px 0px 0px',
    marginRight: 10,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    border: '1px solid #f5f5f5',
    borderRadius: 25,
    fontWeight: 600,
  },
  primaryText: {
    fontSize: 13,
    fontWeight: 600,
  },
  secondaryText: {
    fontSize: 12,
    fontWeight: 500,
  },
  count: {
    padding: '0px 5px',
    fontWeight: 600,
    color: '#333',
    width: 21,
    textAlign: 'center',
    fontSize: 14,
  },
  iconLabel: {
    color: '#333',
    fontWeight: 700,
    marginBottom: 2,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconPlus: {
    color: '#ccc',
    cursor: 'pointer',
  },
  iconArrow: {
    margin: '0 0 0 3px',
    fontSize: 18,
    padding: '0px !important',
  },
  buttonIcon: {
    marginRight: 5,
    fontSize: 18,
    marginTop: -3,
    padding: '0px !important',
  },
  buttonBook: {
    float: 'right',
    color: '#fff',
    margin: '5px 0px 5px 0px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    fontSize: 13,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  divider: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    marginTop: 0,
  },
});

export default withStyles(styles)(TravelersFilter);