import React, { Component, Fragment } from 'react'
import moment from 'moment'
import Cookies from 'universal-cookie'
import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  CardActionArea,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  ListSubheader,
  Paper,
  Typography,
  Grid,
  withStyles,
} from '@material-ui/core';
import { Skeleton } from '@material-ui/lab'
import ReactPixel from 'react-facebook-pixel'
import TripHeader from './TripHeader'
import Itinerary from '../itinerary/Itinerary'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();


class TripBookingBar extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showMobileDialog: true,
      showBookingMobileDialog: false,
      trip_id: props.trip_id,
    }
  }

  openBookTravelLink = () => {
    let flight = this.props.flight

    window.gtag('event', 'purchase', {
      transaction_id: `transportation_${this.state.trip_id}_${(flight ? flight.id : '')}`,
      value: (flight ? Math.round(flight.price * 0.03) : 0),
      currency: this.props.user && (this.props.user.currency || 'USD'),
      items: [{
        item_id: (flight ? flight.id : ''),
        item_name: (flight ? `${flight.cityFrom} ->  ${flight.cityTo}` : ''),
        item_brand: 'Kiwi',
        item_category: 'Transportation',
        price: (flight ? Math.round(flight.price * 0.03) : 0),
      }]
    });
    ReactPixel.track('Purchase', {
      currency: this.props.user && (this.props.user.currency || 'USD'),
      value: (flight ? Math.round(flight.price * 0.03) : 0)
    })
  }

  openBookStayLink = () => {
    let hotel = this.props.hotel

    window.gtag('event', 'purchase', {
      transaction_id: `stay_${this.state.trip_id}_${(hotel ? hotel.hotel_id : '')}`,
      value: (hotel ? Math.round(hotel.total_price * 0.04) : 0),
      currency: (hotel ? hotel.currency : 'USD'),
      items: [{
        item_id: (hotel ? hotel.hotel_name : ''),
        item_name: (hotel ? hotel.hotel_name : ''),
        item_brand: 'Agoda',
        item_category: 'Stay',
        price: (hotel ? Math.round(hotel.total_price * 0.04) : ''),
      }]
    });
    ReactPixel.track('Purchase', {
      currency: (hotel && hotel.currency ? hotel.currency : 'USD'),
      value: (hotel ? Math.round(hotel.total_price * 0.04) : 0)
    })
  }

  totalStayPrice(hotel, stayPricePerNight, nights, travelers) {
    if (hotel.is_hotel) {
      return ((stayPricePerNight / 2 * Math.max(((travelers > 2 && travelers % 2 !== 0) ? travelers - 1 : travelers), 2)) + ((travelers > 2 && travelers % 2 !== 0) ? stayPricePerNight : 0)) * nights
    } else {
      return stayPricePerNight * travelers * nights
    }
  }

  render() {
    const { classes, destination, flight, hotel, hotelBookingLink, isLoading } = this.props;
    const { showMobileDialog, showBookingMobileDialog } = this.state

    const adults = (destination && destination.adults) || 1
    const children = (destination && destination.children) || 0
    const travelers = adults + children
    const travelPrice = (flight ? flight.price : 0)

    const stayPricePerNight = (hotel ? hotel.price_per_night : 0)
    const stayPrice = hotel && hotel.total_price ? hotel.total_price : (destination && hotel && this.totalStayPrice(hotel, stayPricePerNight, destination.nights_in_dest, travelers)) || 0
    const totalPrice = travelPrice + stayPrice

    return(
      <Fragment>
        {showBookingMobileDialog && <Grid container alignItems={'center'} justify={'center'} className={showMobileDialog ? classes.bookingMobileDialog : ''} style={{maxWidth: 830, margin: 'auto', left: 0, right: 0}}>
          <Backdrop className={classes.backdrop} open={showMobileDialog} onClick={() => this.setState({showBookingMobileDialog: false})}></Backdrop>
          <Grid item xs={12} md={11} style={{ margin: 'auto auto -10px auto'}}>
            <Paper className={`${classes.paper} ${classes.bookingPaper}`} elevation={0}>
              {showMobileDialog &&
                <Grid item xs={12} className={classes.header} style={{borderBottom: 'none', minHeight: 50}}>
                  <Button variant="contained" className={classes.buttonClose} onClick={() => this.setState({showBookingMobileDialog: false})}>
                    <Icon>close</Icon>
                    Close
                  </Button>
                </Grid>}

                <Grid container direction="row" justify="flex-end" alignItems="center" className={classes.header}>
                  <Grid item xs={12}>
                    <Typography className={classes.sectionBooking} style={{padding: 20}}>
                      Booking options
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <List component="nav" className={classes.bookingList}>

                      {flight && <Fragment>
                        <Divider className={classes.divider}/>
                        <ListItem className={classes.bookingListItem}>
                          <ListItemText primary={'Travel: ' + flight.cityFrom + ' -> ' + flight.cityTo} secondary={'Book with Kiwi.com'} classes={{primary: classes.bookingItemSecondary , secondary: classes.bookingItemPrimary}}/>
                          <ListItemSecondaryAction className={classes.bookingItemPrimary}>
                            <Button variant="text" color="primary" size="medium" className={classes.buttonCancel} rel="noopener noreferrer" onClick={() => this.openBookTravelLink()} target="_blank" href={flight.deep_link}>
                              Open
                              <Icon className={`material-icons-outlined notranslate`} style={{marginLeft: 10}}>launch</Icon>
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Fragment>}

                      {hotel && <Fragment>
                        <Divider className={classes.divider}/>
                        <ListItem className={classes.bookingListItem}>
                          <ListItemText primary={`Stay in ${hotel.city_name}`} secondary={'Book with Agoda.com'} classes={{primary: classes.bookingItemSecondary, secondary: classes.bookingItemPrimary}}/>
                          <ListItemSecondaryAction className={classes.bookingItemPrimary}>
                            <Button variant="text" color="primary" size="medium" className={classes.buttonCancel} rel="noopener noreferrer" onClick={() => this.openBookStayLink()} target="_blank" href={hotelBookingLink}>
                              Open
                              <Icon className={`material-icons-outlined notranslate`} style={{marginLeft: 10}}>launch</Icon>
                            </Button>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </Fragment>}

                      <Divider className={classes.divider}/>
                      <ListItem className={classes.bookingListItem} style={{minHeight: 40}}>
                        <ListItemSecondaryAction className={classes.bookingItemPrimary}></ListItemSecondaryAction>
                      </ListItem>

                    </List>
                  </Grid>
                </Grid>

            </Paper>
          </Grid>
        </Grid>}

        <Box display={{ xs: 'block', md: 'block' }}>
          <Paper className={classes.bookingMobilePaper} elevation={0}>
            <Grid container style={{maxWidth: 1130, margin: 'auto'}}>
            {!this.state.isLoading ?
              (totalPrice > 0 && <Grid item xs={12} md={8} className={classes.header} style={{margin: 'auto'}}>

                <Fragment>
                  {(flight || hotel) &&
                    <Button variant="contained" color="primary" size="large" className={classes.buttonReserve} onClick={() => this.setState({showBookingMobileDialog: true})}>Reserve</Button>
                  }
                </Fragment>

                <Typography className={classes.section} style={{fontSize: 17, border: 'none', padding: '10px', fontWeight: 800}}>
                  {(flight || hotel) &&
                    <Fragment>
                      {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.props.search_params.currency || 'USD'}).format(Math.ceil(totalPrice / travelers))}
                      <small style={{fontWeight: 600, color: '#333'}}> / person</small>
                    </Fragment>
                  }
                  <br/>
                  <small style={{fontWeight: 700, color: '#333', marginTop: 5, display: 'block'}}>
                    <Fragment>{moment(destination.start_date).format('MMM DD - ')} {moment(destination.end_date).format('MMM DD')}</Fragment>,
                    <Icon className={`material-icons-outlined notranslate ${classes.bookingPeopleIcon}`}>people</Icon> {travelers}
                    {/* <Icon className={`material-icons-outlined notranslate`} style={{fontSize: 14, marginLeft: 5}}>edit</Icon> */}
                  </small>
                </Typography>
              </Grid>)
            :
              <Grid item xs={12} md={8} className={classes.header} style={{margin: 'auto'}}>
                <Typography className={classes.section} style={{fontSize: 18, border: 'none', padding: '15px 20px'}}>
                  <small style={{fontWeight: 500, color: '#333'}}>{this.state.isLoading}</small>
                  <br/>
                  <Skeleton variant="rect" height={15} width={'100px'} className={classes.skeleton} style={{margin: '8px 0px 0px 0px'}}/>
                </Typography>
              </Grid>
            }
            </Grid>
          </Paper>
        </Box>
      </Fragment>
    )
  }
}

const styles = theme => ({
  bookingContainer: {
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  bookingPaper: {
    // boxShadow: 'none',
    border: '1px solid #ddd',
    margin: '0px 0px 0px 0px',
    fontSize: 16,

    // [theme.breakpoints.down('sm')]: {
    //   margin: '0px 0px 0px -11px',
    // },
  },
  bookingList: {
    padding: '0px !important',
    margin: '0px 0px 10px 0px',
  },
  bookingListItem: {
    color: '#333',
    padding: '10px 20px',
  },
  bookingItemPrimary: {
    fontSize: 13,
    fontWeight: 600,
    color: '#999'
  },
  bookingItemSecondary: {
    fontSize: 14,
    fontWeight: 500,
    color: '#000',
    maxWidth: '70%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  bookingItemTotal: {
    fontSize: 15,
    fontWeight: 800,
  },
  bookingMenuLink: {
    textDecoration: 'none',
  },
  bookingFilters: {
    padding: 5,
    backgroundColor: '#f5f5f5',
    // border: '1px solid #f2f2f2',
    width: 'calc(100% - 20px)',
    margin: '0px auto 10px auto',
    borderRadius: 15
  },
  bookingMobilePaper: {
    // boxShadow: 'none',
    borderTop: '1px solid #eee',
    borderRadius: 0,
    margin: 0,
    fontSize: 16,
    width: '100vw',
    position: 'fixed',
    bottom: 0,
    left: 0,
    display: 'block',
    zIndex: 99,
    boxShadow: '0 -0.5px 11px 0 rgba(0, 0, 0, 0.08)',
  },
  bookingPeopleIcon: {
    margin: '3px 0px 0px 7px',
    padding: 0,
    verticalAlign: 'top',
    fontSize: 15,
    // borderBottom: '1px solid #000',
  },
  bookingMobileDialog: {
    position: 'fixed',
    bottom: -9,
    left: -1,
    display: 'block',
    zIndex: 100,
    transition: 'all 2s ease 0s',
  },
  sectionBooking: {
    fontSize: 18.5,
    fontWeight: 700,
    lineHeight: '18px',
    padding: '20px 20px 10px 20px',
    marginTop: -60,

    '& small': {
      color: '#999',
      fontSize: '80%',
    },

    [theme.breakpoints.down('sm')]: {
      marginTop: -60,
    },
  },
  divider: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    marginTop: 10,
  },
  skeleton: {
    borderRadius: 10,
  },
  buttonReserve: {
    float: 'right',
    color: '#fff',
    margin: '15px 10px 10px 5px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonClose: {
    float: 'right',
    color: '#333',
    margin: 10,
    backgroundColor: '#fff',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 600,
    padding: 9,
    minWidth: 50,

    '& .material-icons': {
      fontSize: 20,
      marginRight: 5,
    },

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: 'none',
      boxShadow: 'none',
    }
  },
});

export default withStyles(styles)(TripBookingBar);