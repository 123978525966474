import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Badge,
  Button,
  CardMedia,
  Divider,
  Icon,
  IconButton,
  Grid,
  Slider,
  Tooltip,
  Typography,
} from '@material-ui/core'
import Fade from '@material-ui/core/Fade'
import { CUISINES, THINGS_TO_DO } from '../../utils/lists'


class Score extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  foodItem = (name) => {
    return CUISINES.filter((cuisine) => cuisine.value === name)[0]
  }

  activityItem = (name) => {
    return THINGS_TO_DO.filter((activity) => activity.value === name)[0]
  }

  render() {
    const { classes, score, size } = this.props

    return (
      <Tooltip arrow placement="bottom-end" disableFocusListener TransitionComponent={Fade} classes={{tooltip: classes.scoreTooltip, arrow: classes.scoreArrow}} onClick={(e) => e.stopPropagation()} enterTouchDelay={50} title={
        score !== undefined && score !== null ?
          score.v1 ?
            <Fragment>
              <Typography component='div' style={{textAlign: 'left', marginBottom: 10}}>
                <h4 style={{margin: '0px 0px 15px 0px', color: '#fff', fontSize: 14, fontWeight: 700}}>
                  Destination score <br/>
                  <small style={{color: 'rgba(255, 255, 255, 0.7)'}}>your travel style vs. destination</small>
                </h4>
                <Divider classes={{root: classes.scoreDivider}}/>
              </Typography>
              <Typography component="div" style={{textAlign: 'left'}}>
                <h4 style={{margin: '0px 0px 5px 0px', color: '#fff', fontSize: 14, fontWeight: 700}}>Vibes</h4>
                <Grid container>
                  <Grid item xs={10}>
                    <Slider
                      classes={{thumb: classes.vibeThumb, mark: classes.vibeMark, root: classes.vibeRoot, rail: classes.vibeRail, track: classes.vibeTrack}}
                      min={0.0}
                      max={10.0}
                      defaultValue={score.v1.vibes_similarity}
                      disabled
                    />
                  </Grid>
                  <Grid item xs={2} style={{textAlign: 'right', fontSize: 14, fontWeight: 600, color: '#fff', verticalAlign: 'middle', paddingTop: 3}}>
                    {score.v1.vibes_similarity || '0.0'}
                  </Grid>
                </Grid>
              </Typography>
              <Typography component='div' style={{textAlign: 'left'}}>
                <h4 style={{margin: '10px 0px 10px 0px', color: '#fff', fontSize: 14, fontWeight: 700}}>Activities</h4>
              </Typography>
              <Grid container alignItems={'flex-start'} style={{textAlign: 'left'}}>
                {score.v1.things_to_do.matched.map((name, i) => {
                  const item = this.activityItem(name)
                  return <Grid key={`ma_${i}`} item xs={6}>
                    <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>check</Icon>
                    {item.title}
                  </Grid>
                })} <br/>
                {score.v1.things_to_do.missing.map((name, i) => {
                  const item = this.activityItem(name)
                  return <Grid key={`mi_${i}`} item xs={6}>
                  <Icon className={`material-icons-outlined notranslate ${classes.iconRed}`}>clear</Icon>
                  {item.title}
                </Grid>
                })}
              </Grid>
            </Fragment>
          :
            <Fragment>
              <Typography component='div' style={{textAlign: 'left', marginBottom: 10}}>
                <h4 style={{margin: '0px 0px 15px 0px', color: '#fff', fontSize: 14, fontWeight: 700}}>
                  Destination score <br/>
                  <small style={{color: 'rgba(255, 255, 255, 0.7)'}}>your travel style vs. destination</small>
                </h4>
                <Divider classes={{root: classes.scoreDivider}}/>
              </Typography>
              <Grid container alignItems={'flex-start'}>
                <Grid item xs={12}>
                  <Icon className={`material-icons-outlined notranslate ${classes.iconWhite}`}>insights</Icon>
                </Grid>
                <Grid item xs={12} style={{textAlign: 'center', padding: 10, marginBottom: 20}}>
                  Insights are not available
                </Grid>
              </Grid>
            </Fragment>
        :
          <Fragment>
            <Typography component='div' style={{textAlign: 'left', marginBottom: 10}}>
              <h4 style={{margin: '0px 0px 15px 0px', color: '#fff', fontSize: 14, fontWeight: 700}}>
                Destination score <br/>
                <small style={{color: 'rgba(255, 255, 255, 0.7)'}}>your travel style vs. destination</small>
              </h4>
              <Divider classes={{root: classes.scoreDivider}}/>
            </Typography>
            <Grid container alignItems={'flex-start'}>
              <Grid item xs={12}>
                <Icon className={`material-icons-outlined notranslate ${classes.iconWhite}`}>lock</Icon>
              </Grid>
              <Grid item xs={12} style={{textAlign: 'center', color: '#fff', padding: 10, marginBottom: 20}}>
                Find out details how does the destination match to your travel style.
                <span style={{color: '#2ac8aa'}}>Take the test and sign in</span>
              </Grid>
            </Grid>
          </Fragment>
      }>
      <IconButton aria-label={"Score"} className={`${classes.scoreIcon} ${size === 'large' ? classes.scoreIconLarge : ''} ${score && score.v1 && score.v1.label === 'low' ? classes.scoreLowIcon : (score && score.v1 && score.v1.label === 'medium' ? classes.scoreMediumIcon : (score && score.v1 && score.v1.label === 'high' ? classes.scoreHighIcon : ''))}`} style={{float: 'right'}}>
        <span>{score && score.v1 && score.v1.value > 0 ? score.v1.value : '-' }</span>
      </IconButton>
    </Tooltip>
    )
  }
}

const styles = theme => ({
  scoreIcon: {
    position: 'absolute',
    right: 18,
    borderRadius: 45,
    border: '3px solid #e0e0e0',
    backgroundColor: '#fff',
    marginTop: 5,
    opacity: 1,
    height: 40,
    width: 40,
    fontSize: '15px',
    fontWeight: 800,
    color: '#333',
    padding: '12px 12px 12px 13px',

    '&:hover': {
      backgroundColor: '#f2f2f2',
    },
  },
  scoreIconLarge: {
    marginTop: -13,
    padding: '20px 20px 20px 21px',
  },
  scoreHighIcon: {
    borderColor: '#1ac8a3',
  },
  scoreMediumIcon: {
    borderColor: '#feae3f',
  },
  scoreLowIcon: {
    borderColor: '#fd7879',
  },
  scoreTooltip: {
    maxWidth: '313px',
    fontSize: 13,
    backgroundColor: '#172327',
    borderRadius: 8,
    color: 'rgba(255, 255, 255, 0.7)',
    padding: 15,
  },
  scoreArrow: {
    color: '#172327',
  },
  scoreDivider: {
    backgroundColor: 'rgba(255, 255, 255, 0.1) !important',
  },
  vibeThumb: {
    display: 'none',
  },
  vibeMark: {
    display: 'none',
  },
  vibeRail: {
    height: 5,
    borderRadius: 15,
    backgroundColor: 'rgba(255, 255, 255, 0.2)',
  },
  vibeTrack: {
    height: 5,
    borderRadius: 15,
    backgroundColor: '#1ac8a3',
  },
  icon: {
    marginRight: 5,
    marginBottom: 3,
    fontSize: '16px',
    verticalAlign: 'middle',
    color: '#02c39a',
    fontWeight: 600,
  },
  iconRed: {
    marginRight: 5,
    marginBottom: 3,
    fontSize: '16px',
    verticalAlign: 'middle',
    color: '#fd7979',
    fontWeight: 600,
  },
  iconWhite: {
    fontSize: '45px',
    margin: '20px auto',
    display: 'block',
    verticalAlign: 'middle',
    color: '#fff',
    fontWeight: 400,
  },
})

export default withRouter(withStyles(styles)(Score))
