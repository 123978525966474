import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Typography,
  withStyles,
} from '@material-ui/core';
import { config } from '../config.js'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

class Auth extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trips: [],
      error: false,
    }
  }

  componentDidMount() {
    if (this.props.jwt) {

      const jwt = this.props.jwt

      cookies.set('jwt', this.props.jwt, {
        path: '/',
        domain: config.api.DOMAIN,
        expires: new Date(new Date().setFullYear(new Date().getFullYear() + 1)),
      });

      if (window && window.opener) {
        window.opener.postMessage(
          {
            jwt,
            success: true,
          },
          '*'
        );
      }

      window.close();
    }
  }

  render() {
    const { classes, jwt } = this.props;

    return (
      <Fragment>
        {jwt ? (
          <Typography component={'h4'}>Loading...</Typography>
        ) : (
          <Typography component={'h4'}>Authentication failed</Typography>
        )}
      </Fragment>
    )
  }
}

const styles = theme => ({

})

export default withStyles(styles)(withRouter(Auth));