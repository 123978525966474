const dev = {
  env: 'development',
  api: {
    TRAVEL_URL: "//localhost:5000",
    DOMAIN: "localhost"
  },
  stripe: {
    PRICING_TABLE_ID: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  },
  ui: {
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  },
};

const prod = {
  env: 'production',
  api: {
    TRAVEL_URL: "https://api.travelities.com",
    DOMAIN: ".travelities.com"
  },
  stripe: {
    PRICING_TABLE_ID: process.env.REACT_APP_STRIPE_PRICING_TABLE_ID,
    API_KEY: process.env.REACT_APP_STRIPE_API_KEY,
  },
  ui: {
    GOOGLE_MAPS_API_KEY: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  },
};

export const config = process.env.REACT_APP_STAGE === 'production' ? prod : dev;