import {
  Avatar,
  Button,
  Box,
  Chip,
  Divider,
  Grid,
  Hidden,
  Icon,
  Paper,
  Tabs,
  Tab,
  Tooltip,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp, isWidthDown } from '@material-ui/core/withWidth'
import { lowerCase, map, startCase } from 'lodash'
import React, { Component, Fragment } from 'react'
import ReactPixel from 'react-facebook-pixel'
import Helmet from 'react-helmet'
import InfiniteScroll from 'react-infinite-scroll-component'
import { withRouter } from 'react-router-dom'
import Cookies from 'universal-cookie'
import AppTopBar from '../components/AppTopBar'
import DestinationList from '../components/lists/DestinationList'
import SignupBanner from '../components/forms/SignupBanner'
import PhotoCard from '../components/cards/PhotoCard'
import SearchPanel from '../components/SearchPanel'
import { config } from '../config.js'
import { THINGS_TO_DO, INTERESTS, TRAVEL_RESTRICTIONS, DISTANCE } from '../utils/lists'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();
let abortController = new AbortController()

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class Explore extends Component {
  constructor(props) {
    super(props)
    this.state = {
      categories: INTERESTS,
      address: '',
      countryCode: '',
      addressType: '',
      search_params: {
        ...this.props.search_params,
        interests: this.props.interests || 'all',
      },
      filterDefaults: {
        saved: [],
        things_to_do: ['all'],
        budget: 4000,
        weather: [-20, 40],
        distance: [],
      },
      filters: {},
      group: this.props.interests || 'all',
      from_address: this.props.locationFrom,
      page: 0,
      destination_groups: [],
      headline: '',
      subtitle: '',
      page_banner_image: '',
      hasMore: true,
      isLoading: false,
      tripModalOpen: false,
      showFilters: false,
      showFiltersCount: 0,
    }
  }

  // handleClickOpen = (params) => {
  //   this.setState({tripModalOpen: true});
  // };

  // handleClose = (params) => {
  //   this.setState({tripModalOpen: false});
  // };

  componentDidMount() {
    this.getDestinations()
    ReactPixel.pageView()
  }

  componentDidUpdate(prevProps) {
    if ((JSON.stringify(prevProps.search_params) !== JSON.stringify(this.props.search_params)) || (prevProps.interests !== this.props.interests)) {
      this.setState({
        group: this.props.interests || 'all',
        page: 0,
        destination_groups: [],
        isLoading: true,
      }, this.getDestinations)
    }
  }

  handleFiltersChange = (event) => {
    // console.log({ filters: {...this.state.filters, ...event.target.value} })
    let filters = {...this.state.filters, ...event.target.value}

    this.setState({
      filters: filters,
      page: 0,
      destination_groups: [],
    }, this.getDestinations);

    // update filters count
    let count = 0
    let defaults = this.state.filterDefaults

    Object.keys(filters).forEach(function(key) {
      if (JSON.stringify(filters[key]) !== JSON.stringify(defaults[key])) {
        count += 1
      }
    });

    this.setState({showFiltersCount: count})
  }

  clearFilters = () => {
    this.setState({
      filters: {},
      page: 0,
      destination_groups: [],
      showFiltersCount: 0,
    }, () => {
      this.props.history.push(`/explore`)
      this.getDestinations()
    });
  }

  handleSelectedInterest = (interests) => {
    let location_from = (this.props.search_params.from ? this.props.search_params.from[0] : null)
    let location_to = (this.props.search_params.to ? this.props.search_params.to[0] : null)

    this.setState(prevState => ({
      group: interests,
      search_params: {...this.state.search_params, ...{interests}},
      filters: {...this.state.filters, ...{things_to_do: interests}},
      destination_groups: [],
      destinations_nearby: [],
      page: 0,
      hasNextPage: true
    }), () => {
      this.props.history.push(`/explore/${interests && interests.length > 0 ? interests + '/' : ''}${location_to && location_to.location_type === 'country' ? 'in/' + location_to.country + '/' : ''}${((location_from && location_from.slug) || this.props.locationFrom) ? 'from/' + ((location_from && location_from.slug) || this.props.locationFrom) : ''}`)
      this.getDestinations()
    })
  }

  handleReloadResults = () => {
    this.setState({
      page: 0,
      destination_groups: [],
      isLoading: true,
    }, this.getDestinations)
  }

  handleHomeRedirect = () => {
    this.props.onLocationCleared()
    this.setState({
      destination_groups: [],
      isLoading: false,
      address: '',
      addressType: 'city',
    }, this.props.history.push('/'))
  }

  handleUnknownDestination = () => {
    this.setState({notFoundDestination: true})
  }

  showRecommendations = () => {
    this.setState({notFoundDestination: false})
  }

  saveFilterSettings(params) {
    this.setState({
      search_params: {...this.state.search_params, ...params}
    }, this.handleExplore)

    this.props.onFilterChange(params)
  }

  saveLocationFrom(params) {
    this.setState({...this.state, ...params})
  }

  getDestinations = () => {
    let location_from = (this.props.search_params.from ? this.props.search_params.from[0] : null)
    let location_to = (this.props.search_params.to ? this.props.search_params.to[0] : null)
    let multiple_locations = this.props.search_params.to && this.props.search_params.to.length > 1
    let { dates, travelers, interests, travel_type } = this.props.search_params
    let { filters } = this.state
    let travel_preference = (this.state.group === 'personalized' ? this.props.user.travel_preference : null)

    window.gtag('event', 'explore', {group: this.state.group});

    if (this.state.group !== 'personalized' || (this.state.group === 'personalized' && this.props.user.is_registered) || (travel_preference !== null && travel_preference !== undefined)) {
      this.setState({isLoading: true})

      let url = API + `/destinations/recommendations/${(['multiple', 'personalized', 'popular', 'all', 'discover-weekly', 'my-trips', 'photos', 'recently', 'itineraries', 'top'].includes(this.props.interests) ? this.props.interests : 'all')}` +
      (multiple_locations ? ''
        :
        '?adults=' + travelers.adults +
        '&children=' + travelers.children +
        '&infants=' + travelers.infants +
        (dates && dates.dates_type ? '&dates_type=' + dates.dates_type : '') +
        (dates && dates.start_date ? '&start_date=' + dates.start_date : '') +
        (dates && dates.end_date ? '&end_date=' + dates.end_date : '') +
        (dates && dates.nights ? '&nights=' + dates.nights : '') +
        '&country=' + (this.props.country || (location_to && location_to.location_type === 'country' ? location_to.country : '')) +
        (location_to && location_to.country_code ? '&country_code=' + location_to.country_code : '') +
        '&location_from=' + ((location_from && location_from.slug) || this.props.locationFrom || 'nearby') +
        (location_from && location_from.latitude ? '&latitude_from=' + location_from.latitude : '') +
        (location_from && location_from.longitude ? '&longitude_from=' + location_from.longitude : '') +
        (this.props.tags ? '&tags=' + this.props.tags : '') +
        (this.props.destination ? '&destination=' + this.props.destination : '') +
        (travel_preference && travel_preference.things_to_do !== undefined ? '&things_to_do=' + travel_preference.things_to_do.join(',') : '') +
        (travel_preference && travel_preference.food_cuisine !== undefined ? '&food_cuisine=' + travel_preference.food_cuisine.join(',') : '') +
        (travel_preference ? (Object.entries(travel_preference).map( ([key, value]) => { return ['things_to_do', 'food_cuisine'].includes(key) ? '' : `&${key}=${value}` }).join('')) : '') +
        (filters && filters.budget !== undefined ? '&budget=' + filters.budget : '') +
        (filters && filters.distance !== undefined ? '&distance=' + filters.distance : '') +
        (filters && filters.weather !== undefined ? '&weather=' + filters.weather.join(',') : '') +
        (filters && filters.things_to_do !== undefined && !filters.things_to_do.includes('all') ? '&things_to_do=' + filters.things_to_do : '') +
        (filters && filters.travel_mode !== undefined ? '&travel_mode=' + filters.travel_mode.join(',') : '') +
        (filters && filters.saved !== undefined && filters.saved.length > 0 ? '&saved=' + filters.saved.join(',') : '') +
        (this.props.user ? '&curr=' + (this.props.user.currency || 'USD') : '') +
        '&page_size=' + 24 +
        '&page=' + (this.state.page + 1)
      )

      // abort previous fetch
      abortController.abort()
      abortController = new AbortController();
      const signal = abortController.signal

      let options = {
        method: multiple_locations ? 'POST' : 'GET',
        dataType: 'json',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${cookies.get('jwt')}`,
        },
        signal: signal,
      }

      if (multiple_locations) {
        options["body"] = JSON.stringify({
          page: (this.state.page + 1),
          interests: this.props.search_params.interests,
          from: this.props.search_params.from,
          to: this.props.search_params.to,
          curr: (this.props.user && this.props.user.currency || 'USD'),
          ...this.props.search_params.dates,
          ...this.props.search_params.travelers,
        })
      }

      window.fetch(url, options)
      .then(response => response.json())
      .then(data => {
        if (data.params) {
          let group = data.destination_groups[0]
          let current_group = (Array.isArray(this.state.group) ? this.state.group[0] : this.state.group)

          if (group && data.params.group === current_group) {
            this.setState(prevState => ({
              headline: data.page_details.headline ? data.page_details.headline : group.title,
              subtitle: data.page_details.subtitle ? data.page_details.subtitle : group.description,
              page_banner_image: data.page_details.page_banner_image ? data.page_details.page_banner_image : '',
              isLoading: false,
              destination_groups: prevState.page === 0 ? data.destination_groups : [...prevState.destination_groups, ...data.destination_groups],
              hasMore: data.destination_groups.length > 0 ? true : false,
              page: prevState.page + 1,
            }))
            // this.saveLocationFrom({ from_address: data.params.from_address.toLowerCase().replace(', ', ',') })
          } else {
            this.setState({ isLoading: false, hasMore: false })
          }
        } else {
          this.setState({isLoading: false, hasMore: false})
        }
      })
      .catch(e => {});
    } else {
      this.setState({isLoading: false})
    }
  }

  handleHomeRedirect = () => {
    this.props.onLocationCleared()
    this.props.history.push('/search/city/from/nearby')
  }

  handleSigninChange = (flag, dialogType = 'signup') => {
    this.setState({
      openedSigninDialog: flag,
      openedSigninDialogType: dialogType
    })
  }

  render() {
    const { classes } = this.props
    let group = this.state.categories.filter(category => (category.value === this.props.interests))[0]
    let from = this.state.from_address ? this.state.from_address.replace(/,/g, ', ').replace(/-/g, ' ').split(', ').map(a => startCase(a)).join(', ') : ''
    const group_title = group ? (group.header ? group.header : group.title) : ''


    return (
      <Fragment>
        <Helmet>
          <title>{`Explore ${startCase(this.state.group === 'history' ? 'Historical Towns' : this.state.group)} ${this.props.address && this.props.addressType === 'country' ? 'in ' + startCase(this.props.address) : ''}`}</title>
          <link rel="canonical" href={`https://www${config.api.DOMAIN}/explore${this.state.group && (this.state.group === 'all' ? '' : '/' + this.state.group)}${(this.props.locationFrom && this.props.locationFrom !== 'nearby') ? '/from/' + this.props.locationFrom : ''}`} />
        </Helmet>

        <AppTopBar
          hideLogo={false}
          hideMenu={false}
          showBackButton={false}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.props.onSignin}
          onCurrencyChange={this.handleReloadResults}
          user={this.props.user}
          locationFrom={this.state.from_address}
          tabSelected={this.props.tabSelected || 'home'}
          openedSigninDialog={this.state.openedSigninDialog}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations explore">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 8, backgroundColor: '#fafafa'}}>
              <h1 className={classes.headline}>
              {(() => {
                if (this.props.interests === 'my-trips') {
                  return <Fragment>
                    {/* <strong className="main-title" style={{fontWeight: 800}}>My trips <br/></strong>
                    <small className={classes.subtitle}>Keep track of your upcoming trips</small> */}
                  </Fragment>

                } else if (this.props.interests === 'discover-weekly') {
                  return <Fragment>
                    {/* <strong className="main-title" style={{fontWeight: 800}}>Discover weekly <br/></strong>
                    <small className={classes.subtitle}>Explore destinations just for you every week</small> */}
                  </Fragment>
                } else if (this.props.interests === 'photos') {
                  return <Fragment>
                    {/* <strong className="main-title" style={{fontWeight: 800}}>Photos <br/></strong>
                    <small className={classes.subtitle}>Explore inspirational destinations</small> */}
                  </Fragment>
                } else {
                  return <Fragment>
                    {/* <strong className="main-title" style={{fontWeight: 800}}>Recommended<br/></strong>
                    <small className={classes.subtitle} style={{marginBottom: 50}}>
                      &nbsp;
                      Travel destinations { this.state.from_address && this.state.from_address !== 'nearby' ? `from ${from}` : 'that match your travel style'}
                    </small> */}
                  </Fragment>
                }
              })()}
              </h1>

              <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>
                {!this.props.hideSearchBar && this.props.interests !== 'photos' && <SearchPanel
                  center={true}
                  expandable={isWidthDown('sm', this.props.width) ? true : false}
                  fieldFromMultiple={true}
                  show_description={false}
                  // expandable={true}
                  group={this.state.group === 'recently' ? 'all' : this.state.group}
                  locationFrom={this.state.from_address}
                  search_params={this.props.search_params}
                  onSearch={this.props.onSearch}
                />}
              </Grid>

            </Paper>
          </div>

          <div className="destinations-container">
            <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>

              {/* {((this.state.group === 'personalized' && this.props.user.is_registered === true) || (this.state.group !== 'personalized' && this.props.interests !== 'my-trips' && this.props.interests !== 'discover-weekly' && this.props.interests !== 'photos')) && <Grid item xs={12} className={classes.showcaseDestinations}>
                <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                  <h2 className={classes.showcaseHeadline}>
                    <Typography className={classes.showcaseHeadline}>Find {this.props.interests ? 'places with ' + lowerCase(this.props.interests) : ''}{this.props.country ? ' in ' + startCase(this.props.country) : ''}</Typography>
                    <small className={classes.showcaseSubtitle}>Travel destinations { this.state.from_address && this.state.from_address !== 'nearby' ? `from ${from}` : 'that match your travel style'}</small>
                  </h2>
                </Typography>
              </Grid>} */}

              {/* {(this.props.interests !== 'multiple' && this.props.interests !== 'my-trips' && this.props.interests !== 'discover-weekly' && this.props.interests !== 'photos' && this.props.user.is_registered) && <div className={classes.shadowOnScroll}>
                <div className={classes.categoriesContainer}>
                  {this.state.showFilters ? <Fragment>
                    <div style={{float: 'right', padding: '7px 0px 0px 7px'}}>
                      <ButtonFilter name="filters" defaultSelected={[]} selected={this.state.showFilters} buttonIcon="close" buttonIconSelected="close" label={null} multiple={false} onChange={() => this.setState({showFilters: false})}/>
                    </div>
                    <Tabs
                    value={false}
                    variant="scrollable"
                    scrollButtons="off"
                    aria-label="scrollable auto"
                    style={{marginTop: 7}}
                  >
                    <CustomTab key={-1}><ButtonFilter name="saved" defaultSelected={[]} selected={this.state.filters.saved} buttonIcon="favorite_border" buttonIconSelected="favorite" label="Saved" multiple={false} onChange={this.handleFiltersChange.bind(this)}/></CustomTab>
                    <CustomTab key={1}><SliderFilter name="budget" defaultValue={4000} selected={this.state.filters.budget} min={100} max={4000} step={50} prependLabel="" appendLabel="" description="Max. budget: " label="Budget" onChange={this.handleFiltersChange.bind(this)}/></CustomTab>
                    <CustomTab key={0}><ListFilter name="things_to_do" defaultSelected={['all']} selected={[this.state.group].concat(this.state.filters.things_to_do)} description="What places are you interested in ?" label="Interest" list={this.props.user && this.props.user.travel_preference ? INTERESTS.concat(THINGS_TO_DO).filter(interest => [].concat(this.props.user.travel_preference.things_to_do).concat(["popular"]).includes(interest.value)) : INTERESTS.concat(THINGS_TO_DO)} onChange={(event) => { this.handleSelectedInterest(event.target.value.things_to_do) }}/></CustomTab>
                    <CustomTab key={3}><SliderFilter name="weather" defaultValue={[-20, 40]} selected={this.state.filters.weather} min={-20} max={40} step={10} appendLabel=" °C" description="Temperature: " label="Weather" onChange={this.handleFiltersChange.bind(this)}/></CustomTab>
                    <CustomTab key={2}><ListFilter name="distance" defaultSelected={[]} selected={this.state.filters.distance} description="How far do you want to travel ?" label="Distance" list={DISTANCE} onChange={this.handleFiltersChange.bind(this)}/></CustomTab>
                  </Tabs>
                  </Fragment>
                  :
                  <Fragment>
                    <h1 className={classes.title}>
                      {this.props.interests !== 'recently' &&  <div style={{float: 'right', padding: '2px 0px 0px 0px', marginRight: 0}}>
                        <ButtonFilter name="saved" defaultSelected={[]} selected={this.state.filters.saved} buttonIcon="favorite_border" buttonIconSelected="favorite" label={isWidthDown('sm', this.props.width) ? "" : "Saved"} multiple={false} onChange={this.handleFiltersChange.bind(this)}/>
                      </div>}

                      {this.props.interests === 'recently' ? 'Recently planned' : 'Recommended'}
                      <small className={classes.subtitle} style={{marginBottom: 0, fontSize: 13, margin: '3px 0px 0px 0px'}}>
                      {this.props.interests === 'recently' ? 'Get inspired by other travelers' : 'Based on your style and activity'}
                      </small>
                    </h1>
                  </Fragment>}
                </div>
              </div>} */}

              <div className={classes.shadowOnScroll}>
                <div className={classes.categoriesContainer}>
                  <Fragment>
                    <h1 className={classes.title}>
                      {this.props.interests === 'recently' ? 'Recently planned' : 'Travel deals'}
                      <small className={classes.subtitle} style={{marginBottom: 0, fontSize: 13, margin: '3px 0px 0px 0px'}}>
                      {this.props.interests === 'recently' ? 'Get inspired by other travelers' : 'Based on your style and location'}
                      </small>
                    </h1>
                  </Fragment>
                </div>
              </div>



              {!['discover-weekly', 'my-trips'].includes(this.props.interests) && (this.state.destination_groups.length < 1 && !this.state.isLoading) && <Grid item xs={12} className={classes.showcaseDestinations}>
                <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'center', fontWeight: 400, display: 'block' }}>
                  <h2 className={classes.showcaseHeadline}>
                    <Typography className={classes.showcaseHeadline}>No results</Typography>
                    <small className={classes.showcaseSubtitle} style={{fontSize: "80%"}}>Change some filters or clear them all</small>

                    <Button variant="contained" color="primary" size="small" disableElevation className={`${classes.buttonBook}`} style={{float: 'inherit', marginTop: '20px', marginLeft: 0, boxShadow: 'none'}} onClick={this.clearFilters}>
                      Clear all
                    </Button>
                  </h2>
                </Typography>
              </Grid>}

              {!(this.props.interests === 'personalized' && (this.props.user.travel_preference === undefined || this.props.user.travel_preference === null)) && <Fragment>
                <div style={{ marginTop: 40, marginBottom: 40 }}>
                {this.state.destination_groups.length > 0 ?
                  <InfiniteScroll
                    dataLength={this.state.destination_groups.length} //This is important field to render the next data
                    next={this.getDestinations}
                    hasMore={this.state.hasMore}
                    // hasMore={(this.props.interests || 'all') === 'all' || this.props.interests === 'discover-weekly' || this.props.interests === 'saved' ? this.state.hasMore : false}
                    loader={<div className={classes.buttonLoading}>Loading...</div>}
                    style={{display: 'contents'}}
                    scrollThreshold={0.4}
                  >
                    <Grid container spacing={2} className={classes.destinationsContainer}>
                    {/* {<Grid item xs={12} sm={6} md={4} lg={4} xl={4}>
                        <Paper elevation={0} className={classes.travelStylePaper}>
                          <Grid container direction="row" justify="center" spacing={1}>
                            <Grid item xs={12}>
                              <Typography variant="inherit" style={{ paddingTop: 40, paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                                <Icon className={`material-icons-outlined notranslate ${classes.travelStyleIcon}`} alt="travel style icon">luggage</Icon>
                                <h2 className={classes.h2} style={{marginBottom: 0, padding: 10}}>
                                  Planning a custom trip ?
                                  <br/>
                                  <small className={classes.subtitle} style={{fontSize: '75%', marginTop: 15, lineHeight: '23px', color: "#333"}}>
                                    Create personalized travel plan based on your occasion, style and budget with a little help of our AI-powered planner
                                  </small>
                                </h2>
                              </Typography>
                            </Grid>
                            <Grid item xs={12}>
                              <Button variant="contained" color="primary" size="small" disableElevation className={`${classes.buttonBook} ${classes.travelStyleTestBtn}`} onClick={() => this.props.history.push({pathname: '/planner', state: {step: 'occasion'}})}>
                                Plan a trip
                                <Icon className="material-icons-outlined notranslate" style={{marginLeft: 5}}>east</Icon>
                              </Button>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Grid>} */}


                    {(this.state.destination_groups).map((group, i) => {
                      return <Fragment key={`${group.key}-${i}`}>
                        {this.props.interests !== 'photos' ?
                          <DestinationList key={group ? group.key : i}
                            group={group && group.key}
                            search_params={this.props.search_params}
                            filters={this.state.filters}
                            next_page_url={group && API + group.next_page_url}
                            destinations={group && group.destinations}
                            title={group && group.title}
                            description={group && group.description}
                            has_next_page={(group && ((this.props.interests || 'all') !== 'all' || this.props.interests === 'discover-weekly' || this.props.interests !== 'saved') && group.has_next_page)}
                            link_to_all={group && group.link_to_all}
                            page={group && group.page}
                            country={this.props.country}
                            isLoading={this.state.isLoading}
                            onSelected={this.props.onSearch}
                            // onSelected={this.handleClickOpen}
                            // address={this.props.address}
                            // addressType={this.props.addressType}
                            // countryCode={this.props.countryCode}
                            locationFrom={(this.state.from_address ? this.state.from_address : 'nearby')}
                            // showHeader={(this.state.destination_groups === []) ? false : true}
                            // showHeader={this.props.interests !== 'my-trips' && this.props.interests !== 'discover-weekly' ? false : true}
                            showHeader={false}
                            showLinkToAll={((this.props.interests || 'all') !== 'all') ? false : true}
                            infiniteScroll={false}
                          />
                        :
                          // Show photos only for list of photos
                          <Fragment key={`${group.key}-${i}`}>
                            <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                              <h2 className={classes.h2}>
                                <a className={classes.h2} target="_blank" href={`https://www.google.com/maps/search/${group.title}`}>
                                  {group.title}
                                </a>
                                <small className={classes.subtitle}>{group.description}</small>
                              </h2>
                            </Typography>
                            <Grid container spacing={2} className={classes.destinationsContainer}>
                              {group.destinations.map((image, i) => {
                                return <PhotoCard
                                        key={image.id}
                                        image_url={image.sizes.medium.url}
                                        id={image.id}
                                        description={image.description}
                                        tags={image.tags}
                                        hidden={image.hidden}
                                        featured={image.featured}
                                      />
                              })}
                            </Grid>
                          </Fragment>
                        }
                      </Fragment>
                    })}
                    </Grid>
                  </InfiniteScroll>
                :
                  (this.state.isLoading ? Array.from(new Array(1)) : this.state.destination_groups).map((group, i) => {
                    return <Fragment key={group ? group.key : i}>
                      <Grid container spacing={2} className={classes.destinationsContainer}>
                      <DestinationList key={group ? group.key : i}
                        group={group && group.key}
                        search_params={this.props.search_params}
                        filters={this.state.filters}
                        next_page_url={group && API + group.next_page_url}
                        destinations={group && group.destinations}
                        title={group && group.title}
                        description={group && group.description}
                        has_next_page={(group && this.state.destination_groups.length === 1 && group.has_next_page)}
                        link_to_all={group && group.link_to_all}
                        page={group && group.page}
                        country={this.props.country}
                        isLoading={this.state.isLoading}
                        onSelected={this.props.onSearch}
                        // onSelected={this.handleClickOpen}
                        // address={this.props.address}
                        // addressType={this.props.addressType}
                        // countryCode={this.props.countryCode}
                        locationFrom={(this.state.from_address ? this.state.from_address : 'nearby')}
                        // showHeader={(this.state.destination_groups === []) ? false : true}
                        // showHeader={this.props.interests !== 'my-trips' && this.props.interests !== 'discover-weekly' ? false : true}
                        showHeader={false}
                        showLinkToAll={(this.state.destination_groups.length === 1) ? false : true}
                        infiniteScroll={false}
                      />
                      </Grid>
                    </Fragment>
                  })
                }

                {(this.props.interests === 'saved' && this.state.destination_groups.length > 0) &&
                  !this.props.user.is_registered && <Fragment>
                    <SignupBanner onOpenSigninDialog={this.handleSigninChange}/>
                  </Fragment>
                }
                </div>
              </Fragment>}

            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}



const styles = theme => ({
  green: {
    color: '#02c39a',
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',

    // [theme.breakpoints.down('md')]: {
    //   margin: '0px 10px',
    // },

    ["@media (max-width:1155px)"]: {
      margin: '0px 10px',
    },
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      margin: '-100px auto',
    },
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 600,
    textAlign: 'left',
    marginTop: 0,
    padding: '90px 20px 20px 20px',

    [theme.breakpoints.down('sm')]: {
      marginTop: '30px',
      padding: '120px 20px 20px 20px',
      fontSize: 25,
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      padding: '129px 20px 0px 20px',
      fontSize: 25,
    },
  },
  title: {
    // maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 20,
    fontWeight: 800,
    textAlign: 'left',
    marginTop: 5,
    padding: 0,

    [theme.breakpoints.down('sm')]: {
      marginTop: '0px',
      padding: '5px 0px 0px 0px',
      fontSize: 20,
    },

    [theme.breakpoints.down('xs')]: {
      marginTop: 0,
      padding: '5px 0px 0px 0px',
      fontSize: 17,
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 14,
  },
  featureMedia: {
    maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
    },
  },
  buttonLoading: {
    color: '#02c39a',
    textDecoration: 'none',
    margin: '27px auto',
    display: 'block',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 600,
    padding: '13px 20px 13px 27px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '2px solid #02c39a',
    width: 65,
  },
  categoriesContainer: {
    width: '100%',
    margin: '20px auto -30px auto',
    maxWidth: 1137,
    paddingRight: 0,
    top: -1,
    whiteSpace: 'nowrap',
    overflowX: 'auto',

    position: 'sticky',
    backgroundColor: '#fff',
    zIndex: 98,
    paddingTop: 5,
    // boxShadow: '0 10px 20px -22px rgb(0 0 0 / 52%)',

    [theme.breakpoints.down('md')]: {
      width: '100%',
    },

    // [theme.breakpoints.down('xs')]: {
    //   margin: '50px auto -20px auto',
    // },
  },

  shadowOnScroll: {
    height: 89,
    position: 'sticky',
    top: -14,
    zIndex: 98,
    marginTop: 10,
    marginBottom: -40,
    marginLeft: -7,
    '-webkit-backface-visibility': 'hidden',

    "&::before": {
      content: '""',
      display: 'block',
      height: 39,
      position: 'sticky',
      '-webkit-position': 'sticky',
      top: 11,
      boxShadow: '5px 22px 5px rgba(0,0,0,0.5)',
    },

    "&::after": {
      content: '""',
      display: 'block',
      height: 39,
      position: 'sticky',
      "-webkit-position": 'sticky',
      background: 'linear-gradient(white 10%, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0.4) 70%, transparent)',
      top: 0,
      zIndex: 2,
    },

    "& > div": {
      height: 63,
      padding: '5px 5px 5px 10px',
      position: 'sticky',
      "-webkit-position": 'sticky',
      top: -1,
      marginTop: -26,
      zIndex: 3,
    },
  },

  chipSelected: {
    backgroundColor: 'rgba(7, 195, 153, 0.26) !important',
    boxShadow: 'none !important',
    border: '2px solid rgba(7, 195, 153, 0.26) !important',
  },
  chipRoot: {
    padding: '25px 0px 25px 0px',
    borderRadius: 50,
    marginRight: 10,
    marginBottom: 10,
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    fontWeight: 700,
    fontSize: 12,
    border: '2px solid #f5f5f5',
    backgroundColor: '#fff',

    '&:hover': {
      boxShadow: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.04)',
    },
  },
  chipAvatar: {
    width: '44px !important',
    height: '44px !important',
    marginRight: '0px !important',
    marginLeft: '3px !important',
  },
  showcaseDestinations: {
    margin: '70px 0px 40px 0px',

    [theme.breakpoints.down('xs')]: {
      margin: '70px 0px 30px 0px',
    },
  },
  showcaseHeadline: {
    fontSize: '20px',
    fontWeight: 700,
    color: '#000',
    textDecoration: 'none',

    [theme.breakpoints.down('xs')]: {
      fontSize: '17px',
      marginBottom: -5,
    },
  },
  showcaseSubtitle: {
    fontWeight: 600,
    fontSize: '70% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
  showcaseSelector: {
    color: '#333333',
    textDecoration: 'none',
    float: 'right',
    margin: '7px 0px',
    display: 'block',
    fontSize: '14px',
    fontWeight: 800,
    padding: '16px 26px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '1px solid #f2f2f2',

    '& .material-icons': {
      padding: 0,
      margin: 0,
      verticalAlign: 'middle',
      fontWeight: 400,
    },

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  h2: {
    fontSize: '20px',
    marginBottom: 10,
    fontWeight: 700,
    color: '#333',
    textDecoration: 'none',
  },
  travelStylePaper: {
    border: '1px solid #f2f2f2',
    padding: '5px 10px',
    // marginTop: 40,
    // marginBottom: 10,
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'initial',
    backgroundPosition: '0px -10px',
    // background: 'transparent',
    height: 'calc(100% - 12px)',

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  travelStyleIcon: {
    verticalAlign: 'middle',
    // float: 'left',
    padding: '14px',
    margin: '10px 20px 15px 10px',
    backgroundColor: '#fff',
    color: '#02c39a',
    borderRadius: 50,
    display: 'block',
    fontSize: 50,

    // [theme.breakpoints.down('sm')]: {
    //   display: 'none !important',
    // },

    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  travelStyleTestBtn: {
    // margin: '30px 10px 0px 0px !important',
    // float: 'right',

    [theme.breakpoints.down('xs')]: {
      // margin: '-10px auto 10px auto !important',
      display: 'block',
      // float: 'inherit',
      // width: '100%',
      // padding: '13px 5px !important',
      // fontSize: 17,
    },
  },
  travelStyleValues: {
    textAlign: 'center',
  },
  travelStyleHidden: {
    ["@media (max-width:504px)"]: {
      display: 'none !important',
    },
  },
  travelStyleCategoryIcon: {
    verticalAlign: 'middle',
    margin: '0px 10px 0px 0px',
    color: '#02c39a',
  },
  divider: {
    height: 45,
    margin: '27px 0px 0px -1px',
    backgroundColor: '#f2f2f2',
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 45,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonBookOutlined: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    border: '2px solid #02c39a',
    textTransform: 'none',
    borderRadius: 45,
    fontWeight: 800,
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',
    // minWidth: 155,

    '&:hover': {
      border: '2px solid #02c39a',
      boxShadow: 'none',
    }
  },
})

export default withRouter(withStyles(styles)(withWidth()(Explore)))
