import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Grid,
  Icon,
  InputBase,
  MenuItem,
  Slider,
  Select,
} from '@material-ui/core';
import { Fragment } from 'react';
import { CURRENCIES } from '../../utils/currency.js'


class BudgetFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      budget: props.budget || 700,
      currency: props.currency || (props.user && props.user.currency) || 'USD'
    };
  }

  handleChange = (event, newValue) => {
    this.setState({ budget: newValue })
    this.props.onChange({
      target: {
        name: this.props.name,
        value: newValue
      }
    })
  }

  handleChangeCurrency = (event) => {
    this.setState({currency: event.target.value})
    this.props.onChange({
      target: {
        name: "currency",
        value: event.target.value
      }
    })
  }

  // step = (value) => {
  //   if (value <= 1000) {
  //     return 100
  //   } else if (value >= 1000 && value < 10000) {
  //     return 500
  //   } else if (value >= 10000 && value < 30000) {
  //     return 1000
  //   } else {
  //     return 5000
  //   }
  // }

  render() {
    const { classes, disabled, defaultValue, min, max, step } = this.props;
    const { budget, currency } = this.state;

    return(
      <div role="tab" className={classes.root}>
        <Grid container>
          <Grid item xs={9}>
            <div className={classes.iconLabel}>
              <Icon className={`material-icons-outlined notranslate ${classes.icon}`}>attach_money</Icon>
              {this.props.label || 'Max. budget:'}
              <div style={{display: 'inline-block', fontSize: 16, paddingLeft: 10, color: '#02c39a'}}>
                {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: this.state.currency || 'USD'}).format(this.state.budget)}
              </div>
            </div>
            <div style={{padding: '10px 5px 7px 10px'}}>
              <Slider
                disabled={disabled}
                track={"normal"}
                defaultValue={defaultValue}
                min={min || 100}
                max={max || 15000}
                step={(step || 100)}
                valueLabelDisplay="auto"
                value={budget}
                onChange={this.handleChange}
                size="medium"
                classes={{ mark: classes.sliderMark, markLabel: classes.sliderLabel, valueLabel: classes.sliderValueLabel, thumb: classes.sliderThumb, rail: classes.sliderRail, track: classes.sliderTrack }}
              />
            </div>
          </Grid>

          <Grid item xs={3}>
            <div style={{padding: '10px 5px 7px 10px'}}>
              <Select
                id="currency_select"
                value={currency}
                onChange={this.handleChangeCurrency}
                input={<InputBase classes={{root: classes.currRoot, input: classes.currInput}}/>}
                renderValue={(selected) => selected}
              >
                {Object.entries(CURRENCIES).sort((a, b) => a[1].name.toUpperCase() > b[1].name.toUpperCase() ? 1 : -1).map(([code, currency]) => {
                  return <MenuItem key={code} style={{fontSize: 14, fontWeight: 500}} value={code}>{`${currency.name} • ${currency.symbol}`}</MenuItem>
                })}
              </Select>
            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    padding: '10px 7px 5px',
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    lineHeight: '1.4em',
    marginTop: 0,
    textAlign: 'left',
  },
  iconLabel: {
    color: '#333',
    fontSize: 13,
    fontWeight: 700,
    marginBottom: -4,
    marginLeft: 5,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  sliderLabel: {
    top: -5,
    fontSize: 12,
    fontWeight: 600,
    color: '#fff',
  },
  sliderMark: {
    backgroundColor: '#bfbfbf',
    height: 14,
    width: 1,
    marginTop: -3,
  },
  sliderRail: {
    height: 8,
    borderRadius: 4,
    width: '100%',
  },
  sliderRoot: {
    color: '#02c39a',
    height: 8,
  },
  sliderTrack: {
    height: 8,
    borderRadius: 4,
  },
  sliderThumb: {
    height: '24px !important',
    width: '24px !important',
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: '-8px !important',
    marginLeft: '-12px !important',
    '&:focus, &:hover': {
      boxShadow: 'inherit',
    },
  },
  sliderValueLabel: {
    display: 'none',
    lineHeight: 2.2,
    fontSize: 12,
    padding: 0,
    borderRadius: '50% 50% 50% 0',
    left: 'auto',

    '& > span > span': {
      color: '#fff',
      fontWeight: 560,
    }
  },
  currRoot: {
    margin: 0,
    zIndex: 0,
    textAlign: 'left',
    fontSize: 15,
    fontWeight: 500,
    padding: '13px 10px 10px 10px',

    'label + &': {
      marginTop: theme.spacing(3),
    },

    [theme.breakpoints.down('xs')]: {
      margin: '7px auto 0px auto !important',
    },
  },
});

export default withStyles(styles)(BudgetFilter);