import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Grid,
  Icon,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core';

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class SavePreferencesOption extends Component {
  constructor(props) {
    super(props);

    const user = this.props.getUser()
    this.state = {
      firstName: '',
      lastName: '',
      email: '',
      disabled: false,
      user: user,
      isLoggedIn: (user.first_name !== undefined),
    };
  }

  handleChange = (event) => {
    this.setState({[event.target.name]: event.target.value, error: null});
  }

  handleSubmit = () => {
    const value = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      email: this.state.email,
    }

    const { triggerNextStep } = this.props;

    this.setState({ disabled: true});
    triggerNextStep({ value });
  }

  render() {
    const { disabled, firstName, lastName, email, isLoggedIn } = this.state;
    const { classes, } = this.props;

    return(
      <div role="tab" className={classes.root}>
        <Grid item xs={12} style={{display: 'flex'}} className={classes.background}>
          {!isLoggedIn ? <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component='div' style={{fontWeight: 600, fontSize: 15}}>
                  <h3>Success!</h3>
                  We've built your trip recommendations, <br/>
                  let's create account to see them...
                </Typography>
                <br/><br/>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  autoFocus={false}
                  name="firstName"
                  label=""
                  placeholder="First name"
                  helperText=""
                  variant="outlined"
                  margin="dense"
                  value={firstName}
                  onChange={this.handleChange}
                  className={classes.textfield}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={`${classes.smallIcon} material-icons-outlined notranslate`} style={{color: '#888', fontWeight: 500}}>person</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  autoFocus={false}
                  name="lastName"
                  label=""
                  placeholder="Last name"
                  helperText=""
                  variant="outlined"
                  margin="dense"
                  value={lastName}
                  onChange={this.handleChange}
                  className={classes.textfield}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={`${classes.smallIcon} material-icons-outlined notranslate`} style={{color: '#888', fontWeight: 500}}>person</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  disabled={disabled}
                  autoFocus={false}
                  name="email"
                  label=""
                  placeholder="Enter email address"
                  helperText=""
                  variant="outlined"
                  margin="dense"
                  value={email}
                  onChange={this.handleChange}
                  className={classes.textfield}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Icon className={`${classes.smallIcon} material-icons-outlined notranslate`} style={{color: '#888', fontWeight: 500}}>alternate_email</Icon>
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography style={{textAlign: 'center', fontSize: 11, fontWeight: 500, color: "#777" }}>
                  <Icon className={`${classes.smallIcon} material-icons-outlined notranslate`}>lock</Icon> Your data are safe with us. It will be used for finding you a trip only.
                </Typography>
              </Grid>
            </Grid>
          :
            <Grid item xs={12}>
              <Typography style={{textAlign: 'center', fontSize: 14, fontWeight: 600, color: "#333" }}>
                <Icon className={`${classes.bigIcon} material-icons-outlined notranslate`}>verified</Icon> We have updated your style. Let's see your destinations.
              </Typography>
            </Grid>
          }
        </Grid>
        {disabled === false && <Grid item xs={12}>
          {!isLoggedIn && <Button variant="text" color="default" size="small" className={classes.buttonSkip} onClick={this.handleSubmit}>Skip</Button>}
          <Button disabled={!isLoggedIn && !(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email))} variant="contained" color="primary" size="large" disableElevation className={classes.buttonOk} onClick={this.handleSubmit} style={{margin: '10px 5px 0px 0px', float: 'right'}}>Next</Button>
        </Grid>}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    marginRight: 10,
    marginTop: 0,
    textAlign: 'left',
    width: '100%',
    maxWidth: 500,
  },
  smallIcon: {
    fontSize: 14,
    fontWeight: 500,
    marginTop: 0,
    marginRight: -5,
    marginLeft: -5,
    padding: '0px 5px !important',
    verticalAlign: 'middle',
    color: '#888',
  },
  bigIcon: {
    fontSize: 41,
    fontWeight: 600,
    padding: '0px 10px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
    display: 'block',
    margin: '10px auto',
  },
  buttonOk: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    width: '100px',
    maxWidth: 300,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonSkip: {
    float: 'left',
    color: '#999',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    marginTop: 17,
    fontWeight: 600,

    '&:hover': {
      backgroundColor: 'transparent',
      boxShadow: 'none',
    }
  },
  background: {
    backgroundColor: '#fafafa',
    padding: '20px 30px 30px 30px',
    borderRadius: 40,
  },
  textfield: {
    backgroundColor: '#fff',
    marginTop: 0,
    width: '100%',
    borderRadius: 50,
    borderWidth: 1,
    padding: 3,
    boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: 500,
    borderColor: '#333',

    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
      fontWeight: 500,
    },

    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },

    '& input': {
      fontSize: '14px !important',
      fontWeight: 500,
    }
  },
});

export default withStyles(styles)(SavePreferencesOption);


