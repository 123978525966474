import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Badge,
  Button,
  CardMedia,
  Icon,
  Grid,
} from '@material-ui/core'
import withWidth from '@material-ui/core/withWidth'
import SignupForm from './SignupForm'
import zIndex from '@material-ui/core/styles/zIndex'

class UnlockItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { user, classes } = this.props

    return (
      <Grid className={classes.discoverSignup} container alignItems={'center'} justify={'center'}>
        <Grid item xs={12} sm={12} md={8}>
          <h2 className={`${classes.discoverHeadline} ${classes.headlineCenter}`} style={{margin: '5px 10px 4px 0px', textAlign: 'center'}}>
            <strong className="main-title" style={{fontSize: '1.80rem', margin: '0px', display: 'block', fontWeight: 800}}>
              Log in or Sign up to access this travel plan
            </strong>
            <br/>
            <SignupForm
              type={'signup'}
              onSignin={this.props.onSignin}
              user={user}
              hideHeader={true}
            />
          </h2>
        </Grid>

        {/* <Grid item xs={12} sm={12} md={5} className={classes.discoverImageContainer}>
          <h2 className={classes.discoverHeadline} style={{margin: '5px -50px 4px -30px', padding: 20}}>
            <small className={classes.subtitle} style={{color: 'rgba(130, 130, 130, 0.9)', margin:'0px', display: 'block', lineHeight: 2}}>
              <Grid container spacing={2}>
                <Grid item xs={12} style={{ padding: '3px 8px 3px 10px !important' }}>
                  Account get : <br/><br/>
                </Grid>

                <Grid item xs={1} className={classes.discoverIcon}><Icon>tune</Icon> </Grid>
                <Grid item xs={11} className={classes.discoverText}>
                  <span>
                    <strong style={{color: '#333'}}>AI travel assistant </strong><br/>
                  </span>
                </Grid>
                <Grid item xs={1} className={classes.discoverIcon}><Icon>travel_explore</Icon> </Grid>
                <Grid item xs={11} className={classes.discoverText}>
                  <span>
                    <strong style={{color: '#333'}}>Unlock complete itinerary</strong><br/>
                  </span>
                </Grid>
                <Grid item xs={1} className={classes.discoverIcon}><Icon>group</Icon> </Grid>
                <Grid item xs={11} className={classes.discoverText}>
                  <span>
                    <strong style={{color: '#333'}}>Collaborative planning</strong><br/>
                    <small>Plan a trip together. Choose a destination, flight, hotel and activities</small>
                  </span>
                </Grid>
              </Grid>
            </small>
          </h2>
        </Grid> */}

      </Grid>
    )
  }
}

const styles = theme => ({
  discoverHeadline: {
    padding: '60px 60px 60px 60px',
    fontWeight: 800,

    [theme.breakpoints.down('sm')]: {
      padding: '70px 60px 30px 40px !important',
      margin: '0px !important',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '40px 10px 20px 10px !important',
      margin: '0px !important',
    },
  },
  headlineCenter: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  discoverIcon: {
    padding: '3px 8px 3px 0px !important',

    '& .material-icons': {
      verticalAlign: 'middle',
      color: '#02c39a',
      marginRight: 8,
      padding: '3px 8px'
    }
  },
  discoverText: {
    padding: '3px 8px 15px 18px !important',
    lineHeight: '28px',
  },
  discoverSignup: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '40px auto',
    border: '1px solid #eee',
    backgroundRepeat: 'no-repeat',
    borderRadius: 25,
    marginBottom: 40,
    backgroundColor: '#fff',
    zIndex: 3,
    position: 'relative',

    // display: 'none',

    [theme.breakpoints.down('md')]: {
      margin: '30px 10px 10px 0px',
    },

    [theme.breakpoints.down('sm')]: {
      marginBottom: 50,
      marginTop: 30,
      marginLeft: 0,
      marginRight: 10,
      padding: 10,
    },
  },
  discoverImageContainer: {
    padding: '20px 40px 20px 0px',

    [theme.breakpoints.down('sm')]: {
      padding: '10px 10px 20px 10px',
    },
  },
  discoverImage: {
    position: 'relative',
    // right: '-47px',
    // top: '-65px',
    display: 'block',
    // marginBottom: -125,

    [theme.breakpoints.down('sm')]: {
      maxWidth: 450,
      marginLeft: '-15px',
      marginRight: 'auto',
      // top: 0,
      // right: 0,
      // marginBottom: 0,
      // paddingBottom: 10,
    },
    [theme.breakpoints.down('xs')]: {
      // top: 0,
      // right: 0,
      // marginBottom: 0,
      // paddingBottom: 10,
      // display: 'none',
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '70% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
  btnSignup: {
    color: '#fff',
    borderRadius: 25,
    margin: '7px 5px 0px 0px',
    padding: '13px 35px',
    zIndex: 1,
    fontWeight: 600,

    [theme.breakpoints.down('sm')]: {
      // padding: '13px 25px',
      // margin: '0px auto',
      // display: 'block',
    },

    [theme.breakpoints.down('xs')]: {
      // padding: '10px 17px',
      margin: '0px auto',
      display: 'block',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonCancel: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 700,
    // backgroundColor: '#6b6b6b08',

    '&:hover': {
      backgroundColor: '#e2e2e2',
      border: 'none',
    }
  },
  badge: {
    top: -5,
    right: -7,
  },
  badgeRoot: {
    marginTop: -7,
  },
  travelStyleIcon: {
    verticalAlign: 'middle',
    padding: '14px',
    margin: '0px 0px 20px 0px',
    backgroundColor: '#fff',
    color: '#02c39a',
    borderRadius: 25,
    display: 'block',

    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

export default withRouter(withStyles(styles)(withWidth()(UnlockItinerary)))
