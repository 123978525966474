import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { config } from '../../config.js'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Divider,
  Grid,
  Hidden,
  Icon,
  Paper,
  Slider,
  Tabs,
  Typography,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar'
import DestinationList from '../../components/lists/DestinationList'
import PremiumBanner from '../../components/forms/PremiumBanner'
import Cookies from 'universal-cookie'
import { CUISINES, THINGS_TO_DO, INTERESTS, TRAVEL_RESTRICTIONS, DISTANCE } from '../../utils/lists'

const cookies = new Cookies();
const API = config.api.TRAVEL_URL
let abortController = new AbortController()

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

const ThumbComponent = (props) => {
  return (
    <span {...props}>
       👍
    </span>
  );
}

class UserPreferences extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isLoading: false,
      destination_groups: [],
      filters: {},
      openedDetails: (props.user && props.user.is_registered),
      openedSigninDialog: false,
    }
  }

  handleFiltersChange = (event) => {
    // console.log({ filters: {...this.state.filters, ...event.target.value} })
    this.setState({
      filters: {...this.state.filters, ...event.target.value},
      page: 0,
      destination_groups: [],
    }, this.getDestinations);
  }

  handleSigninChange = (flag, dialogType = 'signup') => {
    this.setState({
      openedSigninDialog: flag,
      openedSigninDialogType: dialogType
    })
  }

  handleSignup = () => {
    this.setState({openedSigninDialog: true})
  }

  foodItem = (name) => {
    return CUISINES.filter((cuisine) => cuisine.value === name)[0]
  }

  activityItem = (name) => {
    return THINGS_TO_DO.filter((activity) => activity.value === name)[0]
  }

  render() {
    const { classes, user } = this.props
    const vibe_score_pair_1 = user.travel_preference && -(user.travel_preference.vibe_adventurous - user.travel_preference.vibe_romantic)
    const vibe_score_pair_2 = user.travel_preference && -(user.travel_preference.vibe_chill_relaxing - user.travel_preference.vibe_fast_paced)
    const vibe_score_pair_3 = user.travel_preference && -(user.travel_preference.vibe_luxurious - user.travel_preference.vibe_curious)
    const vibe_score_pair_4 = user.travel_preference && -(user.travel_preference.vibe_popular - user.travel_preference.vibe_off_the_beaten_path)
    const food_cuisines =  user.travel_preference && user.travel_preference.food_cuisine && user.travel_preference.food_cuisine.concat([null, null, null, null])

    return (
      <Fragment>
        <AppTopBar
          hideMenu={true}
          hideCurrency={true}
          hideBottomMenu={(user && !user.is_registered)}
          showBackButton={false}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onChange={this.props.onFilterChange}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'explore'}
          openedSigninDialog={this.state.openedSigninDialog}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations explore">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 16, backgroundColor: '#fafafa'}}>
              <Paper elevation={0} className={classes.travelStylePaper} style={{maxWidth: 1119, margin: '100px auto -15px auto', padding: '40px 10px 20px 10px'}}>
                <Grid container direction="row" justify="center" spacing={1}>
                  <Grid item xs={10} sm={5} md={4} >
                    <Typography variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                      <h2 className={classes.headline} style={{padding: '2px 0px'}}>
                        <strong className="main-title" style={{fontWeight: 800}}>
                          {user.travel_preference ? 'Summary' : 'Not sure where to?'}
                          <br/>
                        </strong>
                        <small className={classes.subtitle}>
                          {user.travel_preference ? 'Your style based on the quiz' : 'Decide by your style & budget'}
                        </small>
                      </h2>
                    </Typography>
                  </Grid>
                  <Hidden xsDown>
                    <Grid item sm={4} md={5} className={`${classes.travelStyleHidden}`} style={{width: '100%', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box'}}>
                      <Hidden smDown>
                        <Grid item xs={false} sm={false} md={4} className={classes.travelStyleValues}>
                          <h2 className={classes.h2} style={{marginTop: 15}}>
                          <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">room_service</Icon>
                          {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${this.props.user.travel_preference.food_cuisine.length}` : `-`}
                            <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>Foods</small>
                          </h2>
                        </Grid>
                        <Divider orientation="vertical" flexItem className={classes.divider} style={{display: 'block'}}/>
                      </Hidden>
                      <Grid item xs={false} sm={6} md={4} className={`${classes.travelStyleValues} ${classes.travelStyleHidden}`}>
                        <h2 className={classes.h2} style={{marginTop: 15}}>
                        <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">sentiment_satisfied</Icon>
                        {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${4}` : `-`}
                          <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>Vibes</small>
                        </h2>
                      </Grid>
                      <Divider orientation="vertical" flexItem className={`${classes.divider} ${classes.travelStyleHidden}`} style={{display: 'block'}}/>
                      <Grid item xs={false} sm={6} md={4} className={classes.travelStyleValues}>
                        <h2 className={classes.h2} style={{marginTop: 15}}>
                          <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">task_alt</Icon>
                          {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${this.props.user.travel_preference.things_to_do.length}` : `-`}
                          <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>
                            {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null && this.props.user.travel_preference.things_to_do.length < 2) ?  'Activity' : 'Activities'}
                          </small>
                        </h2>
                      </Grid>
                    </Grid>
                  </Hidden>
                  <Grid item xs={2} sm={3} md={3}>
                    {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ?
                      <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonBookOutlined} ${classes.travelStyleTestBtn}`} onClick={() => this.setState(prevState => ({openedDetails: !prevState.openedDetails}))}>
                        <Hidden mdUp><Icon className={`material-icons-outlined notranslate`} alt="travel style icon" style={{margin: '-8px -20px'}}>{this.state.openedDetails ? 'expand_less' : 'expand_more'}</Icon></Hidden>
                        <Hidden smDown>Details <Icon className={`material-icons-outlined notranslate`} alt="travel style icon" style={{margin: '0px -5px 0px 5px'}}>{this.state.openedDetails ? 'expand_less' : 'expand_more'}</Icon></Hidden>
                      </Button>
                    :
                      <Button variant="contained" color="primary" size="small" disableElevation className={`${classes.buttonBook} ${classes.travelStyleTestBtn}`} onClick={() => this.props.history.push('/user/personalize')}>
                        <Hidden mdUp>{'Start'}</Hidden>
                        <Hidden smDown>Start with a quiz</Hidden>
                      </Button>
                    }
                  </Grid>

                  <Hidden smUp>
                    <Grid item sm={12} style={{width: '100%', display: 'flex', flexWrap: 'wrap', boxSizing: 'border-box'}}>
                      <Grid item xs={4} sm={4} className={classes.travelStyleValues}>
                        <h2 className={classes.h2} style={{marginTop: 15}}>
                        <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">room_service</Icon>
                        {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${this.props.user.travel_preference.food_cuisine.length}` : `-`}
                          <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>Foods</small>
                        </h2>
                      </Grid>
                      <Divider orientation="vertical" flexItem className={classes.divider} style={{display: 'block'}}/>
                      <Grid item xs={4} sm={4} className={`${classes.travelStyleValues}`}>
                        <h2 className={classes.h2} style={{marginTop: 15}}>
                        <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">sentiment_satisfied</Icon>
                        {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${4}` : `-`}
                          <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>Vibes</small>
                        </h2>
                      </Grid>
                      <Divider orientation="vertical" flexItem className={`${classes.divider}`} style={{display: 'block'}}/>
                      <Grid item xs={4} sm={4} className={classes.travelStyleValues}>
                        <h2 className={classes.h2} style={{marginTop: 15}}>
                          <Icon className={`material-icons-outlined notranslate ${classes.travelStyleCategoryIcon}`} alt="travel style icon">task_alt</Icon>
                          {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null) ? `${this.props.user.travel_preference.things_to_do.length}` : `-`}
                          <br/><small className={classes.subtitle} style={{fontSize: 14, marginTop: 7}}>
                            {(this.props.user.travel_preference !== undefined && this.props.user.travel_preference !== null && this.props.user.travel_preference.things_to_do.length < 2) ?  'Activity' : 'Activities'}
                          </small>
                        </h2>
                      </Grid>
                    </Grid>
                  </Hidden>
                </Grid>

                {(user && user.is_registered) && <Button variant="outlined" color="primary" size="small" disableElevation className={`${classes.buttonBook} ${classes.travelStyleUpdateBtn}`} onClick={() => this.props.history.push('/user/personalize')}>
                  Update preferences
                </Button>}
              </Paper>


              {(user && user.travel_preference && this.state.openedDetails) && <Grid container spacing={4} className={classes.gridFilters}>
                <Divider style={{display: 'block', width: '97%', margin: 'auto', backgroundColor: '#eee'}}/>
                <Grid item xs={12} sm={5} md={5}>
                  <h3 className={classes.h3}>
                    <Icon className={`material-icons-outlined notranslate ${classes.categoryIcon}`} alt="travel style icon">sentiment_satisfied</Icon>
                    Vibes
                  </h3>
                  <Paper style={{boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)', minHeight: 369, padding: '40px 20px 25px 20px'}}>
                    <Box className={vibe_score_pair_1 > 0 ? classes.vibeLabelSelected : classes.vibeLabel} style={{float: 'right'}}>Relaxing</Box>
                    <Box className={vibe_score_pair_1 < 0 ? classes.vibeLabelSelected : classes.vibeLabel}>Adventurous</Box>
                    <Slider
                      ThumbComponent={ThumbComponent}
                      classes={{thumb: classes.vibeThumb, mark: classes.vibeMark, root: classes.vibeRoot}}
                      min={-1}
                      max={1}
                      step={1}
                      marks={true}
                      defaultValue={vibe_score_pair_1}
                      disabled
                    />
                    <Box className={vibe_score_pair_2 > 0 ? classes.vibeLabelSelected : classes.vibeLabel} style={{float: 'right'}}>Fast-paced</Box>
                    <Box className={vibe_score_pair_2 < 0 ? classes.vibeLabelSelected : classes.vibeLabel}>Slow-paced</Box>
                    <Slider
                      ThumbComponent={ThumbComponent}
                      classes={{thumb: classes.vibeThumb, mark: classes.vibeMark, root: classes.vibeRoot}}
                      min={-1}
                      max={1}
                      step={1}
                      marks={true}
                      defaultValue={vibe_score_pair_2}
                      disabled
                    />
                    <Box className={vibe_score_pair_3 > 0 ? classes.vibeLabelSelected : classes.vibeLabel} style={{float: 'right'}}>Budget</Box>
                    <Box className={vibe_score_pair_3 < 0 ? classes.vibeLabelSelected : classes.vibeLabel}>Comfort</Box>
                    <Slider
                      ThumbComponent={ThumbComponent}
                      classes={{thumb: classes.vibeThumb, mark: classes.vibeMark, root: classes.vibeRoot}}
                      min={-1}
                      max={1}
                      step={1}
                      marks={true}
                      defaultValue={vibe_score_pair_3}
                      disabled
                    />
                    <Box className={vibe_score_pair_4 > 0 ? classes.vibeLabelSelected : classes.vibeLabel} style={{float: 'right'}}>Off the beaten path</Box>
                    <Box className={vibe_score_pair_4 < 0 ? classes.vibeLabelSelected : classes.vibeLabel}>Popular</Box>
                    <Slider
                      ThumbComponent={ThumbComponent}
                      classes={{thumb: classes.vibeThumb, mark: classes.vibeMark, root: classes.vibeRoot}}
                      min={-1}
                      max={1}
                      step={1}
                      marks={true}
                      defaultValue={vibe_score_pair_4}
                      disabled
                    />
                  </Paper>
                </Grid>


                <Grid item xs={12} sm={7} md={7}>
                  <h3 className={classes.h3}>
                    <Icon className={`material-icons-outlined notranslate ${classes.categoryIcon}`} alt="travel style icon">room_service</Icon>
                    Foods
                  </h3>

                  <Tabs
                    value={false}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable"
                    style={{minHeight: 162}}
                  >
                  {user.travel_preference && user.travel_preference.food_cuisine && food_cuisines.slice(0, 5).map((name, i) => {
                    const item = (name !== null ? this.foodItem(name) : null)
                    return <CustomTab key={i}>
                      {item ?
                        <Card className={classes.card} elevation={0}>
                          <CardActionArea
                            component='span'
                            disableRipple
                            disabled={true}>
                              <CardMedia
                                component="img"
                                image={item.imageSrc}
                                style={{width: '100%', height: 100}}
                              />
                              <CardContent style={{padding: 15}}>
                                <Typography className={classes.cardName}>
                                  {item.title}
                                </Typography>
                              </CardContent>
                          </CardActionArea>
                        </Card>
                      :
                        <Card className={classes.cardEmpty} elevation={0} >
                          <CardActionArea
                            component='span'
                            disableRipple
                            disabled={true}>
                              <CardContent style={{padding: 15}}>
                                <Typography className={classes.cardName}>
                                </Typography>
                              </CardContent>
                          </CardActionArea>
                        </Card>
                      }
                    </CustomTab>
                  })}
                  </Tabs>

                  <h3 className={classes.h3}>
                    <Icon className={`material-icons-outlined notranslate ${classes.categoryIcon}`} alt="travel style icon">task_alt</Icon>
                    Activities
                  </h3>
                  <Paper style={{boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)', minHeight: 150, padding: 20}}>
                    {user.travel_preference && user.travel_preference.things_to_do && user.travel_preference.things_to_do.map((name, i) => {
                      const item = this.activityItem(name)
                      return <Typography key={i} component="span" className={classes.activityItem}>
                        {item.icon && <Icon className={`${classes.icon} material-icons-outlined notranslate`}>{item.icon}</Icon>}
                        {item.title}
                        {i !== user.travel_preference.things_to_do.length - 1 && <Typography component="span" style={{color: '#ddd', marginLeft: 5, marginRight: 5}}> •</Typography>}
                      </Typography>
                    })}
                  </Paper>
                </Grid>
              </Grid>}

            </Paper>
          </div>


        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {
    fontWeight: 800,
    fontSize: 20,
    margin: '30px 0px 10px 0px',
  },
  featureMedia: {
    // maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',
    width: '50vw',
    minWidth: '960px',
    maxWidth: '1200px',
    position: 'absolute',
    left: '500px',
    top: '-200px',
    display: 'block',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
    },
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    margin: '-100px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    // minHeight: '60vh',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto 20px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: 6,
    padding: '0px 20px',
  },
  featureHeadline: {
    maxWidth: 400,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px 0px 30px 0px',
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'left',
    // marginTop: '30px',
    padding: '0px 10px',
  },
  featureSubtitle: {
    color: '#828282',
    display: 'block',

    margin:' 10px 0px 0px 0px',
    lineHeight: '30px',
    maxHeight: '158px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    fontSize: 16,
    fontWeight: 600,

    [theme.breakpoints.up('md')]: {
      maxWidth: '60vw',
    },

    '&::before': {
      content: "...",
      position: 'absolute',
      bottom: 0,
      right: 0,
    }
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 10,
  },
  cardRoot: {
    textAlign: 'left',
    position: 'relative',
    fontSize: '20px',
    maxWidth: '1119px',
    margin: '32px auto',
    width: '95vw',
    // minHeight: '80vh',
  },
  // media: {
  //   margin: '20px -25px -25px -25px',
  //   height: '90vh',

  //   [theme.breakpoints.up('md')]: {
  //     margin: '-25px -25px -25px 0px',
  //   },
  // },
  titleWeekly: {
    padding: '20px 40px 20px 20px',
    width: '70px',
    display: 'block',
    textAlign: 'left',

    '& h5': {
      fontSize: '22.5px',
      fontWeight: 700,
      marginTop: 10,
    },
  },
  subtitleWeekly: {
    fontSize: '15px',
    fontWeight: 400,
  },
  buttonWeekly: {
    color: '#fff',
    marginTop: '20px',
    fontSize: '17px',
    marginBottom: '30px',
  },
  media: {
    maxWidth: 550,
    // float: 'right',
    // margin: '-10px 0px -27px 0px !important',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 350,
      margin: -10,
    },
  },
  banerPaper: {
    padding: '0px 0px 0px 0px',
    margin: '100px auto 20px auto',
    color: '#333',
    // textAlign: 'left',
    // width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    fontSize: '21.6px',
    border: '0px solid #fff',
    // backgroundColor: '#fafafa',

    '& img':{
      margin: '20px 20px 20px 0px',
      // maxWidth: 400,
      width: '90%',
    }
  },
  banerHeadline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto 10px auto',
    fontSize: '17px',
    fontWeight: 400,
    textAlign: 'center',
    // marginTop: '30px',
    padding: '0px 30px',
  },

  gridFilters: {
    textAlign: 'left',
    maxWidth: 1142,
    margin: '20px auto 0px auto',
    width: '100%',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  [theme.breakpoints.down('sm')]: {
    homeImg: {
      display: 'none !important',
    },
    featureHeadline: {
      margin: '0px auto',
    },
    media: {
      margin: '20px auto 0px auto !important',
    },
  },
  [theme.breakpoints.up('md')]: {
    homeMobileImg: {
      display: 'none !important',
    },
  },
  [theme.breakpoints.up('sm')]: {
    mediaMobile: {
      display: 'none !important',
      margin: '0px auto 0px auto !important',
    },
  },
  [theme.breakpoints.down('xs')]: {
    homeMobileImg: {
      width: '201% !important',
    },
    mediaMobile: {
      display: 'block !important',
      margin: '0px auto 0px auto !important',
    },
    mediaDesktop: {
      display: 'none !important',
    },
    featureHeadline: {
      margin: '0px auto',
    },
    media: {
      margin: '20px auto 0px auto !important',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  categoryIcon: {
    verticalAlign: 'middle',
    margin: '8px 10px 10px 0px',
    color: '#02c39a',
  },
  card: {
    borderRadius: 15,
    border: '1px solid #f2f2f2',
    minHeight: 86,
    // maxWidth: 120,
    minWidth: 120,
    marginBottom: 8,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)',
    // boxShadow: 'none',
    marginRight: 5,

    '&:hover': {
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)',
    },
  },
  cardEmpty: {
    borderRadius: 15,
    border: '1px solid #f2f2f2',
    minHeight: 149,
    minWidth: 120,
    marginBottom: 8,
    // boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)',
    marginRight: 5,
    backgroundColor: 'transparent',

    '&:hover': {
      boxShadow: '0 5px 10px 0 rgba(0, 0, 0, 0.03)',
    },
  },
  cardName: {
    marginBottom: 0,
    color: '#000',
    textDecoration: 'none',
    fontSize: 13,
    fontWeight: 500,
  },
  coverImage: {
    float: 'right',
    width: 320,
    marginTop: -10,

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  icon: {
    fontSize: 21,
    fontWeight: 600,
    marginTop: 0,
    marginRight: 0,
    marginLeft: -5,
    padding: '8px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  activityItem: {
    fontWeight: 600,
    fontSize: 13,
    whiteSpace: 'nowrap',
    display: 'inline-block',
  },
  vibeRoot: {
    padding: '30px 0px 40px 0px',
    width: '90%',
    margin: '0px auto',
    display: 'block',
  },
  vibeThumb: {
    backgroundColor: '#fff',
    padding: '12px 13px 14px 13px',
    marginTop: '-15px !important',
    boxShadow: '0 2.5px 7.5px 0 rgba(0, 0, 0, 0.1)',
    marginLeft: '-15px !important',
    fontSize: 13,
  },
  vibeMark: {
    width: 4,
    opacity: 1,
    backgroundColor: '#fff',
  },
  vibeLabel: {
    fontWeight: 500,
    fontSize: 13,
    color: '#828282',
    padding: '0px 7px',
  },
  vibeLabelSelected: {
    fontWeight: 700,
    fontSize: 13,
    color: '#333',
    padding: '0px 7px',
  },
  buttonBookOutlined: {
    color: '#02c39a',
    margin: '10px -6px 10px 10px',
    border: '2px solid #02c39a',
    textTransform: 'none',
    borderRadius: 45,
    fontWeight: 800,
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',
    // minWidth: 155,

    '&:hover': {
      border: '2px solid #02c39a',
      boxShadow: 'none',
    }
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 45,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  travelStyleUpdateBtn: {
    display: 'block',
    float: 'inherit',
    padding: '13px 30px !important',
    margin: '0px auto',
  },
  categoriesContainer: {
    width: 'calc(100% - 17px)',
    margin: '25px auto -30px auto',
    maxWidth: 1092,
    paddingRight: '2rem',
    top: 0,
    whiteSpace: 'nowrap',
    overflowX: 'auto',

    [theme.breakpoints.down('sm')]: {
      // margin: '70px auto -20px auto',
      margin: -10,
    },
  },

  travelStylePaper: {
    // border: '1px solid #f2f2f2',
    padding: '5px 10px',
    marginTop: 50,
    marginBottom: -30,
    // backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: '0px -3px',
    backgroundColor: 'transparent',
    // borderBottom: '1px solid #eee',
    borderRadius: 0,

    // [theme.breakpoints.down('xs')]: {
    //   display: 'none',
    // },
  },
  travelStyleIcon: {
    verticalAlign: 'middle',
    float: 'left',
    padding: '14px',
    margin: '0px 20px 0px 10px',
    backgroundColor: '#fff',
    color: '#02c39a',
    borderRadius: 25,
    display: 'block',

    ["@media (max-width:704px)"]: {
      display: 'none !important',
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  travelStyleTestBtn: {
    margin: '17px 10px 0px 0px !important',
    float: 'right',
    minHeight: 54,

    [theme.breakpoints.down('xs')]: {
      margin: '0px !important',
      display: 'block',
      height: 46,
      minHeight: 46,
      padding: 0,

      // float: 'inherit',
      // width: '100%',
      // padding: '13px 5px !important',
      // fontSize: 17,
    },
  },
  travelStyleValues: {
    textAlign: 'center',
  },
  travelStyleHidden: {
    ["@media (max-width:504px)"]: {
      display: 'none !important',
    },
  },
  travelStyleCategoryIcon: {
    verticalAlign: 'middle',
    margin: '0px 10px 0px 0px',
    color: '#02c39a',
  },
  divider: {
    height: 45,
    margin: '27px 0px 0px -1px',
    backgroundColor: '#f2f2f2',
  },
})

export default withRouter(withStyles(styles)(UserPreferences))
