import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  CardMedia,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Icon,
  IconButton,
  Slide,
  TextField,
  Typography,
} from '@material-ui/core'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth';
// import GetStartedField from './GetStartedField'
import GoogleSignInButton from '../buttons/GoogleSignInButton'
import FacebookSignInButton from '../buttons/FacebookSignInButton'
import AppleSignInButton from '../buttons/AppleSignInButton'
import SignupForm from './SignupForm';
import Cookies from 'universal-cookie'
import ReactPixel from 'react-facebook-pixel'
import { config } from '../../config.js'

const cookies = new Cookies();

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class SignupDialog extends Component {
  constructor(props) {
    super(props);
    this.state = {}
  }

  handleClose = (event, reason) => {
    if (reason !== "backdropClick") {
      if (this.props.onSigninModalChanged !== undefined) {
        this.props.onSigninModalChanged(false, 'signup');
      }
    }
  };

  handleSignin = (user) => {
    this.props.onSigninModalChanged(false, 'signup');
    this.props.onSignin(user)

    // // Don't redirect after quiz signup
    // if (!this.props.signinDialogForce && user && !user.travel_preference) {
    //   this.props.history.push('/user/personalize')

    // } else {
    //   let redirectTo = cookies.get('redirectTo')

    //   if (redirectTo) {
    //     cookies.remove('redirectTo', { path: '/', domain: config.api.DOMAIN });
    //     this.props.history.push(redirectTo)
    //   } else {
    //     this.props.history.push('/')
    //   }
    // }

    let redirectTo = cookies.get('redirectTo')

    if (redirectTo) {
      cookies.remove('redirectTo', { path: '/', domain: config.api.DOMAIN });
      this.props.history.push(redirectTo)
    } else {
      this.props.history.push('/')
    }
  }

  showFullscreen = () => {
    if (isWidthUp('sm', this.props.width)) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    const { classes } = this.props

    return (
      <Dialog
        fullWidth={true}
        fullScreen={this.showFullscreen()}
        maxWidth={isWidthUp('md', this.props.width) ? 'md' : 'xs'}
        open={this.props.openedSigninDialog}
        onClose={(this.props.hideCloseBtn !== true) ? () => {} : this.handleClose}
        aria-labelledby="signup-dialog"
        classes={{paper: classes.dialogRoot}}
        TransitionComponent={Transition}
        scroll={'body'}
        disableEscapeKeyDown={this.props.hideCloseBtn === true}
      >
        <DialogTitle id="signup-dialog-title" className={classes.dialogTitle}>
          {(this.props.hideCloseBtn !== true) && <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleClose}>
            <Icon  className="notranslate">close</Icon>
          </IconButton>}
        </DialogTitle>
        <DialogContent className={classes.dialogContentText}>
            <Grid container spacing={1} direction="row" justify="center" alignItems="center">

              <Grid item xs={12} md={6} style={{minHeight: 400}}>
                <SignupForm
                  type={this.props.dialogType || 'signup'}
                  onSignin={this.handleSignin}
                  user={this.props.user}
                  title={this.props.title}
                  subtitle={this.props.subtitle}
                  hideHeader={false}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <div className={classes.dialogImage}>
                  <CardMedia
                    component="img"
                    image={'/cards-group.png'}
                    srcSet="/cards-group@2x.png 2x,
                            /cards-group@3x.png 3x"
                    title="Discover Weekly"
                    alt="Discover Weekly"
                  />
                </div>
              </Grid>

            </Grid>
        </DialogContent>
      </Dialog>
    )
  }
}

const styles = theme => ({
  dialogRoot: {
    // backgroundColor: '#fafafa',
    // backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  dialogTitle: {
    float: 'left',
    backgroundColor: 'transparent',
    position: 'absolute',
    width: 'inherit',
    zIndex: 2,

    [theme.breakpoints.down('xs')]: {
      width: '87%',
    },
  },
  dialogContentText: {
    paddingLeft: 50,
    paddingBottom: 30,
    overflow: 'hidden',

    [theme.breakpoints.down('sm')]: {
      padding: 30,
      overflowX: 'hidden',
    },

    [theme.breakpoints.down('xs')]: {
      padding: 20,
      overflowX: 'hidden',
    },
  },
  closeButton: {
    float: 'right',
  },
  dialogImage: {
    backgroundImage: 'url(/green-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'relative',
    margin: '-9px -24px -31px 0px',
    padding: '94px 30px 19px 30px',

    '& > img': {
      margin: 'auto',
      display: 'block',
      verticalAlign: 'middle',
      position: 'relative',
      maxWidth: 500,
    },

    [theme.breakpoints.down('sm')]: {
      margin: '0px -24px -50px -49px',
      padding: '10px 30px 10px 70px',
      backgroundImage: 'none',
      display: 'none'
    },
  },
  headline: {
    textAlign: 'left',
    lineHeight: 1.67,
    color: '#303030',
    fontSize: '27px',
    fontWeight: 800,

    [theme.breakpoints.up('md')]: {
      margin: '31px 0px 0px 0px',
    },
    [theme.breakpoints.down('sm')]: {
      margin: '-40px 0px 0px 0px',
    },
    [theme.breakpoints.down('xs')]: {
      margin: '-31px 0px 30px 0px',
    },
  },
  subtitle: {
    fontWeight: 600,
    fontSize: '14px !important',
    // fontSize: '12px',
    color: '#797979',
    display: 'block',
    marginBottom: '20px !important',
  },
  discoverIcon: {
    verticalAlign: 'middle',
    color: '#02c39a',
    marginRight: 8,
  },
  thankyouIcon: {
    verticalAlign: 'middle',
    padding: '10px',
    backgroundColor: '#02c39a',
    width: 36,
    height: 36,
    borderRadius: 50,
    margin: '5px auto 5px auto',

    '& > img': {
      margin: '6px 0px 0px 3px',
    },
  },
  btnLink: {
    borderRadius: 25,
    color: '#02c39a',
    fontWeight: 600,
    marginTop: -3,
  },
  btnBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px 0px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '13px 35px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  textfield: {
    backgroundColor: '#fff',
    marginTop: 5,
    width: '94%',
    maxWidth: 375,
    borderRadius: 50,
    borderWidth: 1,
    padding: 9,
    boxShadow: '0 2.5px 5px 0 rgba(0, 0, 0, 0.05)',
    fontWeight: 500,

    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
      fontWeight: 500,
    },

    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
  },
  passcode: {
    '& input': {
      letterSpacing: 40,
      marginLeft: 20,
      border: 0,
      backgroundPosition: 'bottom',
      backgroundSize: '50px 1px',
      backgroundRepeat: 'repeat-x',
      backgroundPositionX: 35,
      fontWeight: 700,
    },
  },
  button: {
    width: '100%',
    maxWidth: 393,
    borderRadius: 50,
    borderWidth: 1,
    padding: 12,
    color: '#fff',
    backgroundColor: '#02c39a',
    marginTop: 14,
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: 15,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
  buttonWhite: {
    width: '100%',
    maxWidth: 393,
    borderRadius: 50,
    borderWidth: 2,
    borderColor: '#ddd',
    padding: 12,
    color: '#333',
    backgroundColor: '#fff',
    marginTop: 14,
    boxShadow: 'none',
    fontWeight: 600,
    fontSize: 13,
    padding: 10,
    // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',

    '&:hover': {
      borderWidth: 2,
      borderColor: '#ddd',
      // boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
    },
  },
  buttonIcon: {
    fontSize: 16,
  },
  divider: {
    width: '100%',
    margin: '10px 0px 6px 0px',
    backgroundColor: '#eee',
  },
})

export default withRouter(withStyles(styles)(withWidth()(SignupDialog)))
