import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Button,
  Grid,
  Paper,
  CardMedia,
  Icon,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar'
import PersonalityTestBanner from '../../components/forms/PersonalityTestBanner'
import Cookies from 'universal-cookie'

const cookies = new Cookies();

const CAMPAIGNS = {
  'discover-weekly-trips-for-single-people': {
    title: 'Discover Weekly trips for single people',
    description: 'Are you single ? Would you like to meet new people on a trip ? Join a travel community and discover trips where you could meet other single people.',
    images_prefix: 'discover-weekly-trips-for-single-people',
  },
}

class HowItWorks extends Component {
  constructor(props) {
    super(props)

    let campaign = CAMPAIGNS[props.campaign] ? props.campaign : 'discover-weekly-trips-for-single-people';

    this.state = {
      campaign: campaign,
      title: CAMPAIGNS[campaign].title,
      description: CAMPAIGNS[campaign].description,
      openedSigninDialog: false,
      openedSigninDialogType: 'signup',
    }
  }

  handleSigninChange = (flag, dialogType = 'signup') => {
    this.setState({
      openedSigninDialog: flag,
      openedSigninDialogType: dialogType
    })
  }

  render() {
    const { classes } = this.props
    // const discover_weekly_cover = `/images/${CAMPAIGNS[this.state.campaign].images_prefix}-${Math.floor((new Date().getDate()) % 8)+1}.jpg`

    return (
      <Fragment>
        <AppTopBar
          hideMenu={false}
          hideCurrency={true}
          showBackButton={true}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onChange={this.props.onFilterChange}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.props.onSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'pages'}
          openedSigninDialog={this.state.openedSigninDialog}
          signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
        />

        <div className="destinations explore">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginTop: 40, marginBottom: -20, paddingBottom: 52, backgroundColor: '#fff'}}>
              <h1 className={classes.headline}>
                <br/><br/><br/>
                <strong className="main-title" style={{fontWeight: 800}}>
                  How it works
                  <br/>
                </strong>
                <small className={classes.subtitle}>
                  Learn more about Discover weekly, Itineraries and Self-guided tours
                </small>
              </h1>
            </Paper>
          </div>

          <div className="destinations-container" ref={ (ref) => this.refHowItWorks = ref }>
            <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>
              <Paper className={classes.banerPaper} id="how-it-works">

                <Grid container alignItems={'center'} justify={'center'} style={{minHeight: '500px', marginBottom: 60, paddingTop: 0}}>
                  <Grid item xs={12} sm={6}>
                    <h2 className={classes.featureHeadline}>
                      <strong className="main-title" style={{fontSize: 28, margin: '0px', fontWeight: 800}}>
                        <span className={classes.green}>1.</span> Personalized discovery
                      </strong>
                      <small className={classes.featureSubtitle}>
                        Can’t decide where to go next? Every Monday, our AI engine travels through the web and finds you the best travel destinations based on your taste and preferences. The same way you are discovering new music on Spotify or movies on Netflix.
                      </small>
                      <br/>
                      <small className={classes.featureSubtitle}>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Road trips within 4-hour drive <br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Weekend trips within 4-hour travel time <br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Week-long trips to new destinations<br/>
                      </small>
                      <br/>
                      <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={() => { this.props.history.push('/user/personalize') }} style={{margin: '0px 5px 0px 0px'}}>
                        Let's start
                      </Button>
                    </h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardMedia
                      component="img"
                      className={classes.media}
                      image={'/feature-3.jpg'}
                      title="Discover Weekly"
                      width="10%"
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems={'center'} justify={'center'} style={{minHeight: '500px', marginBottom: 60, paddingTop: 60}}>
                  <Grid item xs={12} sm={6}>
                    <CardMedia
                      component="img"
                      className={classes.mediaDesktop}
                      image={'/feature-4.jpg'}
                      title="Discover Weekly"
                      width="10%"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <h2 className={classes.featureHeadline} style={{margin: '0px auto 30px auto'}}>
                      <strong className="main-title" style={{fontSize: 28, margin: '0px', fontWeight: 800}}>
                        <span className={classes.green}>2.</span> Instant itinerary
                      </strong>
                      <small className={classes.featureSubtitle}>
                        You don’t have to spend your after hours planning your weekend trip. All you do is choose your destination and the itinerary is taken care of.
                      </small>
                      <br/>
                      <small className={classes.featureSubtitle}>
                        Itinerary includes:
                        <br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Bookable travel ticket <br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Accommodation nearby<br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Self-guided walking or driving tours<br/>
                        <Icon style={{verticalAlign: 'middle'}}>done</Icon> Activities<br/>
                      </small>
                      <br/>
                      <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={() => { this.props.history.push('/trip/to/london,united-kingdom') }} style={{margin: '0px 5px 0px 0px'}}>
                        Check trip to London
                      </Button>
                    </h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardMedia
                      component="img"
                      className={classes.mediaMobile}
                      image={'/feature-4.jpg'}
                      title="Discover Weekly"
                      width="10%"
                    />
                  </Grid>
                </Grid>

                <Grid container alignItems={'center'} justify={'center'} style={{minHeight: '500px', marginBottom: 60, paddingTop: 60}}>

                  <Grid item xs={12} sm={6}>
                    <h2 className={classes.featureHeadline}>
                      <strong className="main-title" style={{fontSize: 28, margin: '0px', fontWeight: 800}}>
                      <span className={classes.green}>3.</span> Self-guided tours
                      </strong>
                      <small className={classes.featureSubtitle}>
                        Once you find an interesting destination, check what you could do there. Even before booking travel you could better compare and choose place where you go on a trip.
                      </small>
                      <br/>
                      <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={() => { this.props.history.push('/trip/to/rome,italy') }} style={{margin: '0px 5px 0px 0px'}}>
                        Check sample tour
                      </Button>
                    </h2>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <CardMedia
                      component="img"
                      className={classes.media}
                      image={'/feature-5.jpg'}
                      srcSet={'/feature-5.jpg 1x, /feature-5@2x.jpg 2x'}
                      title="Discover Weekly"
                      width="10%"
                    />
                  </Grid>
                </Grid>

              </Paper>
              <br/>
              <br/>

              {!this.props.user.is_registered && <PersonalityTestBanner/>}
            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  featureMedia: {
    // maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',
    width: '50vw',
    minWidth: '960px',
    maxWidth: '1200px',
    position: 'absolute',
    left: '500px',
    top: '-200px',
    display: 'block',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
    },
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      margin: '-100px auto',
    },
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '30px',
    padding: '0px 20px',
  },
  featureHeadline: {
    maxWidth: 400,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px 0px 30px 0px',
    fontSize: '20px',
    fontWeight: 400,
    textAlign: 'left',
    // marginTop: '30px',
    padding: '0px 10px',
  },
  featureSubtitle: {
    color: '#828282',
    display: 'block',

    margin:' 10px 0px 0px 0px',
    lineHeight: '30px',
    maxHeight: '158px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    position: 'relative',
    fontSize: 16,
    fontWeight: 600,

    [theme.breakpoints.up('md')]: {
      maxWidth: '60vw',
    },

    '&::before': {
      content: "...",
      position: 'absolute',
      bottom: 0,
      right: 0,
    }
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 10,
  },
  cardRoot: {
    textAlign: 'left',
    position: 'relative',
    fontSize: '20px',
    maxWidth: '1119px',
    margin: '32px auto',
    width: '95vw',
    // minHeight: '80vh',
  },
  // media: {
  //   margin: '20px -25px -25px -25px',
  //   height: '90vh',

  //   [theme.breakpoints.up('md')]: {
  //     margin: '-25px -25px -25px 0px',
  //   },
  // },
  titleWeekly: {
    padding: '20px 40px 20px 20px',
    width: '70px',
    display: 'block',
    textAlign: 'left',

    '& h5': {
      fontSize: '22.5px',
      fontWeight: 700,
      marginTop: 10,
    },
  },
  subtitleWeekly: {
    fontSize: '15px',
    fontWeight: 400,
  },
  buttonWeekly: {
    color: '#fff',
    marginTop: '20px',
    fontSize: '17px',
    marginBottom: '30px',
  },
  media: {
    maxWidth: 550,
    // float: 'right',
    // margin: '-10px 0px -27px 0px !important',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 350,
      margin: -10,
    },
  },
  banerPaper: {
    padding: '0px 0px 0px 0px',
    margin: '100px auto 20px auto',
    color: '#333',
    // textAlign: 'left',
    // width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    fontSize: '21.6px',
    border: '0px solid #fff',
    // backgroundColor: '#fafafa',

    '& img':{
      margin: '20px 20px 20px 0px',
      // maxWidth: 400,
      width: '90%',
    }
  },
  banerHeadline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto 10px auto',
    fontSize: '17px',
    fontWeight: 400,
    textAlign: 'center',
    // marginTop: '30px',
    padding: '0px 30px',
  },

  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',
  },
  [theme.breakpoints.down('sm')]: {
    homeImg: {
      display: 'none !important',
    },
    featureHeadline: {
      margin: '0px auto',
    },
    media: {
      margin: '20px auto 0px auto !important',
    },
  },
  [theme.breakpoints.up('md')]: {
    homeMobileImg: {
      display: 'none !important',
    },
  },
  [theme.breakpoints.up('sm')]: {
    mediaMobile: {
      display: 'none !important',
      margin: '0px auto 0px auto !important',
    },
  },
  [theme.breakpoints.down('xs')]: {
    homeMobileImg: {
      width: '201% !important',
    },
    mediaMobile: {
      display: 'block !important',
      margin: '0px auto 0px auto !important',
    },
    mediaDesktop: {
      display: 'none !important',
    },
    featureHeadline: {
      margin: '0px auto',
    },
    media: {
      margin: '20px auto 0px auto !important',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
})

export default withRouter(withStyles(styles)(HowItWorks))
