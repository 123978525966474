import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import SelectorOption from '../../components/chat/SelectorOption'
import SliderOption from '../../components/chat/SliderOption'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import { THINGS_TO_DO, CUISINES } from '../../utils/lists'

const theme = {
  background: '#fff',
  fontFamily: 'Manrope,Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
  headerBgColor: '#02c39a',
  headerFontColor: '#333',
  headerFontSize: '15px',
  botBubbleColor: 'rgb(250, 250, 250)',
  botFontColor: '#333',
  botFontSize: '14px',
  userBubbleColor: 'rgba(7, 195, 153, 0.26)',
  userFontColor: '#000',
};

class PlannerQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      preferences: props.preferences || {},
    }
  }

  submitRequest = ({ steps, values }) => {
    const test = values.reduce(((r, c) => Object.assign(r, c)), {})
    const preferences = values
    this.setState({preferences: preferences}, this.props.onUpdated({preferences: preferences}))
  }

  getUser = () => {
    return this.props.user
  }

  render() {
    const { classes } = this.props
    const { preferences } = this.state
    const steps = [
      // General Travel Preferences
      // {
      //   id: '1',
      //   message: "What type of destination do you prefer for your trip?",
      //   trigger: '2',
      //   hideInput: true,
      // },
      // {
      //   id: '2',
      //   component: <SelectorOption name={"destination_type"} selected={preferences.destination_type} list={[
      //     {icon: 'beach_access', value: 'beach', title: 'Beach', imageSrc: '/destinations/beach.jpg'},
      //     {icon: 'landscape', value: 'mountains', title: 'Mountains', imageSrc: '/destinations/mountains.jpg'},
      //     {icon: 'location_city', value: 'city', title: 'City', imageSrc: '/destinations/city.jpg'},
      //     {icon: 'park', value: 'countryside', title: 'Countryside', imageSrc: '/destinations/countryside.jpg'},
      //     {icon: 'volcano', value: 'island', title: 'Island', imageSrc: '/destinations/island.jpg'},
      //     {icon: 'castle', value: 'historical', title: 'Historical Sites', imageSrc: '/destinations/historical.jpg'},
      //     {icon: 'downhill_skiing', value: 'ski_resort', title: 'Ski Resort', imageSrc: '/destinations/ski_resort.jpg'},
      //     {icon: 'forest', value: 'national_park', title: 'National Park', imageSrc: '/destinations/national_park.jpg'},
      //     {icon: 'sailing', value: 'cruise', title: 'Cruise', imageSrc: '/destinations/cruise.jpg'},
      //     {icon: 'spa', value: 'wellness_retreat', title: 'Wellness Retreat', imageSrc: '/destinations/wellness.jpg'}
      //   ]} showImages={true} multiple={true} maxSelected={3}/>,
      //   waitAction: true,
      //   trigger: '3',
      //   hideInput: true,
      // },
      // {
      //   id: '3',
      //   message: "What's your preferred climate for this trip?",
      //   trigger: '4',
      //   hideInput: true,
      // },
      // {
      //   id: '4',
      //   component: <SelectorOption name={"climate_preference"} selected={preferences.climate_preference} list={[
      //     {icon: 'wb_sunny', value: 'tropical', title: 'Tropical', imageSrc: '/climate/tropical.jpg'},
      //     {icon: 'ac_unit', value: 'cold', title: 'Cold', imageSrc: '/climate/cold.jpg'},
      //     {icon: 'thermostat', value: 'temperate', title: 'Temperate', imageSrc: '/climate/temperate.jpg'},
      //     {icon: 'waves', value: 'mediterranean', title: 'Mediterranean', imageSrc: '/climate/mediterranean.jpg'},
      //     {icon: 'terrain', value: 'desert', title: 'Desert', imageSrc: '/climate/desert.jpg'}
      //   ]} showImages={true} multiple={false}/>,
      //   waitAction: true,
      //   trigger: '5',
      //   hideInput: true,
      // },
      // {
      //   id: '5',
      //   message: "How long do you plan to stay at your destination?",
      //   trigger: '6',
      //   hideInput: true,
      // },
      // {
      //   id: '6',
      //   component: <SelectorOption name={"trip_duration"} selected={preferences.trip_duration} list={[
      //     {icon: 'weekend', value: 'weekend', title: 'Weekend Getaway (1-3 days)'},
      //     {icon: 'event_available', value: 'short', title: 'Short Trip (4-7 days)'},
      //     {icon: 'date_range', value: 'medium', title: 'Medium Trip (1-2 weeks)'},
      //     {icon: 'calendar_month', value: 'long', title: 'Long Trip (2-4 weeks)'},
      //     {icon: 'flight_takeoff', value: 'extended', title: 'Extended Stay (1+ months)'}
      //   ]} showImages={false} multiple={false}/>,
      //   waitAction: true,
      //   trigger: '9',
      //   hideInput: true,
      // },
      // {
      //   id: '9',
      //   message: "What's your preferred pace of travel?",
      //   trigger: '10',
      //   hideInput: true,
      // },
      // {
      //   id: '10',
      //   component: <SliderOption leftLabel="Relaxed" rightLabel="Fast-paced" defaultValue={0} selected={preferences.travel_pace ? preferences.travel_pace : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
      //     {travel_pace: -1.0, label: "Very Relaxed"},
      //     {travel_pace: -0.5, label: "Relaxed"},
      //     {travel_pace: 0, label: "Balanced"},
      //     {travel_pace: 0.5, label: "Active"},
      //     {travel_pace: 1.0, label: "Very Active"},
      //   ]}/>,
      //   waitAction: true,
      //   trigger: '11',
      //   hideInput: true,
      // },

      // Accommodation Preferences
      {
        id: '11',
        message: "What's your preferred accommodation type?",
        trigger: '12',
        hideInput: true,
      },
      {
        id: '12',
        component: <SelectorOption name={"accommodation_type"} selected={preferences.accommodation_type} list={[
          {icon: 'hotel', value: 'hotel', title: 'Hotel', imageSrc: '/accommodation/hotel.jpg'},
          {icon: 'apartment', value: 'apartment', title: 'Apartment', imageSrc: '/accommodation/apartment.jpg'},
          {icon: 'single_bed', value: 'hostel', title: 'Hostel', imageSrc: '/accommodation/hostel.jpg'},
          {icon: 'beach_access', value: 'resort', title: 'Resort', imageSrc: '/accommodation/resort.jpg'},
          {icon: 'villa', value: 'villa', title: 'Villa', imageSrc: '/accommodation/villa.jpg'},
          {icon: 'cabin', value: 'cabin', title: 'Cabin', imageSrc: '/accommodation/cabin.jpg'},
          {icon: 'festival', value: 'camping', title: 'Camping', imageSrc: '/accommodation/camping.jpg'},
          {icon: 'rv_hookup', value: 'rv', title: 'RV/Campervan', imageSrc: '/accommodation/rv.jpg'}
        ]} showImages={true} multiple={true} maxSelected={6}/>,
        waitAction: true,
        delay: 0,
        trigger: '13',
        hideInput: true,
      },
      {
        id: '13',
        message: "What type of accommodation amenities are most important to you?",
        trigger: '14',
        hideInput: true,
      },
      {
        id: '14',
        component: <SelectorOption name={"accommodation_amenities"} selected={preferences.accommodation_amenities} list={[
          {icon: 'wifi', value: 'wifi', title: 'Free Wi-Fi', imageSrc: '/amenities/wifi.jpg'},
          {icon: 'pool', value: 'pool', title: 'Swimming Pool', imageSrc: '/amenities/pool.jpg'},
          {icon: 'fitness_center', value: 'gym', title: 'Fitness Center', imageSrc: '/amenities/gym.jpg'},
          {icon: 'restaurant', value: 'restaurant', title: 'On-site Restaurant', imageSrc: '/amenities/restaurant.jpg'},
          {icon: 'local_parking', value: 'parking', title: 'Free Parking', imageSrc: '/amenities/parking.jpg'},
          {icon: 'ac_unit', value: 'ac', title: 'Air Conditioning', imageSrc: '/amenities/ac.jpg'},
          {icon: 'spa', value: 'spa', title: 'Spa Services', imageSrc: '/amenities/spa.jpg'},
          {icon: 'room_service', value: 'room_service', title: 'Room Service', imageSrc: '/amenities/room_service.jpg'},
          {icon: 'local_laundry_service', value: 'laundry', title: 'Laundry Facilities', imageSrc: '/amenities/laundry.jpg'},
          {icon: 'kitchen', value: 'kitchen', title: 'In-room Kitchen/Kitchenette', imageSrc: '/amenities/kitchen.jpg'},
          {icon: 'balcony', value: 'balcony', title: 'Private Balcony/Terrace', imageSrc: '/amenities/balcony.jpg'},
          {icon: 'meeting_room', value: 'business_center', title: 'Business Center', imageSrc: '/amenities/business_center.jpg'},
          {icon: 'family_restroom', value: 'family_services', title: 'Family/Child Services', imageSrc: '/amenities/family_services.jpg'},
          {icon: 'pets', value: 'pet_friendly', title: 'Pet-Friendly', imageSrc: '/amenities/pet_friendly.jpg'},
          {icon: 'local_bar', value: 'bar', title: 'Bar/Lounge', imageSrc: '/amenities/bar.jpg'},
          {icon: 'beach_access', value: 'beach_access', title: 'Beach Access', imageSrc: '/amenities/beach_access.jpg'},
          {icon: 'local_cafe', value: 'breakfast', title: 'Free Breakfast', imageSrc: '/amenities/breakfast.jpg'},
          {icon: 'elevator', value: 'elevator', title: 'Elevator', imageSrc: '/amenities/elevator.jpg'},
          {icon: 'hot_tub', value: 'hot_tub', title: 'Hot Tub', imageSrc: '/amenities/hot_tub.jpg'},
          {icon: 'security', value: '24hr_security', title: '24-hour Security', imageSrc: '/amenities/security.jpg'}
        ]} showImages={false} multiple={true} maxSelected={8}/>,
        waitAction: true,
        delay: 0,
        trigger: '15',
        hideInput: true,
      },

      // Activities and Experiences
      {
        id: '15',
        message: "What activities are you most interested in during your trip?",
        trigger: '16',
        hideInput: true,
      },
      {
        id: '16',
        component: <SelectorOption name={"city_activities"} selected={preferences.city_activities} list={[
          {icon: 'museum', value: 'cultural', title: 'Museums & Galleries', imageSrc: '/activities/museums.jpg'},
          {icon: 'restaurant', value: 'culinary', title: 'Food Tours & Restaurants', imageSrc: '/activities/food_tours.jpg'},
          {icon: 'theater_comedy', value: 'theater', title: 'Theater & Performing Arts', imageSrc: '/activities/theater.jpg'},
          {icon: 'local_bar', value: 'nightlife', title: 'Nightlife & Bars', imageSrc: '/activities/nightlife.jpg'},
          {icon: 'shopping_bag', value: 'shopping', title: 'Shopping & Markets', imageSrc: '/activities/shopping.jpg'},
          {icon: 'architecture', value: 'architecture', title: 'Architectural Tours', imageSrc: '/activities/architecture.jpg'},
          {icon: 'directions_walk', value: 'walking_tours', title: 'Walking Tours', imageSrc: '/activities/walking_tours.jpg'},
          {icon: 'photo_camera', value: 'photography', title: 'Urban Photography', imageSrc: '/activities/urban_photography.jpg'},
          {icon: 'park', value: 'parks', title: 'City Parks & Gardens', imageSrc: '/activities/city_parks.jpg'},
          {icon: 'history', value: 'historical', title: 'Historical Sites', imageSrc: '/activities/historical_sites.jpg'},
          {icon: 'local_cafe', value: 'cafe_culture', title: 'Cafe Culture', imageSrc: '/activities/cafe_culture.jpg'},
          {icon: 'sports_soccer', value: 'sports_events', title: 'Sports Events', imageSrc: '/activities/sports_events.jpg'},
          {icon: 'festival', value: 'festivals', title: 'Urban Festivals & Events', imageSrc: '/activities/urban_festivals.jpg'},
          {icon: 'local_movies', value: 'cinema', title: 'Cinema & Film', imageSrc: '/activities/cinema.jpg'},
          {icon: 'brush', value: 'street_art', title: 'Street Art Tours', imageSrc: '/activities/street_art.jpg'},
          {icon: 'wine_bar', value: 'wine_tasting', title: 'Wine Bars & Tastings', imageSrc: '/activities/wine_bars.jpg'},
          {icon: 'directions_bike', value: 'city_biking', title: 'City Biking', imageSrc: '/activities/city_biking.jpg'},
          {icon: 'science', value: 'science_centers', title: 'Science Centers', imageSrc: '/activities/science_centers.jpg'},
          {icon: 'local_mall', value: 'malls', title: 'Shopping Malls', imageSrc: '/activities/shopping_malls.jpg'},
          {icon: 'rowing', value: 'river_activities', title: 'River/Canal Activities', imageSrc: '/activities/river_activities.jpg'},
          {icon: 'tour', value: 'popular_attractions', title: 'Popular Attractions', imageSrc: '/activities/popular_attractions.jpg'}
        ]} showImages={false} multiple={true} maxSelected={8}/>,
        waitAction: true,
        delay: 0,
        trigger: '19',
        hideInput: true,
      },
      {
        id: '19',
        message: "What type of cuisine are you most interested in experiencing?",
        trigger: '20',
        hideInput: true,
      },
      {
        id: '20',
        component: <SelectorOption name={"cuisine_preference"} selected={preferences.cuisine_preference} list={[
          {icon: 'ramen_dining', value: 'asian', title: 'Asian Cuisine'},
          {icon: 'local_pizza', value: 'mediterranean', title: 'Mediterranean Cuisine'},
          {icon: 'kebab_dining', value: 'middle_eastern', title: 'Middle Eastern Cuisine'},
          {icon: 'dinner_dining', value: 'european', title: 'European Cuisine'},
          {icon: 'lunch_dining', value: 'american', title: 'American Cuisine'},
          {icon: 'tapas', value: 'latin_american', title: 'Latin American Cuisine'},
          {icon: 'restaurant', value: 'fine_dining', title: 'Chain restaurants'},
          {icon: 'storefront', value: 'street_food', title: 'Street Food'},
          {icon: 'eco', value: 'vegetarian_vegan', title: 'Vegetarian/Vegan'},
          {icon: 'local_bar', value: 'food_and_drink', title: 'Food and Drink Tours'}
        ]} showImages={false} multiple={true} maxSelected={3}/>,
        waitAction: true,
        delay: 0,
        trigger: '23',
        hideInput: true,
      },
      {
        id: '23',
        message: "What type of souvenirs or shopping items are you most interested in?",
        trigger: '24',
        hideInput: true,
      },
      {
        id: '24',
        component: <SelectorOption name={"shopping_preference"} selected={preferences.shopping_preference} list={[
          {icon: 'palette', value: 'art_crafts', title: 'Local Art and Crafts'},
          {icon: 'wine_bar', value: 'food_drink', title: 'Local Food and Drink'},
          {icon: 'checkroom', value: 'fashion', title: 'Fashion and Clothing'},
          {icon: 'watch', value: 'jewelry', title: 'Jewelry and Accessories'},
          {icon: 'auto_stories', value: 'books', title: 'Books and Music'},
          {icon: 'home', value: 'home_decor', title: 'Home Decor'},
          {icon: 'redeem', value: 'souvenirs', title: 'Traditional Souvenirs'},
          {icon: 'storefront', value: 'markets', title: 'Local Markets'},
          {icon: 'smartphone', value: 'electronics', title: 'Electronics'},
          {icon: 'do_not_touch', value: 'no_shopping', title: 'Not interested in shopping'}
        ]} showImages={false} multiple={true} maxSelected={3}/>,
        waitAction: true,
        delay: 0,
        trigger: '25',
        hideInput: true,
      },

      // Transportation and Logistics
      {
        id: '25',
        message: "How do you prefer to get around at your destination?",
        trigger: '26',
        hideInput: true,
      },
      {
        id: '26',
        component: <SelectorOption name={"transportation"} selected={preferences.transportation} list={[
          {icon: 'directions_walk', value: 'walking', title: 'Walking', imageSrc: '/transportation/walking.jpg'},
          {icon: 'directions_bike', value: 'cycling', title: 'Cycling', imageSrc: '/transportation/cycling.jpg'},
          {icon: 'directions_bus', value: 'public_transport', title: 'Public Transport', imageSrc: '/transportation/public_transport.jpg'},
          {icon: 'directions_car', value: 'rental_car', title: 'Rental Car', imageSrc: '/transportation/rental_car.jpg'},
          {icon: 'local_taxi', value: 'taxi', title: 'Taxi/Ride-sharing', imageSrc: '/transportation/taxi.jpg'},
          {icon: 'tour', value: 'guided_tours', title: 'Guided Tours', imageSrc: '/transportation/guided_tours.jpg'},
          {icon: 'motorcycle', value: 'motorcycle', title: 'Motorcycle', imageSrc: '/transportation/motorcycle.jpg'},
        ]} showImages={false} multiple={true} maxSelected={4}/>,
        waitAction: true,
        delay: 0,
        trigger: '41',
        hideInput: true,
      },

      // Budget and Preferences
      // {
      //   id: '29',
      //   message: "What's your budget preference?",
      //   trigger: '30',
      //   hideInput: true,
      // },
      // {
      //   id: '30',
      //   component: <SliderOption leftLabel="Budget" rightLabel="Luxury" defaultValue={0} selected={0} marks={[0, 1, 2, 3, 4]} values={[
      //     {budget_conscious: 0, label: "Super Budget"},
      //     {budget_conscious: 1, label: "Budget"},
      //     {budget_conscious: 2, label: "Mid-range"},
      //     {budget_conscious: 3, label: "High-end"},
      //     {budget_conscious: 4, label: "Luxury"},
      //   ]}/>,
      //   waitAction: true,
      //   trigger: '31',
      //   hideInput: true,
      // },
      // {
      //   id: '33',
      //   message: "How important is sustainability and eco-friendliness in your travel choices?",
      //   trigger: '34',
      //   hideInput: true,
      // },
      // {
      //   id: '34',
      //   component: <SliderOption leftLabel="Not Important" rightLabel="Very Important" defaultValue={0} selected={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
      //     {sustainability_importance: -1.0, label: "Not Important"},
      //     {sustainability_importance: -0.5, label: "Somewhat Important"},
      //     {sustainability_importance: 0, label: "Moderately Important"},
      //     {sustainability_importance: 0.5, label: "Important"},
      //     {sustainability_importance: 1.0, label: "Very Important"},
      //   ]}/>,
      //   waitAction: true,
      //   trigger: '37',
      //   hideInput: true,
      // },

      // Practical Considerations
      // {
      //   id: '37',
      //   message: "How important is having access to reliable internet during your trip?",
      //   trigger: '38',
      //   hideInput: true,
      // },
      // {
      //   id: '38',
      //   component: <SliderOption leftLabel="Not Important" rightLabel="Very Important" defaultValue={0} selected={preferences.internet_importance ? preferences.internet_importance : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
      //     {internet_importance: -1.0, label: "Not Important"},
      //     {internet_importance: -0.5, label: "Somewhat Important"},
      //     {internet_importance: 0, label: "Moderately Important"},
      //     {internet_importance: 0.5, label: "Important"},
      //     {internet_importance: 1.0, label: "Very Important"},
      //   ]}/>,
      //   waitAction: true,
      //   trigger: '39',
      //   hideInput: true,
      // },
      // {
      //   id: '39',
      //   message: "What factors are most important when choosing a destination?",
      //   trigger: '40',
      //   hideInput: true,
      // },
      // {
      //   id: '40',
      //   component: <SelectorOption name={"destination_factors"} selected={preferences.destination_factors} list={[
      //     {icon: 'beach_access', value: 'scenery', title: 'Natural Scenery', imageSrc: '/factors/scenery.jpg'},
      //     {icon: 'museum', value: 'culture', title: 'Cultural Attractions', imageSrc: '/factors/culture.jpg'},
      //     {icon: 'local_cafe', value: 'food', title: 'Food Scene', imageSrc: '/factors/food.jpg'},
      //     {icon: 'hiking', value: 'outdoor_activities', title: 'Outdoor Activities', imageSrc: '/factors/outdoor.jpg'},
      //     {icon: 'beach_access', value: 'weather', title: 'Good Weather', imageSrc: '/factors/weather.jpg'},
      //     {icon: 'savings', value: 'affordability', title: 'Affordability', imageSrc: '/factors/affordability.jpg'},
      //     {icon: 'flight', value: 'accessibility', title: 'Easy to Get To', imageSrc: '/factors/accessibility.jpg'},
      //     {icon: 'family_restroom', value: 'family_friendly', title: 'Family-Friendly', imageSrc: '/factors/family.jpg'},
      //     {icon: 'local_bar', value: 'nightlife', title: 'Nightlife', imageSrc: '/factors/nightlife.jpg'},
      //     {icon: 'shopping_bag', value: 'shopping', title: 'Shopping Options', imageSrc: '/factors/shopping.jpg'}
      //   ]} showImages={true} multiple={true} maxSelected={5}/>,
      //   waitAction: true,
      //   trigger: '41',
      //   hideInput: true,
      // },
      {
        id: '41',
        message: 'Thank you for completing our comprehensive travel preferences quiz! This information will help us personalize your perfect trip to a single destination.',
        delay: 300,
        hideInput: true,
        end: true,
      },
    ];


    // const steps = [
    //   {
    //     id: '2',
    //     message: "What would you like to do on your trip?",
    //     trigger: '3',
    //     hideInput: true,
    //   },
    //   {
    //     id: '3',
    //     component: <SelectorOption name={"things_to_do"} selected={preferences.things_to_do} list={THINGS_TO_DO.sort(() => Math.random() - 0.5)} showImages={false} multiple={true} maxSelected={THINGS_TO_DO.length}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '6',
    //     hideInput: true,
    //   },
    //   {
    //     id: '6',
    //     message: "What cuisine would you like to try ?",
    //     trigger: '7',
    //     hideInput: true,
    //   },
    //   {
    //     id: '7',
    //     component: <SelectorOption name={"food_cuisine"} selected={preferences.food_cuisine} list={CUISINES.sort(() => Math.random() - 0.5)} multiple={true} showImages={true} maxSelected={5}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '8',
    //     hideInput: true,
    //   },
    //   {
    //     id: '8',
    //     message: "What vibe are you looking for on a trip? (1/4)",
    //     trigger: '10',
    //     hideInput: true,
    //   },
    //   {
    //     id: '10',
    //     component: <SliderOption leftLabel="Adventurous" rightLabel="Relaxing" defaultValue={0} selected={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
    //       {adventurous: "only adventures"},
    //       {adventurous: "mostly adventures and a bit of relax"},
    //       {adventurous: "no preference"},
    //       {adventurous: "mostly relaxing and a bit of adventure"},
    //       {adventurous: "only relaxing"},
    //     ]}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '11',
    //     hideInput: true,
    //   },
    //   {
    //     id: '11',
    //     message: "Slow-paced or fast-paced? (2/4)",
    //     trigger: '12',
    //     hideInput: true,
    //   },
    //   {
    //     id: '12',
    //     component: <SliderOption leftLabel="Slow-paced" rightLabel="Fast-paced" defaultValue={0} selected={preferences.vibe_chill_relaxing ? (-preferences.vibe_chill_relaxing + preferences.vibe_fast_paced) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
    //       {vibe_chill_relaxing: 1.0, vibe_fast_paced: 0.0},
    //       {vibe_chill_relaxing: 0.75, vibe_fast_paced: 0.25},
    //       {vibe_chill_relaxing: 0.0, vibe_fast_paced: 0.0},
    //       {vibe_chill_relaxing: 0.25, vibe_fast_paced: 0.75},
    //       {vibe_chill_relaxing: 0.0, vibe_fast_paced: 1.0},
    //     ]}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '13',
    //     hideInput: true,
    //   },
    //   {
    //     id: '13',
    //     message: "Do you prefer higher comfort or lower budget? (3/4)",
    //     trigger: '14',
    //     hideInput: true,
    //   },
    //   {
    //     id: '14',
    //     component: <SliderOption leftLabel="Comfort" rightLabel="Budget" defaultValue={0} selected={preferences.vibe_luxurious ? (-preferences.vibe_luxurious + preferences.vibe_curious) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
    //       {vibe_luxurious: 1.0, vibe_curious: 0.0},
    //       {vibe_luxurious: 0.75, vibe_curious: 0.25},
    //       {vibe_luxurious: 0.0, vibe_curious: 0.0},
    //       {vibe_luxurious: 0.25, vibe_curious: 0.75},
    //       {vibe_luxurious: 0.0, vibe_curious: 1.0},
    //     ]}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '15',
    //     hideInput: true,
    //   },
    //   {
    //     id: '15',
    //     message: "More popular or more off the beaten path? (4/4)",
    //     trigger: '16',
    //     hideInput: true,
    //   },
    //   {
    //     id: '16',
    //     component: <SliderOption leftLabel="Popular" rightLabel="Off the beaten path" defaultValue={0} selected={preferences.vibe_popular ? (-preferences.vibe_popular + preferences.vibe_off_the_beaten_path) : 0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
    //       {vibe_popular: 1.0, vibe_off_the_beaten_path: 0.0},
    //       {vibe_popular: 0.75, vibe_off_the_beaten_path: 0.25},
    //       {vibe_popular: 0.0, vibe_off_the_beaten_path: 0.0},
    //       {vibe_popular: 0.25, vibe_off_the_beaten_path: 0.75},
    //       {vibe_popular: 0.0, vibe_off_the_beaten_path: 1.0},
    //     ]}/>,
    //     waitAction: true,
    //     delay: 0,
    //     trigger: '20',
    //     hideInput: true,
    //   },
    //   {
    //     id: '20',
    //     message: 'That\'s all',
    //     // trigger: 'end',
    //     delay: 300,
    //     hideInput: true,
    //     end: true,
    //   },
    // ];

    return (
      <Fragment>
        <div className={classes.chatContainer}>
          {/* <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}> */}
            {/* <Grid item xs={12} className={classes.showcaseDestinations}> */}
              <ThemeProvider theme={theme}>
                <ChatBot
                  handleEnd={this.submitRequest}
                  steps={steps}
                  botAvatar="/apple-touch-icon.png"
                  enableSmoothScroll={true}
                  hideHeader={true}
                  hideUserAvatar={true}
                  hideSubmitButton={true}
                  botDelay={1000}
                  userDelay={300}
                  width={'100%'}
                  style={{boxShadow: 'none', height: '100%'}}
                  avatarStyle={{boxShadow: 'none', alignItems: 'flex-start'}}
                  contentStyle={{height: (isWidthUp('sm', this.props.width) ? (isWidthUp('md', this.props.width) ? 'calc(100vh - 255px)' : 'calc(100vh - 255px)') : 'calc(100vh - 182px)')}}
                  bubbleStyle={{fontSize: '15px', fontWeight: 700, boxShadow: 'none', background: 'none', maxWidth: '80%'}}
                  bubbleOptionStyle={{fontFamily: theme.fontFamily, fontSize: '13px', fontWeight: 600, backgroundColor: 'rgba(7, 195, 153, 0.26)'}}
                  customStyle={{boxShadow: 'none', padding: '16px 0px', borderRadius: 0, borderBottom: '1px solid #ddd'}}
                />
              </ThemeProvider>
            {/* </Grid> */}
          {/* </Grid> */}
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  showcaseDestinations: {
    margin: '10px 0px 0px 0px',
  },
  chatContainer: {
    padding: '0px',
    paddingTop: 0,
    minHeight: 'calc(100vh - 455px)',
    backgroundColor: '#fff',
    width: 'inherit',
    borderTop: '1px solid #ddd',
    maxWidth: 600,
  },
  h3: {},
  gridFilters: {},
  headlinePaper: {},
  headline: {},
  subtitle: {},
  listItemIcon: {},
  listItemText: {},
  listItemTextSecondary: {},
  listItemTextPrice: {},
  listItemSelected: {},
  listItem: {},
  buttonBook: {},
  bottomBar: {},
  buttonOutlined: {},
  buttonOutlinedSelected: {},
  priceGuarantee: {},
})

export default withRouter(withStyles(styles)(withWidth()(PlannerQuiz)))
