import openPopup from './openpopup'

export const authenticate = ({
    provider,
    url,
    tab = false,
    cb,
  }) => {
  let name = tab ? provider : provider;
  openPopup(provider, url, name);

  function receiveMessage(event) {
    // Do we trust the sender of this message?  (might be
    // different from what we originally opened, for example).

    if (event.origin !== window.location.origin) {
      return;
    }

    if (event.data.jwt && event.data.success) {
      cb();
    }

    window.removeEventListener('message', receiveMessage, false);
  }

  window.addEventListener('message', receiveMessage, false);
};