import React, { Component, Fragment } from 'react'
import { withRouter, Link } from 'react-router-dom'
import { config } from '../../config.js'
import {
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Icon,
  Grid,
  GridList,
  GridListTile,
  GridListTileBar,
  Paper,
  Tabs,
  Typography,
  Hidden,
} from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthDown } from '@material-ui/core/withWidth'
import { startCase, kebabCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar.js'
import PersonalityTestBanner from '../../components/forms/PersonalityTestBanner.js'
import DestinationList from '../../components/lists/DestinationList.js'
import Cookies from 'universal-cookie'
import SearchPanel from '../../components/SearchPanel.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();
let abortController = new AbortController()

const travelActivitiesTop = [
  {
    img: '/mountain-image.jpg',
    // imgSet: '/mountain-image@2x.jpg 2x, /mountain-image@3x.jpg 3x',
    title: 'Mountain adventures',
    cols: 2,
    rows: 2,
    subtitle: '3 306 destinations',
    link: '/explore/outdoors',
  }
]
const travelActivitiesData = [
  {
    img: '/beach-image.jpg',
    // imgSet: '/beach-image@2x.jpg 2x, /beach-image@3x.jpg 3x',
    title: 'Beach destinations',
    cols: 2,
    rows: 1,
    subtitle: '13 483 destinations',
    link: '/explore/beach',
  },
  {
    img: '/art-image.jpg',
    // imgSet: '/art-image@2x.jpg 2x, /art-image@3x.jpg 3x',
    title: 'Popular attractions',
    cols: 1,
    rows: 1,
    subtitle: '30 515 destinations',
    link: '/explore/popular',
  },
  {
    img: '/outdoor-image.jpg',
    // imgSet: '/outdoor-image@2x.jpg 2x, /outdoor-image@3x.jpg 3x',
    title: 'Adventures and sports',
    cols: 1,
    rows: 1,
    subtitle: '5 658 destinations',
    link: '/explore/adventures_sports',
  },
]

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class Home extends Component {

  constructor(props) {
    super(props)
    this.state = {
      destination_groups: [],
      isLoading: false,
      locationFrom: this.props.locationFrom,
      openInfo: false,
      popular_destinations: null,
      selectedInterest: '',
      openedSigninDialog: false,
      openedSigninDialogType: 'signup',
    }
  }

  componentDidMount() {
    this.getDestinations()
  }

  componentDidUpdate(prevProps) {
    if (startCase(prevProps.address) !== startCase(this.props.address)) {
      this.getDestinations()
    }
  }

  handleClickOpenInfo = () => {
    this.setState({ openInfo: true });
  };

  handleCloseInfo = () => {
    this.setState({ openInfo: false });
  };

  onFilterChange = params => {
    this.props.onFilterChange({params})
  }

  handleSelectedInterest = (interests) => {
    this.props.history.push(`/search/${interests}/from/nearby`)
  }

  saveLocationFrom = (params) => {
    this.setState({...this.state, ...params})
  }

  isSize = (size) => {
    return isWidthDown(size, this.props.width)
  }

  getDestinations = () => {
    this.setState({isLoading: true})

    // abort previous fetch
    abortController.abort()
    abortController = new AbortController();
    const signal = abortController.signal

    window.fetch(API + '/trips/examples',
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`
      },
      signal: signal,
    })
    .then(response => response.json())
    .then(data => {
      if (data) {
        this.setState(prevState => ({
          destination_groups: data.destination_groups || [],
          isLoading: false,
        }))
      }

      this.getPopularDestinations()
    })
    .catch(e => {
      console.log(e)
    });
  }

  getPopularDestinations = () => {
    this.setState({isLoading: true})

    // abort previous fetch
    abortController.abort()
    abortController = new AbortController();
    const signal = abortController.signal

    window.fetch(API + '/destinations/recommendations/homepage' +
    '?location_from=' + (this.props.locationFrom ? this.props.locationFrom : '') +
    (this.props.user ? '&curr=' + (this.props.user.currency || 'USD') : ''),
    {
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`
      },
      signal: signal,
    })
    .then(response => response.json())
    .then(data => {
      if (data && data.params) {
        this.setState(prevState => ({
          popular_destinations: (data.destination_groups && data.destination_groups[0] && data.destination_groups[0].destinations ? data.destination_groups[0].destinations : []),
          locationFrom: data.params.from_address.toLowerCase().replace(', ', ','),
          isLoading: false,
        }))
      }
    })
    .catch(e => {});
  }

  handleReloadResults = () => {
    this.setState({
      page: 0,
      destination_groups: [],
      isLoading: true,
    }, this.getDestinations)
  }

  handleSigninChange = (flag, dialogType = 'signup') => {
    this.setState({
      openedSigninDialog: flag,
      openedSigninDialogType: dialogType
    })
  }

  handlePlanTrip = (search_params) => {
    this.props.history.push({pathname: '/plan', state: {travel: search_params, openedTab: 1}})
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
        <div style={{overflowX: 'hidden', width: '100vw'}}>
          <AppTopBar
            hideMenu={false}
            locationFrom={(this.props.locationFrom && this.props.locationFrom.includes(",") ? kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : undefined)}
            openedSigninDialog={this.state.openedSigninDialog}
            signinDialogType={this.state.openedSigninDialogType !== null && this.state.openedSigninDialogType}
            tabSelected={this.props.tabSelected || 'home'}
            user={this.props.user}
            onSigninModalChanged={this.handleSigninChange}
            onSignin={this.props.onSignin}
            onCurrencyChange={this.handleReloadResults}
          />

          <div className="destinations explore">
            <div>
              <Paper square={true} className={classes.headlinePaper}>
                <h1 className={classes.headline}>
                  <CardMedia
                    component="img"
                    className={classes.featureMedia}
                    image={'/hero-desktop.png'}
                    srcSet={'/hero-desktop.png 1x, /hero-desktop@2x.png 2x, /hero-desktop@3x.png 3x'}
                    title="Discover Weekly"
                    width="1000"
                    height="578"
                  />
                  <div style={{maxWidth: 500, fontWeight: 800}}>
                    <span className={classes.green} style={{whiteSpace: 'nowrap'}}></span>
                    Plan travel & activities with <span style={{whiteSpace: 'nowrap'}}>AI travel assistant
                    </span>
                  </div>
                  <small className={classes.subtitle}>
                    Get recommended travel, stay and activities with little help of AI.
                    {/* From only <strong style={{fontSize: '1.1em', fontWeight: 800 }}>$10 per trip</strong> */}
                  </small>
                  <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={() => this.props.history.push({pathname: '/planner'})} style={{margin: '20px 5px -40px 0px'}}>
                    <Icon className="material-icons-outlined notranslate" style={{marginRight: 10}}>auto_awesome</Icon>
                    Start a trip
                    <Icon className="material-icons-outlined notranslate" style={{marginLeft: 10}}>east</Icon>
                  </Button>
                </h1>
              </Paper>
            </div>

            {/* <div className="destinations explore" style={{backgroundColor: '#fafafa'}}>
              <div className={classes.gridFilters} style={{marginTop: (this.isSize('xs') ? 30 : 30), marginBottom: -40, padding: '70px 10px 0px 10px'}}>
                <SearchPanel
                  planner={true}
                  center={false}
                  show_description={false}
                  expandable={false}
                  // expandable={this.isSize('xs')}
                  // address={this.props.address}
                  // onLocationChanged={this.props.onLocationChanged}
                  // onLocationCleared={this.props.onLocationCleared}
                  locationFrom={(this.props.locationFrom && this.props.locationFrom.includes(",") ? kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : undefined)}
                  search_params={this.props.search_params}
                  // onSearch={this.handlePlanTrip}
                  onSearch={this.props.onSearch}
                />
              </div>
            </div> */}


            <div className="destinations-container no-min-height" style={{padding: '10px 15px 50px 15px'}}>
              <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>
                <div className={classes.featureMobileMedia}>
                  <CardMedia
                    component="img"
                    image={'/hero-mobile.png'}
                    srcSet={'/hero-mobile.png 1x, /hero-mobile@2x.png 2x, /hero-mobile@3x.png 3x'}
                    title="Discover Weekly"
                    width="600"
                    height="353"
                  />
                </div>

                {/* <Grid item xs={12} className={classes.showcaseDestinations}>
                  <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                    <h2 className={classes.showcaseHeadline}>
                      <Typography className={classes.showcaseHeadline}>How it works</Typography>
                    </h2>
                  </Typography>

                  <div style={{ marginTop: 40, marginBottom: 0 }}>
                    <Tabs
                      value={false}
                      variant="scrollable"
                      scrollButtons="off"
                      aria-label="scrollable auto"
                    >
                      <CustomTab className={classes.howBox}>
                        <h2>
                          <strong className={classes.howNumber} style={{padding: '5px 16px'}}>1</strong>Describe your style<br/>
                          <small className={classes.howDescription}>Say goodbye to hours of research. You will take a short quiz that helps us understand your occasion, travel style and budget. </small>
                        </h2>
                        <img className={classes.howImage} style={{marginLeft: 0}} src={'/feature-6.jpg'} />
                      </CustomTab>
                      <CustomTab className={classes.howBox}>
                        <h2>
                          <strong className={classes.howNumber}>2</strong> Explore destinations <br/>
                          <small className={classes.howDescription}>If you're uncertain about where to go, we'll help you find the perfect trip to match your needs.</small>
                        </h2>
                        <img className={classes.howImage} src={'/feature-3.jpg'} />
                      </CustomTab>
                      <CustomTab className={classes.howBox}>
                        <h2>
                          <strong className={classes.howNumber}>3</strong> Receive tailor-made trip<br/>
                          <small className={classes.howDescription}>Our AI-powered travel service creates a personalized travel plan tailored to you in minutes. </small>
                        </h2>
                        <img className={classes.howImage} src={'/feature-7.jpg'} />
                      </CustomTab>
                    </Tabs>

                    <Grid container spacing={2} className={classes.destinationsContainer}>
                      <Grid item xs={12}>
                        <Button variant="contained" color="primary" size="large" disableElevation className={classes.buttonBook} onClick={() => this.props.history.push({pathname: '/planner', state: {step: 'occasion'}})} style={{margin: '40px auto 0px auto', display: 'block'}}>
                          Plan a trip
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </Grid> */}

                <Grid item xs={12} className={classes.showcaseDestinations} style={{marginBottom: 0}}>

                  <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                    <h2 className={classes.showcaseHeadline}>
                      <Link to={`/explore`} className={classes.showcaseHeadline}>
                        Explore
                      </Link>
                      <Hidden xsDown>
                        <Button component="span" className={`${classes.showcaseSelector}`} style={{paddingRight: 16, color: '#02c39a'}} onClick={() => { this.props.history.push(`/explore/recently`) }}>
                          View all <Icon className={`material-icons-outlined notranslate ${classes.green}`}>chevron_right</Icon>
                        </Button>
                      </Hidden>
                      <small className={classes.showcaseSubtitle}>Recently planned trips by other travelers</small>
                    </h2>
                  </Typography>

                  <div className={classes.showcaseList}>
                    <div style={{ marginTop: 40, marginBottom: 0 }}>
                      <Grid container spacing={2} className={classes.destinationsContainer}>
                        {((this.state.isLoading && this.state.destination_groups.length === 0) ? Array.from(new Array(1)) : this.state.destination_groups).map((group) => {
                          return <DestinationList key={group ? group.key : 'loading'}
                                  search_params={this.state.search_params}
                                  next_page_url={group && API + group.next_page_url}
                                  destinations={group && group.destinations}
                                  title={group && group.title}
                                  description={group && group.description}
                                  has_next_page={false}
                                  link_to_all={group && group.link_to_all}
                                  page={group && group.page}
                                  pageSize={3}
                                  group_name={group && group.key}
                                  showHeader={false}
                                  isLoading={this.state.isLoading}
                                  onSelected={this.props.onSearch}
                                  address={this.props.address}
                                  addressType={this.props.addressType}
                                  countryCode={this.props.countryCode}
                                  locationFrom={(this.state.from_address ? this.state.from_address : 'nearby')}
                                />
                        })}
                      </Grid>
                    </div>
                  </div>

                  <Hidden smUp>
                    <Button component="span" className={classes.showcaseViewAll} style={{opacity: (this.state.isLoading ? 0 : 1)}} onClick={() => {
                      this.props.history.push(`/explore/recently/${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`)
                    }}>
                      View All
                    </Button>
                  </Hidden>
                </Grid>


              </Grid>

            </div>

            {/* <div className="destinations-container bg-dark no-min-height" style={{padding: '10px 15px'}}>
              <Grid className={classes.browseByType} container>
                <Grid item xs={12}>
                  <h2 className={classes.browseHeadline}>
                    Browse by travel activities
                    Recently planned
                  </h2>
                </Grid>

                <div className={classes.showcaseList} style={{width: '100%'}}>
                  <div style={{ marginTop: 40, marginBottom: 0 }}>
                    <Grid container spacing={2} className={classes.destinationsContainer}>
                      {(this.state.destination_groups.length === 0 ? Array.from(new Array(1)) : this.state.destination_groups).map((group) => {
                        return <DestinationList key={group ? group.key : 'loading-travel-plans'}
                                search_params={this.state.search_params}
                                next_page_url={group && API + group.next_page_url}
                                destinations={group && group.destinations}
                                title={group && group.title}
                                description={group && group.description}
                                has_next_page={false}
                                link_to_all={group && group.link_to_all}
                                page={group && group.page}
                                pageSize={3}
                                group_name={group && group.key}
                                showHeader={false}
                                isLoading={this.state.destination_groups.length === 0}
                                onSelected={this.props.onSearch}
                                address={this.props.address}
                                addressType={this.props.addressType}
                                countryCode={this.props.countryCode}
                                locationFrom={(this.state.from_address ? this.state.from_address : 'nearby')}
                                openInNewWindow={true}
                              />
                      })}
                    </Grid>
                  </div>
                </div>

                <Grid item xs={12} md={6}>
                  <GridList cellHeight={290} spacing={0} cols={2} className={classes.browseBigTile}>
                    {travelActivitiesTop.map((tile) => (
                      <GridListTile key={tile.img} cols={this.isSize('xs') ? 2 : (tile.cols || 1)} rows={(this.isSize('xs') || this.isSize('sm')) ? 1 : (tile.rows || 1)} classes={{tile: classes.browseTile}}>
                        <Link to={`${tile.link}${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`}>
                          <img src={tile.img} srcSet={tile.srcSet} alt={tile.title} className={classes.browseImg}/>
                          <GridListTileBar classes={{root: classes.browseTileBar, title: classes.browseTileTitle, titleWrap: classes.browseTileWrapTop, subtitle: classes.browseTileSubtitle}} title={tile.title} subtitle={<span>{tile.subtitle}</span>}/>
                        </Link>
                      </GridListTile>
                    ))}
                  </GridList>
                </Grid>
                <Grid item xs={12} md={6}>
                  <GridList cellHeight={280} spacing={20} cols={2} variant="quilted">
                    {travelActivitiesData.map((tile) => (
                      <GridListTile key={tile.img} cols={this.isSize('xs') ? 2 : (tile.cols || 1)} rows={tile.rows || 1} classes={{tile: classes.browseTile}}>
                        <Link to={`${tile.link}${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`}>
                          <img src={tile.img} srcSet={tile.srcSet} alt={tile.title} className={classes.browseImg} />
                          <GridListTileBar classes={{root: classes.browseTileBar, title: classes.browseTileTitle, titleWrap: classes.browseTileWrap, subtitle: classes.browseTileSubtitle}} title={tile.title} subtitle={<span>{tile.subtitle}</span>}/>
                        </Link>
                      </GridListTile>
                    ))}
                  </GridList>
                </Grid>
              </Grid>
            </div> */}

            <div className={classes.popularContainer}>
              <Grid container spacing={2} className={classes.gridFilters}>
                <Grid item xs={12} className={classes.popularDestinations}>
                  <Typography gutterBottom variant="inherit" style={{ paddingBottom: 0, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block' }}>
                    <h1>
                      <Link to={`/explore${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`} className={classes.showcaseHeadline}>
                        Travel deals
                      </Link>
                      <Hidden only={['xs']}>
                        <Button component="span" className={classes.showcaseSelector} onClick={() => {
                            this.props.history.push(`/explore/popular${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`)
                          }}>
                          <Icon className={`material-icons-outlined notranslate ${classes.green}`}>place</Icon> London, UK <Icon className="material-icons-outlined notranslate">expand_more</Icon>
                        </Button>
                      </Hidden>
                      <small className={classes.showcaseSubtitle}>Affordable flight, train or bus connections</small>
                    </h1>
                  </Typography>
                </Grid>
              </Grid>
              <Grid container spacing={2} className={classes.gridFilters}>
                {(this.state.popular_destinations === null ? Array.from(new Array(6)) : (this.state.popular_destinations && this.state.popular_destinations)).slice(0, 12).map((destination, i) => {
                  return <Grid item xs={6} sm={4} md={2} key={i}>
                    {!destination ?
                      <Skeleton variant="rect" height={150} width={'100%'} className={classes.skeleton} />
                    :
                      <Card className={classes.popularCard} elevation={0}>
                        <CardActionArea
                          component='span'
                          onClick={(e) => {
                            e.stopPropagation();
                            this.props.onSearch(
                              {
                                destination: destination.destination,
                                dates: {
                                  nights: (destination && destination.travel.nights_in_dest ? destination.travel.nights_in_dest : null),
                                  start_date: (destination && destination.travel.start_date ? destination.travel.start_date : null),
                                  end_date: (destination && destination.travel.end_date ? destination.travel.end_date : null),
                                  dates_type: (destination && destination.travel.dates_type ? destination.travel.dates_type : null),
                                  travel_type: (destination && destination.search_params ? destination.search_params.travel_type : null),
                                },
                                to: [{
                                  description: `${destination.name}, ${destination.country_id}`,
                                  address: `${destination.name}, ${destination.country_id}`,
                                  place: destination.name,
                                  latitude: destination.coordinates.latitude,
                                  longitude: destination.coordinates.longitude,
                                  country: destination.country_id,
                                  slug: `${kebabCase(destination.name)},${kebabCase(destination.country_id)}`,
                                  locationFrom: `${(this.state.locationFrom).includes(",") ? kebabCase(this.state.locationFrom.split(',')[0]) + ',' + kebabCase(this.state.locationFrom.split(',')[1]) : this.state.locationFrom}`
                                }]
                              })
                          }}
                          disableRipple>
                          <Link to={`/trip${(this.state.locationFrom).includes(",") ? '/' + kebabCase(this.state.locationFrom.split(',')[0]) + ',' + kebabCase(this.state.locationFrom.split(',')[1]) : (this.state.locationFrom === 'nearby' ? '' : '/' + this.state.locationFrom)}/to/${kebabCase(destination.name)},${kebabCase(destination.country_id)}`}
                            style={{width: '0%', textDecoration: 'none'}}
                            onClick={(e) => { e.preventDefault() }}
                          >
                            <CardContent style={{padding: '10px 16px 15px 16px' }}>
                              <Typography className={`notranslate ${classes.popularPrice}`}>
                                {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: destination.travel.currency || 'USD'}).format(destination.travel.travel_price)}
                              </Typography>
                              <img src={`/flags/${destination && (kebabCase(destination.country_id) || 'italy')}.svg`} onError={(e) => (e.target.onerror = null, e.target.src = '/flags/blank.svg')} className={classes.popularFlag} width="28" height="28" alt={`flag of ${destination.country_id}`}/>
                              <h3 className={classes.popularName}>
                                {destination.name} <br/>
                                <small className={classes.popularNights}>
                                  {destination.travel.nights_in_dest} nights &nbsp;
                                </small>
                              </h3>
                            </CardContent>
                          </Link>
                        </CardActionArea>
                      </Card>
                    }
                    </Grid>
                })}
              </Grid>

              {/* <Grid container spacing={2} className={classes.gridFilters}>
                {(this.state.popular_destinations === null ? Array.from(new Array(6)) : (this.state.popular_destinations && this.state.popular_destinations.sort((a, b) => a.travel.price - b.travel.price))).slice(0, 12).map((destination, i) => {
                  return <Grid item xs={12} sm={6} md={4} key={i}>
                  {!destination ?
                    <Skeleton variant="rect" height={150} width={'100%'} className={classes.skeleton} />
                  :
                    <Card className={classes.popularCard} elevation={0}>
                      <CardActionArea
                        component='span'
                        onClick={(e) => {
                          e.stopPropagation();
                          this.props.onSearch(
                            {
                              dates: {
                                nights: (destination && destination.nights_in_dest ? destination.nights_in_dest : null),
                                start_date: (destination && destination.start_date ? destination.start_date : null),
                                end_date: (destination && destination.end_date ? destination.end_date : null),
                                dates_type: (destination && destination.dates_type ? destination.dates_type : null),
                                travel_type: (destination && destination.search_params ? destination.search_params.travel_type : null),
                              },
                              to: [{
                                description: `${destination.name}, ${destination.country_id}`,
                                address: `${destination.name}, ${destination.country_id}`,
                                place: destination.name,
                                latitude: destination.coordinates.latitude,
                                longitude: destination.coordinates.longitude,
                                country: destination.country_id,
                                slug: `${kebabCase(destination.name)},${kebabCase(destination.country_id)}`,
                                locationFrom: `${(this.state.locationFrom).includes(",") ? kebabCase(this.state.locationFrom.split(',')[0]) + ',' + kebabCase(this.state.locationFrom.split(',')[1]) : this.state.locationFrom}`
                              }]
                            })
                        }}
                        disableRipple>
                        <Link to={`/trip${(this.state.locationFrom).includes(",") ? '/' + kebabCase(this.state.locationFrom.split(',')[0]) + ',' + kebabCase(this.state.locationFrom.split(',')[1]) : (this.state.locationFrom === 'nearby' ? '' : '/' + this.state.locationFrom)}/to/${kebabCase(destination.name)},${kebabCase(destination.country_id)}`}
                          style={{width: '0%', textDecoration: 'none'}}
                          onClick={(e) => { e.preventDefault() }}
                        >
                          <Box style={{ display: 'flex' }}>
                            <CardMedia
                              component="img"
                              image={destination.images && destination.images.length > 0 ? destination.images[0].sizes.medium.url.replace('http://', '//') : ''}
                              style={{ width: '45%', height: 137, minWidth: 137 }}
                            />
                            <CardContent style={{padding: '10px 16px 15px 16px',  width: '55%' }}>
                              <Typography className={classes.popularPrice}>
                                {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: destination.travel.currency || 'USD'}).format(destination.travel.price)}
                              </Typography>
                              <img src={`/flags/${destination && (kebabCase(destination.country_id) || 'italy')}.svg`} onError={(e) => (e.target.onerror = null, e.target.src = '/flags/blank.svg')} className={classes.popularFlag} alt="country flag" width="28" height="28"/>
                              <h3 className={classes.popularName}>
                                {destination.name} <br/>
                                <small className={classes.popularNights}>
                                  {destination.travel.nights_in_dest} nights &nbsp;
                                </small>
                              </h3>
                            </CardContent>
                          </Box>
                        </Link>
                      </CardActionArea>
                    </Card>
                  }
                  </Grid>
                })}
              </Grid> */}

              <Hidden smUp>
                <Button
                  component="a"
                  href={`/explore${this.props.locationFrom.includes(",") ? '/from/' + kebabCase(this.props.locationFrom.split(',')[0]) + ',' + kebabCase(this.props.locationFrom.split(',')[1]) : (this.props.locationFrom === 'nearby' ? '' : '/from/' + this.props.locationFrom)}`}
                  className={classes.popularViewAll}
                  style={{margin: 'auto', float: 'none', border: '2px solid #02c39a', padding: '13px 20px', marginTop: 30}}
                >
                  View All
                </Button>
              </Hidden>
            </div>

            {/* <div className={classes.showcaseDestinations}>
              <Grid container spacing={2} className={classes.gridFilters} style={{width: "100%"}}>
                <Grid item xs={12}>
                  <Typography gutterBottom variant="inherit" style={{ paddingBottom: 30, paddingLeft: 5, fontSize: "100%", textAlign: 'left', fontWeight: 400, display: 'block', textAlign: 'center' }}>
                    <h2 className={classes.showcaseHeadline}>
                      <Link to={`/explore`} className={classes.showcaseHeadline}>
                        Choose your plan
                      </Link>
                      <small className={classes.showcaseSubtitle}>Start planning trips with AI assistant for free</small>
                    </h2>
                  </Typography>
                </Grid>
                <Grid item xs={12}><PricingTable/></Grid>
              </Grid>
            </div> */}

            {/* <div className="destinations-container no-min-height" style={{padding: '10px 15px'}}>
              <PersonalityTestBanner />
            </div> */}

          </div>
        </div>
      </Fragment>
    )
  }
}
const styles = theme => ({
  green: {
    color: '#02c39a',
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '13px 30px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',
  },
  headlinePaper: {
    padding: '0px 0px 125px 0px',
    margin: '-124px auto 0px auto',
    color: '#000',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '20px !ompo',
    // minHeight: '15vh',
    // paddingBottom: '67px',
    // paddingBottom: '157px',
    backgroundColor: '#fafafa',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    // [theme.breakpoints.down('sm')]: {
    //   minHeight: '45vh',
    // },

    // [theme.breakpoints.down('xs')]: {
    //   paddingBottom: '12px',
    //   minHeight: 'inherit',
    // },

    // [theme.breakpoints.up('md')]: {
    //   minHeight: '45vh',
    // },

    // [theme.breakpoints.up('xl')]: {
    //   minHeight: '35vh',
    // },
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: '40px',
    fontWeight: 800,
    textAlign: 'left',
    marginTop: '90px',
    padding: '160px 30px 0px 40px',

    [theme.breakpoints.down('xs')]: {
      padding: '90px 20px 0px 20px',
      fontSize: '30px',
      marginTop: '70px',
    },
  },
  subtitle: {
    fontWeight: 700,
    fontSize: '45% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 20,
    maxWidth: 490,
    lineHeight: 1.9,

    [theme.breakpoints.down('xs')]: {
      fontSize: '55% !important',
    },
  },
  featureMedia: {
    // maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',
    width: '50vw',
    minWidth: '1000px',
    maxWidth: '1000px',
    position: 'absolute',
    left: '500px',
    top: '-112px',
    // top: '-152px',
    // top: '-62px',
    display: 'block',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
      display: 'none',
      minWidth: 600,
      left: '-70px',
      top: '-120px',
    },
  },
  featureMobileMedia: {
    display: 'none',
    clipPath: 'url(#wave)',

    [theme.breakpoints.down('xs')]: {
      maxHeight: 341,
      overflow: 'hidden',
      display: 'block',
      margin: '30px -20px 55px -20px',
    },

    '& > img': {
      [theme.breakpoints.down('xs')]: {
        maxWidth: 150,
        display: 'block',
        minWidth: 600,
        left: 210,
        top: 0,
        position: 'relative',
        zIndex: 1,
        float: 'right',
        width: '50vw',
        margin: 0,
      },
    }
  },
  discoverHeadline: {
    padding: '0px 60px 100px 100px',
  },
  discoverIcon: {
    verticalAlign: 'middle',
    color: '#02c39a',
    marginRight: 8,
  },
  discoverSignup: {
    backgroundImage: 'url(/discover-weekly-background.svg)',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',
    // display: 'none',
  },
  discoverImage: {
    position: 'relative',
    right: '-47px',
    top: '-65px',
    display: 'block',
  },
  browseHeadline: {
    color: '#fff',
    fontSize: '30px',
    fontWeight: 800,
    marginBottom: 50,

    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      marginBottom: 30,
      maxWidth: 240,
    },
  },
  browseByType: {
    padding: '78px 0px 100px 0px',
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',

    [theme.breakpoints.down('xs')]: {
      padding: '28px 0px 20px 0px',
    },
  },
  browseTile: {
    borderRadius: 20,
  },
  browseBigTile: {
    marginRight: '20px !important',

    [theme.breakpoints.down('sm')]: {
      marginBottom: '20px !important',
      marginRight: '0px !important',
    },
  },
  browseTileBar: {
    background: 'none',
    backgroundImage: 'linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(30, 30, 30, 0.77))',
    fontWeight: '600',
    height: '100%',
  },
  browseTileTitle: {
    width: 170,
    overflow: 'visible',
    display: 'block',
    position: 'relative',
    whiteSpace: 'unset',
    fontSize: 28,
    fontWeight: 800,
    lineHeight: 1.2,
    padding: 24,
  },
  browseTileWrap: {
    bottom: 20,
    position: 'absolute',
  },
  browseTileWrapTop: {
    bottom: 60,
    left: 30,
    position: 'absolute',

    [theme.breakpoints.down('sm')]: {
      left: 0,
      bottom: 20,
    },
  },
  browseTileSubtitle: {
    position: 'relative',
    whiteSpace: 'unset',
    padding: '0px 24px',
    margin: '-12px 0px 24px 0px',
    color: '#bdbdbd',
    fontWeight: 500,
    fontSize: 14,
  },
  browseImg: {
    minWidth: '100%',
    marginTop: '-10px',
  },
  showcaseList: {
    [theme.breakpoints.up('md')]: {
      maxHeight: 463,
      overflow: 'hidden',
    },
  },
  showcaseDestinations: {
    margin: '100px 0px 70px 0px',

    [theme.breakpoints.down('xs')]: {
      margin: '50px 0px 70px 0px',
    },
  },
  showcaseHeadline: {
    fontSize: '30px',
    fontWeight: 800,
    color: '#000',
    textDecoration: 'none',
    marginBottom: 0,

    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      marginBottom: -20,
    },
  },
  showcaseSubtitle: {
    fontWeight: 600,
    fontSize: '50% !important',
    // fontSize: '12px',
    color: '#828282',
    display: 'block',
    marginTop: 12,
  },
  showcaseSelector: {
    color: '#333333',
    textDecoration: 'none',
    float: 'right',
    margin: '7px 0px',
    display: 'block',
    fontSize: '15px',
    fontWeight: 800,
    padding: '16px 26px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '1px solid #f2f2f2',

    '& .material-icons': {
      padding: 0,
      margin: 0,
      verticalAlign: 'middle',
      fontWeight: 400,
    },
  },
  showcaseViewAll: {
    color: '#02c39a',
    textDecoration: 'none',
    margin: '27px auto 0px auto',
    display: 'block',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 800,
    padding: '10px 20px 10px 27px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: '2px solid #02c39a',
    width: 115,
  },
  popularHeadline: {
    fontSize: '30px',
    fontWeight: 800,
    color: '#000',
    textDecoration: 'none',

    [theme.breakpoints.down('xs')]: {
      fontSize: '25px',
      marginBottom: 20,
      maxWidth: 200,
      float: 'left',
    },
  },
  popularContainer: {
    padding: '10px 15px 50px 15px',
    backgroundColor: '#fafafa',

    [theme.breakpoints.down('sm')]: {
      padding: '10px 25px 50px 10px',
    },
  },
  popularDestinations: {
    margin: '70px 0px 0px 0px',

    [theme.breakpoints.down('sm')]: {
      margin: '20px 0px 0px 0px',
    },
  },
  popularViewAll: {
    color: '#02c39a',
    textDecoration: 'none',
    display: 'block',
    position: 'relative',
    fontSize: '15px',
    fontWeight: 800,
    padding: '13px 20px 13px 27px',
    backgroundColor: 'transparent',
    borderRadius: 50,
    border: 'none',
    float: 'right',
    margin: '-3px -20px 0px 0px',
    width: 110,
  },
  popularCard: {
    borderRadius: 20,
    border: '1px solid #f2f2f2',
    minHeight: 86,
    marginBottom: 8,

    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  popularFlag: {
    width: 28,
    marginRight: 5,
    verticalAlign: 'middle',
    padding: '7px 0px',
  },
  popularPrice: {
    float: 'right',
    fontWeight: 800,
    fontSize: '1.17em',
    marginTop: 9,
    color: '#000',
  },
  popularName: {
    marginBottom: 0,
    color: '#000',
    textDecoration: 'none',
    fontWeight: 800,
  },
  popularNights: {
    fontWeight: 600,
    fontSize: '70%',
    lineHeight: 1.5,
    color: '#828282',
    textDecoration: 'none',
  },
  popularSeparator: {
    color: '#02c39a',
    width: 8,
    height: 8,
    fontSize: 6,
    margin: '0px 5px',
  },
  popularIcon: {
    fontSize: 14,
    margin: '0px',
  },
  skeleton: {
    borderRadius: 25,
  },
  howBox: {
    width: '75vw',
    maxWidth: '400px',
    float: 'left',
    display:'inline-block',
    padding: '10px 20px',

    border: '1px solid #eee',
    borderRadius: 25,
    marginRight: 15,

    '& > h2': {
      fontSize: 18,
    },

    [theme.breakpoints.down('sm')]: {
      padding: '15px',
      width: '50vw',
      minWidth: '0px',
    },

    [theme.breakpoints.down('xs')]: {
      padding: '15px',
      width: '75vw',
      minWidth: '270px',
      maxWidth: '400px',

      '&:last-child': {
        minWidth: '83vw',
      }
    },
  },
  howNumber: {
    marginRight: 10,
    backgroundColor: '#07c39942',
    borderRadius: 25,
    padding: '5px 14px',
  },
  howDescription: {
    fontSize: 15,
    whiteSpace: 'initial',
    // whiteSpace: 'break-spaces',
    color: '#828282',
    fontWeight: 600,
    marginTop: 15,
    display: 'block',
    padding: '10px 0px',
    marginBottom: 20,
  },
  howImage: {
    margin: '0px auto',
    display: 'block',
    maxHeight: 250,
    maxWidth: '100%',
    height: 'auto',

    [theme.breakpoints.down('xs')]: {
      maxHeight: 200,
    },
  },
})

export default withRouter(withStyles(styles)(withWidth()(Home)))
