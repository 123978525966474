import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {

  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Icon,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  withStyles
} from '@material-ui/core';
import { ITINERARY_ITEM_CATEGORIES }  from '../../utils/lists'

class ItineraryItemDialog extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isSubmitting: false,
      openedDialog: false,
      category: "",
      title: "",
      description: "",
      notes: "",
      time: "",
    };
  }

  handleCloseDialog = () => {
    this.setState({
      openedDialog: false,
      title: "",
    })
  }

  handleSelection = (item) => {
    this.props.onCreate(item)
    this.handleCloseDialog()
  }

  render() {
    const {
      classes
    } = this.props

    return (
      <Fragment>
        <Button className={classes.buttonBook} onClick={()=> { this.setState({openedDialog: true}) }}>
          <Icon className={`${''} material-icons-outlined notranslate`} style={{marginRight: 0}}>add</Icon>
          {/* Add to Trip */}
        </Button>

        <Dialog classes={{root: classes.newGroupDialogRoot, paper: classes.newGroupDialogPaper}} disableBackdropClick={true} disableEscapeKeyDown={true} aria-labelledby="customized-dialog-title" fullWidth={true} maxWidth={'sm'} open={this.state.openedDialog}>
          <DialogTitle id="customized-dialog-title">
            <IconButton aria-label="close" className={classes.closeButton} onClick={this.handleCloseDialog}>
              <Icon className="notranslate">close</Icon>
            </IconButton>
            <Box style={{marginTop: 7, float: 'left'}}>
              Add to trip
            </Box>
          </DialogTitle>
          <DialogContent dividers>
            <List className={classes.menu}>
            {ITINERARY_ITEM_CATEGORIES.map((category, i) => {
              return <ListItem button key={category.name} onClick={() => this.handleSelection({category: category.value})}>
                <ListItemIcon><Icon className={`material-icons-outlined notranslate ${classes.listItemIcon}`} style={{color: '#02c39a'}}>{category.icon}</Icon></ListItemIcon>
                <ListItemText primary={category.name} classes={{primary: classes.listItemText, secondary: classes.listItemTextSecondary}} />
              </ListItem>
            })}
            </List>
          </DialogContent>
        </Dialog>
      </Fragment>
    )
  }
}

const styles = theme => ({
  closeButton: {
    float: 'right',
  },
  newGroupDialogRoot: {
    textAlign: 'left'
  },
  newGroupDialogPaper: {
    margin: 10,
    width: '100%',
  },

  listItemIcon: {
    color: '#02c39a',
  },
  listItemText: {
    fontSize: 15,
    fontWeight: 600,
    color: '#000',
    maxWidth: '78%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '3px 0px',
  },
  listItemTextSecondary: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: '78%',
    display: 'block',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  icon: {
    fontSize: 14,
    fontWeight: 600,
    marginTop: -3,
    marginRight: 0,
    marginLeft: 10,
    padding: '5px !important',
    verticalAlign: 'middle',
    color: '#828282',
  },
  buttonBook: {
    zIndex: 2,
    color: '#fff',
    margin: '30px 10px 10px 5px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    // boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    padding: '10px 15px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  flexContainer: {
    justifyContent: 'flex-end',
  },
});

export default withStyles(styles)(withRouter(ItineraryItemDialog));
