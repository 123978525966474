export const airlines = [
  {
    id: "U2",
    lcc: 1,
    name: "easyJet",
    type: "airline",
  },
  {
    id: "TRAINOSEB",
    lcc: null,
    name: "TrainOSE bus",
    type: "bus",
  },
  {
    id: "VU",
    lcc: 0,
    name: "Vietravel Airlines",
    type: "airline",
  },
  {
    id: "AA",
    lcc: 0,
    name: "American Airlines",
    type: "airline",
  },
  {
    id: "ZH",
    lcc: 0,
    name: "Shenzhen Airlines",
    type: "airline",
  },
  {
    id: "GLOBALBIO",
    lcc: null,
    name: "Global biomet",
    type: "bus",
  },
  {
    id: "FR",
    lcc: 1,
    name: "Ryanair",
    type: "airline",
  },
  {
    id: "IB",
    lcc: 0,
    name: "Iberia Airlines",
    type: "airline",
  },
  {
    id: "SEILAAEX",
    lcc: null,
    name: "Seila Angkor Express",
    type: "bus",
  },
  {
    id: "CANDJ",
    lcc: null,
    name: "C&J",
    type: "bus",
  },
  {
    id: "FN",
    lcc: 1,
    name: "Fastjet",
    type: "airline",
  },
  {
    id: "IZY",
    lcc: null,
    name: "IZY",
    type: "train",
  },
  {
    id: "EU",
    lcc: 0,
    name: "Chengdu Airlines",
    type: "airline",
  },
  {
    id: "SV",
    lcc: 0,
    name: "Saudi Arabian Airlines",
    type: "airline",
  },
  {
    id: "JT",
    lcc: 1,
    name: "Lion Air",
    type: "airline",
  },
  {
    id: "AYUEXPRESS",
    lcc: null,
    name: "Ayu Express",
    type: "bus",
  },
  {
    id: "HD",
    lcc: 1,
    name: "Air Do",
    type: "airline",
  },
  {
    id: "GA",
    lcc: 0,
    name: "Garuda Indonesia",
    type: "airline",
  },
  {
    id: "BAOKHANGLI",
    lcc: null,
    name: "Bao Khang Limousine",
    type: "bus",
  },
  {
    id: "JU",
    lcc: 0,
    name: "Air Serbia",
    type: "airline",
  },
  {
    id: "QM",
    lcc: 0,
    name: "Monacair",
    type: "airline",
  },
  {
    id: "AD",
    lcc: 1,
    name: "Azul",
    type: "airline",
  },
  {
    id: "AF",
    lcc: 0,
    name: "Air France",
    type: "airline",
  },
  {
    id: "CAUSEWAYLI",
    lcc: null,
    name: "Causeway Link",
    type: "bus",
  },
  {
    id: "SQ",
    lcc: 0,
    name: "Singapore Airlines",
    type: "airline",
  },
  {
    id: "TEPSA",
    lcc: null,
    name: "Tepsa",
    type: "bus",
  },
  {
    id: "VJ",
    lcc: 1,
    name: "VietJet Air",
    type: "airline",
  },
  {
    id: "KM",
    lcc: 0,
    name: "KM Malta Airlines",
    type: "airline",
  },
  {
    id: "2N",
    lcc: 1,
    name: "NG Eagle",
    type: "airline",
  },
  {
    id: "L7",
    lcc: 0,
    name: "Lugansk Airlines",
    type: "airline",
  },
  {
    id: "IF",
    lcc: 0,
    name: "Fly Baghdad Airlines",
    type: "airline",
  },
  {
    id: "CENTRALPAH",
    lcc: null,
    name: "Central Pahang Omnibus",
    type: "bus",
  },
  {
    id: "2R",
    lcc: null,
    name: "Sunlight Air",
    type: "airline",
  },
  {
    id: "PHUONGNAM",
    lcc: null,
    name: "Phuong Nam",
    type: "bus",
  },
  {
    id: "XY",
    lcc: 1,
    name: "flynas",
    type: "airline",
  },
  {
    id: "N9",
    lcc: null,
    name: "Novair",
    type: "airline",
  },
  {
    id: "F8",
    lcc: 0,
    name: "Flair Airlines",
    type: "airline",
  },
  {
    id: "LUMBINI",
    lcc: null,
    name: "Lumbini",
    type: "bus",
  },
  {
    id: "F3",
    lcc: 0,
    name: "Flyadeal",
    type: "airline",
  },
  {
    id: "CUSELA",
    lcc: null,
    name: "Cusela",
    type: "bus",
  },
  {
    id: "AK",
    lcc: 1,
    name: "AirAsia",
    type: "airline",
  },
  {
    id: "D3",
    lcc: 0,
    name: "Daallo Airlines",
    type: "airline",
  },
  {
    id: "WJ",
    lcc: 0,
    name: "JetSMART Argentina",
    type: "airline",
  },
  {
    id: "GK",
    lcc: 0,
    name: "Jetstar Japan",
    type: "airline",
  },
  {
    id: "PERUBUS",
    lcc: null,
    name: "Peru Bus",
    type: "bus",
  },
  {
    id: "JY",
    lcc: 0,
    name: "interCaribbean Airways",
    type: "airline",
  },
  {
    id: "THANHBAN",
    lcc: null,
    name: "Thanh Ban",
    type: "bus",
  },
  {
    id: "JTD",
    lcc: null,
    name: "Jettime",
    type: "airline",
  },
  {
    id: "NH",
    lcc: 0,
    name: "All Nippon Airways",
    type: "airline",
  },
  {
    id: "OKEXPRESSY",
    lcc: null,
    name: "OK Express Yangon",
    type: "bus",
  },
  {
    id: "UJ",
    lcc: 0,
    name: "AlMasria Universal Airlines",
    type: "airline",
  },
  {
    id: "NAMACHAU",
    lcc: null,
    name: "Nam A Chau",
    type: "bus",
  },
  {
    id: "HAIHOANGGI",
    lcc: null,
    name: "Hai Hoang Gia",
    type: "bus",
  },
  {
    id: "H5",
    lcc: 0,
    name: "CM Airlines",
    type: "airline",
  },
  {
    id: "MANNYARZAR",
    lcc: null,
    name: "Mann Yar Zar Express",
    type: "bus",
  },
  {
    id: "MANSHWEPYI",
    lcc: null,
    name: "Man Shwe Pyi",
    type: "bus",
  },
  {
    id: "XC",
    lcc: 1,
    name: "Corendon",
    type: "airline",
  },
  {
    id: "SRIMAJUGRO",
    lcc: null,
    name: "Sri Maju Group",
    type: "bus",
  },
  {
    id: "K9",
    lcc: 1,
    name: "TezJet",
    type: "airline",
  },
  {
    id: "BOSSEXPRES",
    lcc: null,
    name: "Boss Express",
    type: "bus",
  },
  {
    id: "PUTERAMULY",
    lcc: null,
    name: "Putera Mulya",
    type: "bus",
  },
  {
    id: "SEN",
    lcc: null,
    name: "Señor Air",
    type: "airline",
  },
  {
    id: "HAIAU",
    lcc: null,
    name: "Hai Au",
    type: "bus",
  },
  {
    id: "BENCHMARKT",
    lcc: null,
    name: "Benchmark Tours & Travels",
    type: "bus",
  },
  {
    id: "LO",
    lcc: 0,
    name: "LOT Polish Airlines",
    type: "airline",
  },
  {
    id: "HTETAUNGLI",
    lcc: null,
    name: "Htet Aung Lin",
    type: "bus",
  },
  {
    id: "NHATTUAN",
    lcc: null,
    name: "Nhat Tuan",
    type: "bus",
  },
  {
    id: "LAXMIHOLID",
    lcc: null,
    name: "Laxmi Holidays",
    type: "bus",
  },
  {
    id: "ZE",
    lcc: 1,
    name: "Eastar Jet",
    type: "airline",
  },
  {
    id: "WTS",
    lcc: null,
    name: "WTS",
    type: "bus",
  },
  {
    id: "YST",
    lcc: null,
    name: "Yesmin Tours",
    type: "airline",
  },
  {
    id: "EK",
    lcc: 0,
    name: "Emirates",
    type: "airline",
  },
  {
    id: "AIRITSHUTT",
    lcc: null,
    name: "Air Italy Shuttle",
    type: "bus",
  },
  {
    id: "4C",
    lcc: 0,
    name: "LATAM Colombia",
    type: "airline",
  },
  {
    id: "NAKHONSRIR",
    lcc: null,
    name: "Nakhon Sri Rom Yen Tour",
    type: "bus",
  },
  {
    id: "NAKSIRACH",
    lcc: null,
    name: "Nakhon Si Racha",
    type: "bus",
  },
  {
    id: "TRAINOSET",
    lcc: null,
    name: "TrainOSE train",
    type: "train",
  },
  {
    id: "JJEXPRESS",
    lcc: null,
    name: "JJ Express",
    type: "bus",
  },
  {
    id: "UNITITIEXP",
    lcc: null,
    name: "Unititi Express",
    type: "bus",
  },
  {
    id: "TIENOANH",
    lcc: null,
    name: "Tien Oanh",
    type: "bus",
  },
  {
    id: "SENGCHALEU",
    lcc: null,
    name: "Sengchaleun",
    type: "bus",
  },
  {
    id: "WG",
    lcc: 1,
    name: "Sunwing",
    type: "airline",
  },
  {
    id: "THANHTHUY",
    lcc: null,
    name: "Thanh Thuy",
    type: "bus",
  },
  {
    id: "PERAMATOUR",
    lcc: null,
    name: "Perama Tour & Travel",
    type: "bus",
  },
  {
    id: "BALUT",
    lcc: null,
    name: "Balut",
    type: "bus",
  },
  {
    id: "DV",
    lcc: 1,
    name: "Scat Airlines",
    type: "airline",
  },
  {
    id: "LH",
    lcc: 0,
    name: "Lufthansa",
    type: "airline",
  },
  {
    id: "CHITWANSAF",
    lcc: null,
    name: "Chitwan Safari Travel and Tours",
    type: "bus",
  },
  {
    id: "MR",
    lcc: 0,
    name: "Hunnu Air",
    type: "airline",
  },
  {
    id: "SY",
    lcc: 1,
    name: "Sun Country Airlines",
    type: "airline",
  },
  {
    id: "H3",
    lcc: 0,
    name: "Thomas Cook Aviation",
    type: "airline",
  },
  {
    id: "CODALINES",
    lcc: null,
    name: "Coda Lines",
    type: "bus",
  },
  {
    id: "HOANHO",
    lcc: null,
    name: "Hoa Nho",
    type: "bus",
  },
  {
    id: "707INC",
    lcc: null,
    name: "707 Inc.",
    type: "bus",
  },
  {
    id: "TETLANN",
    lcc: null,
    name: "Tet Lann",
    type: "bus",
  },
  {
    id: "DUONGHONG",
    lcc: null,
    name: "Duong Hong",
    type: "bus",
  },
  {
    id: "AIRPORTEXP",
    lcc: null,
    name: "Airport Express Lima",
    type: "bus",
  },
  {
    id: "WAYBUS",
    lcc: null,
    name: "Way Bus",
    type: "bus",
  },
  {
    id: "BLUEWORLDT",
    lcc: null,
    name: "Blue World Tourist",
    type: "bus",
  },
  {
    id: "TRAVELHOUS",
    lcc: null,
    name: "Travel House",
    type: "bus",
  },
  {
    id: "KTB",
    lcc: null,
    name: "Transaviabaltika",
    type: "airline",
  },
  {
    id: "AEROBUS",
    lcc: null,
    name: "Aerobus",
    type: "bus",
  },
  {
    id: "GIGROUPEXP",
    lcc: null,
    name: "GI Group Express",
    type: "bus",
  },
  {
    id: "LOTUSTRAIN",
    lcc: null,
    name: "Lotus Train",
    type: "train",
  },
  {
    id: "LAMANEXPRE",
    lcc: null,
    name: "Laman Express",
    type: "train",
  },
  {
    id: "PHUTRAVEL",
    lcc: null,
    name: "Phuket Travel",
    type: "bus",
  },
  {
    id: "ONTANORTH",
    lcc: null,
    name: "Ontario Northland",
    type: "bus",
  },
  {
    id: "D2",
    lcc: 1,
    name: "Severstal Air Company",
    type: "airline",
  },
  {
    id: "RQ",
    lcc: 0,
    name: "Kam Air",
    type: "airline",
  },
  {
    id: "2I",
    lcc: 1,
    name: "Star Peru",
    type: "airline",
  },
  {
    id: "GIANTIBIS",
    lcc: null,
    name: "Giant Ibis",
    type: "bus",
  },
  {
    id: "ANDBUS",
    lcc: null,
    name: "Andbus",
    type: "bus",
  },
  {
    id: "BLUESKYTRA",
    lcc: null,
    name: "Blue Sky Travel And Tours",
    type: "bus",
  },
  {
    id: "AVROTRAVEL",
    lcc: null,
    name: "Avro Travels & Tours",
    type: "bus",
  },
  {
    id: "STARQISTNA",
    lcc: null,
    name: "Star Qistna Express",
    type: "bus",
  },
  {
    id: "CHAPAEXPRE",
    lcc: null,
    name: "Chapa Express Train",
    type: "train",
  },
  {
    id: "SAIGONGOLD",
    lcc: null,
    name: "Saigon Golden Train",
    type: "train",
  },
  {
    id: "KERETAAPII",
    lcc: null,
    name: "Kereta API Indonesia",
    type: "train",
  },
  {
    id: "BILLSTEX",
    lcc: null,
    name: "Billion Stars Express",
    type: "bus",
  },
  {
    id: "BUSEXPRESS",
    lcc: null,
    name: "Bus Express",
    type: "bus",
  },
  {
    id: "SEGESAUTO",
    lcc: null,
    name: "Segesta Autolinee S.p.A.",
    type: "bus",
  },
  {
    id: "HERMES",
    lcc: null,
    name: "Hermes",
    type: "bus",
  },
  {
    id: "RODEROTAS",
    lcc: null,
    name: "Roderotas",
    type: "bus",
  },
  {
    id: "INTERBUS",
    lcc: null,
    name: "Interbus S.A.",
    type: "bus",
  },
  {
    id: "DAMAS",
    lcc: null,
    name: "Damas S.A.",
    type: "bus",
  },
  {
    id: "SOCIBUS",
    lcc: null,
    name: "Socibus",
    type: "bus",
  },
  {
    id: "STEVENAGE",
    lcc: null,
    name: "Stevenage 100/101",
    type: "bus",
  },
  {
    id: "VAMOOSE",
    lcc: null,
    name: "Vamoose",
    type: "bus",
  },
  {
    id: "SEAJETS",
    lcc: null,
    name: "Sea Jets",
    type: "ferry",
  },
  {
    id: "AIRTRAINBR",
    lcc: null,
    name: "Air Train Brisbane",
    type: "train",
  },
  {
    id: "BANBUSDOO",
    lcc: null,
    name: "BANBUS d.o.o.",
    type: "bus",
  },
  {
    id: "TIENDALEON",
    lcc: null,
    name: "Tienda Leon",
    type: "bus",
  },
  {
    id: "CAIRTPRAIN",
    lcc: null,
    name: "CAT- City Airport Train",
    type: "train",
  },
  {
    id: "TORNADOBUS",
    lcc: null,
    name: "Tornado Bus",
    type: "bus",
  },
  {
    id: "VO",
    lcc: 0,
    name: "Voyage Air",
    type: "airline",
  },
  {
    id: "KURAKURA",
    lcc: null,
    name: "Kura Kura",
    type: "bus",
  },
  {
    id: "MOVILBUS",
    lcc: null,
    name: "Movil Bus",
    type: "bus",
  },
  {
    id: "HOANGKIMDA",
    lcc: null,
    name: "Hoang Kim Dak Nong",
    type: "bus",
  },
  {
    id: "HARAPANJAY",
    lcc: null,
    name: "Harapan Jaya",
    type: "bus",
  },
  {
    id: "TRANSNATIO",
    lcc: null,
    name: "Transnational",
    type: "bus",
  },
  {
    id: "THUANHIEN",
    lcc: null,
    name: "Thuan Hien",
    type: "bus",
  },
  {
    id: "HALINH",
    lcc: null,
    name: "Ha Linh",
    type: "bus",
  },
  {
    id: "FOUJITRAVE",
    lcc: null,
    name: "Fouji Travels",
    type: "bus",
  },
  {
    id: "LEOTRAVELH",
    lcc: null,
    name: "Leo Travel Heights",
    type: "bus",
  },
  {
    id: "PANDANYBUS",
    lcc: null,
    name: "Panda NY Bus",
    type: "bus",
  },
  {
    id: "VICTORIAEX",
    lcc: null,
    name: "Victoria Express Train",
    type: "train",
  },
  {
    id: "SAPALYEXPR",
    lcc: null,
    name: "Sapaly Express Train",
    type: "train",
  },
  {
    id: "EXPRESSRAI",
    lcc: null,
    name: "Express Rail Link",
    type: "train",
  },
  {
    id: "MAYANGSARI",
    lcc: null,
    name: "Mayang Sari",
    type: "bus",
  },
  {
    id: "GROUPPLUS",
    lcc: null,
    name: "Group Plus Ltd",
    type: "bus",
  },
  {
    id: "ALIBUS",
    lcc: null,
    name: "Alibus",
    type: "bus",
  },
  {
    id: "VIACESTRE",
    lcc: null,
    name: "Viacao Estrela",
    type: "bus",
  },
  {
    id: "ANDMANTAXI",
    lcc: null,
    name: "Andaman Taxi Company Limited",
    type: "private_transfer",
  },
  {
    id: "TURNUPRIDE",
    lcc: null,
    name: "TURNUP RIDES",
    type: "private_transfer",
  },
  {
    id: "ETNATRAS",
    lcc: null,
    name: "Etna Trasporti S.p.A.",
    type: "bus",
  },
  {
    id: "SICILBUS",
    lcc: null,
    name: "Sicilbus S.p.A.",
    type: "bus",
  },
  {
    id: "HEATHRWEXP",
    lcc: null,
    name: "Heathrow Express",
    type: "train",
  },
  {
    id: "ZANTEFE",
    lcc: null,
    name: "Zante Ferries",
    type: "ferry",
  },
  {
    id: "UBEEXPR",
    lcc: null,
    name: "UBE Express",
    type: "bus",
  },
  {
    id: "EXARAGUARI",
    lcc: null,
    name: "Expresso Araguari",
    type: "bus",
  },
  {
    id: "PRINDOIVAI",
    lcc: null,
    name: "Princesa do Ivai",
    type: "bus",
  },
  {
    id: "8Y",
    lcc: null,
    name: "Pan Pacific Airlines",
    type: "airline",
  },
  {
    id: "FUTABUS",
    lcc: null,
    name: "Futa Bus",
    type: "bus",
  },
  {
    id: "SOKPHASERT",
    lcc: null,
    name: "Sokphasert",
    type: "bus",
  },
  {
    id: "ANHOLIDAYS",
    lcc: null,
    name: "AN Holidays",
    type: "bus",
  },
  {
    id: "4N",
    lcc: 0,
    name: "Air North",
    type: "airline",
  },
  {
    id: "SEK",
    lcc: null,
    name: "Star East Airline",
    type: "airline",
  },
  {
    id: "BABAADVENT",
    lcc: null,
    name: "Baba Adventure Travels & Tours",
    type: "bus",
  },
  {
    id: "VIJAYTOURS",
    lcc: null,
    name: "Vijay Tours and Travel",
    type: "bus",
  },
  {
    id: "SHREEMAHAV",
    lcc: null,
    name: "Shree Mahaveer Travels",
    type: "bus",
  },
  {
    id: "BINHPHUONG",
    lcc: null,
    name: "Binh Phuong",
    type: "bus",
  },
  {
    id: "PAHALAKENC",
    lcc: null,
    name: "Pahala Kencana",
    type: "bus",
  },
  {
    id: "NAMRUM",
    lcc: null,
    name: "Nam Rum",
    type: "bus",
  },
  {
    id: "ORIENTEXPR",
    lcc: null,
    name: "Orient Express",
    type: "train",
  },
  {
    id: "VIBOLEXP",
    lcc: null,
    name: "Vibol Express",
    type: "bus",
  },
  {
    id: "SANTOANJO",
    lcc: null,
    name: "Santo Anjo",
    type: "bus",
  },
  {
    id: "STAHOEAIRP",
    lcc: null,
    name: "South Tahoe Airporter",
    type: "bus",
  },
  {
    id: "ANEKSFAST",
    lcc: null,
    name: "Anek Superfast",
    type: "ferry",
  },
  {
    id: "SAIZTOUR",
    lcc: null,
    name: "Saiz Tour",
    type: "bus",
  },
  {
    id: "NORDESTE",
    lcc: null,
    name: "Nordeste",
    type: "bus",
  },
  {
    id: "EXNORDESTE",
    lcc: null,
    name: "Expresso Nordeste",
    type: "bus",
  },
  {
    id: "TGVINOUI",
    lcc: null,
    name: "TGV inOui",
    type: "train",
  },
  {
    id: "OURBUS",
    lcc: null,
    name: "Our Bus",
    type: "bus",
  },
  {
    id: "ETN",
    lcc: null,
    name: "ETN",
    type: "bus",
  },
  {
    id: "ZTIME",
    lcc: null,
    name: "Z-TIME",
    type: "bus",
  },
  {
    id: "BLEKINGEB",
    lcc: null,
    name: "Blekingetrafiken bus",
    type: "bus",
  },
  {
    id: "TOPBUS",
    lcc: null,
    name: "TopBus",
    type: "bus",
  },
  {
    id: "HRN",
    lcc: null,
    name: "Travelcoup",
    type: "airline",
  },
  {
    id: "INFOBUS",
    lcc: null,
    name: "Infobus",
    type: "bus",
  },
  {
    id: "REGIOJETT",
    lcc: 0,
    name: "Regiojet train",
    type: "train",
  },
  {
    id: "PHUONGTRG",
    lcc: null,
    name: "Phuong Trang",
    type: "bus",
  },
  {
    id: "JETSETEXPR",
    lcc: null,
    name: "Jet Set Express",
    type: "bus",
  },
  {
    id: "SANTACRUZ",
    lcc: null,
    name: "Santa Cruz",
    type: "bus",
  },
  {
    id: "MALPENFAST",
    lcc: null,
    name: "Malpensa Fast",
    type: "bus",
  },
  {
    id: "SIMET",
    lcc: null,
    name: "Simet",
    type: "bus",
  },
  {
    id: "ANVANZA",
    lcc: null,
    name: "Avanza",
    type: "bus",
  },
  {
    id: "YORKSTIGER",
    lcc: null,
    name: "Yorkshire Tiger",
    type: "bus",
  },
  {
    id: "HIFE",
    lcc: null,
    name: "Hife",
    type: "bus",
  },
  {
    id: "BANBUS",
    lcc: null,
    name: "BANBUS d.o.o.",
    type: "bus",
  },
  {
    id: "SATAM",
    lcc: null,
    name: "Satam",
    type: "bus",
  },
  {
    id: "BLUESTARFE",
    lcc: null,
    name: "Blue Star Ferries",
    type: "ferry",
  },
  {
    id: "DAINCOSA",
    lcc: null,
    name: "Dainco S.A.",
    type: "bus",
  },
  {
    id: "VIAGARCIA",
    lcc: null,
    name: "Viacao Garcia Ltda",
    type: "bus",
  },
  {
    id: "BRASILSUL",
    lcc: null,
    name: "Brasil Sul",
    type: "bus",
  },
  {
    id: "CAZMATRANS",
    lcc: null,
    name: "Cazmatrans",
    type: "bus",
  },
  {
    id: "FIRSTBUS",
    lcc: null,
    name: "First Bus",
    type: "bus",
  },
  {
    id: "EUROBAHN",
    lcc: null,
    name: "Eurobahn",
    type: "train",
  },
  {
    id: "YELLOWBUS",
    lcc: null,
    name: "Yellow Buses",
    type: "bus",
  },
  {
    id: "CAPITALCOL",
    lcc: null,
    name: "Capital - Colonial Trailways",
    type: "bus",
  },
  {
    id: "OKBUS",
    lcc: null,
    name: "OK bus",
    type: "bus",
  },
  {
    id: "MOUNTAINLI",
    lcc: null,
    name: "Mountain Line Transit Authority",
    type: "bus",
  },
  {
    id: "BOLTBUS",
    lcc: null,
    name: "BoltBus",
    type: "bus",
  },
  {
    id: "AUTOLIFEDE",
    lcc: null,
    name: "Autolinee federico",
    type: "bus",
  },
  {
    id: "SWISSTOURS",
    lcc: null,
    name: "SwissTours",
    type: "bus",
  },
  {
    id: "AEROBUSBCN",
    lcc: null,
    name: "Aerobus Barcelona",
    type: "bus",
  },
  {
    id: "ROMAEX",
    lcc: null,
    name: "Roma Express",
    type: "bus",
  },
  {
    id: "BIGBUS",
    lcc: null,
    name: "Bigbus",
    type: "bus",
  },
  {
    id: "CITYZAP",
    lcc: null,
    name: "Cityzap",
    type: "bus",
  },
  {
    id: "COASTLINER",
    lcc: null,
    name: "Coastliner",
    type: "bus",
  },
  {
    id: "GREENLINE",
    lcc: null,
    name: "Green Line",
    type: "bus",
  },
  {
    id: "STAGECOACB",
    lcc: null,
    name: "Stagecoach bus",
    type: "bus",
  },
  {
    id: "GDNEX",
    lcc: null,
    name: "GDN Express",
    type: "bus",
  },
  {
    id: "NINHBINHLI",
    lcc: null,
    name: "Ninh Binh Limousine",
    type: "bus",
  },
  {
    id: "HY",
    lcc: 0,
    name: "Uzbekistan Airways",
    type: "airline",
  },
  {
    id: "CRNJAT",
    lcc: null,
    name: "Crnja tours",
    type: "bus",
  },
  {
    id: "SVTRANS",
    lcc: null,
    name: "SV TRANS",
    type: "bus",
  },
  {
    id: "TERNVOYAGE",
    lcc: null,
    name: "Ternvoyage",
    type: "bus",
  },
  {
    id: "LUXREISEN",
    lcc: null,
    name: "LUX-REISEN",
    type: "bus",
  },
  {
    id: "3SLIMO",
    lcc: null,
    name: "3S Limo",
    type: "bus",
  },
  {
    id: "SI",
    lcc: 0,
    name: "Blue Islands",
    type: "airline",
  },
  {
    id: "VNRBUS",
    lcc: null,
    name: "VNR",
    type: "bus",
  },
  {
    id: "ITAPEMIR",
    lcc: null,
    name: "Itapemirim",
    type: "bus",
  },
  {
    id: "TRANSDEV",
    lcc: null,
    name: "Transdev",
    type: "bus",
  },
  {
    id: "COLDSHOTB",
    lcc: null,
    name: "Cold Shot Bus",
    type: "bus",
  },
  {
    id: "FI",
    lcc: 0,
    name: "Icelandair",
    type: "airline",
  },
  {
    id: "5G",
    lcc: 0,
    name: "MAYAir",
    type: "airline",
  },
  {
    id: "WR",
    lcc: 1,
    name: "WestJet Encore",
    type: "airline",
  },
  {
    id: "LUXREISBIS",
    lcc: null,
    name: "Lux-reisen BIS",
    type: "bus",
  },
  {
    id: "2D",
    lcc: 0,
    name: "Eastern Airlines",
    type: "airline",
  },
  {
    id: "KRUNGSIAMT",
    lcc: null,
    name: "Krung Siam Tour",
    type: "bus",
  },
  {
    id: "MIRTRANSEX",
    lcc: null,
    name: "MIRTRANSEX",
    type: "bus",
  },
  {
    id: "LANDLINE",
    lcc: null,
    name: "Landline",
    type: "private_transfer",
  },
  {
    id: "EUROCLUB",
    lcc: null,
    name: "Euroclub LLC",
    type: "bus",
  },
  {
    id: "SPRINTBUS",
    lcc: null,
    name: "Sprinter Bus",
    type: "bus",
  },
  {
    id: "HANHCAFE",
    lcc: null,
    name: "Hanh Cafe",
    type: "bus",
  },
  {
    id: "GEPARDEXPR",
    lcc: null,
    name: "Gepard Express",
    type: "bus",
  },
  {
    id: "PIYA",
    lcc: null,
    name: "Piya",
    type: "bus",
  },
  {
    id: "8Q",
    lcc: 1,
    name: "Onur Air",
    type: "airline",
  },
  {
    id: "WINEW",
    lcc: null,
    name: "Winew",
    type: "bus",
  },
  {
    id: "FANSIEXP",
    lcc: null,
    name: "Fansipan Express",
    type: "bus",
  },
  {
    id: "VIRAKBUNEX",
    lcc: null,
    name: "Virak Buntham Express",
    type: "bus",
  },
  {
    id: "SIAMLATOUR",
    lcc: null,
    name: "Siam Lane Tour",
    type: "bus",
  },
  {
    id: "BLUELINEFE",
    lcc: null,
    name: "Blue Line Ferries",
    type: "ferry",
  },
  {
    id: "RAILJET",
    lcc: null,
    name: "Railjet",
    type: "train",
  },
  {
    id: "AUTOTARRIV",
    lcc: null,
    name: "Autotrans by Arriva",
    type: "bus",
  },
  {
    id: "A5",
    lcc: 1,
    name: "HOP!",
    type: "airline",
  },
  {
    id: "QT",
    lcc: 0,
    name: "Avianca Cargo",
    type: "airline",
  },
  {
    id: "ORIONBUS",
    lcc: null,
    name: "Orionbus",
    type: "bus",
  },
  {
    id: "BELLTS",
    lcc: null,
    name: "Bell travel Services",
    type: "bus",
  },
  {
    id: "SCOT",
    lcc: null,
    name: "SCOTTURB",
    type: "bus",
  },
  {
    id: "ADNRDCKCA",
    lcc: null,
    name: "Adirondack Canada",
    type: "bus",
  },
  {
    id: "BAGDERBUS",
    lcc: null,
    name: "Badger Bus",
    type: "bus",
  },
  {
    id: "APPPOZEGA",
    lcc: null,
    name: "App Pozega by Arriva",
    type: "bus",
  },
  {
    id: "9C",
    lcc: 1,
    name: "Spring Airlines",
    type: "airline",
  },
  {
    id: "UA",
    lcc: 0,
    name: "United Airlines",
    type: "airline",
  },
  {
    id: "XT",
    lcc: 0,
    name: "Indonesia AirAsia X",
    type: "airline",
  },
  {
    id: "YK",
    lcc: 0,
    name: "Avia Traffic Airline",
    type: "airline",
  },
  {
    id: "UNIVERSE",
    lcc: null,
    name: "Universe",
    type: "bus",
  },
  {
    id: "KAISSARA",
    lcc: null,
    name: "Kaissara",
    type: "bus",
  },
  {
    id: "SU",
    lcc: 0,
    name: "Aeroflot Russian Airlines",
    type: "airline",
  },
  {
    id: "D7",
    lcc: 0,
    name: "AirAsia X",
    type: "airline",
  },
  {
    id: "XJ",
    lcc: 0,
    name: "Thai AirAsia X",
    type: "airline",
  },
  {
    id: "IFM",
    lcc: null,
    name: "IFLY",
    type: "airline",
  },
  {
    id: "RM",
    lcc: 0,
    name: "Air Dilijans",
    type: "airline",
  },
  {
    id: "9B",
    lcc: 0,
    name: "AccesRail",
    type: "train",
  },
  {
    id: "0B",
    lcc: 1,
    name: "Blue Air",
    type: "airline",
  },
  {
    id: "TRIPSTAIR",
    lcc: null,
    name: "Tripst Air",
    type: "bus",
  },
  {
    id: "INCARAIL",
    lcc: null,
    name: "Inca Rail",
    type: "train",
  },
  {
    id: "KIMSENGEX",
    lcc: null,
    name: "Kim Seng Express",
    type: "bus",
  },
  {
    id: "AVESENEJE",
    lcc: null,
    name: "AVE Senda Ejecutiva",
    type: "bus",
  },
  {
    id: "SOUTCHAITR",
    lcc: null,
    name: "Soutchai Travel",
    type: "bus",
  },
  {
    id: "HAYRENIQ",
    lcc: null,
    name: "Hayreniq Tour",
    type: "bus",
  },
  {
    id: "AUTOSALEMI",
    lcc: null,
    name: "Autoservizi Salemi",
    type: "bus",
  },
  {
    id: "EXPBUSEU",
    lcc: null,
    name: "Express Busline EU",
    type: "bus",
  },
  {
    id: "6E",
    lcc: 1,
    name: "IndiGo Airlines",
    type: "airline",
  },
  {
    id: "COTOLIMO",
    lcc: null,
    name: "Co To Limousine",
    type: "bus",
  },
  {
    id: "RADINAVIP",
    lcc: null,
    name: "Radina VIP",
    type: "bus",
  },
  {
    id: "QCKSHUT",
    lcc: null,
    name: "Quick Shuttle",
    type: "bus",
  },
  {
    id: "ALEX",
    lcc: null,
    name: "Alex",
    type: "train",
  },
  {
    id: "OBERPBAHN",
    lcc: null,
    name: "Oberpfalzbahn",
    type: "train",
  },
  {
    id: "POFERRY",
    lcc: null,
    name: "P&O Ferries",
    type: "ferry",
  },
  {
    id: "LIMOLINER",
    lcc: null,
    name: "LimoLiner",
    type: "bus",
  },
  {
    id: "GPORTO",
    lcc: null,
    name: "Goin' Porto",
    type: "bus",
  },
  {
    id: "HYLNCRUIS",
    lcc: null,
    name: "Hyline Cruises",
    type: "ferry",
  },
  {
    id: "MAXBUS",
    lcc: null,
    name: "MAX Bus",
    type: "bus",
  },
  {
    id: "SBRBNTRNS",
    lcc: null,
    name: "Suburban Transit",
    type: "bus",
  },
  {
    id: "SHORTLINE",
    lcc: null,
    name: "ShortLine",
    type: "bus",
  },
  {
    id: "SLVRMAINL",
    lcc: null,
    name: "Silverado Mainline",
    type: "bus",
  },
  {
    id: "WANDACO",
    lcc: null,
    name: "Wanda Coach",
    type: "bus",
  },
  {
    id: "WSHDELUXE",
    lcc: null,
    name: "Washington Deluxe",
    type: "bus",
  },
  {
    id: "LISCIO",
    lcc: null,
    name: "Liscio",
    type: "bus",
  },
  {
    id: "PTHNTOUR",
    lcc: null,
    name: "Python Tours",
    type: "bus",
  },
  {
    id: "MALOFEIEV",
    lcc: null,
    name: "Malofeiev A.M",
    type: "bus",
  },
  {
    id: "THAIRAIL",
    lcc: null,
    name: "Thai Railway",
    type: "train",
  },
  {
    id: "9Q",
    lcc: 0,
    name: "PB Air",
    type: "airline",
  },
  {
    id: "ONCF",
    lcc: null,
    name: "ONCF",
    type: "train",
  },
  {
    id: "RJ",
    lcc: 0,
    name: "Royal Jordanian",
    type: "airline",
  },
  {
    id: "DAIICHITR",
    lcc: null,
    name: "Daiichi Travel",
    type: "bus",
  },
  {
    id: "BESTBUS",
    lcc: null,
    name: "BestBus",
    type: "bus",
  },
  {
    id: "DOMOREIS",
    lcc: null,
    name: "Domo Reisen",
    type: "bus",
  },
  {
    id: "EBRTRAVL",
    lcc: null,
    name: "Eberhardt Travel",
    type: "bus",
  },
  {
    id: "VLEXX",
    lcc: null,
    name: "Vlexx",
    type: "train",
  },
  {
    id: "WLDBAHN",
    lcc: null,
    name: "Waldbahn",
    type: "train",
  },
  {
    id: "STNSTDCITY",
    lcc: null,
    name: "Stansted Citylink",
    type: "bus",
  },
  {
    id: "PNTARRIV",
    lcc: null,
    name: "Panturist by Arriva",
    type: "bus",
  },
  {
    id: "LUXBUSAM",
    lcc: null,
    name: "Lux Bus America",
    type: "bus",
  },
  {
    id: "EBUS",
    lcc: null,
    name: "Ebus",
    type: "bus",
  },
  {
    id: "PEORIACH",
    lcc: null,
    name: "Peoria Charter",
    type: "bus",
  },
  {
    id: "SENDORPRI",
    lcc: null,
    name: "Sendor Primera",
    type: "bus",
  },
  {
    id: "CLLGECBUS",
    lcc: null,
    name: "College Coach Bus",
    type: "bus",
  },
  {
    id: "HMPTNLXLIN",
    lcc: null,
    name: "Hampton Luxury Liner",
    type: "bus",
  },
  {
    id: "PRGAIRSHUT",
    lcc: null,
    name: "Prague Airport Shuttle",
    type: "bus",
  },
  {
    id: "JADROLNJA",
    lcc: null,
    name: "Jadrolinija",
    type: "ferry",
  },
  {
    id: "TRBUSBG",
    lcc: null,
    name: "Tourbus Bulgaria",
    type: "bus",
  },
  {
    id: "JETBUS",
    lcc: null,
    name: "Jetbus",
    type: "bus",
  },
  {
    id: "PROVIRAJ",
    lcc: null,
    name: "Proviraj",
    type: "bus",
  },
  {
    id: "DRTMTHCO",
    lcc: null,
    name: "Dartmouth Coach",
    type: "bus",
  },
  {
    id: "GOMOVIAG",
    lcc: null,
    name: "Gomo Viaggi",
    type: "ferry",
  },
  {
    id: "VENEZLINE",
    lcc: null,
    name: "Venezia Lines",
    type: "ferry",
  },
  {
    id: "BALIWOWTAT",
    lcc: null,
    name: "Baliwow Tour & Travel",
    type: "bus",
  },
  {
    id: "ODDDORIS",
    lcc: null,
    name: "ODD DORIS",
    type: "bus",
  },
  {
    id: "KHAOSANTT",
    lcc: null,
    name: "Khao San Tara Tour",
    type: "bus",
  },
  {
    id: "BALEARIA",
    lcc: null,
    name: "Balearia",
    type: "bus",
  },
  {
    id: "BUSEIREANN",
    lcc: null,
    name: "Bus Éireann",
    type: "bus",
  },
  {
    id: "DX",
    lcc: 0,
    name: "DAT Danish Air Transport",
    type: "airline",
  },
  {
    id: "VGTLBAHN",
    lcc: null,
    name: "Vogtlandbahn",
    type: "train",
  },
  {
    id: "GRUSENDPLS",
    lcc: null,
    name: "Grupo Senda Plus",
    type: "bus",
  },
  {
    id: "DOUBLET",
    lcc: null,
    name: "Double T",
    type: "bus",
  },
  {
    id: "CATCHRIDE",
    lcc: null,
    name: "Catch-A-Ride",
    type: "bus",
  },
  {
    id: "ICBUS",
    lcc: 0,
    name: "IC Bus",
    type: "bus",
  },
  {
    id: "SAVANNAH",
    lcc: null,
    name: "Savannah16",
    type: "bus",
  },
  {
    id: "PGLAIRBUS",
    lcc: null,
    name: "Pugliairbus",
    type: "bus",
  },
  {
    id: "TRSMEDITR",
    lcc: null,
    name: "Trasmediterranea",
    type: "ferry",
  },
  {
    id: "EILAT",
    lcc: null,
    name: "Eilat Shuttle",
    type: "bus",
  },
  {
    id: "BENZEXP",
    lcc: null,
    name: "Benz Express",
    type: "bus",
  },
  {
    id: "EKAREAEX",
    lcc: null,
    name: "Ekareach Express",
    type: "bus",
  },
  {
    id: "BUSCOAHUL",
    lcc: null,
    name: "Autobuses Coahuilenses",
    type: "bus",
  },
  {
    id: "BRCHGLABHN",
    lcc: null,
    name: "Berchtesgadener Land Bahn",
    type: "train",
  },
  {
    id: "BAYRREGBA",
    lcc: null,
    name: "Bayerische Regiobahn",
    type: "train",
  },
  {
    id: "ERIXX",
    lcc: null,
    name: "Erixx",
    type: "train",
  },
  {
    id: "JQ",
    lcc: 1,
    name: "Jetstar Airways",
    type: "airline",
  },
  {
    id: "EBVRKELWE",
    lcc: null,
    name: "Eisenbahnen und Verkehrsbetriebe Elbe-Weser",
    type: "train",
  },
  {
    id: "TRILEX",
    lcc: null,
    name: "Trilex",
    type: "train",
  },
  {
    id: "VRKVRENECK",
    lcc: null,
    name: "Verkehrsverbund Rhein-Neckar",
    type: "train",
  },
  {
    id: "LIBZADAR",
    lcc: null,
    name: "Liburnija Zadar",
    type: "bus",
  },
  {
    id: "TUFESAEX",
    lcc: null,
    name: "Tufesa Executive",
    type: "bus",
  },
  {
    id: "REDARRMOT",
    lcc: null,
    name: "Red Arrow Motorcoach",
    type: "bus",
  },
  {
    id: "REDCOACH",
    lcc: null,
    name: "RedCoach",
    type: "bus",
  },
  {
    id: "ZLATANREI",
    lcc: null,
    name: "Zlatan Reisen",
    type: "bus",
  },
  {
    id: "TRNSPTAMA",
    lcc: null,
    name: "Transportes Tamaulipas",
    type: "bus",
  },
  {
    id: "MODULTRNS",
    lcc: null,
    name: "Modul Trans",
    type: "bus",
  },
  {
    id: "BOSHARCRUI",
    lcc: null,
    name: "Boston Harbor Cruises",
    type: "ferry",
  },
  {
    id: "EEXPRSBUS",
    lcc: null,
    name: "El Expreso Bus",
    type: "bus",
  },
  {
    id: "YNKLINE",
    lcc: null,
    name: "Yankee Line",
    type: "bus",
  },
  {
    id: "SITA",
    lcc: null,
    name: "Sita",
    type: "bus",
  },
  {
    id: "WSCNCLINE",
    lcc: null,
    name: "Wisconsin Coach Lines",
    type: "bus",
  },
  {
    id: "BOZUR",
    lcc: null,
    name: "Bozur",
    type: "bus",
  },
  {
    id: "ACNAUTOB",
    lcc: null,
    name: "ACN Autobuses",
    type: "bus",
  },
  {
    id: "GOBUSES",
    lcc: null,
    name: "Go Buses",
    type: "bus",
  },
  {
    id: "YELLBUSTH",
    lcc: null,
    name: "Yellow Bus",
    type: "bus",
  },
  {
    id: "INDRAIL",
    lcc: null,
    name: "Indian Railways",
    type: "train",
  },
  {
    id: "2H",
    lcc: null,
    name: "Thalys",
    type: "train",
  },
  {
    id: "ENNO",
    lcc: null,
    name: "Enno",
    type: "train",
  },
  {
    id: "__",
    lcc: 0,
    name: "FakeAirline",
    type: "airline",
  },
  {
    id: "EETRAVEL",
    lcc: null,
    name: "Eastern European Travel",
    type: "bus",
  },
  {
    id: "BK",
    lcc: 0,
    name: "Okay Airways",
    type: "airline",
  },
  {
    id: "SRLANRAIL",
    lcc: null,
    name: "Sri Lanka Railways",
    type: "train",
  },
  {
    id: "9F",
    lcc: null,
    name: "Eurostar",
    type: "train",
  },
  {
    id: "ECOBAJATR",
    lcc: null,
    name: "ECO Baja Tours",
    type: "bus",
  },
  {
    id: "GROUPTOUR",
    lcc: null,
    name: "Grouptour",
    type: "train",
  },
  {
    id: "COMBOIOS",
    lcc: null,
    name: "Comboios de Portugal",
    type: "train",
  },
  {
    id: "LYSATRANS",
    lcc: null,
    name: "LYS-AVTO-TRANS",
    type: "bus",
  },
  {
    id: "P8",
    lcc: 0,
    name: "Sprint Air",
    type: "airline",
  },
  {
    id: "AKVITANIAL",
    lcc: null,
    name: "Akvitania-Lux",
    type: "bus",
  },
  {
    id: "VB",
    lcc: 1,
    name: "VivaAerobus",
    type: "airline",
  },
  {
    id: "EQ",
    lcc: 0,
    name: "TAME",
    type: "airline",
  },
  {
    id: "EXPRESSWAY",
    lcc: null,
    name: "Expressway",
    type: "bus",
  },
  {
    id: "TAGKOMPANI",
    lcc: null,
    name: "Tagkompaniet",
    type: "train",
  },
  {
    id: "DALATRAFIK",
    lcc: null,
    name: "Dalatrafik",
    type: "bus",
  },
  {
    id: "ADAMEKSPRE",
    lcc: null,
    name: "Adam Ekspres",
    type: "bus",
  },
  {
    id: "AIRPORTCOA",
    lcc: null,
    name: "Airport Coach Sdn Bhd",
    type: "bus",
  },
  {
    id: "ALISANGOLD",
    lcc: null,
    name: "Alisan Golden Coach",
    type: "bus",
  },
  {
    id: "ALYAXPRESS",
    lcc: null,
    name: "Alya Xpress",
    type: "bus",
  },
  {
    id: "BANGPHAN",
    lcc: null,
    name: "Bang Phan",
    type: "bus",
  },
  {
    id: "CEPATCEKAP",
    lcc: null,
    name: "Cepat Cekap",
    type: "bus",
  },
  {
    id: "CITYHOLIDA",
    lcc: null,
    name: "City Holidays Express",
    type: "bus",
  },
  {
    id: "COSMICEXPR",
    lcc: null,
    name: "Cosmic Express",
    type: "bus",
  },
  {
    id: "GOTLAND",
    lcc: null,
    name: "Air Gotland",
    type: "airline",
  },
  {
    id: "COLUMBUZZ",
    lcc: null,
    name: "Columbuzz",
    type: "bus",
  },
  {
    id: "12GOTRANSP",
    lcc: null,
    name: "12Go Transport",
    type: "bus",
  },
  {
    id: "FOPSOLOVI",
    lcc: null,
    name: "Carrier XX",
    type: "airline",
  },
  {
    id: "ADIKBERADI",
    lcc: null,
    name: "Adik Beradik",
    type: "bus",
  },
  {
    id: "ARWANAEXPR",
    lcc: null,
    name: "Arwana Express",
    type: "bus",
  },
  {
    id: "NAVARRA",
    lcc: null,
    name: "Compania navarra de autobuses",
    type: "bus",
  },
  {
    id: "P7",
    lcc: 0,
    name: "Small Planet Airline",
    type: "airline",
  },
  {
    id: "CSTRAVELAN",
    lcc: null,
    name: "CS Travel and Tours",
    type: "bus",
  },
  {
    id: "DUCDAT",
    lcc: null,
    name: "Duc Dat",
    type: "bus",
  },
  {
    id: "EDAIRILARI",
    lcc: null,
    name: "ED Airilariana",
    type: "bus",
  },
  {
    id: "SVI",
    lcc: null,
    name: "Sky Vision Airlines",
    type: "airline",
  },
  {
    id: "ARRIVAUKLP",
    lcc: null,
    name: "Arriva UK Liverpool Service",
    type: "bus",
  },
  {
    id: "LUXRKHAR",
    lcc: null,
    name: "Lux-Reisen/Kharkavyi V.I.",
    type: "bus",
  },
  {
    id: "OP",
    lcc: null,
    name: "PassionAir",
    type: "airline",
  },
  {
    id: "DS",
    lcc: 0,
    name: "EasyJet (DS)",
    type: "airline",
  },
  {
    id: "ALIFBATAAQ",
    lcc: null,
    name: "Alif Bata AQM Enterprise",
    type: "bus",
  },
  {
    id: "ANKHANH",
    lcc: null,
    name: "An Khanh",
    type: "bus",
  },
  {
    id: "CALTUR",
    lcc: null,
    name: "Caltur",
    type: "bus",
  },
  {
    id: "RZD",
    lcc: null,
    name: "Russian Railways",
    type: "train",
  },
  {
    id: "CHALTENTRA",
    lcc: null,
    name: "Chalten Travel",
    type: "bus",
  },
  {
    id: "DUHTAWADIM",
    lcc: null,
    name: "Duhtawadi Mann Express",
    type: "bus",
  },
  {
    id: "LUEBECK",
    lcc: null,
    name: "Luebeck Air",
    type: "airline",
  },
  {
    id: "ELINETUR",
    lcc: null,
    name: "E. Line-Tur",
    type: "bus",
  },
  {
    id: "BHRAYONG",
    lcc: null,
    name: "BH Rayong",
    type: "bus",
  },
  {
    id: "BONHAI",
    lcc: null,
    name: "Bon Hai",
    type: "bus",
  },
  {
    id: "BULANRESTU",
    lcc: null,
    name: "Bulan Restu",
    type: "bus",
  },
  {
    id: "FLYERTRANS",
    lcc: null,
    name: "Flyer Transdev",
    type: "bus",
  },
  {
    id: "PERURAIL",
    lcc: null,
    name: "Peru Rail",
    type: "train",
  },
  {
    id: "M9",
    lcc: 0,
    name: "Motor Sich",
    type: "airline",
  },
  {
    id: "CPHAIRTAXI",
    lcc: null,
    name: "Copenhagen Airtaxi",
    type: "airline",
  },
  {
    id: "BTQ",
    lcc: null,
    name: "Boutique Flights",
    type: "airline",
  },
  {
    id: "LIZ",
    lcc: null,
    name: "Air Liaison",
    type: "airline",
  },
  {
    id: "EDARANEKSP",
    lcc: null,
    name: "Edaran Ekspres",
    type: "bus",
  },
  {
    id: "ETIKAEXPRE",
    lcc: null,
    name: "Etika Express",
    type: "bus",
  },
  {
    id: "GAMINLATTW",
    lcc: null,
    name: "GA Min Latt War",
    type: "bus",
  },
  {
    id: "GREENBUS",
    lcc: null,
    name: "Green Bus",
    type: "bus",
  },
  {
    id: "TT",
    lcc: 1,
    name: "Braathens International Airways",
    type: "airline",
  },
  {
    id: "GREENAIR",
    lcc: null,
    name: "Green Airlines",
    type: "airline",
  },
  {
    id: "ULT",
    lcc: null,
    name: "Public Traffic Uppland train",
    type: "train",
  },
  {
    id: "MTRNORDIC",
    lcc: null,
    name: "MTR Nordic",
    type: "train",
  },
  {
    id: "EUROTRANS",
    lcc: null,
    name: "AD EuroTrans",
    type: "bus",
  },
  {
    id: "FLYGBUSSAR",
    lcc: null,
    name: "Flygbussarna",
    type: "bus",
  },
  {
    id: "GREENPARAD",
    lcc: null,
    name: "Green Paradise Travel",
    type: "bus",
  },
  {
    id: "GURUNSUPER",
    lcc: null,
    name: "Gurun Super Nice",
    type: "bus",
  },
  {
    id: "HMANTHITSA",
    lcc: null,
    name: "Hman Thitsar Express",
    type: "bus",
  },
  {
    id: "HUAYRUROTO",
    lcc: null,
    name: "Huayruro Tours",
    type: "bus",
  },
  {
    id: "INTERCITYC",
    lcc: null,
    name: "Intercity Coach",
    type: "bus",
  },
  {
    id: "KEJORAMASY",
    lcc: null,
    name: "Kejora Masyhur Sdn.Bhd (Kejora Express)",
    type: "bus",
  },
  {
    id: "KESATUANEX",
    lcc: null,
    name: "Kesatuan Express",
    type: "bus",
  },
  {
    id: "LPMS",
    lcc: null,
    name: "LPMS",
    type: "bus",
  },
  {
    id: "MARGATAQSA",
    lcc: null,
    name: "Marga Taqsa",
    type: "bus",
  },
  {
    id: "MORAZAEXPR",
    lcc: null,
    name: "Moraza Express",
    type: "bus",
  },
  {
    id: "PERAKTRANS",
    lcc: null,
    name: "Perak Transit",
    type: "bus",
  },
  {
    id: "PHUCLOCTHO",
    lcc: null,
    name: "Phuc Loc Tho Limousine",
    type: "bus",
  },
  {
    id: "PRISMAEXPR",
    lcc: null,
    name: "Prisma Express",
    type: "bus",
  },
  {
    id: "PWINTTHITS",
    lcc: null,
    name: "Pwint Thitsa Yadanar Express",
    type: "bus",
  },
  {
    id: "QUANGTUYEN",
    lcc: null,
    name: "Quang Tuyen",
    type: "bus",
  },
  {
    id: "QUEENEXPRE",
    lcc: null,
    name: "Queen Express",
    type: "bus",
  },
  {
    id: "QUICKLINER",
    lcc: null,
    name: "Quickliner Express",
    type: "bus",
  },
  {
    id: "RES2EXPRES",
    lcc: null,
    name: "Res2 Express",
    type: "bus",
  },
  {
    id: "SANIEXPRES",
    lcc: null,
    name: "Sani Express",
    type: "bus",
  },
  {
    id: "SANWAEXPRE",
    lcc: null,
    name: "Sanwa Express",
    type: "bus",
  },
  {
    id: "SBETRANSPO",
    lcc: null,
    name: "SBE Transport",
    type: "bus",
  },
  {
    id: "SEASONSEXP",
    lcc: null,
    name: "Seasons Express",
    type: "bus",
  },
  {
    id: "SHWEMANDAL",
    lcc: null,
    name: "Shwe Mandalar",
    type: "bus",
  },
  {
    id: "SINYONG",
    lcc: null,
    name: "Sin Yong",
    type: "bus",
  },
  {
    id: "STARSHUTTL",
    lcc: null,
    name: "Star Shuttle Express",
    type: "bus",
  },
  {
    id: "SUASANATON",
    lcc: null,
    name: "Suasana Tony Coach",
    type: "bus",
  },
  {
    id: "TANDUNGTIE",
    lcc: null,
    name: "Tan Dung Tien",
    type: "bus",
  },
  {
    id: "TRANSLITOR",
    lcc: null,
    name: "Trans Litoral",
    type: "bus",
  },
  {
    id: "TRANSMVSEX",
    lcc: null,
    name: "Trans MVS Express",
    type: "bus",
  },
  {
    id: "VANMINH",
    lcc: null,
    name: "Van Minh",
    type: "bus",
  },
  {
    id: "TOURGROUP",
    lcc: null,
    name: "Tour Group",
    type: "bus",
  },
  {
    id: "RER",
    lcc: null,
    name: "Aeroregional",
    type: "airline",
  },
  {
    id: "BER",
    lcc: null,
    name: "Fly Air41",
    type: "airline",
  },
  {
    id: "GOTRANSITT",
    lcc: null,
    name: "GO Transit train",
    type: "train",
  },
  {
    id: "DU",
    lcc: null,
    name: "Air Liaison",
    type: "airline",
  },
  {
    id: "EKSPRESMUS",
    lcc: null,
    name: "Ekspres Musafir",
    type: "bus",
  },
  {
    id: "EKSPRESMUT",
    lcc: null,
    name: "Ekspres Mutiara",
    type: "bus",
  },
  {
    id: "GOFAMILYVI",
    lcc: null,
    name: "GO Family VIP & Express",
    type: "bus",
  },
  {
    id: "HAIHANH",
    lcc: null,
    name: "Hai Hanh",
    type: "bus",
  },
  {
    id: "HNINCHERRY",
    lcc: null,
    name: "Hnin Cherry Express",
    type: "bus",
  },
  {
    id: "INTERTOPEX",
    lcc: null,
    name: "Intertop Express",
    type: "bus",
  },
  {
    id: "JASMINEEXP",
    lcc: null,
    name: "Jasmine Express",
    type: "bus",
  },
  {
    id: "KPBKLUANG",
    lcc: null,
    name: "KPB (Kluang)",
    type: "bus",
  },
  {
    id: "LAPANMALAY",
    lcc: null,
    name: "Lapan Malayasia",
    type: "bus",
  },
  {
    id: "MARALINER",
    lcc: null,
    name: "Maraliner",
    type: "bus",
  },
  {
    id: "MERIDIANHO",
    lcc: null,
    name: "Meridian Holidays",
    type: "bus",
  },
  {
    id: "MINHQUOC",
    lcc: null,
    name: "Minh Quoc",
    type: "bus",
  },
  {
    id: "MYXPRESS",
    lcc: null,
    name: "My Xpress",
    type: "bus",
  },
  {
    id: "MYAYADANAR",
    lcc: null,
    name: "Mya Yadanar Express",
    type: "bus",
  },
  {
    id: "NEWASIANEX",
    lcc: null,
    name: "New Asian Express",
    type: "bus",
  },
  {
    id: "NGOCANH",
    lcc: null,
    name: "Ngoc Anh",
    type: "bus",
  },
  {
    id: "PAHANGLINS",
    lcc: null,
    name: "Pahang Lin Siong Motor Co Bhd",
    type: "bus",
  },
  {
    id: "SAONGHELIM",
    lcc: null,
    name: "Sao Nghe Limousine",
    type: "bus",
  },
  {
    id: "SAPADRAGON",
    lcc: null,
    name: "Sapa Dragon Express",
    type: "bus",
  },
  {
    id: "SHWEKEINNE",
    lcc: null,
    name: "Shwe Keinnery",
    type: "bus",
  },
  {
    id: "SMBEKSPRES",
    lcc: null,
    name: "SMB Ekspress",
    type: "bus",
  },
  {
    id: "SPBUMI",
    lcc: null,
    name: "SP BUMI",
    type: "bus",
  },
  {
    id: "THEINTHANK",
    lcc: null,
    name: "Thein Than Kaday Express",
    type: "bus",
  },
  {
    id: "THUKHAWAIH",
    lcc: null,
    name: "Thu Kha Wai Hlyan",
    type: "bus",
  },
  {
    id: "LAHOLIDAYS",
    lcc: null,
    name: "LA Holidays Travel and Tours Sdn Bhd",
    type: "bus",
  },
  {
    id: "BOREYMI",
    lcc: null,
    name: "Boreychuk M.I.",
    type: "bus",
  },
  {
    id: "BT",
    lcc: 1,
    name: "airBaltic",
    type: "airline",
  },
  {
    id: "XR",
    lcc: 0,
    name: "Corendon Airlines Europe",
    type: "airline",
  },
  {
    id: "ELTABINAJA",
    lcc: null,
    name: "Eltabina Jaya Express",
    type: "bus",
  },
  {
    id: "EVAEXPRESS",
    lcc: null,
    name: "Eva Express",
    type: "bus",
  },
  {
    id: "GJGEXPRESS",
    lcc: null,
    name: "GJG Express",
    type: "bus",
  },
  {
    id: "KKKLEXPRES",
    lcc: null,
    name: "KKKL Express",
    type: "bus",
  },
  {
    id: "KKKLLAHOLI",
    lcc: null,
    name: "KKKL Express(LA Holidays)",
    type: "bus",
  },
  {
    id: "NGOCCUONG",
    lcc: null,
    name: "Ngoc Cuong",
    type: "bus",
  },
  {
    id: "NGWEHNINMA",
    lcc: null,
    name: "Ngwe Hnin Mandalar",
    type: "bus",
  },
  {
    id: "NISHMALEST",
    lcc: null,
    name: "Nishma Lestary (Naik Selalu)",
    type: "bus",
  },
  {
    id: "PHUCLOC",
    lcc: null,
    name: "Phuc Loc",
    type: "bus",
  },
  {
    id: "SHWETAUNGY",
    lcc: null,
    name: "Shwe Taung Yoe Express",
    type: "bus",
  },
  {
    id: "STARCOEK",
    lcc: null,
    name: "Star Coach Express",
    type: "bus",
  },
  {
    id: "SUASANAEXP",
    lcc: null,
    name: "Suasana Express",
    type: "bus",
  },
  {
    id: "SUNGEIMERA",
    lcc: null,
    name: "Sungei Merah",
    type: "bus",
  },
  {
    id: "THETRANSPO",
    lcc: null,
    name: "The Transport Co. Ltd.",
    type: "bus",
  },
  {
    id: "TRANSMANCO",
    lcc: null,
    name: "Trans Manco Kapac",
    type: "bus",
  },
  {
    id: "TRANSTITIC",
    lcc: null,
    name: "Trans Titicaca Bolivia",
    type: "bus",
  },
  {
    id: "SVS",
    lcc: null,
    name: "SAS Link",
    type: "airline",
  },
  {
    id: "TRANSMALAY",
    lcc: null,
    name: "TransMalaya Ekspres",
    type: "bus",
  },
  {
    id: "TRANSNASIO",
    lcc: null,
    name: "Transnasional",
    type: "bus",
  },
  {
    id: "TRUNGNGA",
    lcc: null,
    name: "Trung Nga",
    type: "bus",
  },
  {
    id: "TURISMOPAN",
    lcc: null,
    name: "Turismo Panamericano",
    type: "bus",
  },
  {
    id: "UNITRANSCO",
    lcc: null,
    name: "Unitransco",
    type: "bus",
  },
  {
    id: "UTAMAEKSPR",
    lcc: null,
    name: "Utama Ekspres",
    type: "bus",
  },
  {
    id: "YOYOEXPRES",
    lcc: null,
    name: "YOYO Express",
    type: "bus",
  },
  {
    id: "ATRANZIT",
    lcc: null,
    name: "Avtotranzit",
    type: "bus",
  },
  {
    id: "BTF",
    lcc: null,
    name: "Boutique Flights",
    type: "airline",
  },
  {
    id: "TRANCO",
    lcc: null,
    name: "Transport Co",
    type: "bus",
  },
  {
    id: "FIRSTCOACH",
    lcc: null,
    name: "First Coach Malaysia",
    type: "bus",
  },
  {
    id: "GOGENTING",
    lcc: null,
    name: "Go Genting",
    type: "bus",
  },
  {
    id: "JASAPELANG",
    lcc: null,
    name: "Jasa Pelangi Ekspres",
    type: "bus",
  },
  {
    id: "JOOSENGTRA",
    lcc: null,
    name: "Joo Seng Travel",
    type: "bus",
  },
  {
    id: "KHANHTHUY",
    lcc: null,
    name: "Khanh Thuy",
    type: "bus",
  },
  {
    id: "LEDUNG",
    lcc: null,
    name: "Le Dung",
    type: "bus",
  },
  {
    id: "LIENADIAEX",
    lcc: null,
    name: "Lienadia Express",
    type: "bus",
  },
  {
    id: "ORESUNDST",
    lcc: 0,
    name: "Oresundstag",
    type: "train",
  },
  {
    id: "RIDECR",
    lcc: null,
    name: "RideCR",
    type: "bus",
  },
  {
    id: "COTAXI",
    lcc: null,
    name: "Cotaxi",
    type: "bus",
  },
  {
    id: "TAXIVERPS",
    lcc: null,
    name: "Taxis Verdes Private Services",
    type: "bus",
  },
  {
    id: "SINHTOUR",
    lcc: null,
    name: "The Sinh Tourist",
    type: "bus",
  },
  {
    id: "FANT",
    lcc: null,
    name: "FlyAntalya",
    type: "bus",
  },
  {
    id: "WESTBAHN",
    lcc: null,
    name: "WESTbahn",
    type: "train",
  },
  {
    id: "NORNEANE",
    lcc: null,
    name: "Nor Neane Transport",
    type: "bus",
  },
  {
    id: "LUMBINIEX",
    lcc: null,
    name: "Lumbini Express",
    type: "bus",
  },
  {
    id: "BANGKOKBUS",
    lcc: null,
    name: "Bangkok Busline",
    type: "bus",
  },
  {
    id: "EDITRAMS",
    lcc: null,
    name: "Edinburgh Trams",
    type: "train",
  },
  {
    id: "BUDSARAKHA",
    lcc: null,
    name: "Budsarakham Tour",
    type: "bus",
  },
  {
    id: "MYOSETTHIT",
    lcc: null,
    name: "Myo Set Thit Express",
    type: "bus",
  },
  {
    id: "NAZAEXPRES",
    lcc: null,
    name: "Naza Express",
    type: "bus",
  },
  {
    id: "LEDA",
    lcc: null,
    name: "LEDA",
    type: "bus",
  },
  {
    id: "NEOLINEREX",
    lcc: null,
    name: "Neoliner Express",
    type: "bus",
  },
  {
    id: "OHAYAMITRA",
    lcc: null,
    name: "Ohayami Trans",
    type: "bus",
  },
  {
    id: "PERDANAEXP",
    lcc: null,
    name: "Perdana Express",
    type: "bus",
  },
  {
    id: "QUANGNGHI",
    lcc: null,
    name: "Quang Nghi",
    type: "bus",
  },
  {
    id: "QUEENCAFEB",
    lcc: null,
    name: "Queen Cafe Bus",
    type: "bus",
  },
  {
    id: "RHINOAIRIE",
    lcc: null,
    name: "Rhino Airiel Express",
    type: "bus",
  },
  {
    id: "SAWASDEEAL",
    lcc: null,
    name: "Sawasdee All Thai",
    type: "bus",
  },
  {
    id: "SHWESINSET",
    lcc: null,
    name: "Shwe Sin Setkyar Express",
    type: "bus",
  },
  {
    id: "SOUTHERNST",
    lcc: null,
    name: "Southern Star Bus Transit",
    type: "bus",
  },
  {
    id: "STARCOEKSD",
    lcc: null,
    name: "Star Coach Ekspres Sdn Bhd",
    type: "bus",
  },
  {
    id: "TIENTUE",
    lcc: null,
    name: "Tien Tue",
    type: "bus",
  },
  {
    id: "TOPLINER",
    lcc: null,
    name: "Top Liner",
    type: "bus",
  },
  {
    id: "YELLOWSTAR",
    lcc: null,
    name: "Yellow Star Express",
    type: "bus",
  },
  {
    id: "ZAIMEXPRES",
    lcc: null,
    name: "Zaim Express",
    type: "bus",
  },
  {
    id: "KUMSAM",
    lcc: null,
    name: "Kumho Samco",
    type: "bus",
  },
  {
    id: "BAY",
    lcc: null,
    name: "Fly Erbil",
    type: "airline",
  },
  {
    id: "11",
    lcc: 0,
    name: "TUIfly (X3)",
    type: "airline",
  },
  {
    id: "MADTRANS",
    lcc: null,
    name: "Madeltrans",
    type: "bus",
  },
  {
    id: "COMATIPSG",
    lcc: null,
    name: "Comati PSG",
    type: "bus",
  },
  {
    id: "EUROLINES",
    lcc: 0,
    name: "Eurolines",
    type: "bus",
  },
  {
    id: "FILS",
    lcc: null,
    name: "Fils",
    type: "bus",
  },
  {
    id: "ISILINES",
    lcc: 0,
    name: "Isilines",
    type: "bus",
  },
  {
    id: "NOMAGO",
    lcc: null,
    name: "Nomago",
    type: "bus",
  },
  {
    id: "SINDBAD",
    lcc: null,
    name: "Sindbad",
    type: "bus",
  },
  {
    id: "8W",
    lcc: null,
    name: "Fly Allways",
    type: "airline",
  },
  {
    id: "FLIBCO",
    lcc: 0,
    name: "Flibco",
    type: "bus",
  },
  {
    id: "FLIXTRAIN",
    lcc: 0,
    name: "Flixtrain",
    type: "train",
  },
  {
    id: "EXOMONTRET",
    lcc: null,
    name: "Exo Montreal RTM train",
    type: "train",
  },
  {
    id: "SNCB",
    lcc: 0,
    name: "SNCB",
    type: "train",
  },
  {
    id: "TRA",
    lcc: null,
    name: "Tara Air",
    type: "airline",
  },
  {
    id: "6B",
    lcc: 0,
    name: "TUI fly Nordic",
    type: "airline",
  },
  {
    id: "SAGALES",
    lcc: null,
    name: "Sagales",
    type: "bus",
  },
  {
    id: "SR",
    lcc: 0,
    name: "Sundair",
    type: "airline",
  },
  {
    id: "ROONGREUAN",
    lcc: null,
    name: "Roong Reuang Coach",
    type: "bus",
  },
  {
    id: "6R",
    lcc: 1,
    name: "Alrosa",
    type: "airline",
  },
  {
    id: "Q2",
    lcc: 0,
    name: "Maldivian",
    type: "airline",
  },
  {
    id: "DW",
    lcc: null,
    name: "Great Dane Airlines",
    type: "airline",
  },
  {
    id: "YD",
    lcc: 0,
    name: "Gomelavia",
    type: "airline",
  },
  {
    id: "99",
    lcc: 0,
    name: "Ciao Air",
    type: "airline",
  },
  {
    id: "LC",
    lcc: null,
    name: "Skyway CR",
    type: "airline",
  },
  {
    id: "LIENHUNG",
    lcc: null,
    name: "Lien Hung",
    type: "bus",
  },
  {
    id: "LONGTEST",
    lcc: 0,
    name: "Long iata code test",
    type: "bus",
  },
  {
    id: "BUSCENTER",
    lcc: null,
    name: "BusCenter",
    type: "bus",
  },
  {
    id: "6D",
    lcc: 0,
    name: "Pelita Air",
    type: "airline",
  },
  {
    id: "QQ",
    lcc: 0,
    name: "Alliance Airlines",
    type: "airline",
  },
  {
    id: "GREYHOUND",
    lcc: 0,
    name: "Greyhound",
    type: "bus",
  },
  {
    id: "LUXEXPRES",
    lcc: null,
    name: "Lux Express",
    type: "bus",
  },
  {
    id: "ARYSTAN",
    lcc: null,
    name: "FlyArystan",
    type: "airline",
  },
  {
    id: "DK",
    lcc: 0,
    name: "Sunclass Airlines",
    type: "airline",
  },
  {
    id: "TRALAPA",
    lcc: null,
    name: "Tralapa",
    type: "bus",
  },
  {
    id: "WST",
    lcc: null,
    name: "Western Air",
    type: "airline",
  },
  {
    id: "VIARAIL",
    lcc: null,
    name: "Via Rail Canada",
    type: "train",
  },
  {
    id: "METRONOM",
    lcc: null,
    name: "Metronom",
    type: "train",
  },
  {
    id: "GDE",
    lcc: null,
    name: "Great Dane Airlines",
    type: "airline",
  },
  {
    id: "BUTAAIR",
    lcc: 1,
    name: "Buta Airways",
    type: "airline",
  },
  {
    id: "E8",
    lcc: null,
    name: "Tayaranjet",
    type: "airline",
  },
  {
    id: "NATEXPRESS",
    lcc: 0,
    name: "National Express",
    type: "bus",
  },
  {
    id: "KIRANTRANS",
    lcc: null,
    name: "Kiran Transportation",
    type: "bus",
  },
  {
    id: "RXP",
    lcc: null,
    name: "Royal Air Maroc Express",
    type: "airline",
  },
  {
    id: "FLIXBUS",
    lcc: 0,
    name: "Flixbus",
    type: "bus",
  },
  {
    id: "PHANTRAV",
    lcc: null,
    name: "Phantip Travel",
    type: "bus",
  },
  {
    id: "HEYTRAVEL",
    lcc: null,
    name: "Hey Travel",
    type: "bus",
  },
  {
    id: "HUNGTHANH",
    lcc: null,
    name: "Hung Thanh",
    type: "bus",
  },
  {
    id: "CHERRYBUS",
    lcc: null,
    name: "Cherry Bus",
    type: "bus",
  },
  {
    id: "CAMELTRAVE",
    lcc: null,
    name: "Camel Travel",
    type: "bus",
  },
  {
    id: "UYUNIPANAS",
    lcc: null,
    name: "Uyuni Panasur",
    type: "bus",
  },
  {
    id: "TRANSTARTR",
    lcc: null,
    name: "Transtar Travel Pte",
    type: "bus",
  },
  {
    id: "TAMHANHTRA",
    lcc: null,
    name: "Tam Hanh Travel",
    type: "bus",
  },
  {
    id: "HALONGFANC",
    lcc: null,
    name: "Ha Long Fancy Travel",
    type: "bus",
  },
  {
    id: "QUANGHANH",
    lcc: null,
    name: "Quang Hanh",
    type: "bus",
  },
  {
    id: "NAGAEXTRAV",
    lcc: null,
    name: "Naga Express Travel",
    type: "bus",
  },
  {
    id: "SAPAEXPRES",
    lcc: null,
    name: "Sapa Express",
    type: "bus",
  },
  {
    id: "OLTURSA",
    lcc: null,
    name: "Oltursa",
    type: "bus",
  },
  {
    id: "RUNGKITTOU",
    lcc: null,
    name: "Rungkit Tour",
    type: "bus",
  },
  {
    id: "MAICHAUNAT",
    lcc: null,
    name: "Mai Chau Nature Lodge",
    type: "bus",
  },
  {
    id: "BAGMTEX",
    lcc: null,
    name: "Bagan Min Thar Express",
    type: "bus",
  },
  {
    id: "MIBAGONEEX",
    lcc: null,
    name: "Mi Ba Gone Express",
    type: "bus",
  },
  {
    id: "SHWEYARZAR",
    lcc: null,
    name: "Shwe Yar Zar Express",
    type: "bus",
  },
  {
    id: "ORSUKSATOU",
    lcc: null,
    name: "Orsuksa Tour",
    type: "bus",
  },
  {
    id: "POSANTOSO",
    lcc: null,
    name: "PO Santoso",
    type: "bus",
  },
  {
    id: "THAOCHAU",
    lcc: null,
    name: "Thao Chau",
    type: "bus",
  },
  {
    id: "HELLOEXPRE",
    lcc: null,
    name: "Hello Express",
    type: "bus",
  },
  {
    id: "TUANTU",
    lcc: null,
    name: "Tuan Tu",
    type: "bus",
  },
  {
    id: "RAINBOWSAF",
    lcc: null,
    name: "Rainbow Safari Tours & Travels",
    type: "bus",
  },
  {
    id: "HIMEXNEPAL",
    lcc: null,
    name: "HimEx Nepal",
    type: "bus",
  },
  {
    id: "NORTHERNTR",
    lcc: null,
    name: "Northern Travels",
    type: "bus",
  },
  {
    id: "SALVATION",
    lcc: null,
    name: "Salvation (Mokshya) Adventure Travels",
    type: "bus",
  },
  {
    id: "KPBEKSPRES",
    lcc: null,
    name: "KPB Ekspres",
    type: "bus",
  },
  {
    id: "LOTUSPIBOO",
    lcc: null,
    name: "Lotus Piboon",
    type: "bus",
  },
  {
    id: "JKPMOCHIT",
    lcc: null,
    name: "JKP Mochit",
    type: "bus",
  },
  {
    id: "TTANDTRVL",
    lcc: null,
    name: "Tara Tour and Travel",
    type: "bus",
  },
  {
    id: "IW",
    lcc: 0,
    name: "Wings Air",
    type: "airline",
  },
  {
    id: "TS",
    lcc: 0,
    name: "Air Transat",
    type: "airline",
  },
  {
    id: "TERAVSN",
    lcc: null,
    name: "Terravision",
    type: "bus",
  },
  {
    id: "MARINO",
    lcc: 0,
    name: "Marino Bus",
    type: "bus",
  },
  {
    id: "LEOEXB",
    lcc: null,
    name: "LEOEXPRESS Bus",
    type: "bus",
  },
  {
    id: "LY",
    lcc: 1,
    name: "El Al Israel Airlines",
    type: "airline",
  },
  {
    id: "IN",
    lcc: 0,
    name: "Nam Air",
    type: "airline",
  },
  {
    id: "CHIHUAHUEN",
    lcc: null,
    name: "Transportes Chihuahuenses",
    type: "bus",
  },
  {
    id: "FE",
    lcc: null,
    name: "748 Air Services",
    type: "airline",
  },
  {
    id: "SSINTERNA",
    lcc: null,
    name: "S&S International Express",
    type: "bus",
  },
  {
    id: "IRCITYLINK",
    lcc: null,
    name: "Irish Citylink",
    type: "bus",
  },
  {
    id: "TRANSFERO",
    lcc: 0,
    name: "Transfero",
    type: "bus",
  },
  {
    id: "APPENINO",
    lcc: null,
    name: "Appenino shuttle",
    type: "bus",
  },
  {
    id: "CITYLINK",
    lcc: null,
    name: "Citylink",
    type: "bus",
  },
  {
    id: "EXPRTRANS",
    lcc: null,
    name: "Ekspres transfer",
    type: "bus",
  },
  {
    id: "LEBUSDIR",
    lcc: 0,
    name: "Le Bus Direct",
    type: "bus",
  },
  {
    id: "DEINBUS",
    lcc: 0,
    name: "DeinBus",
    type: "bus",
  },
  {
    id: "AIRBUEX",
    lcc: null,
    name: "Airport Bus Express",
    type: "bus",
  },
  {
    id: "8E",
    lcc: 0,
    name: "Bering Air",
    type: "airline",
  },
  {
    id: "LANDVETTER",
    lcc: null,
    name: "Landvetter Expressen",
    type: "bus",
  },
  {
    id: "LEOEXT",
    lcc: null,
    name: "LEOEXPRESS Train",
    type: "train",
  },
  {
    id: "IA",
    lcc: 0,
    name: "Iraqi Airways",
    type: "airline",
  },
  {
    id: "AIRBUSEX",
    lcc: null,
    name: "Airport Bus Express",
    type: "bus",
  },
  {
    id: "UM",
    lcc: 0,
    name: "Air Zimbabwe",
    type: "airline",
  },
  {
    id: "KA",
    lcc: 0,
    name: "Cathay Dragon",
    type: "airline",
  },
  {
    id: "2Q",
    lcc: 0,
    name: "Air Cargo Carriers",
    type: "airline",
  },
  {
    id: "SP",
    lcc: 0,
    name: "SATA Air Acores",
    type: "airline",
  },
  {
    id: "6G",
    lcc: 0,
    name: "Air Wales",
    type: "airline",
  },
  {
    id: "4M",
    lcc: 0,
    name: "Mavi Gök Airlines",
    type: "airline",
  },
  {
    id: "6K",
    lcc: 0,
    name: "Asian Spirit",
    type: "airline",
  },
  {
    id: "NUA",
    lcc: null,
    name: "United Nigeria",
    type: "airline",
  },
  {
    id: "NU",
    lcc: 0,
    name: "Japan Transocean Air",
    type: "airline",
  },
  {
    id: "PVG",
    lcc: null,
    name: "Privilege Style",
    type: "airline",
  },
  {
    id: "UL",
    lcc: 0,
    name: "SriLankan Airlines",
    type: "airline",
  },
  {
    id: "VIAS",
    lcc: null,
    name: "Vias",
    type: "train",
  },
  {
    id: "XL",
    lcc: 0,
    name: "LATAM Ecuador",
    type: "airline",
  },
  {
    id: "JC",
    lcc: 0,
    name: "JAL Express",
    type: "airline",
  },
  {
    id: "EXPRESSBUS",
    lcc: null,
    name: "ExpressBus",
    type: "bus",
  },
  {
    id: "CZECHRAILB",
    lcc: null,
    name: "Czech Rail bus",
    type: "bus",
  },
  {
    id: "SLOVAKLNS",
    lcc: null,
    name: "Slovak Lines",
    type: "bus",
  },
  {
    id: "ESTLOREK",
    lcc: null,
    name: "EST Lorek",
    type: "bus",
  },
  {
    id: "LUFTHBUS",
    lcc: null,
    name: "Lufthansa express bus",
    type: "bus",
  },
  {
    id: "BI",
    lcc: 0,
    name: "Royal Brunei Airlines",
    type: "airline",
  },
  {
    id: "KQ",
    lcc: 0,
    name: "Kenya Airways",
    type: "airline",
  },
  {
    id: "VASTAVTO",
    lcc: null,
    name: "VasilkivTransAvto",
    type: "bus",
  },
  {
    id: "AR",
    lcc: 0,
    name: "Aerolineas Argentinas",
    type: "airline",
  },
  {
    id: "AI",
    lcc: 0,
    name: "Air India Limited",
    type: "airline",
  },
  {
    id: "BR",
    lcc: 1,
    name: "EVA Air",
    type: "airline",
  },
  {
    id: "LEOEXM",
    lcc: 0,
    name: "LEOEXPRESS Minibus",
    type: "bus",
  },
  {
    id: "BLUELINE",
    lcc: null,
    name: "Blue Line",
    type: "bus",
  },
  {
    id: "NM",
    lcc: 0,
    name: "Air Moana",
    type: "airline",
  },
  {
    id: "BN",
    lcc: 0,
    name: "Luxwing",
    type: "airline",
  },
  {
    id: "RO",
    lcc: 0,
    name: "Tarom",
    type: "airline",
  },
  {
    id: "QI",
    lcc: 0,
    name: "Ibom Air",
    type: "airline",
  },
  {
    id: "TR",
    lcc: 1,
    name: "Scoot",
    type: "airline",
  },
  {
    id: "MU",
    lcc: 0,
    name: "China Eastern Airlines",
    type: "airline",
  },
  {
    id: "LIGNITETOU",
    lcc: null,
    name: "Lignite Tour",
    type: "bus",
  },
  {
    id: "ELITEEXPRE",
    lcc: null,
    name: "Elite Express",
    type: "bus",
  },
  {
    id: "CZECHRAILT",
    lcc: null,
    name: "Czech Rail train",
    type: "train",
  },
  {
    id: "4P",
    lcc: 0,
    name: "Regional sky",
    type: "airline",
  },
  {
    id: "CAT",
    lcc: null,
    name: "Copehagen Air Taxi",
    type: "airline",
  },
  {
    id: "8L",
    lcc: 1,
    name: "Lucky air",
    type: "airline",
  },
  {
    id: "PHUCXUYEN",
    lcc: null,
    name: "Phuc Xuyen",
    type: "bus",
  },
  {
    id: "P9",
    lcc: 1,
    name: "Peruvian Airlines",
    type: "airline",
  },
  {
    id: "MERIBOBBRB",
    lcc: null,
    name: "Meridian, BOB, BRB",
    type: "train",
  },
  {
    id: "MEMENTO",
    lcc: null,
    name: "MementoBUS",
    type: "bus",
  },
  {
    id: "SUNLINES",
    lcc: null,
    name: "Sun lines",
    type: "bus",
  },
  {
    id: "SVENSKABUS",
    lcc: null,
    name: "Svenska Buss",
    type: "bus",
  },
  {
    id: "VIEAPLINES",
    lcc: null,
    name: "Vienna Airport Lines",
    type: "bus",
  },
  {
    id: "GOOPTI",
    lcc: null,
    name: "GoOpti",
    type: "bus",
  },
  {
    id: "OXFORDBUS",
    lcc: null,
    name: "Oxford Bus Company",
    type: "bus",
  },
  {
    id: "PKSZCZECIN",
    lcc: null,
    name: "PKS Szczencin",
    type: "bus",
  },
  {
    id: "STANSTDEXP",
    lcc: null,
    name: "Stansted Express",
    type: "train",
  },
  {
    id: "OS",
    lcc: 0,
    name: "Austrian Airlines",
    type: "airline",
  },
  {
    id: "M4",
    lcc: 0,
    name: "Mistral Air",
    type: "airline",
  },
  {
    id: "IS",
    lcc: 0,
    name: "Fly Ais Airlines",
    type: "airline",
  },
  {
    id: "JW",
    lcc: 1,
    name: "Vanilla Air",
    type: "airline",
  },
  {
    id: "VN",
    lcc: 0,
    name: "Vietnam Airlines",
    type: "airline",
  },
  {
    id: "CX",
    lcc: 0,
    name: "Cathay Pacific",
    type: "airline",
  },
  {
    id: "KLIAEKSP",
    lcc: null,
    name: "KLIA Ekspress",
    type: "train",
  },
  {
    id: "QF",
    lcc: 0,
    name: "Qantas",
    type: "airline",
  },
  {
    id: "EJR",
    lcc: null,
    name: "JR East",
    type: "train",
  },
  {
    id: "AC",
    lcc: 0,
    name: "Air Canada",
    type: "airline",
  },
  {
    id: "TRANSJUG",
    lcc: null,
    name: "Trans Jug",
    type: "bus",
  },
  {
    id: "LBR",
    lcc: null,
    name: "Air Borealis",
    type: "airline",
  },
  {
    id: "JL",
    lcc: 0,
    name: "Japan Airlines",
    type: "airline",
  },
  {
    id: "OU",
    lcc: 0,
    name: "Croatia Airlines",
    type: "airline",
  },
  {
    id: "4R",
    lcc: 0,
    name: "Renfe",
    type: "train",
  },
  {
    id: "UX",
    lcc: 0,
    name: "Air Europa",
    type: "airline",
  },
  {
    id: "MS",
    lcc: 0,
    name: "Egyptair",
    type: "airline",
  },
  {
    id: "H8",
    lcc: 0,
    name: "Sky Airline Peru",
    type: "airline",
  },
  {
    id: "UO",
    lcc: 1,
    name: "Hong Kong Express Airways",
    type: "airline",
  },
  {
    id: "LA",
    lcc: 0,
    name: "LATAM Airlines",
    type: "airline",
  },
  {
    id: "XX",
    lcc: 0,
    name: "Africa United Airlines",
    type: "airline",
  },
  {
    id: "EZ",
    lcc: 0,
    name: "Sun-Air of Scandinavia",
    type: "airline",
  },
  {
    id: "ET",
    lcc: 0,
    name: "Ethiopian Airlines",
    type: "airline",
  },
  {
    id: "CM",
    lcc: 0,
    name: "Copa Airlines",
    type: "airline",
  },
  {
    id: "DG",
    lcc: 0,
    name: "CebGo",
    type: "airline",
  },
  {
    id: "BAGANMINTH",
    lcc: null,
    name: "Bagan Min Thar",
    type: "bus",
  },
  {
    id: "TRANSZELA",
    lcc: null,
    name: "Transzela",
    type: "bus",
  },
  {
    id: "SUKHOTHAIW",
    lcc: null,
    name: "Sukhothai Wintour",
    type: "bus",
  },
  {
    id: "PHONGPHU",
    lcc: null,
    name: "Phong Phu",
    type: "bus",
  },
  {
    id: "SN",
    lcc: 0,
    name: "Brussels Airlines",
    type: "airline",
  },
  {
    id: "20",
    lcc: 0,
    name: "Air Salone",
    type: "airline",
  },
  {
    id: "REGIOJET",
    lcc: 0,
    name: "RegioJet",
    type: "bus",
  },
  {
    id: "Z8",
    lcc: 1,
    name: "Amaszonas",
    type: "airline",
  },
  {
    id: "4X",
    lcc: 0,
    name: "Red Jet Mexico",
    type: "airline",
  },
  {
    id: "JP",
    lcc: 0,
    name: "Adria Airways",
    type: "airline",
  },
  {
    id: "ABELLIO",
    lcc: null,
    name: "Abellio",
    type: "train",
  },
  {
    id: "LANDERBAHN",
    lcc: null,
    name: "Landerbahn",
    type: "train",
  },
  {
    id: "OZ",
    lcc: 0,
    name: "Asiana Airlines",
    type: "airline",
  },
  {
    id: "ME",
    lcc: 0,
    name: "Middle East Airlines",
    type: "airline",
  },
  {
    id: "GEORGIBUS",
    lcc: null,
    name: "Georgian Bus",
    type: "bus",
  },
  {
    id: "CFLB",
    lcc: null,
    name: "CFL",
    type: "bus",
  },
  {
    id: "DOMO",
    lcc: null,
    name: "Domo Swiss Express",
    type: "bus",
  },
  {
    id: "AEROBUSLIS",
    lcc: null,
    name: "Aerobus Lisbon",
    type: "bus",
  },
  {
    id: "SITBUS",
    lcc: null,
    name: "Sit Bus Shuttle",
    type: "bus",
  },
  {
    id: "GETBUS",
    lcc: null,
    name: "Get Bus",
    type: "bus",
  },
  {
    id: "MEGABUS",
    lcc: null,
    name: "Megabus",
    type: "bus",
  },
  {
    id: "GALICJAEX",
    lcc: null,
    name: "Galicja Express",
    type: "bus",
  },
  {
    id: "NAVETTE",
    lcc: null,
    name: "Navette de Vatry",
    type: "bus",
  },
  {
    id: "INTERGLOB",
    lcc: null,
    name: "Follow me! Interglobus",
    type: "bus",
  },
  {
    id: "MICCOLIS",
    lcc: null,
    name: "Miccolis",
    type: "bus",
  },
  {
    id: "MINIBUD",
    lcc: null,
    name: "Minibud Ltd.",
    type: "bus",
  },
  {
    id: "CILENTO",
    lcc: null,
    name: "Cilento",
    type: "bus",
  },
  {
    id: "OLLEX",
    lcc: null,
    name: "Ollex (express)",
    type: "bus",
  },
  {
    id: "CITYBUSEXP",
    lcc: null,
    name: "CityBusExpress",
    type: "bus",
  },
  {
    id: "WAGNERTRNS",
    lcc: null,
    name: "Wagner Transport",
    type: "bus",
  },
  {
    id: "GLOBTOUR",
    lcc: null,
    name: "Globtour",
    type: "bus",
  },
  {
    id: "ARRIVA",
    lcc: null,
    name: "Arriva",
    type: "bus",
  },
  {
    id: "NETTBUSS",
    lcc: null,
    name: "Vy Buss",
    type: "bus",
  },
  {
    id: "BG",
    lcc: 1,
    name: "Biman Bangladesh Airlines",
    type: "airline",
  },
  {
    id: "AFRIUA",
    lcc: null,
    name: "Africa United Airlines",
    type: "airline",
  },
  {
    id: "QR",
    lcc: 0,
    name: "Qatar Airways",
    type: "airline",
  },
  {
    id: "A3",
    lcc: 1,
    name: "Aegean",
    type: "airline",
  },
  {
    id: "QU",
    lcc: null,
    name: "Azur Air Ukraine",
    type: "airline",
  },
  {
    id: "THAVISOUK",
    lcc: null,
    name: "Thavisouk",
    type: "bus",
  },
  {
    id: "SC",
    lcc: 0,
    name: "Shandong Airlines",
    type: "airline",
  },
  {
    id: "NTH",
    lcc: null,
    name: "Hokkaido Air System",
    type: "airline",
  },
  {
    id: "CI",
    lcc: 0,
    name: "China Airlines",
    type: "airline",
  },
  {
    id: "UQ",
    lcc: 0,
    name: "Urumqi Airlines",
    type: "airline",
  },
  {
    id: "LX",
    lcc: 0,
    name: "Swiss International Air Lines",
    type: "airline",
  },
  {
    id: "BUS4YOU",
    lcc: null,
    name: "Bus4You",
    type: "bus",
  },
  {
    id: "AM",
    lcc: 0,
    name: "AeroMéxico",
    type: "airline",
  },
  {
    id: "GOBUS",
    lcc: null,
    name: "Gobus",
    type: "bus",
  },
  {
    id: "BUSPLANA",
    lcc: null,
    name: "Busplana",
    type: "bus",
  },
  {
    id: "LTKRONOBUS",
    lcc: null,
    name: "LT Kronoberg",
    type: "bus",
  },
  {
    id: "BILOVUSVB",
    lcc: null,
    name: "Bilovus V.B.",
    type: "bus",
  },
  {
    id: "PHUCANEXPR",
    lcc: null,
    name: "Phuc An Express",
    type: "bus",
  },
  {
    id: "OK",
    lcc: 0,
    name: "Czech Airlines",
    type: "airline",
  },
  {
    id: "SKANETRAF",
    lcc: null,
    name: "Skanetrafiken",
    type: "train",
  },
  {
    id: "NSB",
    lcc: null,
    name: "NSB",
    type: "train",
  },
  {
    id: "VASTTRAF",
    lcc: null,
    name: "Vasttrafik",
    type: "train",
  },
  {
    id: "TGVLYRIA",
    lcc: null,
    name: "TGV Lyria",
    type: "train",
  },
  {
    id: "THELLO",
    lcc: null,
    name: "Thello",
    type: "train",
  },
  {
    id: "THALYS",
    lcc: null,
    name: "Thalys",
    type: "train",
  },
  {
    id: "ALSA",
    lcc: 0,
    name: "Alsa",
    type: "bus",
  },
  {
    id: "AMTRAKT",
    lcc: null,
    name: "Amtrak train",
    type: "train",
  },
  {
    id: "MAV",
    lcc: null,
    name: "MAV",
    type: "train",
  },
  {
    id: "SBB",
    lcc: null,
    name: "SBB",
    type: "train",
  },
  {
    id: "LTKRONOTRN",
    lcc: null,
    name: "LT Kronoberg",
    type: "train",
  },
  {
    id: "EUROSTAR",
    lcc: null,
    name: "Eurostar",
    type: "train",
  },
  {
    id: "RHONEXP",
    lcc: null,
    name: "Rhônexpress",
    type: "train",
  },
  {
    id: "BELARURAIL",
    lcc: null,
    name: "Belarusian Railway",
    type: "train",
  },
  {
    id: "MAROZZI",
    lcc: null,
    name: "Marozzi",
    type: "bus",
  },
  {
    id: "AEH",
    lcc: null,
    name: "Aero4M",
    type: "airline",
  },
  {
    id: "AMTRAKB",
    lcc: null,
    name: "Amtrak bus",
    type: "bus",
  },
  {
    id: "MENORCA",
    lcc: null,
    name: "Berlinas Menorca",
    type: "bus",
  },
  {
    id: "DUBLINBUS",
    lcc: null,
    name: "Dublin Bus",
    type: "bus",
  },
  {
    id: "LAMEZIA",
    lcc: null,
    name: "Lamezia Multiservizi",
    type: "bus",
  },
  {
    id: "EIREAGLE",
    lcc: null,
    name: "Eireagle",
    type: "bus",
  },
  {
    id: "YCAT",
    lcc: null,
    name: "Yuma County Area Transit",
    type: "bus",
  },
  {
    id: "OUIBUS",
    lcc: 0,
    name: "Ouibus",
    type: "bus",
  },
  {
    id: "JEFFERSON",
    lcc: null,
    name: "Jefferson Lines",
    type: "bus",
  },
  {
    id: "PRESTIA",
    lcc: null,
    name: "Prestia e Comande",
    type: "bus",
  },
  {
    id: "AIRBEXP",
    lcc: null,
    name: "Airport Bus Express",
    type: "bus",
  },
  {
    id: "ADO",
    lcc: null,
    name: "ADO",
    type: "bus",
  },
  {
    id: "DD",
    lcc: 1,
    name: "Nok Air",
    type: "airline",
  },
  {
    id: "SK",
    lcc: 0,
    name: "SAS",
    type: "airline",
  },
  {
    id: "BLAB",
    lcc: null,
    name: "BlaBlabus",
    type: "bus",
  },
  {
    id: "BMCAEROBUS",
    lcc: null,
    name: "BMC Aerobus",
    type: "bus",
  },
  {
    id: "MALTATRANS",
    lcc: null,
    name: "Maltatransfer",
    type: "bus",
  },
  {
    id: "PARKSOFHAM",
    lcc: null,
    name: "Park's of Hamilton",
    type: "bus",
  },
  {
    id: "NYCAIRPORT",
    lcc: null,
    name: "NYC Airporter",
    type: "bus",
  },
  {
    id: "VIRGINIABR",
    lcc: null,
    name: "Virginia Breeze",
    type: "bus",
  },
  {
    id: "FLYBUS",
    lcc: null,
    name: "Flybus Iceland",
    type: "bus",
  },
  {
    id: "BEAUVAIS",
    lcc: null,
    name: "Beauvaisbus",
    type: "bus",
  },
  {
    id: "ADIRONDACK",
    lcc: null,
    name: "Adirondack Trailways",
    type: "bus",
  },
  {
    id: "SALTLAKEEX",
    lcc: null,
    name: "Salt Lake Express",
    type: "bus",
  },
  {
    id: "QUICKLLAMA",
    lcc: null,
    name: "QuickLlama",
    type: "bus",
  },
  {
    id: "HOOSIER",
    lcc: null,
    name: "Hoosier ride",
    type: "bus",
  },
  {
    id: "100RUMOS",
    lcc: null,
    name: "100Rumos",
    type: "bus",
  },
  {
    id: "DSB",
    lcc: null,
    name: "DSB",
    type: "train",
  },
  {
    id: "CFLT",
    lcc: null,
    name: "CFL",
    type: "train",
  },
  {
    id: "KLJ",
    lcc: null,
    name: "KlasJet",
    type: "airline",
  },
  {
    id: "LF",
    lcc: 0,
    name: "Contour Airlines",
    type: "airline",
  },
  {
    id: "DN",
    lcc: 0,
    name: "Dan Air",
    type: "airline",
  },
  {
    id: "PN",
    lcc: 1,
    name: "West Air China",
    type: "airline",
  },
  {
    id: "2C",
    lcc: 0,
    name: "SNCF",
    type: "train",
  },
  {
    id: "ZSSK",
    lcc: null,
    name: "Slovak rail",
    type: "train",
  },
  {
    id: "ANPHUTRAVE",
    lcc: null,
    name: "An Phu Travel",
    type: "bus",
  },
  {
    id: "FOPPAVLOV",
    lcc: null,
    name: "FOP Pavlovskyy",
    type: "bus",
  },
  {
    id: "CZECHRAIL",
    lcc: null,
    name: "Czech Rail",
    type: "train",
  },
  {
    id: "NORDWEST",
    lcc: null,
    name: "NordWestBahn",
    type: "train",
  },
  {
    id: "WESTFALEN",
    lcc: null,
    name: "Westfalen Bahn",
    type: "train",
  },
  {
    id: "3Q",
    lcc: 0,
    name: "Yunnan Airlines",
    type: "airline",
  },
  {
    id: "FD",
    lcc: 0,
    name: "Thai AirAsia",
    type: "airline",
  },
  {
    id: "KP",
    lcc: 0,
    name: "ASKY Airlines",
    type: "airline",
  },
  {
    id: "6W",
    lcc: 1,
    name: "FlyBosnia",
    type: "airline",
  },
  {
    id: "DI",
    lcc: 0,
    name: "Marabu",
    type: "airline",
  },
  {
    id: "ITALONTV",
    lcc: null,
    name: "Italo NTV",
    type: "train",
  },
  {
    id: "RT",
    lcc: 0,
    name: "JSC UVT Aero",
    type: "airline",
  },
  {
    id: "CORTINAEXP",
    lcc: null,
    name: "Cortina Express",
    type: "bus",
  },
  {
    id: "FLYGBUSAC",
    lcc: null,
    name: "Flygbussarna Airport Coaches",
    type: "bus",
  },
  {
    id: "S9",
    lcc: 0,
    name: "Starbow Airlines",
    type: "airline",
  },
  {
    id: "SX",
    lcc: 0,
    name: "SkyWork Airlines",
    type: "airline",
  },
  {
    id: "QA",
    lcc: 0,
    name: "Click (Mexicana)",
    type: "airline",
  },
  {
    id: "D4",
    lcc: 0,
    name: "Georgian Wings",
    type: "airline",
  },
  {
    id: "V0",
    lcc: 1,
    name: "Conviasa",
    type: "airline",
  },
  {
    id: "KARATS",
    lcc: null,
    name: "Karat - S",
    type: "bus",
  },
  {
    id: "ITALOBUS",
    lcc: null,
    name: "Italobus",
    type: "bus",
  },
  {
    id: "ZB",
    lcc: 1,
    name: "Air Albania",
    type: "airline",
  },
  {
    id: "WD",
    lcc: 0,
    name: "Amsterdam Airlines",
    type: "airline",
  },
  {
    id: "SLT",
    lcc: null,
    name: "Stockholm Public Transport train",
    type: "train",
  },
  {
    id: "Y8",
    lcc: 0,
    name: "Suparna Airlines",
    type: "airline",
  },
  {
    id: "ANAKANTOR",
    lcc: null,
    name: "Anatolii Kantor",
    type: "bus",
  },
  {
    id: "KHANHSINHT",
    lcc: null,
    name: "Khanh Sinh Tour",
    type: "bus",
  },
  {
    id: "CHINARAIL",
    lcc: null,
    name: "China Railway",
    type: "train",
  },
  {
    id: "5W",
    lcc: 0,
    name: "Wizz Air Abu Dhabi",
    type: "airline",
  },
  {
    id: "RAPIDOOCHO",
    lcc: null,
    name: "Rapido Ochoa",
    type: "bus",
  },
  {
    id: "CLICKBUSME",
    lcc: null,
    name: "Clickbus Mexico",
    type: "bus",
  },
  {
    id: "PARTASTRAN",
    lcc: null,
    name: "Partas Transportation Company",
    type: "bus",
  },
  {
    id: "ZAYARMYAIN",
    lcc: null,
    name: "Zayar Myaing Gyi Express",
    type: "bus",
  },
  {
    id: "CIVA",
    lcc: null,
    name: "Civa",
    type: "bus",
  },
  {
    id: "6Z",
    lcc: null,
    name: "SmartLynx Airlines Estonia",
    type: "airline",
  },
  {
    id: "CU",
    lcc: 0,
    name: "Cubana de Aviación",
    type: "airline",
  },
  {
    id: "PW",
    lcc: 0,
    name: "Precision Air",
    type: "airline",
  },
  {
    id: "RS",
    lcc: 0,
    name: "Air Seoul",
    type: "airline",
  },
  {
    id: "QV",
    lcc: 0,
    name: "Lao Airlines",
    type: "airline",
  },
  {
    id: "7H",
    lcc: 1,
    name: "Ravn Alaska",
    type: "airline",
  },
  {
    id: "OUIGO",
    lcc: 0,
    name: "Ouigo",
    type: "train",
  },
  {
    id: "X4",
    lcc: 0,
    name: "Air Excursions",
    type: "airline",
  },
  {
    id: "HZ",
    lcc: 0,
    name: "Aurora Airlines",
    type: "airline",
  },
  {
    id: "IP",
    lcc: 0,
    name: "Pelita Air",
    type: "airline",
  },
  {
    id: "I0",
    lcc: null,
    name: "LEVEL operated by Iberia DO NOT USE",
    type: "airline",
  },
  {
    id: "VYRAIL",
    lcc: null,
    name: "Vyrail",
    type: "train",
  },
  {
    id: "KTTRAVEL",
    lcc: null,
    name: "KT Travel",
    type: "bus",
  },
  {
    id: "YOEYOELAYE",
    lcc: null,
    name: "Yoe Yoe Lay Express",
    type: "bus",
  },
  {
    id: "2A",
    lcc: 0,
    name: "Deutsche Bahn",
    type: "train",
  },
  {
    id: "OV",
    lcc: 1,
    name: "SalamAir",
    type: "airline",
  },
  {
    id: "W3",
    lcc: 0,
    name: "Arik Air",
    type: "airline",
  },
  {
    id: "J5",
    lcc: 0,
    name: "Alaska Seaplane Service",
    type: "airline",
  },
  {
    id: "PE",
    lcc: 0,
    name: "People's Viennaline PE",
    type: "airline",
  },
  {
    id: "8B",
    lcc: 0,
    name: "TransNusa",
    type: "airline",
  },
  {
    id: "KB",
    lcc: 0,
    name: "Druk Air",
    type: "airline",
  },
  {
    id: "JI",
    lcc: 0,
    name: "Meraj Air",
    type: "airline",
  },
  {
    id: "THUYNGAN",
    lcc: null,
    name: "Thuy Ngan",
    type: "bus",
  },
  {
    id: "BJ",
    lcc: 0,
    name: "NouvelAir",
    type: "airline",
  },
  {
    id: "9J",
    lcc: 0,
    name: "Dana Airlines Limited",
    type: "airline",
  },
  {
    id: "XU",
    lcc: 1,
    name: "African Express",
    type: "airline",
  },
  {
    id: "A2",
    lcc: 0,
    name: "Animawings",
    type: "airline",
  },
  {
    id: "GU",
    lcc: 1,
    name: "Avianca Guatemala",
    type: "airline",
  },
  {
    id: "2P",
    lcc: 1,
    name: "PAL Express",
    type: "airline",
  },
  {
    id: "8G",
    lcc: 0,
    name: "Mid Africa Aviation",
    type: "airline",
  },
  {
    id: "3I",
    lcc: 0,
    name: "Air Comet Chile",
    type: "airline",
  },
  {
    id: "MV",
    lcc: 0,
    name: "Air Mediterranean",
    type: "airline",
  },
  {
    id: "Y2",
    lcc: 0,
    name: "AirCentury",
    type: "airline",
  },
  {
    id: "MILLERTR",
    lcc: null,
    name: "Miller Transportation",
    type: "bus",
  },
  {
    id: "A6",
    lcc: 0,
    name: "Air Travel",
    type: "airline",
  },
  {
    id: "XQ",
    lcc: 1,
    name: "SunExpress",
    type: "airline",
  },
  {
    id: "S1",
    lcc: 0,
    name: "Serbian Airlines",
    type: "airline",
  },
  {
    id: "VV",
    lcc: 0,
    name: "Viva Air",
    type: "airline",
  },
  {
    id: "PT",
    lcc: 0,
    name: "Piedmont Airlines",
    type: "airline",
  },
  {
    id: "NN",
    lcc: 1,
    name: "VIM Airlines",
    type: "airline",
  },
  {
    id: "P3",
    lcc: null,
    name: "Porter Airlines Inc.",
    type: "airline",
  },
  {
    id: "JON",
    lcc: null,
    name: "Jonair",
    type: "airline",
  },
  {
    id: "NY",
    lcc: 1,
    name: "Air Iceland Connect",
    type: "airline",
  },
  {
    id: "Z4",
    lcc: 0,
    name: "Ibom Air",
    type: "airline",
  },
  {
    id: "II",
    lcc: 0,
    name: "IBC Airways",
    type: "airline",
  },
  {
    id: "OO",
    lcc: 0,
    name: "SkyWest",
    type: "airline",
  },
  {
    id: "SE",
    lcc: 1,
    name: "XL Airways France",
    type: "airline",
  },
  {
    id: "FL",
    lcc: 0,
    name: "Air Leap",
    type: "airline",
  },
  {
    id: "4Z",
    lcc: 0,
    name: "Airlink",
    type: "airline",
  },
  {
    id: "3U",
    lcc: 0,
    name: "Sichuan Airlines",
    type: "airline",
  },
  {
    id: "Y7",
    lcc: 1,
    name: "NordStar Airlines",
    type: "airline",
  },
  {
    id: "LT",
    lcc: 0,
    name: "LongJiang Airlines",
    type: "airline",
  },
  {
    id: "HN",
    lcc: 0,
    name: "Equair",
    type: "airline",
  },
  {
    id: "00",
    lcc: 1,
    name: "Anadolujet",
    type: "airline",
  },
  {
    id: "9U",
    lcc: 0,
    name: "Air Moldova",
    type: "airline",
  },
  {
    id: "HG",
    lcc: 0,
    name: "Hibernian Airlines",
    type: "airline",
  },
  {
    id: "AP",
    lcc: 0,
    name: "AlbaStar",
    type: "airline",
  },
  {
    id: "2U",
    lcc: 0,
    name: "Sun d'Or International Airlines",
    type: "airline",
  },
  {
    id: "4D",
    lcc: 0,
    name: "Aero Dirrekt",
    type: "airline",
  },
  {
    id: "M2",
    lcc: 0,
    name: "Rhein-Neckar Air",
    type: "airline",
  },
  {
    id: "KS",
    lcc: 0,
    name: "Air Connect",
    type: "airline",
  },
  {
    id: "VRG",
    lcc: 1,
    name: "Voyage Air",
    type: "airline",
  },
  {
    id: "MK",
    lcc: 0,
    name: "Air Mauritius",
    type: "airline",
  },
  {
    id: "M1",
    lcc: 0,
    name: "Maryland Air",
    type: "airline",
  },
  {
    id: "OF",
    lcc: 0,
    name: "Overland Airways",
    type: "airline",
  },
  {
    id: "PINEHILLK",
    lcc: null,
    name: "Pine Hill Kingston",
    type: "bus",
  },
  {
    id: "ZM",
    lcc: 0,
    name: "Air Manas",
    type: "airline",
  },
  {
    id: "T0",
    lcc: 0,
    name: "Avianca Peru",
    type: "airline",
  },
  {
    id: "YW",
    lcc: 0,
    name: "Air Nostrum",
    type: "airline",
  },
  {
    id: "XM",
    lcc: 0,
    name: "Alitalia Express",
    type: "airline",
  },
  {
    id: "FK",
    lcc: 0,
    name: "Africa West",
    type: "airline",
  },
  {
    id: "GG",
    lcc: 0,
    name: "Air Guyane",
    type: "airline",
  },
  {
    id: "TE",
    lcc: 0,
    name: "FlyLal",
    type: "airline",
  },
  {
    id: "WI",
    lcc: 0,
    name: "White Airways",
    type: "airline",
  },
  {
    id: "H1",
    lcc: 0,
    name: "Hahn Air",
    type: "airline",
  },
  {
    id: "PP",
    lcc: 0,
    name: "Air Indus",
    type: "airline",
  },
  {
    id: "U8",
    lcc: 0,
    name: "TUS Airways",
    type: "airline",
  },
  {
    id: "KJ",
    lcc: 0,
    name: "British Mediterranean Airways",
    type: "airline",
  },
  {
    id: "U1",
    lcc: 0,
    name: "Aviabus",
    type: "airline",
  },
  {
    id: "VL",
    lcc: 0,
    name: "Med-View Airline",
    type: "airline",
  },
  {
    id: "R7",
    lcc: 0,
    name: "Aserca Airlines",
    type: "airline",
  },
  {
    id: "EI",
    lcc: 1,
    name: "Aer Lingus",
    type: "airline",
  },
  {
    id: "O7",
    lcc: 0,
    name: "Orenburzhye Airline",
    type: "airline",
  },
  {
    id: "KO",
    lcc: 0,
    name: "Komiaviatrans",
    type: "airline",
  },
  {
    id: "W8",
    lcc: 0,
    name: "Cargojet Airways",
    type: "airline",
  },
  {
    id: "3J",
    lcc: 0,
    name: "Jubba Airways",
    type: "airline",
  },
  {
    id: "3E",
    lcc: 0,
    name: "Air Choice One",
    type: "airline",
  },
  {
    id: "7E",
    lcc: 0,
    name: "Sylt Air",
    type: "airline",
  },
  {
    id: "UI",
    lcc: 0,
    name: "Auric Air",
    type: "airline",
  },
  {
    id: "9P",
    lcc: 1,
    name: "Fly Jinnah",
    type: "airline",
  },
  {
    id: "LP",
    lcc: 0,
    name: "LATAM Peru",
    type: "airline",
  },
  {
    id: "HZRAIL",
    lcc: null,
    name: "Hrvatske Zeljeznice",
    type: "train",
  },
  {
    id: "I8",
    lcc: 0,
    name: "Izhavia",
    type: "airline",
  },
  {
    id: "IQ",
    lcc: 0,
    name: "Qazaq Air",
    type: "airline",
  },
  {
    id: "LI",
    lcc: 0,
    name: "Leeward Islands Air Transport",
    type: "airline",
  },
  {
    id: "BU",
    lcc: 0,
    name: "Compagnie Africaine d'Aviation CAA",
    type: "airline",
  },
  {
    id: "PM",
    lcc: 0,
    name: "Canary Fly",
    type: "airline",
  },
  {
    id: "W7",
    lcc: 0,
    name: "Wings of Lebanon",
    type: "airline",
  },
  {
    id: "B4",
    lcc: 0,
    name: "BeOnd",
    type: "airline",
  },
  {
    id: "6P",
    lcc: 0,
    name: "Gryphon Airlines",
    type: "airline",
  },
  {
    id: "ECOLINES",
    lcc: null,
    name: "Ecolines",
    type: "bus",
  },
  {
    id: "NP",
    lcc: 0,
    name: "Nile Air",
    type: "airline",
  },
  {
    id: "LG",
    lcc: 0,
    name: "Luxair",
    type: "airline",
  },
  {
    id: "TC",
    lcc: 0,
    name: "Air Tanzania",
    type: "airline",
  },
  {
    id: "NE",
    lcc: 0,
    name: "Nesma Airlines",
    type: "airline",
  },
  {
    id: "DJ",
    lcc: 1,
    name: "Air Djibouti",
    type: "airline",
  },
  {
    id: "NEWPACIFIC",
    lcc: null,
    name: "New Pacific Airlines",
    type: "airline",
  },
  {
    id: "KR",
    lcc: 0,
    name: "Cambodia Airways",
    type: "airline",
  },
  {
    id: "AS",
    lcc: 0,
    name: "Alaska Airlines",
    type: "airline",
  },
  {
    id: "N7",
    lcc: 0,
    name: "Nordic Regional Airlines",
    type: "airline",
  },
  {
    id: "MD",
    lcc: 0,
    name: "Air Madagascar",
    type: "airline",
  },
  {
    id: "0X",
    lcc: 0,
    name: "Copenhagen Express",
    type: "airline",
  },
  {
    id: "GH",
    lcc: 0,
    name: "Globus",
    type: "airline",
  },
  {
    id: "WO",
    lcc: 0,
    name: "Swoop",
    type: "airline",
  },
  {
    id: "RC",
    lcc: 0,
    name: "Atlantic Airways",
    type: "airline",
  },
  {
    id: "FJ",
    lcc: 0,
    name: "Fiji Airways",
    type: "airline",
  },
  {
    id: "ZT",
    lcc: 0,
    name: "Titan Airways",
    type: "airline",
  },
  {
    id: "CT",
    lcc: 0,
    name: "Alitalia Cityliner",
    type: "airline",
  },
  {
    id: "B9",
    lcc: 0,
    name: "Air Bangladesh",
    type: "airline",
  },
  {
    id: "5Y",
    lcc: 0,
    name: "Atlas Air",
    type: "airline",
  },
  {
    id: "QH",
    lcc: 0,
    name: "Bamboo Airways",
    type: "airline",
  },
  {
    id: "OT",
    lcc: 0,
    name: "Aeropelican Air Services",
    type: "airline",
  },
  {
    id: "5L",
    lcc: 0,
    name: "Aerosur",
    type: "airline",
  },
  {
    id: "Z3",
    lcc: 0,
    name: "Avient Aviation",
    type: "airline",
  },
  {
    id: "JS",
    lcc: 0,
    name: "Air Koryo",
    type: "airline",
  },
  {
    id: "RE",
    lcc: 0,
    name: "Stobart Air",
    type: "airline",
  },
  {
    id: "EO",
    lcc: 0,
    name: "Pegas Fly",
    type: "airline",
  },
  {
    id: "8N",
    lcc: 0,
    name: "Regional Air Services",
    type: "airline",
  },
  {
    id: "FV",
    lcc: 0,
    name: "Rossiya-Russian Airlines",
    type: "airline",
  },
  {
    id: "NF",
    lcc: 0,
    name: "Air Vanuatu",
    type: "airline",
  },
  {
    id: "GS",
    lcc: 0,
    name: "Tianjin Airlines",
    type: "airline",
  },
  {
    id: "EN",
    lcc: 0,
    name: "Air Dolomiti",
    type: "airline",
  },
  {
    id: "IO",
    lcc: 1,
    name: "IrAero",
    type: "airline",
  },
  {
    id: "E1",
    lcc: 0,
    name: "Usa Sky Cargo",
    type: "airline",
  },
  {
    id: "3R",
    lcc: 0,
    name: "Divi Divi Air",
    type: "airline",
  },
  {
    id: "BS",
    lcc: 0,
    name: "US-Bangla Airlines",
    type: "airline",
  },
  {
    id: "DQ",
    lcc: 0,
    name: "Coastal Air",
    type: "airline",
  },
  {
    id: "B8",
    lcc: 0,
    name: "Eritrean Airlines",
    type: "airline",
  },
  {
    id: "BD",
    lcc: 0,
    name: "Cambodia Bayon Airlines",
    type: "airline",
  },
  {
    id: "LU",
    lcc: 0,
    name: "LATAM Express",
    type: "airline",
  },
  {
    id: "7G",
    lcc: 1,
    name: "Star Flyer",
    type: "airline",
  },
  {
    id: "SASANUNTRA",
    lcc: null,
    name: "Sasanun Transport",
    type: "bus",
  },
  {
    id: "EXPRESSHAG",
    lcc: null,
    name: "Express Ha Giang",
    type: "bus",
  },
  {
    id: "CAUME",
    lcc: null,
    name: "Cau Me",
    type: "bus",
  },
  {
    id: "E3",
    lcc: 0,
    name: "EGO Airways",
    type: "airline",
  },
  {
    id: "UNESULDT",
    lcc: null,
    name: "Unesul DT",
    type: "bus",
  },
  {
    id: "DJB",
    lcc: null,
    name: "Air Djibouti",
    type: "airline",
  },
  {
    id: "2T",
    lcc: 0,
    name: "BermudAir",
    type: "airline",
  },
  {
    id: "SS",
    lcc: 0,
    name: "Corsair International",
    type: "airline",
  },
  {
    id: "OI",
    lcc: 0,
    name: "Hinterland Aviation",
    type: "airline",
  },
  {
    id: "PD",
    lcc: 1,
    name: "Porter Airlines",
    type: "airline",
  },
  {
    id: "SAISAUTO",
    lcc: null,
    name: "Sais Autolinee",
    type: "bus",
  },
  {
    id: "DO",
    lcc: 0,
    name: "Sky High Aviation Services",
    type: "airline",
  },
  {
    id: "ZL",
    lcc: 1,
    name: "Regional Express",
    type: "airline",
  },
  {
    id: "8D",
    lcc: 0,
    name: "FitsAir",
    type: "airline",
  },
  {
    id: "Y9",
    lcc: 0,
    name: "Lynx Air",
    type: "airline",
  },
  {
    id: "J8",
    lcc: 0,
    name: "Berjaya Air",
    type: "airline",
  },
  {
    id: "2L",
    lcc: 0,
    name: "Helvetic Airways",
    type: "airline",
  },
  {
    id: "WX",
    lcc: 0,
    name: "CityJet",
    type: "airline",
  },
  {
    id: "LR",
    lcc: 0,
    name: "Avianca Costa Rica",
    type: "airline",
  },
  {
    id: "2K",
    lcc: 0,
    name: "Avianca Ecuador",
    type: "airline",
  },
  {
    id: "AJ",
    lcc: 0,
    name: "Aero Contractors",
    type: "airline",
  },
  {
    id: "CH",
    lcc: 0,
    name: "Bemidji Airlines",
    type: "airline",
  },
  {
    id: "BRIONI",
    lcc: null,
    name: "Brioni Pula",
    type: "bus",
  },
  {
    id: "L8",
    lcc: 0,
    name: "Line Blue",
    type: "airline",
  },
  {
    id: "UD",
    lcc: 0,
    name: "UR Airlines",
    type: "airline",
  },
  {
    id: "GF",
    lcc: 0,
    name: "Gulf Air Bahrain",
    type: "airline",
  },
  {
    id: "TY",
    lcc: 0,
    name: "Air Caledonie",
    type: "airline",
  },
  {
    id: "G0",
    lcc: 0,
    name: "Ghana International Airlines",
    type: "airline",
  },
  {
    id: "IC",
    lcc: 0,
    name: "Indian Airlines",
    type: "airline",
  },
  {
    id: "D6",
    lcc: 0,
    name: "Interair South Africa",
    type: "airline",
  },
  {
    id: "KV",
    lcc: 0,
    name: "Kavminvodyavia",
    type: "airline",
  },
  {
    id: "7K",
    lcc: 0,
    name: "Kogalymavia Air Company",
    type: "airline",
  },
  {
    id: "NG",
    lcc: 0,
    name: "Lauda Air",
    type: "airline",
  },
  {
    id: "TIENDALEO",
    lcc: null,
    name: "Tienda León",
    type: "bus",
  },
  {
    id: "6A",
    lcc: 0,
    name: "Armenia Airways",
    type: "airline",
  },
  {
    id: "MZ",
    lcc: 0,
    name: "Merpati Nusantara Airlines",
    type: "airline",
  },
  {
    id: "MX",
    lcc: 0,
    name: "BREEZE",
    type: "airline",
  },
  {
    id: "H7",
    lcc: 0,
    name: "HiSky",
    type: "airline",
  },
  {
    id: "PV",
    lcc: 0,
    name: "St Barth Commuter",
    type: "airline",
  },
  {
    id: "FW",
    lcc: 0,
    name: "Ibex Airlines",
    type: "airline",
  },
  {
    id: "4G",
    lcc: 0,
    name: "Gazpromavia",
    type: "airline",
  },
  {
    id: "M5",
    lcc: 0,
    name: "Kenmore Air",
    type: "airline",
  },
  {
    id: "LN",
    lcc: 0,
    name: "Libyan Arab Airlines",
    type: "airline",
  },
  {
    id: "T3",
    lcc: 0,
    name: "Eastern Airways",
    type: "airline",
  },
  {
    id: "UZ",
    lcc: 0,
    name: "El-Buraq Air Transport",
    type: "airline",
  },
  {
    id: "KF",
    lcc: 0,
    name: "Air Belgium",
    type: "airline",
  },
  {
    id: "EH",
    lcc: 0,
    name: "ANA Wings",
    type: "airline",
  },
  {
    id: "MP",
    lcc: 0,
    name: "Martinair",
    type: "airline",
  },
  {
    id: "YV",
    lcc: 0,
    name: "Mesa Airlines",
    type: "airline",
  },
  {
    id: "NC",
    lcc: 0,
    name: "National Jet Systems",
    type: "airline",
  },
  {
    id: "8J",
    lcc: 0,
    name: "Eco Jet",
    type: "airline",
  },
  {
    id: "PI",
    lcc: 0,
    name: "Polar Airlines",
    type: "airline",
  },
  {
    id: "3P",
    lcc: 0,
    name: "Tiara Air",
    type: "airline",
  },
  {
    id: "U7",
    lcc: 0,
    name: "Northern Dene Airways",
    type: "airline",
  },
  {
    id: "NSRAIL",
    lcc: null,
    name: "Dutch Railways",
    type: "train",
  },
  {
    id: "O2",
    lcc: 0,
    name: "Linear Air",
    type: "airline",
  },
  {
    id: "LITOR",
    lcc: null,
    name: "LitorAli Airlines",
    type: "airline",
  },
  {
    id: "RL",
    lcc: 0,
    name: "Royal Flight Airlines",
    type: "airline",
  },
  {
    id: "8I",
    lcc: 0,
    name: "MyAir",
    type: "airline",
  },
  {
    id: "EA",
    lcc: 0,
    name: "Emerald Airlines",
    type: "airline",
  },
  {
    id: "5U",
    lcc: 0,
    name: "Transportes Aéreos Guatemaltecos",
    type: "airline",
  },
  {
    id: "RZ",
    lcc: 0,
    name: "Sansa Air",
    type: "airline",
  },
  {
    id: "WY",
    lcc: 0,
    name: "Oman Air",
    type: "airline",
  },
  {
    id: "SZ",
    lcc: 0,
    name: "Somon Air",
    type: "airline",
  },
  {
    id: "A9",
    lcc: 0,
    name: "Georgian Airways",
    type: "airline",
  },
  {
    id: "J4",
    lcc: 0,
    name: "Badr Airlines",
    type: "airline",
  },
  {
    id: "5M",
    lcc: 0,
    name: "Sibaviatrans",
    type: "airline",
  },
  {
    id: "ZS",
    lcc: 0,
    name: "Sama Airlines",
    type: "airline",
  },
  {
    id: "8P",
    lcc: 0,
    name: "Pacific Coastal Airline",
    type: "airline",
  },
  {
    id: "5Q",
    lcc: 0,
    name: "Holiday Europe",
    type: "airline",
  },
  {
    id: "JH",
    lcc: 0,
    name: "Fuji Dream Airlines",
    type: "airline",
  },
  {
    id: "J3",
    lcc: 0,
    name: "Northwestern Air",
    type: "airline",
  },
  {
    id: "U4",
    lcc: 0,
    name: "Buddha Air",
    type: "airline",
  },
  {
    id: "GZ",
    lcc: 0,
    name: "Air Rarotonga",
    type: "airline",
  },
  {
    id: "QB",
    lcc: 0,
    name: "Qeshm Air",
    type: "airline",
  },
  {
    id: "RA",
    lcc: 0,
    name: "Nepal Airlines",
    type: "airline",
  },
  {
    id: "KE",
    lcc: 0,
    name: "Korean Air",
    type: "airline",
  },
  {
    id: "IK",
    lcc: 0,
    name: "Air Kiribati",
    type: "airline",
  },
  {
    id: "BB",
    lcc: 0,
    name: "Seaborne Airlines",
    type: "airline",
  },
  {
    id: "GM",
    lcc: 0,
    name: "Chair Airlines",
    type: "airline",
  },
  {
    id: "2M",
    lcc: 0,
    name: "Maya Island Air",
    type: "airline",
  },
  {
    id: "I9",
    lcc: 0,
    name: "Air Italy",
    type: "airline",
  },
  {
    id: "8F",
    lcc: 0,
    name: "STP Airways",
    type: "airline",
  },
  {
    id: "I7",
    lcc: 0,
    name: "Int'Air Iles",
    type: "airline",
  },
  {
    id: "MY",
    lcc: 0,
    name: "MASwings",
    type: "airline",
  },
  {
    id: "VX",
    lcc: 1,
    name: "Virgin America",
    type: "airline",
  },
  {
    id: "PU",
    lcc: 0,
    name: "Plus Ultra Lineas Aereas",
    type: "airline",
  },
  {
    id: "ON",
    lcc: 0,
    name: "Nauru Air Corporation",
    type: "airline",
  },
  {
    id: "RH",
    lcc: 0,
    name: "Republic Express Airlines",
    type: "airline",
  },
  {
    id: "EJ",
    lcc: 0,
    name: "New England Airlines",
    type: "airline",
  },
  {
    id: "P2",
    lcc: 1,
    name: "Air Kenya",
    type: "airline",
  },
  {
    id: "XZ",
    lcc: 0,
    name: "Aeroitalia",
    type: "airline",
  },
  {
    id: "S5",
    lcc: 0,
    name: "Shuttle America",
    type: "airline",
  },
  {
    id: "G6",
    lcc: 0,
    name: "Fly Arna",
    type: "airline",
  },
  {
    id: "KW",
    lcc: 0,
    name: "Carnival Air Lines",
    type: "airline",
  },
  {
    id: "3T",
    lcc: 0,
    name: "Turan Air",
    type: "airline",
  },
  {
    id: "YX",
    lcc: 0,
    name: "Republic Airways",
    type: "airline",
  },
  {
    id: "UF",
    lcc: 0,
    name: "UM Airlines",
    type: "airline",
  },
  {
    id: "VANLUCTUNG",
    lcc: null,
    name: "Van Luc Tung",
    type: "bus",
  },
  {
    id: "G7",
    lcc: 0,
    name: "GoJet Airlines",
    type: "airline",
  },
  {
    id: "9E",
    lcc: 0,
    name: "Endeavor Air",
    type: "airline",
  },
  {
    id: "VANGAL",
    lcc: null,
    name: "Van Galder",
    type: "bus",
  },
  {
    id: "US",
    lcc: 0,
    name: "US Airways",
    type: "airline",
  },
  {
    id: "CG",
    lcc: 0,
    name: "PNG Air",
    type: "airline",
  },
  {
    id: "GE",
    lcc: 0,
    name: "LIFT airline",
    type: "airline",
  },
  {
    id: "MQ",
    lcc: 0,
    name: "Envoy Air",
    type: "airline",
  },
  {
    id: "FS",
    lcc: 0,
    name: "Flyr",
    type: "airline",
  },
  {
    id: "TRONGMINH",
    lcc: null,
    name: "Trong Minh",
    type: "bus",
  },
  {
    id: "PH",
    lcc: 0,
    name: "Polynesian Airlines",
    type: "airline",
  },
  {
    id: "RN",
    lcc: null,
    name: "Eswatini Air",
    type: "airline",
  },
  {
    id: "V7",
    lcc: 1,
    name: "Volotea",
    type: "airline",
  },
  {
    id: "6J",
    lcc: 0,
    name: "Solaseed Air",
    type: "airline",
  },
  {
    id: "8O",
    lcc: 0,
    name: "West Coast Air",
    type: "airline",
  },
  {
    id: "FT",
    lcc: 0,
    name: "FlyEgypt FT",
    type: "airline",
  },
  {
    id: "RB",
    lcc: 0,
    name: "Syrian Arab Airlines",
    type: "airline",
  },
  {
    id: "47",
    lcc: 0,
    name: "88",
    type: "airline",
  },
  {
    id: "69",
    lcc: 0,
    name: "Royal European Airlines",
    type: "airline",
  },
  {
    id: "4L",
    lcc: 0,
    name: "Euroline",
    type: "airline",
  },
  {
    id: "KT",
    lcc: 0,
    name: "VickJet",
    type: "airline",
  },
  {
    id: "D1",
    lcc: 0,
    name: "Domenican Airlines",
    type: "airline",
  },
  {
    id: "VG",
    lcc: 0,
    name: "VLM Airlines",
    type: "airline",
  },
  {
    id: "V6",
    lcc: 0,
    name: "VIP Ecuador",
    type: "airline",
  },
  {
    id: "V9",
    lcc: 0,
    name: "L'Odyssey",
    type: "airline",
  },
  {
    id: "NB",
    lcc: 0,
    name: "Sterling Airlines",
    type: "airline",
  },
  {
    id: "6Y",
    lcc: 0,
    name: "SmartLynx Airlines",
    type: "airline",
  },
  {
    id: "B7",
    lcc: 0,
    name: "Uni Air",
    type: "airline",
  },
  {
    id: "7Y",
    lcc: 0,
    name: "Mann Yadanarpon Airlines",
    type: "airline",
  },
  {
    id: "JR",
    lcc: 0,
    name: "Joy Air",
    type: "airline",
  },
  {
    id: "G5",
    lcc: 0,
    name: "China Express Airlines",
    type: "airline",
  },
  {
    id: "SD",
    lcc: 0,
    name: "Sudan Airways",
    type: "airline",
  },
  {
    id: "IY",
    lcc: 0,
    name: "Yemenia",
    type: "airline",
  },
  {
    id: "AX",
    lcc: 0,
    name: "Trans States Airlines",
    type: "airline",
  },
  {
    id: "9Y",
    lcc: 0,
    name: "Air Kazakhstan",
    type: "airline",
  },
  {
    id: "PZ",
    lcc: 0,
    name: "LATAM Paraguay",
    type: "airline",
  },
  {
    id: "SZS",
    lcc: null,
    name: "Scandinavian Airlines Connect Ltd.",
    type: "airline",
  },
  {
    id: "S6",
    lcc: 0,
    name: "Sunrise Airways",
    type: "airline",
  },
  {
    id: "O6",
    lcc: 0,
    name: "Avianca Brazil",
    type: "airline",
  },
  {
    id: "OLONGPICHE",
    lcc: null,
    name: "Olongpich Express",
    type: "bus",
  },
  {
    id: "TI",
    lcc: 0,
    name: "Tailwind Airlines",
    type: "airline",
  },
  {
    id: "1T",
    lcc: 0,
    name: "Hitit Bilgisayar Hizmetleri",
    type: "airline",
  },
  {
    id: "TA",
    lcc: 0,
    name: "Avianca El Salvador",
    type: "airline",
  },
  {
    id: "4Q",
    lcc: 0,
    name: "Safi Airlines",
    type: "airline",
  },
  {
    id: "XO",
    lcc: 0,
    name: "LTE International Airways",
    type: "airline",
  },
  {
    id: "JK",
    lcc: 0,
    name: "Spanair",
    type: "airline",
  },
  {
    id: "S3",
    lcc: 0,
    name: "SBA Airlines",
    type: "airline",
  },
  {
    id: "3G",
    lcc: 0,
    name: "Atlant-Soyuz Airlines",
    type: "airline",
  },
  {
    id: "DT",
    lcc: 0,
    name: "TAAG Angola Airlines",
    type: "airline",
  },
  {
    id: "7Q",
    lcc: 0,
    name: "Elite Airways",
    type: "airline",
  },
  {
    id: "MOETHOUTTU",
    lcc: null,
    name: "Moe Thout Tun",
    type: "bus",
  },
  {
    id: "YM",
    lcc: 0,
    name: "Montenegro Airlines",
    type: "airline",
  },
  {
    id: "THAISRIRAM",
    lcc: null,
    name: "Thai Sriram",
    type: "bus",
  },
  {
    id: "IV",
    lcc: 0,
    name: "GP Aviation",
    type: "airline",
  },
  {
    id: "HT",
    lcc: 0,
    name: "Air Horizont",
    type: "airline",
  },
  {
    id: "TD",
    lcc: 0,
    name: "Tbilisi Airways",
    type: "airline",
  },
  {
    id: "2W",
    lcc: 0,
    name: "World2fly",
    type: "airline",
  },
  {
    id: "BY",
    lcc: 1,
    name: "TUI Airways",
    type: "airline",
  },
  {
    id: "OC",
    lcc: 0,
    name: "Oriental Air Bridge",
    type: "airline",
  },
  {
    id: "NO",
    lcc: 1,
    name: "Neos Air",
    type: "airline",
  },
  {
    id: "7Z",
    lcc: 0,
    name: "EZ Air",
    type: "airline",
  },
  {
    id: "HK",
    lcc: 0,
    name: "Yangon Airways",
    type: "airline",
  },
  {
    id: "G4",
    lcc: 1,
    name: "Allegiant Air",
    type: "airline",
  },
  {
    id: "ZF",
    lcc: 0,
    name: "Azur Air",
    type: "airline",
  },
  {
    id: "FM",
    lcc: 0,
    name: "Shanghai Airlines",
    type: "airline",
  },
  {
    id: "GY",
    lcc: 0,
    name: "Colorful Guizhou Airlines",
    type: "airline",
  },
  {
    id: "QD",
    lcc: 0,
    name: "JC International Airlines",
    type: "airline",
  },
  {
    id: "L6",
    lcc: 0,
    name: "Mauritania Airlines International",
    type: "airline",
  },
  {
    id: "R5",
    lcc: 0,
    name: "Jordan Aviation",
    type: "airline",
  },
  {
    id: "HC",
    lcc: 0,
    name: "Air Senegal",
    type: "airline",
  },
  {
    id: "1I",
    lcc: 0,
    name: "NetJets",
    type: "airline",
  },
  {
    id: "SB",
    lcc: 0,
    name: "Aircalin",
    type: "airline",
  },
  {
    id: "NX",
    lcc: 0,
    name: "Air Macau",
    type: "airline",
  },
  {
    id: "2B",
    lcc: 0,
    name: "AlbaWings",
    type: "airline",
  },
  {
    id: "7L",
    lcc: 0,
    name: "Sun D'Or",
    type: "airline",
  },
  {
    id: "VI",
    lcc: 0,
    name: "Volga-Dnepr Airlines",
    type: "airline",
  },
  {
    id: "ZN",
    lcc: 0,
    name: "Zambia Airways",
    type: "airline",
  },
  {
    id: "YO",
    lcc: 0,
    name: "TransHolding System",
    type: "airline",
  },
  {
    id: "QY",
    lcc: 0,
    name: "Red Jet Canada",
    type: "airline",
  },
  {
    id: "12",
    lcc: 0,
    name: "12 North",
    type: "airline",
  },
  {
    id: "1B",
    lcc: 0,
    name: "Abacus International",
    type: "airline",
  },
  {
    id: "CE",
    lcc: 0,
    name: "Chalair",
    type: "airline",
  },
  {
    id: "DZ",
    lcc: 0,
    name: "Donghai Airlines",
    type: "airline",
  },
  {
    id: "MB",
    lcc: 0,
    name: "MNG Airlines",
    type: "airline",
  },
  {
    id: "ENZ",
    lcc: null,
    name: "Jota Aviation",
    type: "airline",
  },
  {
    id: "8H",
    lcc: 0,
    name: "BH Air",
    type: "airline",
  },
  {
    id: "C4",
    lcc: 0,
    name: "LionXpress",
    type: "airline",
  },
  {
    id: "A4",
    lcc: 0,
    name: "Azimuth",
    type: "airline",
  },
  {
    id: "AH",
    lcc: 0,
    name: "Air Algerie",
    type: "airline",
  },
  {
    id: "L3",
    lcc: 0,
    name: "LTU Austria",
    type: "airline",
  },
  {
    id: "SRITRANGTO",
    lcc: null,
    name: "Sri Trang Tour",
    type: "bus",
  },
  {
    id: "NW",
    lcc: 0,
    name: "Northwest Airlines",
    type: "airline",
  },
  {
    id: "QO",
    lcc: 0,
    name: "Origin Pacific Airways",
    type: "airline",
  },
  {
    id: "RD",
    lcc: 0,
    name: "Ryan International Airlines",
    type: "airline",
  },
  {
    id: "WQ",
    lcc: 0,
    name: "Swiftair",
    type: "airline",
  },
  {
    id: "78",
    lcc: 0,
    name: "Southjet cargo",
    type: "airline",
  },
  {
    id: "K1",
    lcc: 0,
    name: "Kostromskie avialinii",
    type: "airline",
  },
  {
    id: "L4",
    lcc: 0,
    name: "LASA Argentina",
    type: "airline",
  },
  {
    id: "ZX",
    lcc: 0,
    name: "Japan Regio",
    type: "airline",
  },
  {
    id: "YY",
    lcc: 0,
    name: "Virginwings",
    type: "airline",
  },
  {
    id: "BH",
    lcc: 0,
    name: "Hawkair",
    type: "airline",
  },
  {
    id: "XBUS",
    lcc: null,
    name: "X-Bus",
    type: "bus",
  },
  {
    id: "T4",
    lcc: 0,
    name: "Hellas Jet",
    type: "airline",
  },
  {
    id: "ZA",
    lcc: 0,
    name: "Sky Angkor Airlines",
    type: "airline",
  },
  {
    id: "JB",
    lcc: 0,
    name: "Helijet",
    type: "airline",
  },
  {
    id: "EV",
    lcc: 0,
    name: "EV",
    type: "airline",
  },
  {
    id: "OG",
    lcc: 0,
    name: "Play",
    type: "airline",
  },
  {
    id: "UH",
    lcc: 0,
    name: "AtlasGlobal Ukraine",
    type: "airline",
  },
  {
    id: "3F",
    lcc: 0,
    name: "FLYONE ARMENIA",
    type: "airline",
  },
  {
    id: "R8",
    lcc: 0,
    name: "AirRussia",
    type: "airline",
  },
  {
    id: "XV",
    lcc: 0,
    name: "BVI Airways",
    type: "airline",
  },
  {
    id: "O8",
    lcc: 0,
    name: "Marathon Airlines",
    type: "airline",
  },
  {
    id: "BQ",
    lcc: 0,
    name: "Sky Alps",
    type: "airline",
  },
  {
    id: "CQ",
    lcc: 0,
    name: "Coastal Aviation",
    type: "airline",
  },
  {
    id: "HI",
    lcc: 0,
    name: "Papillon Grand Canyon Helicopters",
    type: "airline",
  },
  {
    id: "YR",
    lcc: 0,
    name: "SENIC AIRLINES",
    type: "airline",
  },
  {
    id: "QC",
    lcc: 0,
    name: "Camair-co",
    type: "airline",
  },
  {
    id: "8U",
    lcc: 0,
    name: "Afriqiyah Airways",
    type: "airline",
  },
  {
    id: "FG",
    lcc: 0,
    name: "Ariana Afghan Airlines",
    type: "airline",
  },
  {
    id: "GV",
    lcc: 0,
    name: "Grant Aviation",
    type: "airline",
  },
  {
    id: "HF",
    lcc: 0,
    name: "Air Cote d'Ivoire",
    type: "airline",
  },
  {
    id: "9T",
    lcc: 0,
    name: "ACT Airlines",
    type: "airline",
  },
  {
    id: "8Z",
    lcc: 0,
    name: "Wizz Air Hungary",
    type: "airline",
  },
  {
    id: "CV",
    lcc: 0,
    name: "Cargolux",
    type: "airline",
  },
  {
    id: "M7",
    lcc: 0,
    name: "MasAir",
    type: "airline",
  },
  {
    id: "4K",
    lcc: 0,
    name: "Askari Aviation",
    type: "airline",
  },
  {
    id: "JF",
    lcc: 0,
    name: "Jetairfly",
    type: "airline",
  },
  {
    id: "CD",
    lcc: 0,
    name: "Corendon Dutch Airlines B.V.",
    type: "airline",
  },
  {
    id: "PO",
    lcc: 0,
    name: "Polar Airlines",
    type: "airline",
  },
  {
    id: "W1",
    lcc: 0,
    name: "WorldTicket",
    type: "airline",
  },
  {
    id: "HE",
    lcc: 0,
    name: "Luftfahrtgesellschaft Walter",
    type: "airline",
  },
  {
    id: "QJ",
    lcc: 0,
    name: "Jet Airways",
    type: "airline",
  },
  {
    id: "FU",
    lcc: 0,
    name: "Fuzhou Airlines",
    type: "airline",
  },
  {
    id: "KG",
    lcc: 0,
    name: "Key Lime Air",
    type: "airline",
  },
  {
    id: "HW",
    lcc: 0,
    name: "Hello",
    type: "airline",
  },
  {
    id: "L5",
    lcc: 0,
    name: "Atlas Atlantique Airlines",
    type: "airline",
  },
  {
    id: "MA",
    lcc: 0,
    name: "Malév",
    type: "airline",
  },
  {
    id: "ZC",
    lcc: 0,
    name: "Korongo Airlines",
    type: "airline",
  },
  {
    id: "CB",
    lcc: 0,
    name: "CCML Airlines",
    type: "airline",
  },
  {
    id: "GB",
    lcc: 0,
    name: "BRAZIL AIR",
    type: "airline",
  },
  {
    id: "N1",
    lcc: 0,
    name: "N1",
    type: "airline",
  },
  {
    id: "G1",
    lcc: 0,
    name: "Indya Airline Group",
    type: "airline",
  },
  {
    id: "M3",
    lcc: 0,
    name: "Air Norway",
    type: "airline",
  },
  {
    id: "24",
    lcc: 0,
    name: "Euro Jet",
    type: "airline",
  },
  {
    id: "ZY",
    lcc: 0,
    name: "Ada Air",
    type: "airline",
  },
  {
    id: "C1",
    lcc: 0,
    name: "CanXpress",
    type: "airline",
  },
  {
    id: "OM",
    lcc: 0,
    name: "MIAT Mongolian Airlines",
    type: "airline",
  },
  {
    id: "0D",
    lcc: 0,
    name: "Darwin Airline",
    type: "airline",
  },
  {
    id: "W5",
    lcc: 0,
    name: "Mahan Air",
    type: "airline",
  },
  {
    id: "AN",
    lcc: 0,
    name: "Advanced Air",
    type: "airline",
  },
  {
    id: "SORYABUS",
    lcc: null,
    name: "Sorya Bus",
    type: "bus",
  },
  {
    id: "F1",
    lcc: 0,
    name: "Fly Brasil",
    type: "airline",
  },
  {
    id: "TQ",
    lcc: 0,
    name: "Tandem Aero",
    type: "airline",
  },
  {
    id: "RF",
    lcc: 0,
    name: "Florida West International Airways",
    type: "airline",
  },
  {
    id: "N5",
    lcc: 0,
    name: "Nolinor",
    type: "airline",
  },
  {
    id: "YS",
    lcc: 0,
    name: "Flightlink",
    type: "airline",
  },
  {
    id: "Y1",
    lcc: 0,
    name: "Yellowstone Club Private Shuttle",
    type: "airline",
  },
  {
    id: "WA",
    lcc: 0,
    name: "KLM Cityhopper",
    type: "airline",
  },
  {
    id: "9S",
    lcc: 0,
    name: "Southern Air",
    type: "airline",
  },
  {
    id: "YH",
    lcc: 0,
    name: "Air Thanlwin",
    type: "airline",
  },
  {
    id: "WU",
    lcc: 0,
    name: "WesternAir",
    type: "airline",
  },
  {
    id: "CL",
    lcc: 0,
    name: "Lufthansa CityLine",
    type: "airline",
  },
  {
    id: "2F",
    lcc: 0,
    name: "Azul Conecta",
    type: "airline",
  },
  {
    id: "N0",
    lcc: 0,
    name: "Norse Atlantic Airways",
    type: "airline",
  },
  {
    id: "DM",
    lcc: 0,
    name: "Arajet",
    type: "airline",
  },
  {
    id: "AU",
    lcc: 0,
    name: "AU",
    type: "airline",
  },
  {
    id: "Q3",
    lcc: 0,
    name: "Anguilla Air Services",
    type: "airline",
  },
  {
    id: "TU",
    lcc: 0,
    name: "Tunisair",
    type: "airline",
  },
  {
    id: "T7",
    lcc: 0,
    name: "Twin Jet",
    type: "airline",
  },
  {
    id: "PA",
    lcc: 1,
    name: "Airblue",
    type: "airline",
  },
  {
    id: "TJ",
    lcc: 0,
    name: "Tradewind Aviation",
    type: "airline",
  },
  {
    id: "W2",
    lcc: 0,
    name: "Flexflight",
    type: "airline",
  },
  {
    id: "NA",
    lcc: 0,
    name: "Nesma Air",
    type: "airline",
  },
  {
    id: "9I",
    lcc: 0,
    name: "Alliance Air",
    type: "airline",
  },
  {
    id: "EM",
    lcc: 0,
    name: "Empire Airlines",
    type: "airline",
  },
  {
    id: "CS",
    lcc: 0,
    name: "Continental Micronesia",
    type: "airline",
  },
  {
    id: "T6",
    lcc: 0,
    name: "Airswift",
    type: "airline",
  },
  {
    id: "RG",
    lcc: 0,
    name: "Rotana Jet",
    type: "airline",
  },
  {
    id: "TH",
    lcc: 0,
    name: "Raya Airways",
    type: "airline",
  },
  {
    id: "H9",
    lcc: 0,
    name: "Himalaya Airlines",
    type: "airline",
  },
  {
    id: "XB",
    lcc: 0,
    name: "NEXT Brasil",
    type: "airline",
  },
  {
    id: "GN",
    lcc: 0,
    name: "GNB Linhas Aereas",
    type: "airline",
  },
  {
    id: "SO",
    lcc: 0,
    name: "Salsa dHaiti",
    type: "airline",
  },
  {
    id: "FH",
    lcc: 0,
    name: "Freebird Airlines",
    type: "airline",
  },
  {
    id: "HM",
    lcc: 0,
    name: "Air Seychelles",
    type: "airline",
  },
  {
    id: "VF",
    lcc: 0,
    name: "Valuair",
    type: "airline",
  },
  {
    id: "1C",
    lcc: 0,
    name: "OneChina",
    type: "airline",
  },
  {
    id: "HQ",
    lcc: 0,
    name: "Thomas Cook Belgium",
    type: "airline",
  },
  {
    id: "DA",
    lcc: 0,
    name: "Aerolinea de Antioquia",
    type: "airline",
  },
  {
    id: "VP",
    lcc: 0,
    name: "Villa Air",
    type: "airline",
  },
  {
    id: "ZZ",
    lcc: 0,
    name: "Zz",
    type: "airline",
  },
  {
    id: "5E",
    lcc: 0,
    name: "SGA Airlines",
    type: "airline",
  },
  {
    id: "SF",
    lcc: 0,
    name: "Tassili Airlines",
    type: "airline",
  },
  {
    id: "LB",
    lcc: 1,
    name: "Air Costa",
    type: "airline",
  },
  {
    id: "TX",
    lcc: 0,
    name: "Air Caraïbes",
    type: "airline",
  },
  {
    id: "K8",
    lcc: 0,
    name: "Kan Air",
    type: "airline",
  },
  {
    id: "IR",
    lcc: 0,
    name: "Iran Air",
    type: "airline",
  },
  {
    id: "K6",
    lcc: 0,
    name: "Cambodia Angkor Air",
    type: "airline",
  },
  {
    id: "AE",
    lcc: 0,
    name: "Mandarin Airlines",
    type: "airline",
  },
  {
    id: "CJ",
    lcc: 0,
    name: "BA CityFlyer",
    type: "airline",
  },
  {
    id: "C5",
    lcc: 0,
    name: "CommuteAir",
    type: "airline",
  },
  {
    id: "XE",
    lcc: 0,
    name: "JetSuiteX",
    type: "airline",
  },
  {
    id: "HUNT",
    lcc: null,
    name: "HUNTUR",
    type: "bus",
  },
  {
    id: "XG",
    lcc: 0,
    name: "SunExpress",
    type: "airline",
  },
  {
    id: "9K",
    lcc: 1,
    name: "Cape Air",
    type: "airline",
  },
  {
    id: "6U",
    lcc: 0,
    name: "Air Cargo Germany",
    type: "airline",
  },
  {
    id: "1X",
    lcc: 0,
    name: "Branson Air Express",
    type: "airline",
  },
  {
    id: "4H",
    lcc: 0,
    name: "HiSky",
    type: "airline",
  },
  {
    id: "ZQ",
    lcc: 0,
    name: "German Airways",
    type: "airline",
  },
  {
    id: "YE",
    lcc: 0,
    name: "Yan Air",
    type: "airline",
  },
  {
    id: "C3",
    lcc: 0,
    name: "Trade Air",
    type: "airline",
  },
  {
    id: "IZ",
    lcc: 1,
    name: "Arkia",
    type: "airline",
  },
  {
    id: "2O",
    lcc: 0,
    name: "Island Air Kodiak",
    type: "airline",
  },
  {
    id: "I6",
    lcc: 0,
    name: "Iryo",
    type: "train",
  },
  {
    id: "76",
    lcc: 0,
    name: "Southjet",
    type: "airline",
  },
  {
    id: "77",
    lcc: 0,
    name: "Southjet connect",
    type: "airline",
  },
  {
    id: "AO",
    lcc: 0,
    name: "Avianova (Russia)",
    type: "airline",
  },
  {
    id: "A0",
    lcc: 1,
    name: "BA EuroFlyer",
    type: "airline",
  },
  {
    id: "9L",
    lcc: 0,
    name: "Colgan Air",
    type: "airline",
  },
  {
    id: "9X",
    lcc: 1,
    name: "Southern Airways Express",
    type: "airline",
  },
  {
    id: "TN",
    lcc: 0,
    name: "Air Tahiti Nui",
    type: "airline",
  },
  {
    id: "WW",
    lcc: 1,
    name: "WOW air",
    type: "airline",
  },
  {
    id: "OE",
    lcc: 1,
    name: "FlyOne Romania",
    type: "airline",
  },
  {
    id: "5H",
    lcc: 1,
    name: "Fly540",
    type: "airline",
  },
  {
    id: "JM",
    lcc: 0,
    name: "Jambojet",
    type: "airline",
  },
  {
    id: "WV",
    lcc: 0,
    name: "Westair Aviation",
    type: "airline",
  },
  {
    id: "3C",
    lcc: null,
    name: "Air Chathams Limited",
    type: "airline",
  },
  {
    id: "NJ",
    lcc: 0,
    name: "Nordic Global Airlines",
    type: "airline",
  },
  {
    id: "6T",
    lcc: 1,
    name: "Air Mandalay",
    type: "airline",
  },
  {
    id: "7N",
    lcc: 0,
    name: "Pawa Dominicana",
    type: "airline",
  },
  {
    id: "GR",
    lcc: 0,
    name: "Aurigny Air Services",
    type: "airline",
  },
  {
    id: "QL",
    lcc: 1,
    name: "Laser Air",
    type: "airline",
  },
  {
    id: "C7",
    lcc: 1,
    name: "Cinnamon Air",
    type: "airline",
  },
  {
    id: "P0",
    lcc: 0,
    name: "Proflight Zambia",
    type: "airline",
  },
  {
    id: "JG",
    lcc: 1,
    name: "JetGo",
    type: "airline",
  },
  {
    id: "ES",
    lcc: 0,
    name: "Estelar Latinoamerica",
    type: "airline",
  },
  {
    id: "2J",
    lcc: 0,
    name: "Air Burkina",
    type: "airline",
  },
  {
    id: "THANHBUOI",
    lcc: null,
    name: "Thanh Buoi",
    type: "bus",
  },
  {
    id: "CC",
    lcc: 0,
    name: "CM Airlines",
    type: "airline",
  },
  {
    id: "STARMARTEX",
    lcc: null,
    name: "StarMart Express",
    type: "bus",
  },
  {
    id: "YEAUNGLANE",
    lcc: null,
    name: "Ye Aung Lan Express",
    type: "bus",
  },
  {
    id: "LPA",
    lcc: null,
    name: "Air Leap",
    type: "airline",
  },
  {
    id: "VC",
    lcc: 0,
    name: "Aleutian Airways",
    type: "airline",
  },
  {
    id: "9M",
    lcc: 0,
    name: "Central Mountain Air",
    type: "airline",
  },
  {
    id: "OH",
    lcc: 0,
    name: "PSA Airlines",
    type: "airline",
  },
  {
    id: "QX",
    lcc: 0,
    name: "Horizon Air",
    type: "airline",
  },
  {
    id: "YU",
    lcc: 0,
    name: "EuroAtlantic Airways",
    type: "airline",
  },
  {
    id: "5D",
    lcc: 0,
    name: "Aerolitoral",
    type: "airline",
  },
  {
    id: "V3",
    lcc: 0,
    name: "Carpatair",
    type: "airline",
  },
  {
    id: "ATV",
    lcc: null,
    name: "Avanti Air",
    type: "airline",
  },
  {
    id: "RODONORTE",
    lcc: null,
    name: "Rodonorte",
    type: "bus",
  },
  {
    id: "SW",
    lcc: 0,
    name: "Air Namibia",
    type: "airline",
  },
  {
    id: "R4",
    lcc: 0,
    name: "Real Tonga",
    type: "airline",
  },
  {
    id: "KI",
    lcc: 1,
    name: "SKS Airways",
    type: "airline",
  },
  {
    id: "EE",
    lcc: 0,
    name: "Xfly",
    type: "airline",
  },
  {
    id: "U6",
    lcc: 1,
    name: "Ural Airlines",
    type: "airline",
  },
  {
    id: "A1",
    lcc: 0,
    name: "A.P.G. Distribution System",
    type: "airline",
  },
  {
    id: "Z9",
    lcc: 0,
    name: "MYAirline",
    type: "airline",
  },
  {
    id: "X5",
    lcc: null,
    name: "Air Europa express",
    type: "airline",
  },
  {
    id: "M8",
    lcc: 1,
    name: "SkyJet Airlines",
    type: "airline",
  },
  {
    id: "6F",
    lcc: 1,
    name: "Primera Air Nordic",
    type: "airline",
  },
  {
    id: "RRV",
    lcc: null,
    name: "Mombasa Air Safari",
    type: "airline",
  },
  {
    id: "STEWISLAND",
    lcc: null,
    name: "Stewart Island Flights",
    type: "airline",
  },
  {
    id: "SKYPASADA",
    lcc: null,
    name: "Sky Pasada",
    type: "airline",
  },
  {
    id: "UNIAIR",
    lcc: null,
    name: "Unity Air",
    type: "airline",
  },
  {
    id: "ASSALAAM",
    lcc: null,
    name: "As Salaam Air",
    type: "airline",
  },
  {
    id: "GRUMAIR",
    lcc: null,
    name: "Grumeti Air",
    type: "airline",
  },
  {
    id: "ATTO",
    lcc: null,
    name: "Adis Travel & Tours",
    type: "bus",
  },
  {
    id: "EF",
    lcc: 1,
    name: "EF",
    type: "airline",
  },
  {
    id: "EMETEBE",
    lcc: null,
    name: "Emetebe Airlines",
    type: "airline",
  },
  {
    id: "FLYSAFARI",
    lcc: null,
    name: "Fly Safari Airlink",
    type: "airline",
  },
  {
    id: "P6",
    lcc: 0,
    name: "Pascan Aviation",
    type: "airline",
  },
  {
    id: "RY",
    lcc: 1,
    name: "Air Jiangxi",
    type: "airline",
  },
  {
    id: "OW",
    lcc: null,
    name: "Skyward Express Limited",
    type: "airline",
  },
  {
    id: "M0",
    lcc: 1,
    name: "Aero Mongolia",
    type: "airline",
  },
  {
    id: "SBK",
    lcc: null,
    name: "Blue Sky Aviation",
    type: "airline",
  },
  {
    id: "F4",
    lcc: null,
    name: "Air Flamenco",
    type: "airline",
  },
  {
    id: "O4",
    lcc: null,
    name: "Orange2Fly",
    type: "airline",
  },
  {
    id: "BP",
    lcc: 0,
    name: "Air Botswana",
    type: "airline",
  },
  {
    id: "V8",
    lcc: 0,
    name: "ATRAN Cargo Airlines",
    type: "airline",
  },
  {
    id: "GP",
    lcc: 0,
    name: "APG Airlines",
    type: "airline",
  },
  {
    id: "MW",
    lcc: 0,
    name: "Mokulele Flight Service",
    type: "airline",
  },
  {
    id: "K5",
    lcc: 0,
    name: "Silverstone Air",
    type: "airline",
  },
  {
    id: "Y6",
    lcc: null,
    name: "AB Aviation",
    type: "airline",
  },
  {
    id: "Y5",
    lcc: 1,
    name: "Golden Myanmar Airlines",
    type: "airline",
  },
  {
    id: "6S",
    lcc: 0,
    name: "SaudiGulf Airlines",
    type: "airline",
  },
  {
    id: "DELIMAEXPR",
    lcc: null,
    name: "Delima Express",
    type: "bus",
  },
  {
    id: "OB",
    lcc: 1,
    name: "Boliviana de Aviación",
    type: "airline",
  },
  {
    id: "RLY",
    lcc: null,
    name: "Air Loyaute",
    type: "airline",
  },
  {
    id: "CW",
    lcc: 0,
    name: "Air Marshall Islands",
    type: "airline",
  },
  {
    id: "ZJ",
    lcc: 0,
    name: "Zambezi Airlines (ZMA)",
    type: "airline",
  },
  {
    id: "C0",
    lcc: 0,
    name: "Centralwings",
    type: "airline",
  },
  {
    id: "9H",
    lcc: 0,
    name: "Air Changan",
    type: "airline",
  },
  {
    id: "CRAVIATION",
    lcc: null,
    name: "CR Aviation",
    type: "airline",
  },
  {
    id: "BZ",
    lcc: 0,
    name: "Blue Bird Airways",
    type: "airline",
  },
  {
    id: "JE",
    lcc: 1,
    name: "Mango",
    type: "airline",
  },
  {
    id: "DVR",
    lcc: null,
    name: "Divi Divi Air",
    type: "airline",
  },
  {
    id: "WT",
    lcc: null,
    name: "SwiftAir",
    type: "airline",
  },
  {
    id: "YT",
    lcc: 0,
    name: "Yeti Airways",
    type: "airline",
  },
  {
    id: "SM",
    lcc: 1,
    name: "Air Cairo",
    type: "airline",
  },
  {
    id: "DF",
    lcc: 0,
    name: "Michael Airlines",
    type: "airline",
  },
  {
    id: "QN",
    lcc: null,
    name: "Skytrans",
    type: "airline",
  },
  {
    id: "B3",
    lcc: 0,
    name: "Bhutan Airlines",
    type: "airline",
  },
  {
    id: "ULENDO",
    lcc: null,
    name: "Fly Ulendo",
    type: "airline",
  },
  {
    id: "FP",
    lcc: 0,
    name: "Pelican Airlines",
    type: "airline",
  },
  {
    id: "A8",
    lcc: null,
    name: "Aerolink Uganda",
    type: "airline",
  },
  {
    id: "XLL",
    lcc: null,
    name: "Air Excel Limited",
    type: "airline",
  },
  {
    id: "VH",
    lcc: 0,
    name: "Viva Air",
    type: "airline",
  },
  {
    id: "P5",
    lcc: 0,
    name: "Wingo airlines",
    type: "airline",
  },
  {
    id: "GT",
    lcc: 1,
    name: "Air Guilin",
    type: "airline",
  },
  {
    id: "C2",
    lcc: 0,
    name: "Ceiba Intercontinental",
    type: "airline",
  },
  {
    id: "GCS",
    lcc: null,
    name: "Skyway CR",
    type: "airline",
  },
  {
    id: "IG",
    lcc: 1,
    name: "Air Italy",
    type: "airline",
  },
  {
    id: "RV",
    lcc: 0,
    name: "Air Canada Rouge",
    type: "airline",
  },
  {
    id: "GRENADINE",
    lcc: null,
    name: "Grenadine Airways",
    type: "airline",
  },
  {
    id: "R3",
    lcc: 1,
    name: "Yakutia Airlines",
    type: "airline",
  },
  {
    id: "Q5",
    lcc: 0,
    name: "40-Mile Air",
    type: "airline",
  },
  {
    id: "EP",
    lcc: 0,
    name: "Iran Aseman Airlines",
    type: "airline",
  },
  {
    id: "IE",
    lcc: 0,
    name: "Solomon Airlines",
    type: "airline",
  },
  {
    id: "V5",
    lcc: 0,
    name: "Aerovías DAP",
    type: "airline",
  },
  {
    id: "T5",
    lcc: 0,
    name: "Turkmenistan Airlines",
    type: "airline",
  },
  {
    id: "EG",
    lcc: 0,
    name: "Aer Lingus UK",
    type: "airline",
  },
  {
    id: "7D",
    lcc: null,
    name: "Madagasikara Airways",
    type: "airline",
  },
  {
    id: "U3",
    lcc: 0,
    name: "Avies",
    type: "airline",
  },
  {
    id: "T2",
    lcc: 0,
    name: "Thai Air Cargo",
    type: "airline",
  },
  {
    id: "XF",
    lcc: 0,
    name: "Vladivostok Air",
    type: "airline",
  },
  {
    id: "6H",
    lcc: 0,
    name: "Israir",
    type: "airline",
  },
  {
    id: "HP",
    lcc: 0,
    name: "Amapola Flyg",
    type: "airline",
  },
  {
    id: "5T",
    lcc: 0,
    name: "Canadian North",
    type: "airline",
  },
  {
    id: "WC",
    lcc: 0,
    name: "Avianca Honduras",
    type: "airline",
  },
  {
    id: "7V",
    lcc: 0,
    name: "Federal Airlines",
    type: "airline",
  },
  {
    id: "VD",
    lcc: 0,
    name: "Air Libert",
    type: "airline",
  },
  {
    id: "ML",
    lcc: 1,
    name: "Air Mediterranee",
    type: "airline",
  },
  {
    id: "B1",
    lcc: 0,
    name: "Baltic Air lines",
    type: "airline",
  },
  {
    id: "J1",
    lcc: 0,
    name: "One Jet",
    type: "airline",
  },
  {
    id: "P1",
    lcc: 0,
    name: "Regional Sky",
    type: "airline",
  },
  {
    id: "Z7",
    lcc: 1,
    name: "Amaszonas Uruguay",
    type: "airline",
  },
  {
    id: "MT",
    lcc: 1,
    name: "Malta MedAir",
    type: "airline",
  },
  {
    id: "6I",
    lcc: 0,
    name: "Alsie Express",
    type: "airline",
  },
  {
    id: "FLZ",
    lcc: null,
    name: "Flightlink",
    type: "airline",
  },
  {
    id: "ST",
    lcc: 1,
    name: "Air Thanlwin",
    type: "airline",
  },
  {
    id: "THANHCHUN",
    lcc: null,
    name: "Thanh Chung",
    type: "bus",
  },
  {
    id: "JZ",
    lcc: 0,
    name: "JetSMART Peru",
    type: "airline",
  },
  {
    id: "0V",
    lcc: 0,
    name: "VASCO",
    type: "airline",
  },
  {
    id: "N6",
    lcc: 0,
    name: "Nomad Aviation",
    type: "airline",
  },
  {
    id: "7J",
    lcc: 1,
    name: "Tajik Air",
    type: "airline",
  },
  {
    id: "Z6",
    lcc: 1,
    name: "Dniproavia",
    type: "airline",
  },
  {
    id: "R6",
    lcc: 0,
    name: "DAT LT",
    type: "airline",
  },
  {
    id: "RW",
    lcc: 0,
    name: "Royal Air Philippines",
    type: "airline",
  },
  {
    id: "4A",
    lcc: 0,
    name: "ATSA Airlines",
    type: "airline",
  },
  {
    id: "SH",
    lcc: 0,
    name: "Sharp Airlines",
    type: "airline",
  },
  {
    id: "F7",
    lcc: 0,
    name: "iFly Airlines",
    type: "airline",
  },
  {
    id: "NQ",
    lcc: 0,
    name: "Air Japan",
    type: "airline",
  },
  {
    id: "TZ",
    lcc: 1,
    name: "Tsaradia",
    type: "airline",
  },
  {
    id: "S2",
    lcc: 0,
    name: "Jet Konnect",
    type: "airline",
  },
  {
    id: "DB",
    lcc: 0,
    name: "Brit Air",
    type: "airline",
  },
  {
    id: "7I",
    lcc: 1,
    name: "Insel Air",
    type: "airline",
  },
  {
    id: "1F",
    lcc: 0,
    name: "CB Airways UK ( Interliging Flights )",
    type: "airline",
  },
  {
    id: "8T",
    lcc: 0,
    name: "Air Tindi",
    type: "airline",
  },
  {
    id: "JD",
    lcc: 0,
    name: "Beijing Capital Airlines",
    type: "airline",
  },
  {
    id: "UB",
    lcc: 0,
    name: "Myanmar National Airlines",
    type: "airline",
  },
  {
    id: "YN",
    lcc: 0,
    name: "Air Creebec",
    type: "airline",
  },
  {
    id: "TM",
    lcc: 0,
    name: "LAM Mozambique Airlines",
    type: "airline",
  },
  {
    id: "PX",
    lcc: 0,
    name: "Air Niugini",
    type: "airline",
  },
  {
    id: "ZD",
    lcc: 0,
    name: "EWA Air",
    type: "airline",
  },
  {
    id: "4W",
    lcc: 0,
    name: "Allied Air",
    type: "airline",
  },
  {
    id: "ER",
    lcc: null,
    name: "SereneAir",
    type: "airline",
  },
  {
    id: "VT",
    lcc: 0,
    name: "Air Tahiti",
    type: "airline",
  },
  {
    id: "LVIA",
    lcc: null,
    name: "L’Aurora Viaggi",
    type: "bus",
  },
  {
    id: "V4",
    lcc: 0,
    name: "Vieques Air Link",
    type: "airline",
  },
  {
    id: "THEONETRAV",
    lcc: null,
    name: "The One Travel & Tours",
    type: "bus",
  },
  {
    id: "JO",
    lcc: 0,
    name: "Jonika airlines",
    type: "airline",
  },
  {
    id: "F2",
    lcc: 0,
    name: "Safarilink Aviation",
    type: "airline",
  },
  {
    id: "ZW",
    lcc: 0,
    name: "Air Wisconsin",
    type: "airline",
  },
  {
    id: "GI",
    lcc: 0,
    name: "Itek Air",
    type: "airline",
  },
  {
    id: "U9",
    lcc: 0,
    name: "Tatarstan Airlines",
    type: "airline",
  },
  {
    id: "5Z",
    lcc: 1,
    name: "Cem Air",
    type: "airline",
  },
  {
    id: "FA",
    lcc: 1,
    name: "Fly Safair",
    type: "airline",
  },
  {
    id: "5C",
    lcc: 1,
    name: "Nature Air",
    type: "airline",
  },
  {
    id: "AB",
    lcc: 1,
    name: "Bonza",
    type: "airline",
  },
  {
    id: "9N",
    lcc: 0,
    name: "Tropic Air Limited",
    type: "airline",
  },
  {
    id: "8V",
    lcc: 0,
    name: "Wright Air Service",
    type: "airline",
  },
  {
    id: "ZV",
    lcc: 1,
    name: "Aerus",
    type: "airline",
  },
  {
    id: "6O",
    lcc: 0,
    name: "Orbest",
    type: "airline",
  },
  {
    id: "PB",
    lcc: 0,
    name: "PAL Airlines",
    type: "airline",
  },
  {
    id: "ID",
    lcc: 1,
    name: "Batik Air",
    type: "airline",
  },
  {
    id: "E2",
    lcc: 0,
    name: "Eurowings Europe Austria",
    type: "airline",
  },
  {
    id: "J7",
    lcc: 1,
    name: "Afrijet Business Service",
    type: "airline",
  },
  {
    id: "AW",
    lcc: 1,
    name: "Africa World Airlines",
    type: "airline",
  },
  {
    id: "PF",
    lcc: 1,
    name: "Primera Air",
    type: "airline",
  },
  {
    id: "EL",
    lcc: 1,
    name: "Ellinair",
    type: "airline",
  },
  {
    id: "DR",
    lcc: 1,
    name: "Ruili Airlines",
    type: "airline",
  },
  {
    id: "EB",
    lcc: 1,
    name: "Wamos Air",
    type: "airline",
  },
  {
    id: "S8",
    lcc: 1,
    name: "Sounds Air",
    type: "airline",
  },
  {
    id: "I2",
    lcc: 0,
    name: "Iberia Express",
    type: "airline",
  },
  {
    id: "V2",
    lcc: 0,
    name: "Vision Airlines",
    type: "airline",
  },
  {
    id: "JX",
    lcc: 1,
    name: "Starlux Airlines",
    type: "airline",
  },
  {
    id: "MJ",
    lcc: 1,
    name: "Myway Airlines",
    type: "airline",
  },
  {
    id: "WB",
    lcc: 0,
    name: "Rwandair",
    type: "airline",
  },
  {
    id: "NL",
    lcc: 1,
    name: "Amelia",
    type: "airline",
  },
  {
    id: "9V",
    lcc: 0,
    name: "Avior Airlines",
    type: "airline",
  },
  {
    id: "G9",
    lcc: 1,
    name: "Air Arabia",
    type: "airline",
  },
  {
    id: "9W",
    lcc: 0,
    name: "Jet Airways",
    type: "airline",
  },
  {
    id: "YZ",
    lcc: 0,
    name: "Alas Uruguay",
    type: "airline",
  },
  {
    id: "8A",
    lcc: 0,
    name: "Atlas Blue",
    type: "airline",
  },
  {
    id: "CO",
    lcc: 0,
    name: "Cobalt Air",
    type: "airline",
  },
  {
    id: "MO",
    lcc: 1,
    name: "Calm Air",
    type: "airline",
  },
  {
    id: "VQ",
    lcc: 1,
    name: "Novoair",
    type: "airline",
  },
  {
    id: "5O",
    lcc: 0,
    name: "ASL Airlines France",
    type: "airline",
  },
  {
    id: "HOANGLONG",
    lcc: null,
    name: "Hoang Long",
    type: "bus",
  },
  {
    id: "WS",
    lcc: 1,
    name: "WestJet",
    type: "airline",
  },
  {
    id: "KX",
    lcc: 0,
    name: "Cayman Airways",
    type: "airline",
  },
  {
    id: "CF",
    lcc: 0,
    name: "City Airline",
    type: "airline",
  },
  {
    id: "KD",
    lcc: 0,
    name: "Kalstar Aviation",
    type: "airline",
  },
  {
    id: "RX",
    lcc: 1,
    name: "Regent Airways",
    type: "airline",
  },
  {
    id: "2G",
    lcc: 1,
    name: "Angara airlines",
    type: "airline",
  },
  {
    id: "7R",
    lcc: 1,
    name: "Rusline",
    type: "airline",
  },
  {
    id: "WP",
    lcc: 0,
    name: "Air Antwerp",
    type: "airline",
  },
  {
    id: "BC",
    lcc: 1,
    name: "Skymark Airlines",
    type: "airline",
  },
  {
    id: "BM",
    lcc: 0,
    name: "flybmi",
    type: "airline",
  },
  {
    id: "JV",
    lcc: 0,
    name: "Perimeter",
    type: "airline",
  },
  {
    id: "G3",
    lcc: 1,
    name: "Gol Transportes Aéreos",
    type: "airline",
  },
  {
    id: "OR",
    lcc: 1,
    name: "TUI fly Netherlands",
    type: "airline",
  },
  {
    id: "I4",
    lcc: 0,
    name: "Island Air Express",
    type: "airline",
  },
  {
    id: "BX",
    lcc: 1,
    name: "Air Busan",
    type: "airline",
  },
  {
    id: "5N",
    lcc: 1,
    name: "Smartavia",
    type: "airline",
  },
  {
    id: "DP",
    lcc: 1,
    name: "Pobeda",
    type: "airline",
  },
  {
    id: "IX",
    lcc: 1,
    name: "Air India Express",
    type: "airline",
  },
  {
    id: "KK",
    lcc: 1,
    name: "Leav Aviation",
    type: "airline",
  },
  {
    id: "3S",
    lcc: 0,
    name: "Air Antilles Express",
    type: "airline",
  },
  {
    id: "ZI",
    lcc: 0,
    name: "Aigle Azur",
    type: "airline",
  },
  {
    id: "K7",
    lcc: 0,
    name: "Air KBZ",
    type: "airline",
  },
  {
    id: "VW",
    lcc: 0,
    name: "Aeromar",
    type: "airline",
  },
  {
    id: "QW",
    lcc: 0,
    name: "Qingdao Airlines",
    type: "airline",
  },
  {
    id: "HO",
    lcc: 1,
    name: "Juneyao Airlines",
    type: "airline",
  },
  {
    id: "XN",
    lcc: 0,
    name: "Xpressair",
    type: "airline",
  },
  {
    id: "B6",
    lcc: 1,
    name: "JetBlue Airways",
    type: "airline",
  },
  {
    id: "FO",
    lcc: 1,
    name: "Flybondi",
    type: "airline",
  },
  {
    id: "5K",
    lcc: 0,
    name: "Hi Fly",
    type: "airline",
  },
  {
    id: "QK",
    lcc: 0,
    name: "Air Canada Jazz",
    type: "airline",
  },
  {
    id: "KC",
    lcc: 0,
    name: "Air Astana",
    type: "airline",
  },
  {
    id: "TF",
    lcc: 0,
    name: "Braathens Regional Aviation",
    type: "airline",
  },
  {
    id: "2Z",
    lcc: 1,
    name: "Voepass",
    type: "airline",
  },
  {
    id: "7M",
    lcc: 0,
    name: "MAP Linhas Aéreas",
    type: "airline",
  },
  {
    id: "8M",
    lcc: 0,
    name: "Myanmar Airways",
    type: "airline",
  },
  {
    id: "SOMBATTOUR",
    lcc: null,
    name: "Sombat Tour",
    type: "bus",
  },
  {
    id: "TRALANVIEN",
    lcc: null,
    name: "Tra Lan Vien",
    type: "bus",
  },
  {
    id: "OD",
    lcc: 1,
    name: "Batik Air Malaysia",
    type: "airline",
  },
  {
    id: "TL",
    lcc: 1,
    name: "Airnorth",
    type: "airline",
  },
  {
    id: "UE",
    lcc: 0,
    name: "Nasair",
    type: "airline",
  },
  {
    id: "DH",
    lcc: 0,
    name: "Dennis Sky",
    type: "airline",
  },
  {
    id: "Q7",
    lcc: 0,
    name: "Sky Bahamas",
    type: "airline",
  },
  {
    id: "1D",
    lcc: 1,
    name: "Aerolinea de Antioquia",
    type: "airline",
  },
  {
    id: "FC",
    lcc: 1,
    name: "Link Airways",
    type: "airline",
  },
  {
    id: "WM",
    lcc: 0,
    name: "Windward Islands Airways",
    type: "airline",
  },
  {
    id: "8R",
    lcc: 1,
    name: "Amelia",
    type: "airline",
  },
  {
    id: "3H",
    lcc: 1,
    name: "Air Inuit",
    type: "airline",
  },
  {
    id: "TB",
    lcc: 1,
    name: "TUI fly Belgium",
    type: "airline",
  },
  {
    id: "B0",
    lcc: 0,
    name: "La Compagnie",
    type: "airline",
  },
  {
    id: "OJ",
    lcc: 0,
    name: "NyxAir",
    type: "airline",
  },
  {
    id: "WF",
    lcc: 0,
    name: "Widerøe",
    type: "airline",
  },
  {
    id: "EW",
    lcc: 1,
    name: "Eurowings",
    type: "airline",
  },
  {
    id: "YJ",
    lcc: 1,
    name: "Asian Wings Air",
    type: "airline",
  },
  {
    id: "LK",
    lcc: 0,
    name: "Lao Skyway",
    type: "airline",
  },
  {
    id: "SG",
    lcc: 1,
    name: "Spicejet",
    type: "airline",
  },
  {
    id: "PG",
    lcc: 1,
    name: "Bangkok Airways",
    type: "airline",
  },
  {
    id: "RP",
    lcc: 0,
    name: "Aeroexpress Regional",
    type: "airline",
  },
  {
    id: "3M",
    lcc: 1,
    name: "Silver Airways",
    type: "airline",
  },
  {
    id: "KU",
    lcc: 0,
    name: "Kuwait Airways",
    type: "airline",
  },
  {
    id: "DY",
    lcc: 1,
    name: "Norwegian",
    type: "airline",
  },
  {
    id: "UN",
    lcc: 1,
    name: "Transaero Airlines",
    type: "airline",
  },
  {
    id: "YB",
    lcc: 1,
    name: "Harbour Air (Priv)",
    type: "airline",
  },
  {
    id: "FKK",
    lcc: null,
    name: "Unity Air",
    type: "airline",
  },
  {
    id: "CFR",
    lcc: null,
    name: "Caile Ferate Romane",
    type: "train",
  },
  {
    id: "CP",
    lcc: 0,
    name: "Compass Airlines",
    type: "airline",
  },
  {
    id: "TRAVELNEPA",
    lcc: null,
    name: "Travel Nepal Bus Service",
    type: "bus",
  },
  {
    id: "7F",
    lcc: 0,
    name: "First Air",
    type: "airline",
  },
  {
    id: "FY",
    lcc: 1,
    name: "Firefly",
    type: "airline",
  },
  {
    id: "BW",
    lcc: 0,
    name: "Caribbean Airlines",
    type: "airline",
  },
  {
    id: "C9",
    lcc: 1,
    name: "SkyWise",
    type: "airline",
  },
  {
    id: "EMT",
    lcc: null,
    name: "Emetebe Airlines",
    type: "airline",
  },
  {
    id: "PJ",
    lcc: 0,
    name: "Air Saint Pierre",
    type: "airline",
  },
  {
    id: "TW",
    lcc: 1,
    name: "Tway Airlines",
    type: "airline",
  },
  {
    id: "ZO",
    lcc: null,
    name: "Zagros Airlines",
    type: "airline",
  },
  {
    id: "B5",
    lcc: 0,
    name: "East African",
    type: "airline",
  },
  {
    id: "K2",
    lcc: 0,
    name: "EuroLot",
    type: "airline",
  },
  {
    id: "OL",
    lcc: 0,
    name: "Samoa Airways",
    type: "airline",
  },
  {
    id: "Q9",
    lcc: 0,
    name: "Green Africa Airways",
    type: "airline",
  },
  {
    id: "BV",
    lcc: 1,
    name: "Luke Air",
    type: "airline",
  },
  {
    id: "R2",
    lcc: 1,
    name: "Transair",
    type: "airline",
  },
  {
    id: "Y4",
    lcc: 1,
    name: "Volaris",
    type: "airline",
  },
  {
    id: "FZ",
    lcc: 1,
    name: "Fly Dubai",
    type: "airline",
  },
  {
    id: "DE",
    lcc: 1,
    name: "Condor",
    type: "airline",
  },
  {
    id: "GL",
    lcc: 0,
    name: "Air Greenland",
    type: "airline",
  },
  {
    id: "9R",
    lcc: 1,
    name: "SATENA",
    type: "airline",
  },
  {
    id: "4O",
    lcc: 1,
    name: "Interjet",
    type: "airline",
  },
  {
    id: "D8",
    lcc: 0,
    name: "Norwegian International",
    type: "airline",
  },
  {
    id: "UK",
    lcc: 0,
    name: "Air Vistara",
    type: "airline",
  },
  {
    id: "DL",
    lcc: 0,
    name: "Delta Air Lines",
    type: "airline",
  },
  {
    id: "QZ",
    lcc: 0,
    name: "Indonesia AirAsia",
    type: "airline",
  },
  {
    id: "3L",
    lcc: 1,
    name: "Air Arabia Abu Dhabi",
    type: "airline",
  },
  {
    id: "W6",
    lcc: 1,
    name: "Wizz Air",
    type: "airline",
  },
  {
    id: "3K",
    lcc: 0,
    name: "Jetstar Asia Airways",
    type: "airline",
  },
  {
    id: "W4",
    lcc: 1,
    name: "Wizz Air Malta",
    type: "airline",
  },
  {
    id: "J9",
    lcc: 1,
    name: "Jazeera Airways",
    type: "airline",
  },
  {
    id: "F9",
    lcc: 1,
    name: "Frontier Airlines",
    type: "airline",
  },
  {
    id: "SL",
    lcc: 1,
    name: "Thai Lion Air",
    type: "airline",
  },
  {
    id: "LW",
    lcc: 0,
    name: "Lauda Europe",
    type: "airline",
  },
  {
    id: "CN",
    lcc: 0,
    name: "Grand China Air",
    type: "airline",
  },
  {
    id: "UP",
    lcc: 1,
    name: "Bahamasair",
    type: "airline",
  },
  {
    id: "PL",
    lcc: 0,
    name: "Southern Air Charter",
    type: "airline",
  },
  {
    id: "S0",
    lcc: 1,
    name: "Aerolineas Sosa",
    type: "airline",
  },
  {
    id: "AG",
    lcc: 0,
    name: "Aruba Airlines",
    type: "airline",
  },
  {
    id: "H2",
    lcc: 1,
    name: "Sky Airline",
    type: "airline",
  },
  {
    id: "BF",
    lcc: 0,
    name: "French Bee",
    type: "airline",
  },
  {
    id: "TK",
    lcc: 0,
    name: "Turkish Airlines",
    type: "airline",
  },
  {
    id: "PC",
    lcc: 1,
    name: "Pegasus",
    type: "airline",
  },
  {
    id: "HR",
    lcc: 0,
    name: "Hahn Airlines",
    type: "airline",
  },
  {
    id: "HU",
    lcc: 0,
    name: "Hainan Airlines",
    type: "airline",
  },
  {
    id: "PY",
    lcc: 0,
    name: "Surinam Airways",
    type: "airline",
  },
  {
    id: "Q8",
    lcc: 0,
    name: "Trans Air Congo",
    type: "airline",
  },
  {
    id: "GX",
    lcc: 1,
    name: "GX airlines",
    type: "airline",
  },
  {
    id: "I3",
    lcc: null,
    name: "ATA Airlines (Iran)",
    type: "airline",
  },
  {
    id: "ED",
    lcc: 1,
    name: "AirExplore",
    type: "airline",
  },
  {
    id: "PHUONGHENG",
    lcc: null,
    name: "Phuong Heng",
    type: "bus",
  },
  {
    id: "THIENTHANH",
    lcc: null,
    name: "Thien Thanh Limousine",
    type: "bus",
  },
  {
    id: "SRISUTOUR",
    lcc: null,
    name: "Sri Suthep Tour",
    type: "bus",
  },
  {
    id: "SRISIATOUR",
    lcc: null,
    name: "Sri Siam Tour",
    type: "bus",
  },
  {
    id: "SHA",
    lcc: null,
    name: "Shree Airlines",
    type: "airline",
  },
  {
    id: "GQ",
    lcc: 0,
    name: "SKY express",
    type: "airline",
  },
  {
    id: "ZK",
    lcc: 0,
    name: "Great Lakes Airlines",
    type: "airline",
  },
  {
    id: "RI",
    lcc: 0,
    name: "Mandala Airlines",
    type: "airline",
  },
  {
    id: "TROPOCEAN",
    lcc: null,
    name: "Tropic Ocean Airways",
    type: "airline",
  },
  {
    id: "GOVERNORS",
    lcc: null,
    name: "Governors Aviation",
    type: "airline",
  },
  {
    id: "FLYTRISTAR",
    lcc: null,
    name: "Fly Tristar Services",
    type: "airline",
  },
  {
    id: "DC",
    lcc: 1,
    name: "Braathens Regional Airways",
    type: "airline",
  },
  {
    id: "LV",
    lcc: 0,
    name: "Level",
    type: "airline",
  },
  {
    id: "HH",
    lcc: 0,
    name: "Qanot Shark",
    type: "airline",
  },
  {
    id: "WE",
    lcc: 1,
    name: "Thai Smile",
    type: "airline",
  },
  {
    id: "7B",
    lcc: 0,
    name: "Bees Airline",
    type: "airline",
  },
  {
    id: "VE",
    lcc: 0,
    name: "Clic",
    type: "airline",
  },
  {
    id: "BL",
    lcc: 0,
    name: "Pacific Airlines",
    type: "airline",
  },
  {
    id: "OX",
    lcc: 1,
    name: "Canarian Airways",
    type: "airline",
  },
  {
    id: "AQ",
    lcc: 1,
    name: "9 Air",
    type: "airline",
  },
  {
    id: "D9",
    lcc: 0,
    name: "Daallo Somalia",
    type: "airline",
  },
  {
    id: "G8",
    lcc: 1,
    name: "Go First",
    type: "airline",
  },
  {
    id: "UG",
    lcc: 0,
    name: "TunisAir Express",
    type: "airline",
  },
  {
    id: "IJ",
    lcc: null,
    name: "Spring Airlines Japan",
    type: "airline",
  },
  {
    id: "OPENSKIES",
    lcc: 1,
    name: "Openskies",
    type: "airline",
  },
  {
    id: "PR",
    lcc: 0,
    name: "Philippine Airlines",
    type: "airline",
  },
  {
    id: "NATTAKANTR",
    lcc: null,
    name: "Nattakan Travel",
    type: "bus",
  },
  {
    id: "CIASA",
    lcc: null,
    name: "Cooperativa Interurbana Andorrana S.A.",
    type: "bus",
  },
  {
    id: "NSI",
    lcc: null,
    name: "NS International",
    type: "train",
  },
  {
    id: "EX",
    lcc: null,
    name: "Regional Express Americas",
    type: "airline",
  },
  {
    id: "ARMAN",
    lcc: null,
    name: "Arman Bus",
    type: "bus",
  },
  {
    id: "NIKOLO",
    lcc: null,
    name: "Nikolo s.r.o.",
    type: "bus",
  },
  {
    id: "ADAMIST",
    lcc: null,
    name: "Adamis Tours",
    type: "bus",
  },
  {
    id: "BARAZA",
    lcc: null,
    name: "Autocares Baraza",
    type: "bus",
  },
  {
    id: "AUTH",
    lcc: null,
    name: "Autoherc",
    type: "bus",
  },
  {
    id: "BASEBUS",
    lcc: null,
    name: "Basebus",
    type: "bus",
  },
  {
    id: "CEVESA",
    lcc: null,
    name: "Cevesa",
    type: "bus",
  },
  {
    id: "COSTAAZUL",
    lcc: null,
    name: "Costa Azul",
    type: "bus",
  },
  {
    id: "JIMENEZDOR",
    lcc: null,
    name: "Jimenez Dorado",
    type: "bus",
  },
  {
    id: "LAUNION",
    lcc: null,
    name: "Autobuses La Union",
    type: "bus",
  },
  {
    id: "COBU",
    lcc: null,
    name: "ContraBUS",
    type: "bus",
  },
  {
    id: "DRIN",
    lcc: null,
    name: "Drinatrans",
    type: "bus",
  },
  {
    id: "SAMAR",
    lcc: null,
    name: "Samar",
    type: "bus",
  },
  {
    id: "PANO",
    lcc: null,
    name: "Panonijabus",
    type: "bus",
  },
  {
    id: "E4",
    lcc: 0,
    name: "Enter Air",
    type: "airline",
  },
  {
    id: "7T",
    lcc: 0,
    name: "Trenitalia",
    type: "train",
  },
  {
    id: "NGUYENKIM",
    lcc: null,
    name: "Nguyen Kim",
    type: "bus",
  },
  {
    id: "LUXURYCOAC",
    lcc: null,
    name: "Luxury Coach",
    type: "bus",
  },
  {
    id: "TUNAYAR",
    lcc: null,
    name: "Tun Ayar",
    type: "bus",
  },
  {
    id: "BLAUWNET",
    lcc: null,
    name: "Blauwnet",
    type: "train",
  },
  {
    id: "ARRIVANLT",
    lcc: null,
    name: "Arriva NL train",
    type: "train",
  },
  {
    id: "LE",
    lcc: null,
    name: "Norwegian Air Sweden LE",
    type: "airline",
  },
  {
    id: "VZ",
    lcc: 0,
    name: "Thai Vietjet",
    type: "airline",
  },
  {
    id: "CONCORDCOA",
    lcc: null,
    name: "Concord Coach",
    type: "bus",
  },
  {
    id: "MN",
    lcc: 1,
    name: "Kulula",
    type: "airline",
  },
  {
    id: "ERFURTER",
    lcc: null,
    name: "Erfurter Bahn",
    type: "train",
  },
  {
    id: "AGILIS",
    lcc: null,
    name: "Agilis",
    type: "train",
  },
  {
    id: "JJ",
    lcc: 0,
    name: "LATAM Brasil",
    type: "airline",
  },
  {
    id: "INDIANTAI",
    lcc: null,
    name: "Indian Trails",
    type: "bus",
  },
  {
    id: "XW",
    lcc: 1,
    name: "NokScoot",
    type: "airline",
  },
  {
    id: "DTUR",
    lcc: null,
    name: "DRD Turizem",
    type: "bus",
  },
  {
    id: "AUTOSTRAD",
    lcc: null,
    name: "Autostradale",
    type: "bus",
  },
  {
    id: "MONBUS",
    lcc: null,
    name: "Monbus",
    type: "bus",
  },
  {
    id: "AEXP",
    lcc: null,
    name: "Arlanda Express",
    type: "train",
  },
  {
    id: "CVIA",
    lcc: null,
    name: "Calanda Viaggi Reisen",
    type: "bus",
  },
  {
    id: "PETERPAN",
    lcc: null,
    name: "Peter Pan Bus",
    type: "bus",
  },
  {
    id: "FULLINGTON",
    lcc: null,
    name: "Fullington Trailways",
    type: "bus",
  },
  {
    id: "X3",
    lcc: 1,
    name: "TUI fly Deutschland",
    type: "airline",
  },
  {
    id: "VASTTRAFB",
    lcc: null,
    name: "Västtrafik Bus",
    type: "bus",
  },
  {
    id: "METROTUR",
    lcc: null,
    name: "Metro Turizm",
    type: "bus",
  },
  {
    id: "NR",
    lcc: null,
    name: "Manta Air",
    type: "airline",
  },
  {
    id: "IT",
    lcc: 0,
    name: "Tigerair Taiwan",
    type: "airline",
  },
  {
    id: "LM",
    lcc: 0,
    name: "LoganAir",
    type: "airline",
  },
  {
    id: "UT",
    lcc: 1,
    name: "UTair",
    type: "airline",
  },
  {
    id: "VR",
    lcc: 0,
    name: "Cabo Verde Airlines",
    type: "airline",
  },
  {
    id: "LQ",
    lcc: 1,
    name: "Lanmei Airlines",
    type: "airline",
  },
  {
    id: "GW",
    lcc: 0,
    name: "Costa Rica Green Airways",
    type: "airline",
  },
  {
    id: "BE",
    lcc: 1,
    name: "flybe",
    type: "airline",
  },
  {
    id: "VTTRANSLIN",
    lcc: null,
    name: "Vermont Translines",
    type: "bus",
  },
  {
    id: "CONSORZIO",
    lcc: null,
    name: "Consorzio Autolinee",
    type: "bus",
  },
  {
    id: "PEGA",
    lcc: null,
    name: "Pegasus",
    type: "bus",
  },
  {
    id: "TRANSALIS",
    lcc: null,
    name: "Transalis",
    type: "bus",
  },
  {
    id: "LITRAIL",
    lcc: null,
    name: "Lithuanian Railways",
    type: "train",
  },
  {
    id: "KROSATAGEN",
    lcc: null,
    name: "Krosatagen",
    type: "train",
  },
  {
    id: "TPRO",
    lcc: null,
    name: "TransfersPro",
    type: "bus",
  },
  {
    id: "LEON",
    lcc: null,
    name: "Leonettibus",
    type: "bus",
  },
  {
    id: "MBUZ",
    lcc: null,
    name: "MiniBuzz",
    type: "private_transfer",
  },
  {
    id: "PINK",
    lcc: null,
    name: "Pinkbus",
    type: "bus",
  },
  {
    id: "LCLT",
    lcc: null,
    name: "Limobus Co",
    type: "bus",
  },
  {
    id: "NBEA",
    lcc: null,
    name: "Navette paris-beauvais",
    type: "bus",
  },
  {
    id: "TOKS",
    lcc: null,
    name: "Toks",
    type: "bus",
  },
  {
    id: "TVERSKEXP",
    lcc: null,
    name: "Tverskoy Express",
    type: "train",
  },
  {
    id: "KKAV",
    lcc: null,
    name: "KTEL Kavala",
    type: "bus",
  },
  {
    id: "CTSR",
    lcc: null,
    name: "Caronna tour srl",
    type: "bus",
  },
  {
    id: "YELLOWTRAN",
    lcc: null,
    name: "Yellow Transfers",
    type: "bus",
  },
  {
    id: "AL",
    lcc: 0,
    name: "Malta Air",
    type: "airline",
  },
  {
    id: "ARRIVAUK",
    lcc: null,
    name: "Arriva United Kingdom",
    type: "bus",
  },
  {
    id: "15SHCOLTD",
    lcc: null,
    name: "15 SH Co. Ltd.",
    type: "bus",
  },
  {
    id: "BUS4LANKA",
    lcc: null,
    name: "Bus4Lanka",
    type: "bus",
  },
  {
    id: "REGIOJETB",
    lcc: 0,
    name: "Regiojet Bus",
    type: "bus",
  },
  {
    id: "GJ",
    lcc: 0,
    name: "Loong Air",
    type: "airline",
  },
  {
    id: "AERO",
    lcc: null,
    name: "Aeroexpress",
    type: "train",
  },
  {
    id: "OQ",
    lcc: 0,
    name: "Chongqing Airlines",
    type: "airline",
  },
  {
    id: "UU",
    lcc: 0,
    name: "Air Austral",
    type: "airline",
  },
  {
    id: "REUR",
    lcc: null,
    name: "Racic Eurobus",
    type: "bus",
  },
  {
    id: "SBNE",
    lcc: null,
    name: "SkyBus New Zealand",
    type: "bus",
  },
  {
    id: "NS",
    lcc: 0,
    name: "Hebei Airlines",
    type: "airline",
  },
  {
    id: "COMMUNITYC",
    lcc: null,
    name: "Community Coach",
    type: "bus",
  },
  {
    id: "SKANETRAFB",
    lcc: null,
    name: "Skanetrafiken bus",
    type: "bus",
  },
  {
    id: "STOU",
    lcc: null,
    name: "Simeonidis Tours",
    type: "bus",
  },
  {
    id: "SKBU",
    lcc: null,
    name: "SKYBUS",
    type: "bus",
  },
  {
    id: "ISAJ",
    lcc: null,
    name: "Inter Saj",
    type: "bus",
  },
  {
    id: "SKANETRAFT",
    lcc: null,
    name: "Skanetrafiken train",
    type: "train",
  },
  {
    id: "VASTTRAFT",
    lcc: null,
    name: "Västtrafik Train",
    type: "train",
  },
  {
    id: "NATRAIL",
    lcc: null,
    name: "National Rail",
    type: "train",
  },
  {
    id: "NI",
    lcc: 0,
    name: "Portugalia",
    type: "airline",
  },
  {
    id: "KY",
    lcc: 0,
    name: "Kunming Airlines",
    type: "airline",
  },
  {
    id: "ANEW",
    lcc: null,
    name: "Sky Bus",
    type: "bus",
  },
  {
    id: "ATNE",
    lcc: null,
    name: "Arda-Tur (National)",
    type: "bus",
  },
  {
    id: "N4",
    lcc: 0,
    name: "Nordwind Airlines",
    type: "airline",
  },
  {
    id: "RR",
    lcc: null,
    name: "Buzz",
    type: "airline",
  },
  {
    id: "UR",
    lcc: 0,
    name: "Uganda Airlines",
    type: "airline",
  },
  {
    id: "JLTT",
    lcc: null,
    name: "JLT train",
    type: "train",
  },
  {
    id: "MI",
    lcc: 0,
    name: "Freebird Airlines Europe",
    type: "airline",
  },
  {
    id: "CITYLINES",
    lcc: null,
    name: "City Lines",
    type: "private_transfer",
  },
  {
    id: "AAER",
    lcc: null,
    name: "Amsterdam Airport Express",
    type: "bus",
  },
  {
    id: "TDN",
    lcc: null,
    name: "Transportes del Norte",
    type: "bus",
  },
  {
    id: "CITL",
    lcc: null,
    name: "City Lines",
    type: "bus",
  },
  {
    id: "LABE",
    lcc: null,
    name: "Lasta",
    type: "bus",
  },
  {
    id: "FUTURA",
    lcc: null,
    name: "Futura",
    type: "bus",
  },
  {
    id: "ORIENTE",
    lcc: null,
    name: "Omnibus de Oriente",
    type: "bus",
  },
  {
    id: "ELITE",
    lcc: null,
    name: "Elite",
    type: "bus",
  },
  {
    id: "PACIFICO",
    lcc: null,
    name: "Pacifico",
    type: "bus",
  },
  {
    id: "ANAHUAC",
    lcc: null,
    name: "Anahuac",
    type: "bus",
  },
  {
    id: "TBUS",
    lcc: null,
    name: "Torres Bus",
    type: "bus",
  },
  {
    id: "VRLY",
    lcc: null,
    name: "Jet 747 Edinburgh",
    type: "bus",
  },
  {
    id: "RVP",
    lcc: null,
    name: "Sevenair",
    type: "airline",
  },
  {
    id: "JAMAIR",
    lcc: null,
    name: "Jam Air express",
    type: "airline",
  },
  {
    id: "H6",
    lcc: 0,
    name: "Bulgarian Air Charter",
    type: "airline",
  },
  {
    id: "EAF",
    lcc: null,
    name: "Electra Airways EAF",
    type: "airline",
  },
  {
    id: "FIUMICINO",
    lcc: null,
    name: "Fiumicino express",
    type: "bus",
  },
  {
    id: "AUBUS",
    lcc: null,
    name: "Autobuses Unidos",
    type: "bus",
  },
  {
    id: "FRONTERA",
    lcc: null,
    name: "Frontera",
    type: "bus",
  },
  {
    id: "OCC",
    lcc: null,
    name: "Omnibus Cristobal Colon",
    type: "bus",
  },
  {
    id: "POSTBUSAT",
    lcc: null,
    name: "Postbus Austria",
    type: "bus",
  },
  {
    id: "MAGICSHUT",
    lcc: null,
    name: "Magical Shuttle",
    type: "bus",
  },
  {
    id: "ADEC",
    lcc: null,
    name: "Air Decker",
    type: "bus",
  },
  {
    id: "OBB",
    lcc: null,
    name: "OBB",
    type: "train",
  },
  {
    id: "GTOURSSA",
    lcc: null,
    name: "Giosy tours SA",
    type: "bus",
  },
  {
    id: "ARPTSUPERS",
    lcc: null,
    name: "Airport Supersaver",
    type: "bus",
  },
  {
    id: "SMSFLUG",
    lcc: null,
    name: "SMS Flughafen",
    type: "bus",
  },
  {
    id: "XCOUNTRY",
    lcc: null,
    name: "CrossCountry",
    type: "train",
  },
  {
    id: "CHILTERN",
    lcc: null,
    name: "Chiltern Railways",
    type: "train",
  },
  {
    id: "LONDONOVER",
    lcc: null,
    name: "London Overground",
    type: "train",
  },
  {
    id: "SCOTRAIL",
    lcc: null,
    name: "ScotRail",
    type: "train",
  },
  {
    id: "GRANDCENTR",
    lcc: null,
    name: "Grand Central Railway",
    type: "train",
  },
  {
    id: "WALESRAIL",
    lcc: null,
    name: "Transport for Wales Rail Services",
    type: "train",
  },
  {
    id: "GWR",
    lcc: null,
    name: "Great Western Railway",
    type: "train",
  },
  {
    id: "C2C",
    lcc: null,
    name: "C2C",
    type: "train",
  },
  {
    id: "TPEXPRESS",
    lcc: null,
    name: "Transpennine Express",
    type: "train",
  },
  {
    id: "TFLRAIL",
    lcc: null,
    name: "TfL rail",
    type: "train",
  },
  {
    id: "SOUTHERN",
    lcc: null,
    name: "Southern Railway",
    type: "train",
  },
  {
    id: "CALEDONIAN",
    lcc: null,
    name: "Caledonian Sleeper",
    type: "train",
  },
  {
    id: "MERSEYRAIL",
    lcc: null,
    name: "Merseyrail",
    type: "train",
  },
  {
    id: "NORTHERN",
    lcc: null,
    name: "Northern Railway",
    type: "train",
  },
  {
    id: "LNER",
    lcc: null,
    name: "London North Eastern Railway",
    type: "train",
  },
  {
    id: "SOUTHWESTE",
    lcc: null,
    name: "South Western Railway",
    type: "train",
  },
  {
    id: "HULL",
    lcc: null,
    name: "Hull Trains",
    type: "train",
  },
  {
    id: "GREATERANG",
    lcc: null,
    name: "Greater Anglia",
    type: "train",
  },
  {
    id: "AVANTIWEST",
    lcc: null,
    name: "Avanti West Coast",
    type: "train",
  },
  {
    id: "GTR",
    lcc: null,
    name: "Govia Thameslink Railway",
    type: "train",
  },
  {
    id: "GRANDEXP",
    lcc: null,
    name: "Grand Express",
    type: "train",
  },
  {
    id: "EASTMIDLAN",
    lcc: null,
    name: "East Midlands Trains",
    type: "train",
  },
  {
    id: "WESTMIDLAN",
    lcc: null,
    name: "West Midlands Trains",
    type: "train",
  },
  {
    id: "SJRAIL",
    lcc: null,
    name: "Swedish Railways",
    type: "train",
  },
  {
    id: "SOUTHEASTE",
    lcc: null,
    name: "London South Eastern Railway",
    type: "train",
  },
  {
    id: "KORAIL",
    lcc: null,
    name: "Korail",
    type: "train",
  },
  {
    id: "EVABUS",
    lcc: null,
    name: "Eva bus",
    type: "bus",
  },
  {
    id: "GO",
    lcc: 0,
    name: "ULS Airlines Cargo",
    type: "airline",
  },
  {
    id: "SUDTHURING",
    lcc: null,
    name: "Sud-Thuringen-Bahn",
    type: "train",
  },
  {
    id: "ORLMIAMI",
    lcc: null,
    name: "BusLine Orlando Miami",
    type: "bus",
  },
  {
    id: "COACHUSA",
    lcc: null,
    name: "Coach USA",
    type: "bus",
  },
  {
    id: "EASYBUS",
    lcc: null,
    name: "easyBus",
    type: "bus",
  },
  {
    id: "9A",
    lcc: null,
    name: "GCA Airlines",
    type: "airline",
  },
  {
    id: "AVLO",
    lcc: null,
    name: "AVLO",
    type: "train",
  },
  {
    id: "TRANSALTI",
    lcc: null,
    name: "Transaltitude",
    type: "bus",
  },
  {
    id: "GATWICKEXP",
    lcc: null,
    name: "Gatwick Express",
    type: "train",
  },
  {
    id: "TURIMEX",
    lcc: null,
    name: "Turimex",
    type: "bus",
  },
  {
    id: "SWANBROOK",
    lcc: null,
    name: "Swanbrook",
    type: "bus",
  },
  {
    id: "OUROEPRATA",
    lcc: null,
    name: "Ouro e Prata",
    type: "bus",
  },
  {
    id: "EUCATUR",
    lcc: null,
    name: "Eucatur",
    type: "bus",
  },
  {
    id: "FLYBAIR",
    lcc: null,
    name: "flyBAIR",
    type: "airline",
  },
  {
    id: "KASSELRT",
    lcc: null,
    name: "Kassel RegioTram",
    type: "train",
  },
  {
    id: "SWEG",
    lcc: null,
    name: "SWEG Südwestdeutsche Landesverkehrs",
    type: "train",
  },
  {
    id: "T40",
    lcc: null,
    name: "T40",
    type: "bus",
  },
  {
    id: "AUTOPROMET",
    lcc: null,
    name: "Autopromet Slunj",
    type: "bus",
  },
  {
    id: "WINDSTAR",
    lcc: null,
    name: "Windstar",
    type: "bus",
  },
  {
    id: "TRENTBARTO",
    lcc: null,
    name: "Trentbarton",
    type: "bus",
  },
  {
    id: "HARROGATE",
    lcc: null,
    name: "Harrogate Bus Company",
    type: "bus",
  },
  {
    id: "OBBICBUS",
    lcc: null,
    name: "OBB Intercity Bus",
    type: "bus",
  },
  {
    id: "SNALLTAGET",
    lcc: null,
    name: "Snalltaget",
    type: "train",
  },
  {
    id: "KEIGHLEY",
    lcc: null,
    name: "Keighley Bus Company",
    type: "bus",
  },
  {
    id: "CAROUSEL",
    lcc: null,
    name: "Carousel",
    type: "bus",
  },
  {
    id: "WITCHWAY",
    lcc: null,
    name: "The Witch Way",
    type: "bus",
  },
  {
    id: "KERNOWBUS",
    lcc: null,
    name: "Kernow Bus",
    type: "bus",
  },
  {
    id: "ROBERTQBUS",
    lcc: null,
    name: "Robert Q Airbus",
    type: "bus",
  },
  {
    id: "GOTRANSITB",
    lcc: null,
    name: "GO Transit bus",
    type: "bus",
  },
  {
    id: "GOAHEADNOR",
    lcc: null,
    name: "GoAhead Nordic",
    type: "train",
  },
  {
    id: "VALLEYTRAN",
    lcc: null,
    name: "Valley Transit",
    type: "bus",
  },
  {
    id: "BUSTANG",
    lcc: null,
    name: "Bustang",
    type: "bus",
  },
  {
    id: "MONTEREYSA",
    lcc: null,
    name: "Monterey Salinas Transit",
    type: "bus",
  },
  {
    id: "MARTZTRAIL",
    lcc: null,
    name: "Martz Trailways",
    type: "bus",
  },
  {
    id: "BEELINEEXP",
    lcc: null,
    name: "Bee Line Express",
    type: "bus",
  },
  {
    id: "DELTABUS",
    lcc: null,
    name: "Delta Bus Lines",
    type: "bus",
  },
  {
    id: "LAMERSBUS",
    lcc: null,
    name: "Lamers Bus Line",
    type: "bus",
  },
  {
    id: "EXPRARROW",
    lcc: null,
    name: "Express Arrow",
    type: "bus",
  },
  {
    id: "ALLABOARD",
    lcc: null,
    name: "All Aboard America",
    type: "bus",
  },
  {
    id: "KLTB",
    lcc: null,
    name: "KLT - Kalmar Lanstrafik bus",
    type: "bus",
  },
  {
    id: "SORMLANDB",
    lcc: null,
    name: "Sormlandstrafiken bus",
    type: "bus",
  },
  {
    id: "SLB",
    lcc: null,
    name: "Stockholm Public Transport bus",
    type: "bus",
  },
  {
    id: "VLB",
    lcc: null,
    name: "Vastmanlands Lokaltrafik bus",
    type: "bus",
  },
  {
    id: "TAGAB",
    lcc: null,
    name: "Tagab",
    type: "train",
  },
  {
    id: "5P",
    lcc: 0,
    name: "Panorama Airways",
    type: "airline",
  },
  {
    id: "LTOREBROB",
    lcc: null,
    name: "LT Orebro bus",
    type: "bus",
  },
  {
    id: "OSTGOTAT",
    lcc: null,
    name: "Ostgotatrafiken train",
    type: "train",
  },
  {
    id: "KUSTPILEN",
    lcc: null,
    name: "Kustpilen",
    type: "train",
  },
  {
    id: "ULB",
    lcc: null,
    name: "Public Traffic Uppland bus",
    type: "bus",
  },
  {
    id: "VARMLANDB",
    lcc: null,
    name: "Varmlandstrafik bus",
    type: "bus",
  },
  {
    id: "XTRAFIKB",
    lcc: null,
    name: "X-trafik bus",
    type: "bus",
  },
  {
    id: "CITIEXPRES",
    lcc: null,
    name: "Citi Express",
    type: "bus",
  },
  {
    id: "XTRAFIKT",
    lcc: null,
    name: "X-trafik train",
    type: "train",
  },
  {
    id: "REDEEXPRES",
    lcc: null,
    name: "Rede Expressos",
    type: "bus",
  },
  {
    id: "CRUZDELSUR",
    lcc: null,
    name: "Cruz del Sur",
    type: "bus",
  },
  {
    id: "SOMERSET",
    lcc: null,
    name: "The Buses of Somerset",
    type: "bus",
  },
  {
    id: "E9",
    lcc: 0,
    name: "Iberojet Airlines",
    type: "airline",
  },
  {
    id: "QG",
    lcc: 1,
    name: "Citilink",
    type: "airline",
  },
  {
    id: "WK",
    lcc: 0,
    name: "Edelweiss Air",
    type: "airline",
  },
  {
    id: "BURLINGTON",
    lcc: null,
    name: "Burlington Trailways",
    type: "bus",
  },
  {
    id: "ARDATUR",
    lcc: null,
    name: "Arda Tur",
    type: "bus",
  },
  {
    id: "OXFORDTUBE",
    lcc: null,
    name: "Oxford Tube",
    type: "bus",
  },
  {
    id: "CHRISTRAN",
    lcc: null,
    name: "Christian Transfers",
    type: "bus",
  },
  {
    id: "AUTNA",
    lcc: null,
    name: "Autna SL - Spain",
    type: "bus",
  },
  {
    id: "MEGABUSB",
    lcc: null,
    name: "Megabus bus",
    type: "bus",
  },
  {
    id: "BARONSBUS",
    lcc: null,
    name: "Barons Bus",
    type: "bus",
  },
  {
    id: "NYTRAILWAY",
    lcc: null,
    name: "New York Trailways",
    type: "bus",
  },
  {
    id: "HIGHPEAK",
    lcc: null,
    name: "High Peak",
    type: "bus",
  },
  {
    id: "BATHBUS",
    lcc: null,
    name: "Bath Bus Company",
    type: "bus",
  },
  {
    id: "POLREGIO",
    lcc: null,
    name: "PolRegio",
    type: "train",
  },
  {
    id: "VRRAIL",
    lcc: null,
    name: "VR",
    type: "train",
  },
  {
    id: "MEGABUST",
    lcc: null,
    name: "Megabus train",
    type: "train",
  },
  {
    id: "RENEX",
    lcc: null,
    name: "Renex",
    type: "bus",
  },
  {
    id: "ARRIVADKT",
    lcc: null,
    name: "Arriva Denmark train",
    type: "train",
  },
  {
    id: "LOKALTOG",
    lcc: null,
    name: "Lokaltog",
    type: "train",
  },
  {
    id: "OY",
    lcc: 0,
    name: "Andes Líneas Aéreas",
    type: "airline",
  },
  {
    id: "3B",
    lcc: 0,
    name: "Binter Cabo Verde",
    type: "airline",
  },
  {
    id: "FQ",
    lcc: 0,
    name: "Thomas Cook Airlines",
    type: "airline",
  },
  {
    id: "GOLDENCOAC",
    lcc: null,
    name: "Golden Coach Express",
    type: "bus",
  },
  {
    id: "WONDERPERU",
    lcc: null,
    name: "Wonder Peru",
    type: "bus",
  },
  {
    id: "JN",
    lcc: 0,
    name: "JOON",
    type: "airline",
  },
  {
    id: "VK",
    lcc: 0,
    name: "LEVEL EUROPE DO NOT USE",
    type: "airline",
  },
  {
    id: "HA",
    lcc: 0,
    name: "Hawaiian Airlines",
    type: "airline",
  },
  {
    id: "VS",
    lcc: 0,
    name: "Virgin Atlantic Airways",
    type: "airline",
  },
  {
    id: "Z2",
    lcc: 0,
    name: "Philippines AirAsia",
    type: "airline",
  },
  {
    id: "LS",
    lcc: 1,
    name: "Jet2",
    type: "airline",
  },
  {
    id: "5J",
    lcc: 1,
    name: "Cebu Pacific",
    type: "airline",
  },
  {
    id: "4U",
    lcc: 1,
    name: "germanwings",
    type: "airline",
  },
  {
    id: "LZ",
    lcc: 0,
    name: "belleair",
    type: "airline",
  },
  {
    id: "VY",
    lcc: 1,
    name: "Vueling",
    type: "airline",
  },
  {
    id: "X9",
    lcc: 0,
    name: "Avion Express",
    type: "airline",
  },
  {
    id: "PS",
    lcc: 0,
    name: "Ukraine International Airlines",
    type: "airline",
  },
  {
    id: "SESTAGES",
    lcc: null,
    name: "Southeastern Stages",
    type: "bus",
  },
  {
    id: "TP",
    lcc: 0,
    name: "TAP Portugal",
    type: "airline",
  },
  {
    id: "ND",
    lcc: 0,
    name: "Nordica",
    type: "airline",
  },
  {
    id: "P4",
    lcc: 0,
    name: "Air Peace Limited",
    type: "airline",
  },
  {
    id: "I5",
    lcc: 0,
    name: "AirAsia India",
    type: "airline",
  },
  {
    id: "NT",
    lcc: 1,
    name: "Binter Canarias",
    type: "airline",
  },
  {
    id: "LAPANLAPAN",
    lcc: null,
    name: "Lapan Lapan",
    type: "bus",
  },
  {
    id: "CONCORDE",
    lcc: null,
    name: "Concorde",
    type: "bus",
  },
  {
    id: "SUPERNICEG",
    lcc: null,
    name: "Supernice Grassland",
    type: "bus",
  },
  {
    id: "ARCHIPELAG",
    lcc: null,
    name: "Archipelago Travel",
    type: "bus",
  },
  {
    id: "INKAEXPRES",
    lcc: null,
    name: "Inka Express",
    type: "bus",
  },
  {
    id: "LECEYLAN",
    lcc: null,
    name: "Le Ceylan",
    type: "bus",
  },
  {
    id: "BARRIANNTR",
    lcc: null,
    name: "Barri Ann Travel",
    type: "bus",
  },
  {
    id: "NK",
    lcc: 1,
    name: "Spirit Airlines",
    type: "airline",
  },
  {
    id: "LNH",
    lcc: null,
    name: "Lanhsa Airlines",
    type: "airline",
  },
  {
    id: "PQ",
    lcc: 0,
    name: "SkyUp Airlines",
    type: "airline",
  },
  {
    id: "OGN",
    lcc: null,
    name: "Origin Air",
    type: "airline",
  },
  {
    id: "IL",
    lcc: null,
    name: "Trigana Air",
    type: "airline",
  },
  {
    id: "TO",
    lcc: 0,
    name: "Transavia France",
    type: "airline",
  },
  {
    id: "LTR",
    lcc: null,
    name: "Lufttransport",
    type: "airline",
  },
  {
    id: "NIS",
    lcc: null,
    name: "La Costena",
    type: "airline",
  },
  {
    id: "HV",
    lcc: 1,
    name: "Transavia",
    type: "airline",
  },
  {
    id: "HHI",
    lcc: null,
    name: "Air41",
    type: "airline",
  },
  {
    id: "PNP",
    lcc: null,
    name: "Pineapple Air",
    type: "airline",
  },
  {
    id: "7P",
    lcc: 1,
    name: "AirPanama",
    type: "airline",
  },
  {
    id: "AZ",
    lcc: 0,
    name: "ITA Airways",
    type: "airline",
  },
  {
    id: "LJ",
    lcc: 1,
    name: "Jin Air",
    type: "airline",
  },
  {
    id: "IOS",
    lcc: null,
    name: "Scilly Skybus",
    type: "airline",
  },
  {
    id: "7C",
    lcc: 1,
    name: "Jeju Air",
    type: "airline",
  },
  {
    id: "SMM",
    lcc: null,
    name: "Summit Air",
    type: "airline",
  },
  {
    id: "Q6",
    lcc: 0,
    name: "Volaris Costa Rica",
    type: "airline",
  },
  {
    id: "ACK",
    lcc: null,
    name: "Nantucket Airlines",
    type: "airline",
  },
  {
    id: "E7",
    lcc: null,
    name: "Equaflight Services",
    type: "airline",
  },
  {
    id: "J2",
    lcc: 0,
    name: "Azerbaijan Airlines",
    type: "airline",
  },
  {
    id: "SJ",
    lcc: 0,
    name: "Sriwijaya Air",
    type: "airline",
  },
  {
    id: "NWTRAILS",
    lcc: null,
    name: "Northwestern Trailways",
    type: "bus",
  },
  {
    id: "TROLLEYS",
    lcc: null,
    name: "Trolleys Inc",
    type: "bus",
  },
  {
    id: "SCRUZMT",
    lcc: null,
    name: "Santa Cruz Metro Transit",
    type: "bus",
  },
  {
    id: "LTAEXPRESS",
    lcc: null,
    name: "Land to Air Express",
    type: "bus",
  },
  {
    id: "JA",
    lcc: 0,
    name: "JetSMART",
    type: "airline",
  },
  {
    id: "TEZ",
    lcc: null,
    name: "Tez Jet",
    type: "airline",
  },
  {
    id: "VA",
    lcc: 1,
    name: "Virgin Australia Airlines",
    type: "airline",
  },
  {
    id: "MM",
    lcc: 1,
    name: "Peach Aviation",
    type: "airline",
  },
  {
    id: "KL",
    lcc: 0,
    name: "KLM Royal Dutch Airlines",
    type: "airline",
  },
  {
    id: "PANCAMATA",
    lcc: null,
    name: "Pancaran Matahari",
    type: "bus",
  },
  {
    id: "LONGTRAVEL",
    lcc: null,
    name: "The Long Travel",
    type: "bus",
  },
  {
    id: "AY",
    lcc: 0,
    name: "Finnair",
    type: "airline",
  },
  {
    id: "RK",
    lcc: 0,
    name: "Ryanair UK",
    type: "airline",
  },
  {
    id: "4T",
    lcc: 0,
    name: "4T",
    type: "airline",
  },
  {
    id: "AMX",
    lcc: null,
    name: "Amakusa Airlines",
    type: "airline",
  },
  {
    id: "CY",
    lcc: 0,
    name: "Cyprus Airways",
    type: "airline",
  },
  {
    id: "WN",
    lcc: 1,
    name: "Southwest Airline",
    type: "airline",
  },
  {
    id: "E5",
    lcc: 0,
    name: "Air Arabia Egypt",
    type: "airline",
  },
  {
    id: "5F",
    lcc: 1,
    name: "FLY ONE",
    type: "airline",
  },
  {
    id: "EXPRESOBR",
    lcc: null,
    name: "Expreso Brasilia",
    type: "bus",
  },
  {
    id: "COPETRAN",
    lcc: null,
    name: "Copetran",
    type: "bus",
  },
  {
    id: "KPBSEREMB",
    lcc: null,
    name: "KPB Seremban",
    type: "bus",
  },
  {
    id: "CISCO",
    lcc: null,
    name: "Cisco",
    type: "bus",
  },
  {
    id: "X1",
    lcc: null,
    name: "Hahn Air Technologies",
    type: "airline",
  },
  {
    id: "AMSTARTOUR",
    lcc: null,
    name: "American Star Tours",
    type: "bus",
  },
  {
    id: "CAPAREATR",
    lcc: null,
    name: "Capitol Area Transp System",
    type: "bus",
  },
  {
    id: "NORTHWESTB",
    lcc: null,
    name: "Northwest bus",
    type: "bus",
  },
  {
    id: "PCRESTBUS",
    lcc: null,
    name: "Pacific Crest Bus Lines",
    type: "bus",
  },
  {
    id: "QS",
    lcc: 1,
    name: "Smartwings",
    type: "airline",
  },
  {
    id: "PKP",
    lcc: null,
    name: "PKP Intercity",
    type: "train",
  },
  {
    id: "1L",
    lcc: null,
    name: "CitizenPlane",
    type: "airline",
  },
  {
    id: "YL",
    lcc: 0,
    name: "Libyan Wings",
    type: "airline",
  },
  {
    id: "PHILTRANCO",
    lcc: null,
    name: "Philtranco",
    type: "bus",
  },
  {
    id: "TRMTRAVEL",
    lcc: null,
    name: "Turmo Travel",
    type: "bus",
  },
  {
    id: "NALUANG",
    lcc: null,
    name: "Naluang",
    type: "bus",
  },
  {
    id: "ROYALEXP",
    lcc: null,
    name: "Royal Express",
    type: "bus",
  },
  {
    id: "DLTB",
    lcc: null,
    name: "DLTB",
    type: "bus",
  },
  {
    id: "AT",
    lcc: 0,
    name: "Royal Air Maroc",
    type: "airline",
  },
  {
    id: "MF",
    lcc: 0,
    name: "Xiamen Airlines",
    type: "airline",
  },
  {
    id: "DISARUEXP",
    lcc: null,
    name: "Disaru Expressway",
    type: "bus",
  },
  {
    id: "TG",
    lcc: 0,
    name: "Thai Airways International",
    type: "airline",
  },
  {
    id: "INTER2000",
    lcc: null,
    name: "Inter2000",
    type: "bus",
  },
  {
    id: "REPANAMA",
    lcc: null,
    name: "R&E Panama",
    type: "bus",
  },
  {
    id: "4B",
    lcc: 0,
    name: "BoutiqueAir",
    type: "airline",
  },
  {
    id: "MARITIME",
    lcc: null,
    name: "Maritime bus",
    type: "bus",
  },
  {
    id: "CA",
    lcc: 0,
    name: "Air China",
    type: "airline",
  },
  {
    id: "AV",
    lcc: 0,
    name: "Avianca",
    type: "airline",
  },
  {
    id: "ALIANZAIBE",
    lcc: null,
    name: "Alianza Iberica",
    type: "bus",
  },
  {
    id: "IHO",
    lcc: null,
    name: "748 Air Services",
    type: "airline",
  },
  {
    id: "U5",
    lcc: 0,
    name: "SkyUp MT",
    type: "airline",
  },
  {
    id: "ZP",
    lcc: 0,
    name: "Paranair",
    type: "airline",
  },
  {
    id: "4Y",
    lcc: null,
    name: "Eurowings Discover",
    type: "airline",
  },
  {
    id: "TRAVELEURO",
    lcc: null,
    name: "Traveleuro",
    type: "bus",
  },
  {
    id: "FB",
    lcc: 0,
    name: "Bulgaria Air",
    type: "airline",
  },
  {
    id: "EC",
    lcc: 0,
    name: "Easyjet Europe",
    type: "airline",
  },
  {
    id: "DUBLINEXP",
    lcc: null,
    name: "Dublin Express",
    type: "bus",
  },
  {
    id: "S7",
    lcc: 0,
    name: "S7 Airlines",
    type: "airline",
  },
  {
    id: "YQ",
    lcc: 0,
    name: "TAR Aerolineas",
    type: "airline",
  },
  {
    id: "DISCOVERY",
    lcc: null,
    name: "Discovery Travel",
    type: "bus",
  },
  {
    id: "DSGUNASEK",
    lcc: null,
    name: "DS Gunasekara Express Service",
    type: "bus",
  },
  {
    id: "DUYLONG",
    lcc: null,
    name: "Duy Long",
    type: "bus",
  },
  {
    id: "B2",
    lcc: 0,
    name: "Belavia Belarusian Airlines",
    type: "airline",
  },
  {
    id: "G2",
    lcc: null,
    name: "GullivAir",
    type: "airline",
  },
  {
    id: "H4",
    lcc: null,
    name: "HiSky Europe",
    type: "airline",
  },
  {
    id: "DDBUSSEWA",
    lcc: null,
    name: "Desh Darshan Bus Sewa",
    type: "bus",
  },
  {
    id: "AIRMLTOUR",
    lcc: null,
    name: "Air Muang Loei Tour",
    type: "bus",
  },
  {
    id: "3O",
    lcc: 0,
    name: "Air Arabia Maroc",
    type: "airline",
  },
  {
    id: "JKPTRANS",
    lcc: null,
    name: "JKP Transport",
    type: "bus",
  },
  {
    id: "BROWNTOUR",
    lcc: null,
    name: "Brown Tours",
    type: "bus",
  },
  {
    id: "UBE",
    lcc: null,
    name: "Bees Airline",
    type: "airline",
  },
  {
    id: "CATBAEXPR",
    lcc: null,
    name: "Cat Ba Express",
    type: "bus",
  },
  {
    id: "AGUIABR",
    lcc: null,
    name: "Aguia Branca",
    type: "bus",
  },
  {
    id: "CITRAL",
    lcc: null,
    name: "Citral",
    type: "bus",
  },
  {
    id: "SALUTARIS",
    lcc: null,
    name: "Salutaris",
    type: "bus",
  },
  {
    id: "FTO",
    lcc: null,
    name: "Tropic Ocean Airways",
    type: "airline",
  },
  {
    id: "L9",
    lcc: null,
    name: "Lumiwings",
    type: "airline",
  },
  {
    id: "MYANMARRA",
    lcc: null,
    name: "Myanmar Railways",
    type: "train",
  },
  {
    id: "VIOLETTET",
    lcc: null,
    name: "Violette Trains",
    type: "train",
  },
  {
    id: "BLUEEXP",
    lcc: null,
    name: "Blueline Express Train",
    type: "train",
  },
  {
    id: "KINGEXPRE",
    lcc: null,
    name: "King Express",
    type: "train",
  },
  {
    id: "NEWLIVITR",
    lcc: null,
    name: "New Livitrans",
    type: "train",
  },
  {
    id: "PODIMENIK",
    lcc: null,
    name: "Podi Menike Express",
    type: "train",
  },
  {
    id: "UDARATAME",
    lcc: null,
    name: "Udarata Menike Express",
    type: "train",
  },
  {
    id: "20DEJUNIO",
    lcc: null,
    name: "20 De Junio",
    type: "bus",
  },
  {
    id: "ABVIETNAM",
    lcc: null,
    name: "AB Vietnam Travel",
    type: "bus",
  },
  {
    id: "AIRPORTBU",
    lcc: null,
    name: "Airport Bus Phuket",
    type: "bus",
  },
  {
    id: "ALRASHITH",
    lcc: null,
    name: "Al Rashith Travels & Tours",
    type: "bus",
  },
  {
    id: "AMIHANBUS",
    lcc: null,
    name: "Amihan Bus",
    type: "bus",
  },
  {
    id: "ANAHUACSE",
    lcc: null,
    name: "Anahuac Select",
    type: "bus",
  },
  {
    id: "ANDESMAR",
    lcc: null,
    name: "Andesmar",
    type: "bus",
  },
  {
    id: "ARWANAEKS",
    lcc: null,
    name: "Arwana Ekspres",
    type: "bus",
  },
  {
    id: "ASIAEXPRE",
    lcc: null,
    name: "Asia Express",
    type: "bus",
  },
  {
    id: "ATWEXPRES",
    lcc: null,
    name: "ATW Express",
    type: "bus",
  },
  {
    id: "AUAUTOUNI",
    lcc: null,
    name: "AU (Autobuses Unidos)",
    type: "bus",
  },
  {
    id: "AUNGTHEIN",
    lcc: null,
    name: "Aung Thein Peik Express",
    type: "bus",
  },
  {
    id: "AUTOKINGD",
    lcc: null,
    name: "Auto Kingdom",
    type: "bus",
  },
  {
    id: "AUTOBUSES",
    lcc: null,
    name: "Autobuses del Evora",
    type: "bus",
  },
  {
    id: "AUTOTRAVE",
    lcc: null,
    name: "Autotravel",
    type: "bus",
  },
  {
    id: "BATAANTRA",
    lcc: null,
    name: "Bataan Transit",
    type: "bus",
  },
  {
    id: "BEACHTOUR",
    lcc: null,
    name: "Beach Tours Travel",
    type: "bus",
  },
  {
    id: "BICOLISAR",
    lcc: null,
    name: "Bicol Isarog",
    type: "bus",
  },
  {
    id: "CATAINTER",
    lcc: null,
    name: "Cata Internacional",
    type: "bus",
  },
  {
    id: "CBSTRANSP",
    lcc: null,
    name: "CBS Transport",
    type: "bus",
  },
  {
    id: "CERESTRAN",
    lcc: null,
    name: "Ceres Transport",
    type: "bus",
  },
  {
    id: "CHANTHACH",
    lcc: null,
    name: "Chanthachone Transportation",
    type: "bus",
  },
  {
    id: "CHOKEANAN",
    lcc: null,
    name: "Choke Anan Tours",
    type: "bus",
  },
  {
    id: "CONDOREST",
    lcc: null,
    name: "Condor Estrella",
    type: "bus",
  },
  {
    id: "CTGTOURS",
    lcc: null,
    name: "CTG Tours",
    type: "bus",
  },
  {
    id: "CUONGLAN",
    lcc: null,
    name: "Cuong Lan",
    type: "bus",
  },
  {
    id: "DAGUPANBU",
    lcc: null,
    name: "Dagupan Bus Co.",
    type: "bus",
  },
  {
    id: "DARULIMAN",
    lcc: null,
    name: "Darul Iman Express",
    type: "bus",
  },
  {
    id: "DATMOIBUS",
    lcc: null,
    name: "Dat Moi Bus",
    type: "bus",
  },
  {
    id: "DAVAOMETR",
    lcc: null,
    name: "Davao Metro Shuttle",
    type: "bus",
  },
  {
    id: "DCOMTRAVE",
    lcc: null,
    name: "DCOM Travel",
    type: "bus",
  },
  {
    id: "DELFINEST",
    lcc: null,
    name: "Delfines Tours",
    type: "bus",
  },
  {
    id: "DESHDARSH",
    lcc: null,
    name: "Desh Darshan Bus Sewa",
    type: "bus",
  },
  {
    id: "VIETNAMRA",
    lcc: null,
    name: "Vietnam Railways",
    type: "train",
  },
  {
    id: "ELPRACTIC",
    lcc: null,
    name: "El Practico",
    type: "bus",
  },
  {
    id: "ESTRELLAD",
    lcc: null,
    name: "Estrella de Oro",
    type: "bus",
  },
  {
    id: "ESTRELPLUS",
    lcc: null,
    name: "Estrella de Oro Pluss",
    type: "bus",
  },
  {
    id: "EXPRESOPA",
    lcc: null,
    name: "Expreso Paz de Rio",
    type: "bus",
  },
  {
    id: "EXTRALUXU",
    lcc: null,
    name: "Extra Luxury Coach",
    type: "bus",
  },
  {
    id: "GENESISTR",
    lcc: null,
    name: "Genesis Transport",
    type: "bus",
  },
  {
    id: "GMSAPA",
    lcc: null,
    name: "Good Morning Sapa",
    type: "bus",
  },
  {
    id: "JKPTRANSP",
    lcc: null,
    name: "JKP Transport",
    type: "bus",
  },
  {
    id: "JOYBUSEXE",
    lcc: null,
    name: "Joybus Executive Coach of Genesis",
    type: "bus",
  },
  {
    id: "MANDALARM",
    lcc: null,
    name: "Mandalar Minn",
    type: "bus",
  },
  {
    id: "MEKONGEXP",
    lcc: null,
    name: "Mekong Express",
    type: "bus",
  },
  {
    id: "MOUNTAINO",
    lcc: null,
    name: "Mountain Overland",
    type: "bus",
  },
  {
    id: "NGOCSON",
    lcc: null,
    name: "Ngoc Son",
    type: "bus",
  },
  {
    id: "ELRAPIDOD",
    lcc: null,
    name: "El Rapido Duitama",
    type: "bus",
  },
  {
    id: "FLOTAMAGD",
    lcc: null,
    name: "Flota Magdalena",
    type: "bus",
  },
  {
    id: "FULLMOONP",
    lcc: null,
    name: "Full Moon Party",
    type: "bus",
  },
  {
    id: "THANHLICH",
    lcc: null,
    name: "Thanh Lich",
    type: "bus",
  },
  {
    id: "HASONHAIV",
    lcc: null,
    name: "Ha Son Hai Van",
    type: "bus",
  },
  {
    id: "HMTRANSPO",
    lcc: null,
    name: "HM Transport",
    type: "bus",
  },
  {
    id: "HUETOURIS",
    lcc: null,
    name: "Hue Tourist",
    type: "bus",
  },
  {
    id: "MANMEXPRES",
    lcc: null,
    name: "Mandalar Minn Express",
    type: "bus",
  },
  {
    id: "MEYHONGTR",
    lcc: null,
    name: "Mey Hong Transport",
    type: "bus",
  },
  {
    id: "NACHA21",
    lcc: null,
    name: "Nakhonchai 21",
    type: "bus",
  },
  {
    id: "NOTOSTRAV",
    lcc: null,
    name: "Notos Travel",
    type: "bus",
  },
  {
    id: "PANGASINA",
    lcc: null,
    name: "Pangasinan Solid North Transit",
    type: "bus",
  },
  {
    id: "PHUCHAI",
    lcc: null,
    name: "Phuc Hai",
    type: "bus",
  },
  {
    id: "PLATABUS",
    lcc: null,
    name: "Platabus",
    type: "bus",
  },
  {
    id: "ENAPARIBA",
    lcc: null,
    name: "ENA Paribahan",
    type: "bus",
  },
  {
    id: "EXCLUCIVA",
    lcc: null,
    name: "Excluciva",
    type: "bus",
  },
  {
    id: "FIRSTNORT",
    lcc: null,
    name: "First North Luzon Transit",
    type: "bus",
  },
  {
    id: "FIVESTARB",
    lcc: null,
    name: "Five Star Bus",
    type: "bus",
  },
  {
    id: "GREENTOUR",
    lcc: null,
    name: "Greenline Tours",
    type: "bus",
  },
  {
    id: "HANHLUYEN",
    lcc: null,
    name: "Hanh Luyen",
    type: "bus",
  },
  {
    id: "HUNGDUC",
    lcc: null,
    name: "Hung Duc",
    type: "bus",
  },
  {
    id: "JACLINER",
    lcc: null,
    name: "JAC Liner",
    type: "bus",
  },
  {
    id: "JAMLINER",
    lcc: null,
    name: "JAM Liner",
    type: "bus",
  },
  {
    id: "LEGASPIST",
    lcc: null,
    name: "Legaspi St. Jude Transport Lines Inc.",
    type: "bus",
  },
  {
    id: "LIVIGNOEX",
    lcc: null,
    name: "Livigno Express",
    type: "bus",
  },
  {
    id: "MINHTHU",
    lcc: null,
    name: "Minh Thu",
    type: "bus",
  },
  {
    id: "NAGATRAVE",
    lcc: null,
    name: "Naga Travel",
    type: "bus",
  },
  {
    id: "NBOPENBUS",
    lcc: null,
    name: "NB Open Bus",
    type: "bus",
  },
  {
    id: "NEWROADTR",
    lcc: null,
    name: "New Road Travels & Tours",
    type: "bus",
  },
  {
    id: "OURLADYOF",
    lcc: null,
    name: "Our Lady of Salvacion Bus Line Inc.",
    type: "bus",
  },
  {
    id: "PINTADOS",
    lcc: null,
    name: "Pintados",
    type: "bus",
  },
  {
    id: "ETPUMPKIN",
    lcc: null,
    name: "Et-Pumpkin",
    type: "bus",
  },
  {
    id: "FAMETOURS",
    lcc: null,
    name: "Fame Tours & Services",
    type: "bus",
  },
  {
    id: "GMCATBA",
    lcc: null,
    name: "Good Morning Cat Ba",
    type: "bus",
  },
  {
    id: "HATRANG",
    lcc: null,
    name: "Ha Trang",
    type: "bus",
  },
  {
    id: "INTERBUSL",
    lcc: null,
    name: "Interbuslines",
    type: "bus",
  },
  {
    id: "KANCHANAB",
    lcc: null,
    name: "Kanchanaburi Express",
    type: "bus",
  },
  {
    id: "KETDOAN",
    lcc: null,
    name: "Ket Doan",
    type: "bus",
  },
  {
    id: "KHAINGMAN",
    lcc: null,
    name: "Khaing Mandalay Express",
    type: "bus",
  },
  {
    id: "KIMCHI265",
    lcc: null,
    name: "Kim Chi 265",
    type: "bus",
  },
  {
    id: "KUMHOVIET",
    lcc: null,
    name: "Kumho Viet Thanh",
    type: "bus",
  },
  {
    id: "LAOSGROUP",
    lcc: null,
    name: "Laos Group Tour",
    type: "bus",
  },
  {
    id: "MANHHA",
    lcc: null,
    name: "Manh Ha",
    type: "bus",
  },
  {
    id: "MAYANCARI",
    lcc: null,
    name: "Mayan Caribbean Travel",
    type: "bus",
  },
  {
    id: "MUTHUTRAV",
    lcc: null,
    name: "Muthu Travels",
    type: "bus",
  },
  {
    id: "MYATMANDA",
    lcc: null,
    name: "Myat Mandalar Tun Express",
    type: "bus",
  },
  {
    id: "NACHATOUR",
    lcc: null,
    name: "Nakhonchai Tour",
    type: "bus",
  },
  {
    id: "FARINASTR",
    lcc: null,
    name: "Farinas Transit",
    type: "bus",
  },
  {
    id: "FLECHABUS",
    lcc: null,
    name: "Flecha Bus",
    type: "bus",
  },
  {
    id: "GREENSAPA",
    lcc: null,
    name: "Green Sapa Bus",
    type: "bus",
  },
  {
    id: "HASON",
    lcc: null,
    name: "Ha Son",
    type: "bus",
  },
  {
    id: "HONGAISON",
    lcc: null,
    name: "Hongai Son Duc",
    type: "bus",
  },
  {
    id: "JAGADAMBA",
    lcc: null,
    name: "Jagadamba Travels",
    type: "bus",
  },
  {
    id: "KINGOFBUS",
    lcc: null,
    name: "King of Bus",
    type: "bus",
  },
  {
    id: "KONSORTIU",
    lcc: null,
    name: "Konsortium (Malaysia)",
    type: "bus",
  },
  {
    id: "KRABIAIRP",
    lcc: null,
    name: "Krabi Airport Bus",
    type: "bus",
  },
  {
    id: "LONGVANLI",
    lcc: null,
    name: "Long Van Limousine",
    type: "bus",
  },
  {
    id: "MALANGIND",
    lcc: null,
    name: "Malang Indah Transindo",
    type: "bus",
  },
  {
    id: "MINHMAP",
    lcc: null,
    name: "Minh Map",
    type: "bus",
  },
  {
    id: "NGOCTHUAN",
    lcc: null,
    name: "Ngoc Thuan",
    type: "bus",
  },
  {
    id: "PENAFRANC",
    lcc: null,
    name: "Penafrancia Tours and Travel Transport",
    type: "bus",
  },
  {
    id: "PHILIPTRA",
    lcc: null,
    name: "Philip Travel",
    type: "bus",
  },
  {
    id: "PHUONGTHU",
    lcc: null,
    name: "Phuong Thu",
    type: "bus",
  },
  {
    id: "PLUSLINER",
    lcc: null,
    name: "Plusliner",
    type: "bus",
  },
  {
    id: "PLUSMAR",
    lcc: null,
    name: "Plusmar",
    type: "bus",
  },
  {
    id: "PORNPIRIY",
    lcc: null,
    name: "Porn Piriya Tour",
    type: "bus",
  },
  {
    id: "PPKOHSAME",
    lcc: null,
    name: "PP Koh Samed",
    type: "bus",
  },
  {
    id: "PRINCETRA",
    lcc: null,
    name: "Prince Travels",
    type: "bus",
  },
  {
    id: "PRITHIVIR",
    lcc: null,
    name: "Prithivi Rajmarga Bus Company",
    type: "bus",
  },
  {
    id: "PULLMANGE",
    lcc: null,
    name: "Pullman General Belgrano",
    type: "bus",
  },
  {
    id: "QUANGGIAN",
    lcc: null,
    name: "Quang Giang",
    type: "bus",
  },
  {
    id: "QUANGTUAN",
    lcc: null,
    name: "Quang Tuan",
    type: "bus",
  },
  {
    id: "QUETRIHUE",
    lcc: null,
    name: "Quetrihue Viajes y Turismo",
    type: "bus",
  },
  {
    id: "RAINBOWTR",
    lcc: null,
    name: "Rainbow Travels",
    type: "bus",
  },
  {
    id: "RAJATRAVE",
    lcc: null,
    name: "Raja Travels",
    type: "bus",
  },
  {
    id: "RAYONGTOU",
    lcc: null,
    name: "Rayong Tour",
    type: "bus",
  },
  {
    id: "ROROBUS",
    lcc: null,
    name: "RoRo Bus",
    type: "bus",
  },
  {
    id: "ROSAECOBU",
    lcc: null,
    name: "Rosa Eco Bus",
    type: "bus",
  },
  {
    id: "RSLBUS",
    lcc: null,
    name: "RSL Bus",
    type: "bus",
  },
  {
    id: "SAMARASEK",
    lcc: null,
    name: "Samarasekara Highway Powerline",
    type: "bus",
  },
  {
    id: "SAOVIET",
    lcc: null,
    name: "Sao Viet",
    type: "bus",
  },
  {
    id: "SAPASHUTT",
    lcc: null,
    name: "Sapa Shuttle Bus",
    type: "bus",
  },
  {
    id: "SAWASDEEE",
    lcc: null,
    name: "Sawasdee Esan Tour",
    type: "bus",
  },
  {
    id: "SHOHAGHPA",
    lcc: null,
    name: "Shohagh Paribahan",
    type: "bus",
  },
  {
    id: "SHUTTLEIT",
    lcc: null,
    name: "Shuttle Italy Airport",
    type: "bus",
  },
  {
    id: "SHWELUNPY",
    lcc: null,
    name: "Shwe Lun Pyan",
    type: "bus",
  },
  {
    id: "SHWEMANTH",
    lcc: null,
    name: "Shwe Man Thu",
    type: "bus",
  },
  {
    id: "SHWEPYILW",
    lcc: null,
    name: "Shwe Pyi Lwin",
    type: "bus",
  },
  {
    id: "SIMETBUSS",
    lcc: null,
    name: "Simet Bus S.P.A.",
    type: "bus",
  },
  {
    id: "SLTB",
    lcc: null,
    name: "SLTB",
    type: "bus",
  },
  {
    id: "SONGSERM",
    lcc: null,
    name: "Songserm",
    type: "bus",
  },
  {
    id: "SOUTHWEST",
    lcc: null,
    name: "Southwest Travel and Tours",
    type: "bus",
  },
  {
    id: "SRIMURUGA",
    lcc: null,
    name: "Sri Murugan Travels",
    type: "bus",
  },
  {
    id: "SRIRACHAT",
    lcc: null,
    name: "Sriracha Tour",
    type: "bus",
  },
  {
    id: "SUPER88EX",
    lcc: null,
    name: "Super 88 Express",
    type: "bus",
  },
  {
    id: "SUVARNABH",
    lcc: null,
    name: "Suvarnabhumi Burapha Bus",
    type: "bus",
  },
  {
    id: "SWIFTHOLI",
    lcc: null,
    name: "SWIFT Holidays Private LTD",
    type: "bus",
  },
  {
    id: "TANKIMCHI",
    lcc: null,
    name: "Tan Kim Chi",
    type: "bus",
  },
  {
    id: "TERMINALG",
    lcc: null,
    name: "Terminal Green",
    type: "bus",
  },
  {
    id: "TFFRONTER",
    lcc: null,
    name: "TF Frontera",
    type: "bus",
  },
  {
    id: "CHAMPAMEK",
    lcc: null,
    name: "Champa Mekong Express",
    type: "bus",
  },
  {
    id: "TNKTRAVEL",
    lcc: null,
    name: "TNK Travel",
    type: "bus",
  },
  {
    id: "VIETNHAT",
    lcc: null,
    name: "Viet Nhat",
    type: "bus",
  },
  {
    id: "YOMAMANDA",
    lcc: null,
    name: "Yoma Mandalar Express",
    type: "bus",
  },
  {
    id: "DUPTOURS",
    lcc: null,
    name: "Duptours",
    type: "bus",
  },
  {
    id: "TOURWITHT",
    lcc: null,
    name: "Tour with Thai",
    type: "bus",
  },
  {
    id: "VIETNAMTR",
    lcc: null,
    name: "Vietnam Transports",
    type: "bus",
  },
  {
    id: "TRANSSALV",
    lcc: null,
    name: "Trans Salvador",
    type: "bus",
  },
  {
    id: "TRAVELMAR",
    lcc: null,
    name: "Travel Mart",
    type: "bus",
  },
  {
    id: "VELOTAX",
    lcc: null,
    name: "Velotax",
    type: "bus",
  },
  {
    id: "XENHA",
    lcc: null,
    name: "Xe Nha",
    type: "bus",
  },
  {
    id: "CARIBESHU",
    lcc: null,
    name: "Caribe Shuttle",
    type: "bus",
  },
  {
    id: "SINDICATO",
    lcc: null,
    name: "Sindicato Lázaro Cárdenas",
    type: "bus",
  },
  {
    id: "XINHXINH",
    lcc: null,
    name: "Xinh Xinh",
    type: "bus",
  },
  {
    id: "RUTER",
    lcc: null,
    name: "Ruter",
    type: "bus",
  },
  {
    id: "ITABUSSPA",
    lcc: null,
    name: "Itabus Spa",
    type: "bus",
  },
  {
    id: "CZ",
    lcc: 0,
    name: "China Southern Airlines",
    type: "airline",
  },
  {
    id: "KN",
    lcc: 0,
    name: "China United",
    type: "airline",
  },
  {
    id: "SKYALPS",
    lcc: null,
    name: "Sky Alps",
    type: "airline",
  },
  {
    id: "KKKLTAT",
    lcc: null,
    name: "KKKL Travel & Tour",
    type: "bus",
  },
  {
    id: "MANHQUAN",
    lcc: null,
    name: "Manh Quan",
    type: "bus",
  },
  {
    id: "SQS",
    lcc: null,
    name: "Susi Air",
    type: "airline",
  },
  {
    id: "WH",
    lcc: 0,
    name: "WDL Aviation",
    type: "airline",
  },
  {
    id: "7W",
    lcc: 0,
    name: "Windrose Airlines",
    type: "airline",
  },
  {
    id: "SRIMAJUEX",
    lcc: null,
    name: "Sri Maju Express",
    type: "bus",
  },
  {
    id: "SRIMAJUKAN",
    lcc: null,
    name: "Sri Maju Kangar",
    type: "bus",
  },
  {
    id: "FLOTAOCCID",
    lcc: null,
    name: "Flota Occidental",
    type: "bus",
  },
  {
    id: "TICABUS",
    lcc: null,
    name: "Tica Bus",
    type: "bus",
  },
  {
    id: "SUNBUS",
    lcc: null,
    name: "Sun Bus",
    type: "bus",
  },
  {
    id: "TRNSDELSOL",
    lcc: null,
    name: "Transporte del Sol",
    type: "bus",
  },
  {
    id: "LEVELSPAIN",
    lcc: null,
    name: "IBERIA for LEVEL SPAIN",
    type: "airline",
  },
  {
    id: "EUCOACHCM",
    lcc: null,
    name: "Euroscoach By Cm Tours",
    type: "bus",
  },
  {
    id: "PRM",
    lcc: null,
    name: "Premier Airlines",
    type: "airline",
  },
  {
    id: "RNG",
    lcc: null,
    name: "Renegade Air",
    type: "airline",
  },
  {
    id: "YC",
    lcc: 1,
    name: "Yamal Air",
    type: "airline",
  },
  {
    id: "JUMBOTDO",
    lcc: null,
    name: "Jumbo Tours Dominican Republic",
    type: "bus",
  },
  {
    id: "TRANSGH",
    lcc: null,
    name: "Transportes Gomez Hernandez",
    type: "bus",
  },
  {
    id: "FLOTALAMAC",
    lcc: null,
    name: "Flota La Macarena",
    type: "bus",
  },
  {
    id: "GIPSYY",
    lcc: null,
    name: "Gipsyy",
    type: "bus",
  },
  {
    id: "TWHSRAIL",
    lcc: null,
    name: "Taiwan High Speed Rail",
    type: "train",
  },
  {
    id: "WINCHKVAN",
    lcc: null,
    name: "Win Chang Kui Van",
    type: "bus",
  },
  {
    id: "CHEVALLIER",
    lcc: null,
    name: "Chevallier",
    type: "bus",
  },
  {
    id: "RIOURUG",
    lcc: null,
    name: "Rio Uruguay",
    type: "bus",
  },
  {
    id: "VOSA",
    lcc: null,
    name: "Vosa",
    type: "bus",
  },
  {
    id: "SARDABUS",
    lcc: null,
    name: "Sardabus",
    type: "bus",
  },
  {
    id: "SLD",
    lcc: null,
    name: "Silver Air",
    type: "airline",
  },
  {
    id: "3Z",
    lcc: null,
    name: "Smartwings Poland",
    type: "airline",
  },
  {
    id: "AIRCOACH",
    lcc: null,
    name: "Aircoach",
    type: "bus",
  },
  {
    id: "WSG",
    lcc: null,
    name: "Wasaya Airways",
    type: "airline",
  },
  {
    id: "UEPFLY",
    lcc: null,
    name: "UEP Fly",
    type: "airline",
  },
  {
    id: "EZZ",
    lcc: null,
    name: "ETF Airways",
    type: "airline",
  },
  {
    id: "SA",
    lcc: 0,
    name: "South African Airways",
    type: "airline",
  },
  {
    id: "GRUPOJULIA",
    lcc: null,
    name: "Grupo Julia",
    type: "bus",
  },
  {
    id: "PRJ",
    lcc: null,
    name: "Prishtina JET",
    type: "airline",
  },
  {
    id: "SLORAIL",
    lcc: null,
    name: "Slovenian Railways",
    type: "train",
  },
  {
    id: "UNIONIV",
    lcc: null,
    name: "Union Ivkoni",
    type: "bus",
  },
  {
    id: "XK",
    lcc: 0,
    name: "Air Corsica",
    type: "airline",
  },
  {
    id: "XP",
    lcc: null,
    name: "Avelo",
    type: "airline",
  },
  {
    id: "A7",
    lcc: 0,
    name: "Calafia Airlines",
    type: "airline",
  },
  {
    id: "MNE",
    lcc: null,
    name: "Air Montenegro",
    type: "airline",
  },
  {
    id: "JIMENEZ",
    lcc: null,
    name: "Jimenez Dorado Autocares",
    type: "bus",
  },
  {
    id: "WZ",
    lcc: 1,
    name: "Red Wings",
    type: "airline",
  },
  {
    id: "FHM",
    lcc: null,
    name: "Freebird Airlines Europe",
    type: "airline",
  },
  {
    id: "EYBUS",
    lcc: null,
    name: "Etihad Bus",
    type: "bus",
  },
  {
    id: "ARRIVACZT",
    lcc: null,
    name: "Arriva CZ train",
    type: "train",
  },
  {
    id: "GWTRAIN",
    lcc: null,
    name: "GW Train Regio",
    type: "train",
  },
  {
    id: "GLX",
    lcc: null,
    name: "Global crossing airlines",
    type: "airline",
  },
  {
    id: "GXA",
    lcc: null,
    name: "Global Crossing Airlines",
    type: "airline",
  },
  {
    id: "HUNISPHERE",
    lcc: null,
    name: "Hunisphere",
    type: "bus",
  },
  {
    id: "PRINDOSCAM",
    lcc: null,
    name: "Princesa dos Campos",
    type: "bus",
  },
  {
    id: "9D",
    lcc: null,
    name: "Genghis Khan Airlines",
    type: "airline",
  },
  {
    id: "SJB",
    lcc: null,
    name: "SKS Airways",
    type: "airline",
  },
  {
    id: "IU",
    lcc: null,
    name: "Super Air Jet",
    type: "airline",
  },
  {
    id: "PK",
    lcc: 0,
    name: "Pakistan International Airlines",
    type: "airline",
  },
  {
    id: "QP",
    lcc: null,
    name: "Akasa Air",
    type: "airline",
  },
  {
    id: "AOA",
    lcc: null,
    name: "Air Ocean Airlines",
    type: "airline",
  },
  {
    id: "OA",
    lcc: 0,
    name: "Olympic Air",
    type: "airline",
  },
  {
    id: "TELAVIVAIR",
    lcc: null,
    name: "Tel Aviv Air",
    type: "airline",
  },
  {
    id: "HELLOFLY",
    lcc: null,
    name: "HelloFly",
    type: "airline",
  },
  {
    id: "LOD",
    lcc: null,
    name: "L'Odyssey",
    type: "airline",
  },
  {
    id: "LIT",
    lcc: null,
    name: "FlyleOne",
    type: "airline",
  },
  {
    id: "EY",
    lcc: 0,
    name: "Etihad Airways",
    type: "airline",
  },
  {
    id: "JRCENTRAL",
    lcc: null,
    name: "JR Central",
    type: "train",
  },
  {
    id: "MYRAIL",
    lcc: null,
    name: "Malaysian Railways",
    type: "train",
  },
  {
    id: "PARHIKUNI",
    lcc: null,
    name: "Parhikuni",
    type: "bus",
  },
  {
    id: "ROMALINEE",
    lcc: null,
    name: "Roma Linee",
    type: "bus",
  },
  {
    id: "SKYLYNX",
    lcc: null,
    name: "Skylynx",
    type: "bus",
  },
  {
    id: "DAMARIS",
    lcc: null,
    name: "Damaris Express",
    type: "bus",
  },
  {
    id: "BORKAEXPR",
    lcc: null,
    name: "Borka Express",
    type: "bus",
  },
  {
    id: "ORANGELINE",
    lcc: null,
    name: "Orange Lines",
    type: "bus",
  },
  {
    id: "TMB",
    lcc: null,
    name: "Transports Metropolitans de Barcelona",
    type: "bus",
  },
  {
    id: "LETSVAN",
    lcc: null,
    name: "Let's Van",
    type: "bus",
  },
  {
    id: "DAROJKOVIC",
    lcc: null,
    name: "Darojkovic Promet",
    type: "bus",
  },
  {
    id: "TRAVENTUR",
    lcc: null,
    name: "Traventuria",
    type: "bus",
  },
  {
    id: "TIMPREMIER",
    lcc: null,
    name: "Tim Premier",
    type: "bus",
  },
  {
    id: "TRANSTUR",
    lcc: null,
    name: "Transturist",
    type: "bus",
  },
  {
    id: "KTELRHODES",
    lcc: null,
    name: "KTEL Rhodes",
    type: "bus",
  },
  {
    id: "EWEUROLINE",
    lcc: null,
    name: "East West Eurolines",
    type: "bus",
  },
  {
    id: "ALPYBUS",
    lcc: null,
    name: "AlpyBus",
    type: "bus",
  },
  {
    id: "Q4",
    lcc: null,
    name: "Euroairlines",
    type: "airline",
  },
  {
    id: "SSJ",
    lcc: null,
    name: "KrasAvia",
    type: "airline",
  },
  {
    id: "SARPA",
    lcc: null,
    name: "Sarpa",
    type: "airline",
  },
  {
    id: "O1",
    lcc: 0,
    name: "Orbit Airlines Azerbaijan",
    type: "airline",
  },
  {
    id: "GJT",
    lcc: null,
    name: "GetJet",
    type: "airline",
  },
  {
    id: "NICEAIR",
    lcc: null,
    name: "Niceair",
    type: "airline",
  },
  {
    id: "ALSAMARRAK",
    lcc: null,
    name: "Alsa Marrakech Bus Touristique",
    type: "bus",
  },
  {
    id: "HST",
    lcc: null,
    name: "Heston Airlines",
    type: "airline",
  },
  {
    id: "4J",
    lcc: null,
    name: "Jetair Caribbean",
    type: "airline",
  },
  {
    id: "BAR",
    lcc: null,
    name: "BAR Aviation",
    type: "airline",
  },
  {
    id: "RLB",
    lcc: null,
    name: "Sunlight Air",
    type: "airline",
  },
  {
    id: "ZG",
    lcc: 0,
    name: "ZIPAIR",
    type: "airline",
  },
  {
    id: "FNA",
    lcc: null,
    name: "Norlandair",
    type: "airline",
  },
  {
    id: "ORIGIN",
    lcc: null,
    name: "Origin Air",
    type: "airline",
  },
  {
    id: "LAROCA",
    lcc: null,
    name: "La Roca Shopping Bus",
    type: "bus",
  },
  {
    id: "BILMANBUS",
    lcc: null,
    name: "Bilman Bus",
    type: "bus",
  },
  {
    id: "OZEROUTE",
    lcc: null,
    name: "OzeRoute",
    type: "bus",
  },
  {
    id: "N3",
    lcc: null,
    name: "Volaris El Salvador",
    type: "airline",
  },
  {
    id: "SUBUS",
    lcc: null,
    name: "Subus",
    type: "bus",
  },
  {
    id: "BA",
    lcc: 0,
    name: "British Airways",
    type: "airline",
  },
  {
    id: "TGCOMES",
    lcc: null,
    name: "Transportes Generales Comes",
    type: "bus",
  },
  {
    id: "CBGEORGIA",
    lcc: null,
    name: "City Bus Georgia",
    type: "bus",
  },
  {
    id: "VIBASA",
    lcc: null,
    name: "Vibasa",
    type: "bus",
  },
  {
    id: "KTELARGO",
    lcc: null,
    name: "KTEL Argolidas",
    type: "bus",
  },
  {
    id: "SENDADIA",
    lcc: null,
    name: "Senda Diamante",
    type: "bus",
  },
  {
    id: "PHUKETSBUS",
    lcc: null,
    name: "Phuket Smart Bus",
    type: "bus",
  },
  {
    id: "BEREXPRESS",
    lcc: null,
    name: "Berlin Expressen",
    type: "bus",
  },
  {
    id: "SARIYAH",
    lcc: null,
    name: "Sariyah",
    type: "bus",
  },
  {
    id: "PISAMOVER",
    lcc: null,
    name: "Pisamover",
    type: "bus",
  },
  {
    id: "PRTURISIMO",
    lcc: null,
    name: "Primar Turismo",
    type: "bus",
  },
  {
    id: "MATUSZEK",
    lcc: null,
    name: "Matuszek",
    type: "bus",
  },
  {
    id: "SAIS",
    lcc: null,
    name: "Sais",
    type: "bus",
  },
  {
    id: "CASTLE",
    lcc: null,
    name: "Castleline",
    type: "bus",
  },
  {
    id: "BRONTE",
    lcc: null,
    name: "Brontebus",
    type: "bus",
  },
  {
    id: "THE36",
    lcc: null,
    name: "The 36",
    type: "bus",
  },
  {
    id: "4F",
    lcc: null,
    name: "Freedom Airline Express",
    type: "airline",
  },
  {
    id: "4V",
    lcc: null,
    name: "Fly Gangwon",
    type: "airline",
  },
  {
    id: "HB",
    lcc: null,
    name: "Greater Bay Airlines",
    type: "airline",
  },
  {
    id: "YI",
    lcc: null,
    name: "Fly OYA",
    type: "airline",
  },
  {
    id: "YP",
    lcc: null,
    name: "Air Premia",
    type: "airline",
  },
  {
    id: "MBU",
    lcc: null,
    name: "Marabu",
    type: "airline",
  },
  {
    id: "X8",
    lcc: null,
    name: "Avion Express Malta",
    type: "airline",
  },
  {
    id: "2S",
    lcc: null,
    name: "Southwind Airlines",
    type: "airline",
  },
  {
    id: "CITYBUSGE",
    lcc: null,
    name: "City Bus Georgia",
    type: "bus",
  },
  {
    id: "U0",
    lcc: null,
    name: "Ultra Air",
    type: "airline",
  },
  {
    id: "F6",
    lcc: null,
    name: "Fly2Sky",
    type: "airline",
  },
  {
    id: "NZ",
    lcc: 1,
    name: "Air New Zealand",
    type: "airline",
  },
  {
    id: "S4",
    lcc: 0,
    name: "SATA Azores Airlines",
    type: "airline",
  },
  {
    id: "TV",
    lcc: 0,
    name: "Tibet Airlines",
    type: "airline",
  },
  {
    id: "W9",
    lcc: 0,
    name: "Wizz Air UK",
    type: "airline",
  },
  {
    id: "UPEXP",
    lcc: null,
    name: "UP Express",
    type: "train",
  },
  {
    id: "E6",
    lcc: null,
    name: "Eurowings Europe Malta",
    type: "airline",
  },
  {
    id: "Z0",
    lcc: null,
    name: "Norse Atlantic UK",
    type: "airline",
  },
  {
    id: "TAH",
    lcc: null,
    name: "Air Anka",
    type: "airline",
  },
  {
    id: "MTO",
    lcc: null,
    name: "Marathon Airlines",
    type: "airline",
  },
  {
    id: "PWF",
    lcc: null,
    name: "Private Wings",
    type: "airline",
  },
  {
    id: "ALS",
    lcc: null,
    name: "Fly ALS",
    type: "airline",
  },
  {
    id: "HX",
    lcc: 1,
    name: "Hong Kong Airlines",
    type: "airline",
  },
  {
    id: "MH",
    lcc: 1,
    name: "Malaysia Airlines",
    type: "airline",
  },
];
