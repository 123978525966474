import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { config } from '../../config.js'
import {
  Box,
  Chip,
  CardMedia,
  Grid,
  Icon,
  Paper,
  Link,
  CardHeader,
} from '@material-ui/core'
import AppTopBar from '../../components/AppTopBar'
import SelectorOption from '../../components/chat/SelectorOption'
import SliderOption from '../../components/chat/SliderOption'
import SavePreferencesOption from '../../components/chat/SavePreferencesOption'
import { Skeleton } from "@material-ui/lab"
import { withStyles } from '@material-ui/core/styles'
import withWidth, { isWidthUp } from '@material-ui/core/withWidth'
import { startCase, toUpper } from 'lodash'
import Helmet from 'react-helmet'
import ChatBot from 'react-simple-chatbot'
import { ThemeProvider } from 'styled-components'
import { THINGS_TO_DO, CUISINES } from '../../utils/lists'
import Cookies from 'universal-cookie'
import ReactPixel from 'react-facebook-pixel'

const API = config.api.TRAVEL_URL
const cookies = new Cookies()

const theme = {
  background: '#fff',
  fontFamily: 'Manrope,Helvetica Neue,Helvetica,-apple-system,BlinkMacSystemFont,Segoe UI,Arial,sans-serif',
  headerBgColor: '#02c39a',
  headerFontColor: '#333',
  headerFontSize: '15px',
  botBubbleColor: 'rgb(250, 250, 250)',
  botFontColor: '#333',
  botFontSize: '14px',
  userBubbleColor: 'rgba(7, 195, 153, 0.26)',
  userFontColor: '#000',
};

class UserQuiz extends Component {
  constructor(props) {
    super(props)
    this.state = {
      signinDialogOpened: false,
      signinDialogForce: false,
      signinDialogType: 'signup',
      signinDialogTitle: null,
      signinDialogSubtitle: null,

      isProcessingPreferences: false,
    }
    window.gtag('event', 'start_personality_test');
  }

  handleSignin = (user) => {
    const travel_preference = this.props.user.travel_preference

    this.props.onSignin({...user, travel_preference})
    this.savePreferences(travel_preference, true)
  }

  savePreferences = (travel_preference, after_signin = false) => {
    const user_jwt_token = cookies.get('jwt')

    if (this.props.user.is_registered && user_jwt_token !== undefined) {
      // Update user preferences
      fetch(config.api.TRAVEL_URL + '/users/me', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_jwt_token}`,
        },
        dataType: 'json',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({
          travel_preference: travel_preference,
          currency: (cookies.get('curr') ? cookies.get('curr') : 'USD'),
        })
      })
      .then(response => response.json())
      .then(data => {

        if (after_signin) {
          this.props.history.push('/')

        } else {
          window.gtag('event', 'updated_personality_test')

          let redirectTo = cookies.get('redirectTo')
          if (redirectTo) {
            cookies.remove('redirectTo', { path: '/', domain: config.api.DOMAIN });
            this.props.history.push(redirectTo)
          } else {
            this.props.history.push('/user/preferences')
          }
        }
      })
    }

  }

  handleSubmit = ({ steps, values }) => {
    const user_jwt_token = cookies.get('jwt')
    const test = values.reduce(((r, c) => Object.assign(r, c)), {})
    // const user = {
    //   email: (test.email && test.email.toLowerCase()),
    //   first_name: test.firstName,
    //   last_name: test.lastName,
    // }
    const travel_preference = {
      food_cuisine: test.food_cuisine.split(','),
      things_to_do: test.things_to_do.split(','),
      vibe_adventurous: test.vibe_adventurous,
      vibe_chill_relaxing: test.vibe_chill_relaxing,
      vibe_curious: test.vibe_curious,
      vibe_fast_paced: test.vibe_fast_paced,
      vibe_luxurious: test.vibe_luxurious,
      vibe_off_the_beaten_path: test.vibe_off_the_beaten_path,
      vibe_popular: test.vibe_popular,
      vibe_romantic: test.vibe_romantic,
    }

    window.gtag('event', 'end_personality_test');
    this.props.onUpdateUser({...this.props.user, travel_preference})

    if (this.props.user.is_registered && user_jwt_token !== undefined) {
      this.savePreferences(travel_preference)

    } else {
      this.props.history.push('/user/preferences')

      // // Analyze user preferences
      // this.setState({ isProcessingPreferences: true })

      // // Create user account
      // setTimeout( () => {
      //   this.setState({
      //     signinDialogOpened: true,
      //     signinDialogForce: true,
      //     signinDialogTitle: 'Success!',
      //     signinDialogSubtitle: "We've built your trip recommendations, let's create account to see them..."
      //   })
      // }, 12000)
    }

  }

  getUser = () => {
    return this.props.user
  }

  render() {
    const { classes } = this.props
    const steps = [
      // {
      //   id: '0',
      //   message: "Discover destinations that match your travel style",
      //   trigger: '1',
      //   hideInput: true,
      // },
      // {
      //   id: '0',
      //   message: "Few questions help us to show you better suggestions",
      //   trigger: '2',
      //   hideInput: true,
      // },
      {
        id: '2',
        message: "What would you like to do on your trip?",
        trigger: '3',
        hideInput: true,
      },
      {
        id: '3',
        component: <SelectorOption name={"things_to_do"} list={THINGS_TO_DO} showImages={false} multiple={true} maxSelected={THINGS_TO_DO.length}/>,
        waitAction: true,
        delay: 0,
        trigger: '6',
        hideInput: true,
      },
      {
        id: '6',
        message: "What cuisine would you like to try?",
        trigger: '7',
        hideInput: true,
      },
      {
        id: '7',
        component: <SelectorOption name={"food_cuisine"} list={CUISINES.sort(() => Math.random() - 0.5)} multiple={true} showImages={true} maxSelected={5}/>,
        waitAction: true,
        delay: 0,
        trigger: '8',
        hideInput: true,
      },
      {
        id: '8',
        message: "What vibe are you looking for on a trip? (1/4)",
        trigger: '10',
        hideInput: true,
      },
      // {
      //   id: '9',
      //   message: "More adventurous or more romantic? ",
      //   trigger: '10',
      //   hideInput: true,
      // },
      {
        id: '10',
        component: <SliderOption leftLabel="Adventurous" rightLabel="Relaxing" defaultValue={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_adventurous: 1.0, vibe_romantic: 0.0},
          {vibe_adventurous: 0.75, vibe_romantic: 0.25},
          {vibe_adventurous: 0.0, vibe_romantic: 0.0},
          {vibe_adventurous: 0.25, vibe_romantic: 0.75},
          {vibe_adventurous: 0.0, vibe_romantic: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '11',
        hideInput: true,
      },
      {
        id: '11',
        message: "Slow-paced or fast-paced? (2/4)",
        trigger: '12',
        hideInput: true,
      },
      {
        id: '12',
        component: <SliderOption leftLabel="Slow-paced" rightLabel="Fast-paced" defaultValue={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_chill_relaxing: 1.0, vibe_fast_paced: 0.0},
          {vibe_chill_relaxing: 0.75, vibe_fast_paced: 0.25},
          {vibe_chill_relaxing: 0.0, vibe_fast_paced: 0.0},
          {vibe_chill_relaxing: 0.25, vibe_fast_paced: 0.75},
          {vibe_chill_relaxing: 0.0, vibe_fast_paced: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '13',
        hideInput: true,
      },
      {
        id: '13',
        message: "Do you prefer higher comfort or lower budget? (3/4)",
        trigger: '14',
        hideInput: true,
      },
      {
        id: '14',
        component: <SliderOption leftLabel="Comfort" rightLabel="Budget" defaultValue={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_luxurious: 1.0, vibe_curious: 0.0},
          {vibe_luxurious: 0.75, vibe_curious: 0.25},
          {vibe_luxurious: 0.0, vibe_curious: 0.0},
          {vibe_luxurious: 0.25, vibe_curious: 0.75},
          {vibe_luxurious: 0.0, vibe_curious: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '15',
        hideInput: true,
      },
      {
        id: '15',
        message: "More popular or more off the beaten path? (4/4)",
        trigger: '16',
        hideInput: true,
      },
      {
        id: '16',
        component: <SliderOption leftLabel="Popular" rightLabel="Off the beaten path" defaultValue={0} marks={[-1.0, -0.5, 0, 0.5, 1.0]} values={[
          {vibe_popular: 1.0, vibe_off_the_beaten_path: 0.0},
          {vibe_popular: 0.75, vibe_off_the_beaten_path: 0.25},
          {vibe_popular: 0.0, vibe_off_the_beaten_path: 0.0},
          {vibe_popular: 0.25, vibe_off_the_beaten_path: 0.75},
          {vibe_popular: 0.0, vibe_off_the_beaten_path: 1.0},
        ]}/>,
        waitAction: true,
        delay: 0,
        trigger: '19',
        hideInput: true,
      },
      // {
      //   id: '20',
      //   message: 'We\'re analysing the best matches for your responses...',
      //   delay: 0,
      //   trigger: '21',
      //   hideInput: true,
      // },
      {
        id: '19',
        // message: 'We\'re building personalized recommendations...',
        message: 'Please wait a few seconds while we find destinations that match your style',
        delay: 0,
        trigger: '20',
        hideInput: true,
      },
      {
        id: '20',
        message: 'That\'s all',
        // trigger: 'end',
        delay: 3000,
        hideInput: true,
        end: true,
      },
      // {
      //   id: 'end',
      //   delay: 0,
      //   component: <SavePreferencesOption getUser={this.getUser}/>,
      //   hideInput: true,
      //   waitAction: true,
      //   end: true,
      // },
    ];

    return (
      <Fragment>
        <Helmet>
          <title>{`Describe your travel style`}</title>
          <link rel="canonical" href={`https://www${config.api.DOMAIN}/user/personalize`} />
        </Helmet>
        <AppTopBar
          hideMenu={true}
          hideBottomMenu={true}
          hideCurrency={true}
          showBackButton={this.props.showBackButton !== undefined ? this.props.showBackButton : !this.state.isProcessingPreferences}
          user={this.props.user}
          onSignin={this.handleSignin}
          address={startCase(this.props.address)}
          // onLocationChanged={this.props.onLocationChanged}
          // onLocationCleared={this.props.onLocationCleared}
          // onChange={this.props.onFilterChange}
          onSigninModalChanged={this.handleSigninChange}
          // address={startCase(this.props.address)}
          locationFrom={this.state.from_address}
          tabSelected={this.props.tabSelected || (this.props.user.is_registered === true ? 'home' : 'explore')}
          openedSigninDialog={this.state.signinDialogOpened}
          signinDialogType={this.state.signinDialogType !== null && this.state.signinDialogType}
          signinDialogForce={this.state.signinDialogForce}
          signinDialogTitle={this.state.signinDialogTitle}
          signinDialogSubtitle={this.state.signinDialogSubtitle}
        />

        {!this.state.isProcessingPreferences ? <div className="destinations">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 16, backgroundColor: '#fafafa'}}>
              <h2 className={classes.headline}>
                <strong className="main-title" style={{fontWeight: 800}}>
                  Create travel style
                </strong>
                <small className={classes.subtitle}>
                  Tell us about your travel preferences
                </small>
              </h2>
            </Paper>
          </div>
          <div style={{minHeight: 'calc(100vh - 240px)'}} className={classes.chatContainer}>
            <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>
              <Grid item xs={12} className={classes.showcaseDestinations}>
                <ThemeProvider theme={theme}>
                  <ChatBot
                    handleEnd={this.handleSubmit}
                    steps={steps}
                    botAvatar="/apple-touch-icon.png"
                    enableSmoothScroll={true}
                    hideHeader={true}
                    hideUserAvatar={true}
                    hideSubmitButton={true}
                    botDelay={1000}
                    userDelay={300}
                    width={'100%'}
                    style={{boxShadow: 'none', height: '100%'}}
                    contentStyle={{height: (isWidthUp('sm', this.props.width) ? (isWidthUp('md', this.props.width) ? 'calc(100vh - 255px)' : 'calc(100vh - 195px)') : 'calc(100vh - 82px)')}}
                    bubbleStyle={{fontSize: '14px', fontWeight: 600}}
                    bubbleOptionStyle={{fontFamily: theme.fontFamily, fontSize: '13px', fontWeight: 600, backgroundColor: 'rgba(7, 195, 153, 0.26)'}}
                    customStyle={{boxShadow: 'none', padding: '16px 0px'}}
                  />
                </ThemeProvider>
              </Grid>

            </Grid>
          </div>
        </div>
        :
        (<div className="destinations">
          <div>
            <Paper square className={classes.headlinePaper} style={{marginBottom: -20}}>
              <h2 className={classes.headline}>
                <strong className="main-title" style={{fontWeight: 800}}>
                  {this.state.signinDialogOpened ? 'Recommended' : 'Building trip recommendations'}
                  <br/>
                </strong>
                <small className={classes.subtitle}>
                  {this.state.signinDialogOpened ? 'Based on your travel style and activity' : 'We are analyzing your answers and identifying your travel style...'}
                </small>
              </h2>
            </Paper>
          </div>
          <div className={classes.analyzingTitle}>
            <h1>
              {this.state.signinDialogOpened ? this.state.signinDialogTitle : 'Building trip recommendations'}
              <small style={{fontSize: '55%', fontWeight: 500, marginTop: 10, lineHeight: 1.8, display: 'block'}}>
                {this.state.signinDialogOpened ? this.state.signinDialogSubtitle : 'We are analyzing your answers and identifying your travel style...'}
              </small>
            </h1>
          </div>
          <div style={{minHeight: 'calc(100vh - 240px)'}} className={classes.chatContainer}>
            <Box style={{ display: 'flex' }} className={classes.analyzingPreferences}>
              <CardMedia component="img" image={'./animation/theater.jpg'} />
              <CardMedia component="img" image={'./animation/market.jpg'} />
              <CardMedia component="img" image={'./animation/bakery.jpg'} />
              <CardMedia component="img" image={'./animation/gallery.jpg'} />
              <CardMedia component="img" image={'./animation/rafting.jpg'} />
              <CardMedia component="img" image={'./animation/wine.jpg'} />
              <CardMedia component="img" image={'./animation/beach.jpg'} />
              <CardMedia component="img" image={'./animation/mountains.jpg'} />
            </Box>
          </div>
        </div>)
        }
      </Fragment>
    )
  }
}

const styles = theme => ({
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1130,
    margin: '0px auto',
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    margin: '-100px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    paddingBottom: '32px',
    // backgroundColor: 'transparent',
    backgroundColor: '#fafafa',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',

    [theme.breakpoints.down('sm')]: {
      paddingBottom: 4,
      marginTop: -110,
    },
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 600,
    textAlign: 'center',
    marginTop: '30px',
    padding: '120px 20px 20px 20px',

    [theme.breakpoints.down('sm')]: {
      marginTop: 10,
      paddingBottom: 10,
      fontSize: 25,
    },

    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 5,
  },
  showcaseDestinations: {
    margin: '10px 0px 0px 0px',

    [theme.breakpoints.down('xs')]: {
      margin: '40px 0px 0px 0px',
    },
  },
  chatContainer: {
    padding: '10px 10px',
    paddingTop: 10,
    minHeight: 'calc(100vh - 155px)',
    backgroundColor: '#fff',

    [theme.breakpoints.down('xs')]: {
      paddingTop: 65,
    },
  },

  "@keyframes customFadeInOut": {
    '0%': {
     opacity: 1,
    },
    '17%': {
     opacity: 1,
    },
    '25%': {
      opacity: 0,
    },
    '92%': {
      opacity: 0,
    },
    '100%': {
      opacity: 1,
    },
  },

  analyzingTitle: {
    zIndex: 2,
    padding: '50px 20px 20px 20px',
    width: '100%',
    maxWidth: 1130,
    margin: 'auto',
    textAlign: 'left',
    display: 'none',

    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
      padding: '120px 30px 20px 30px',
      marginBottom: -120,
      textAlign: 'left',
      width: 'auto',
      display: 'block',
    },
  },
  analyzingPreferences: {
    position: 'relative',
    height: 'calc(70vh - 105px)',
    width: '100%',
    maxWidth: 1130,
    margin: '40px auto',
    borderRadius: 25,

    [theme.breakpoints.down('md')]: {
      width: 'inherit',
      margin: '40px 20px',
    },

    '& img': {
      borderRadius: 25,
      position: 'absolute',
      left: 0,
      height: 'calc(70vh - 105px)',
      animationName: "$customFadeInOut",
      animationTimingFunction: 'ease-in-out',
      animationIterationCount: 'infinit',
      animationDuration: '16s',

      [theme.breakpoints.down('md')]: {
      },
    },
    '& img:nth-of-type(1)': {
      animationDelay: '14s',
    },
    '& img:nth-of-type(2)': {
      animationDelay: '12s',
    },
    '& img:nth-of-type(3)': {
      animationDelay: '10s',
    },
    '& img:nth-of-type(4)': {
      animationDelay: '8s',
    },
    '& img:nth-of-type(5)': {
      animationDelay: '6s',
    },
    '& img:nth-of-type(6)': {
      animationDelay: '4s',
    },
    '& img:nth-of-type(7)': {
      animationDelay: '2s',
    },
    '& img:nth-of-type(8)': {
      animationDelay: '0s',
    },
  }
})

export default withRouter(withStyles(styles)(withWidth()(UserQuiz)))
