import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Slider,
  Grid,
} from '@material-ui/core';

const CustomTab = ({ className, children }) => {
  return <Box component="div" className={className} children={children} />;
};

class SliderOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.selected,
      disabled: false,
    };
  }

  handleSubmit = () => {
    const index = this.props.marks.indexOf(this.state.value)
    const value = this.props.values[index]
    const { triggerNextStep } = this.props;

    this.setState({ disabled: true});

    triggerNextStep({ value });
  }

  render() {
    const { disabled, value } = this.state;
    const { classes, marks, defaultValue, leftLabel, rightLabel } = this.props;

    const steps = marks.map((step, i) => {
      return ({
        value: step,
        label: (i === 0 ? leftLabel : (i === marks.length -1 ? rightLabel : ''))
      })
    })

    return(
      <div role="tab" className={classes.root}>
        <Grid item xs={12} style={{display: 'flex'}} className={classes.background}>
          <Slider
            disabled={disabled}
            aria-label="Restricted values"
            track={false}
            defaultValue={defaultValue}
            min={marks[0]}
            max={marks[marks.length - 1]}
            step={null}
            valueLabelDisplay="off"
            value={value}
            onChange={(event, newValue) => { this.setState({value: newValue}) }}
            marks={steps}
            size="medium"
            classes={{ mark: classes.sliderMark, markLabel: classes.sliderLabel, thumb: classes.sliderThumb, rail: classes.sliderRail, track: classes.sliderTrack }}
          />
        </Grid>
        {disabled === false && <Grid item xs={12}>
          <Button disabled={value === null || value === defaultValue} variant="contained" color="primary" size="large" disableElevation className={classes.buttonOk} onClick={this.handleSubmit} style={{margin: '10px 5px 0px 0px', float: 'right'}}>Next</Button>
        </Grid>}
      </div>
    )
  }
}

const styles = theme => ({
  root: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    marginRight: 10,
    marginTop: 0,
    textAlign: 'left',
    width: '100%',
    maxWidth: 500,
  },
  sliderLabel: {
    top: -15,
    fontSize: 12,
    fontWeight: 600,
  },
  sliderMark: {
    backgroundColor: '#bfbfbf',
    height: 8,
    width: 1,
    marginTop: 0,
  },
  sliderRail: {
    height: 8,
    borderRadius: 4,
    width: '104%',
    left: '-2%',
  },
  sliderRoot: {
    color: '#52af77',
    height: 8,
  },
  sliderTrack: {
    height: 8,
    borderRadius: 4,
  },
  sliderThumb: {
    height: '24px !important',
    width: '24px !important',
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: '-8px !important',
    marginLeft: '-12px !important',
    '&:focus, &:hover, &:active': {
      boxShadow: 'inherit',
    },
  },
  buttonOk: {
    // float: 'right',
    color: '#fff',
    margin: '10px -6px 10px 10px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  background: {
    // backgroundColor: '#fafafa',
    padding: '30px 50px 10px 50px',
    borderRadius: 40,
  },
});

export default withStyles(styles)(SliderOption);