import React, { Component, Fragment } from 'react'
import { Link, withRouter } from 'react-router-dom'
import {
  Grid,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  CardHeader,
  Typography,
  Icon,
  IconButton,
  Tooltip,
  withStyles
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { startCase, kebabCase } from 'lodash';
import Cookies from 'universal-cookie'
import { config } from '../../config.js'

const API = config.api.TRAVEL_URL
const cookies = new Cookies();

class PhotoCard extends Component {
  constructor(props) {
    super(props)
    this.state = {
      featured: this.props.featured || false,
      hide: this.props.hidden || false,
    };
  }

  saveInteraction(action, value) {
    this.setState(prevState => ({
      [action]: !prevState[action]
    }))

    fetch(API + `/admin/photos/${this.props.id}`, {
      method: 'PUT',
      dataType: 'json',
      body: JSON.stringify({
        [action]: value,
      }),
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
        'X-USER': cookies.get('_uid')
      }
    })
  }

  render() {
    const {
      image_url,
      tags,
      description,
      classes
    } = this.props

    return (
      <Grid item xs={12} sm={6} md={4} lg={3} xl={3}>
        <Card className={classes.card} elevation={0} variant="outlined">
          <CardHeader
            classes={{root: classes.header, action: classes.headerAction}}
            avatar={!this.state.hide ? (
              <Tooltip title="Hide" arrow>
                <IconButton aria-label="Hide" onClick={() => this.saveInteraction("hide", true)} className={classes.favoriteIcon}><Icon className={`notranslate ${classes.favoriteIconSize}`}>visibility</Icon></IconButton>
              </Tooltip>
            ) : (
              <Tooltip title="Show" arrow>
                <IconButton aria-label="Show" onClick={() => this.saveInteraction("hide", false)} className={classes.favoriteIconSelected}><Icon className={`notranslate ${classes.favoriteIconSize}`}>visibility_off</Icon></IconButton>
              </Tooltip>
            )
            }
            action={
              !this.state.featured ? (
                <Tooltip title="Feature" arrow>
                  <IconButton aria-label="Feature" onClick={() => this.saveInteraction("featured", true)} className={classes.favoriteIcon}><Icon className={`notranslate ${classes.favoriteIconSize}`}>star_border</Icon></IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Featured" arrow>
                  <IconButton aria-label="Featured" onClick={() => this.saveInteraction("featured", false)} className={classes.favoriteIconSelected}><Icon className={`notranslate ${classes.favoriteIconSize}`}>star</Icon></IconButton>
                </Tooltip>
              )
            }
          />}
          <CardMedia
            component="img"
            className={classes.media}
            height="450"
            image={image_url.replace('http://', '//')}
          />
          <CardContent className={classes.content}>
            <Typography gutterBottom variant="h3" className={classes.dates}>
              <span className={classes.small}>
                {description}
              </span>
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    )
  }
}

const styles = theme => ({
  card: {
    // maxWidth: 450,
    textAlign: 'left',
    // boxShadow: 'none',
    border: '1px solid #f2f2f2',
    // borderRadius: '10px',

    '&:hover': {
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
      // boxShadow: '0px 1px 3px 0px rgba(0,0,0,0.2), 0px 1px 1px 0px rgba(0,0,0,0.14), 0px 2px 1px -1px rgba(0,0,0,0.12)',
      border: '1px solid #f2f2f2',
    },
  },
  media: {
    // ⚠️ object-fit is not supported by IE 11.
    objectFit: 'cover',
    height: '60vw',
    maxHeight: 360,
    // minHeight: 360,

    // [theme.breakpoints.down('xs')]: {
    //   height: '100vw',
    // },
  },
  intro: {
    overflow: 'hidden',
    height: 34,
    fontSize: '13px',
    padding: 5,
    // marginBottom: 10,
    color: '#555',
  },
  actions: {
    display: 'none',
    padding: '10px 11px',
  },
  small: {
    display: 'block',
    fontSize: '13px',
    color: '#828282',
    fontWeight: 600,
    marginTop: 4,
  },
  smallCountry: {
    display: 'block',
    fontSize: '13px',
    color: '#828282',
    fontWeight: 600,
    marginTop: 8,
    width: 'calc(100% - 90px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  price: {
    minHeight: 30,
    display: 'block',
  },
  name: {
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    padding: '0px 10px',
    color: '#000',
    fontWeight: 800,
    fontSize: '1.15rem',

    [theme.breakpoints.down('sm')]: {
      fontSize: '1.1rem',
      fontWeight: 800,
    },

    [theme.breakpoints.down('xs')]: {
      fontSize: '0.98rem',
      fontWeight: 800,
    },
  },
  dates: {
    overflow: 'hidden',
    display: 'block',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#828282',
    textAlign: 'center',
    borderTop: '1px solid #f2f2f2',
    margin: '10px 0px 0px 0px',
    padding: '10px 0px 0px 0px',
  },
  flight: {
    float: 'right',
    textAlign: 'right',
  },
  actionPrimary: {
    width: '100%',
    color: '#fff',
    margin: '0px 0px 0px 4px',
    backgroundColor: '#00a1ff',
    borderRadius: '20px',
    // maxWidth: '47%',
    maxWidth: '98%',
    fontWeight: 600,

    '&:hover': {
      backgroundColor: '#303f9f',
    },
  },
  actionSecondary: {
    width: '47%',
    color: '#555',
    borderRadius: '20px',
    backgroundColor: 'rgba(0, 0, 0, 0.08)',

    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  content: {
    // position: 'absolute',
    // bottom: 0,
    // width: '100%',
    padding: '12px',
    // background: 'linear-gradient(0deg, rgba(0,0,0,0.9) 10%, rgba(0,0,0,0) 100%)',
  },
  contentLoading: {
    position: 'absolute',
    bottom: 0,
    width: '100%',
    padding: '60px 0px 0px 0px',
    background: 'none',
    display: 'none',
  },
  icon: {
    marginRight: 5,
    marginBottom: 3,
    fontSize: '16px',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  flag: {
    width: 18,
    marginRight: 5,
    marginTop: -3,
    verticalAlign: 'middle',
  },
  weather: {
    // position: 'absolute',
    fontSize: 13,
    fontWeight: 600,
    // backgroundColor: '#0000008c',
    borderRadius: 5,
    padding:'2px 5px 0px 0px',
    color: '#828282',
    bottom: 0,
    marginLeft: 4,
    // display: 'inline-block',
    // display: 'none',
  },
  weatherIcon: {
    width: 30,
    verticalAlign: 'middle',
    marginTop: '-3px',
    marginLeft: -4,
  },
  header: {
    marginBottom: '-85px',
    zIndex: 100,
    backgroundColor: 'transparent',
    width: 'calc(100% - 32px)',
    position: 'relative',
    padding: '14px 16px',
  },
  headerAction: {
    margin: '0px -3px 0px 0px',
  },
  favoriteIcon: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: '#25082f',
  },
  favoriteIconSelected: {
    borderRadius: 25,
    background: '#fff',
    padding: '10px 9px 8px 9px',
    color: 'rgba(255, 107, 107, 0.9)',
  },
  favoriteIconSize: {
    fontSize: 20,
  },
  doneIcon: {
    borderRadius: 25,
    padding: '7px',
    color: '#25082f',
    marginTop: -8,
    marginLeft: -8,
  },
  flightIcon: {
    borderRadius: 12,
    backgroundColor: 'rgba(255, 166, 43, 0.9)',
    marginRight: 6,
    opacity: 1,
    height: 40,
    width: 40,

    '&:hover': {
      backgroundColor: 'rgba(255, 166, 43, 0.9)',
    },
  },
  hotelIcon: {
    borderRadius: 12,
    backgroundColor: '#02c39a',
    // backgroundColor: 'rgba(255, 107, 107, 0.9)',
    marginRight: 6,
    opacity: 1,
    height: 40,
    width: 40,

    '&:hover': {
      backgroundColor: '#02c39a',
    },
  },
});

export default withStyles(styles)(withRouter(PhotoCard));