import React, { Component, Fragment } from 'react'
import { withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  ClickAwayListener,
  Divider,
  Icon,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  MenuItem,
  Popper,
  Slider,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import DayPickerRangeControllerWrapper from './DayPickerRangeControllerWrapper';

const dates = [
  {
    name: 'Cheapest Dates',
    value: 'cheapest',
    visible_calendar: false,
  },
  {
    name: 'Weekends',
    value: 'weekends',
    visible_calendar: false,
  },
  // {
  //   name: 'Within a Month',
  //   value: 'month',
  //   visible_calendar: false,
  // },
  // {
  //   name: 'Within 3 Months',
  //   value: 'three_months',
  //   visible_calendar: false,
  // },
  {
    name: 'Choose Dates',
    value: 'specific',
    icon: 'arrow_right',
    visible_calendar: true,
  },
]

class DatesFilter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nights: this.props.nights || [1, 3],
      dates_type: this.props.dates_type || 'cheapest',
      start_date: this.props.start_date ? moment(this.props.start_date, 'YYYY-MM-DD') : null,
      end_date: this.props.end_date ? moment(this.props.end_date, 'YYYY-MM-DD') : null,
      open: false,
      visible_calendar: this.props.start_date !== null && this.props.end_date !== null,
    };
  }

  componentDidUpdate(prevProps) {
    if ((prevProps.start_date !== this.props.start_date) || (prevProps.end_date !== this.props.end_date) || (prevProps.dates_type !== this.props.dates_type) || (prevProps.nights !== this.props.nights)) {
      this.setState({
        nights: this.props.nights || [1, 3],
        dates_type: this.props.dates_type || 'cheapest',
        start_date: this.props.start_date ? moment(this.props.start_date, 'YYYY-MM-DD') : null,
        end_date: this.props.end_date ? moment(this.props.end_date, 'YYYY-MM-DD') : null,
      })
    }
  }

  handleToggle = () => {
    this.setState(state => ({ open: !state.open }));
  }

  handleChange = () => {
    if (!this.props.readonly && (!this.props.updateAfterClose || (this.props.updateAfterClose === true && this.state.open === false))) {
      this.props.onChange({
        target: {
          name: this.props.name,
          value: {
            dates_type: this.state.dates_type,
            start_date: this.state.start_date && this.state.dates_type !== 'cheapest' ? this.state.start_date.format('YYYY-MM-DD') : null,
            end_date: this.state.end_date && this.state.dates_type !== 'cheapest' ? this.state.end_date.format('YYYY-MM-DD') : null,
            nights: this.state.nights,
          }
        }
      })
    }
  }

  handleNightsChange = (event, nights) => {
    this.setState({ nights }, this.handleChange);
  };

  handleClose = event => {
    let params = {
      dates_type: this.state.dates_type,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      nights: this.state.nights,
      visible_calendar: this.state.visible_calendar
    }

    if (this.state.dates_type === 'specific' && (this.state.start_date === null || this.state.end_date === null)) {
      params['dates_type'] = 'cheapest'
      params['start_date'] = null
      params['end_date'] = null
      params['visible_calendar'] = false
    }

    this.setState({
      dates_type: params['dates_type'],
      start_date: params['start_date'],
      end_date: params['end_date'],
      nights: params['nights'],
      visible_calendar: params['visible_calendar'],
      open: false
    }, this.handleChange)
  }

  onDatesChange = (start_date, end_date) => {
    this.setState({
      start_date: start_date,
      end_date: end_date
    }, this.handleChange)
  }

  onDateTypeChange(item) {
    this.setState({
      dates_type: item['value'],
      visible_calendar: item['visible_calendar'] || false,
    }, this.handleChange)
  }

  renderButtonLabel() {
    if (this.state.dates_type === 'cheapest') {
      return `${this.state.nights[0]}${this.state.nights[0] === this.state.nights[1] ? '' : '-' + this.state.nights[1]} night${(this.state.nights[1] > 1 ? 's' : '')}`
    } else {
      return (
        dates.map((date, index) => {
          if (this.state.dates_type === date['value']) {
            let start_date = !this.state.start_date ? 'Start Date' : this.state.start_date.format('MMM DD');
            let end_date = !this.state.end_date ? 'End Date' : this.state.end_date.format('MMM DD');

            // return one date if start/end date is the same
            if (start_date === end_date) {
              return start_date;

            // return specific dates that has been selected
            } else if (this.state.dates_type === 'specific') {
              return start_date + ' - ' + end_date;
            } else if (this.state.dates_type === 'range') {
              return start_date + ' .. ' + end_date;

            // return named date period
            } else if (this.state.dates_type === 'weekends') {
              return date['name']

            } else if (this.state.dates_type === 'cheapest') {
              return `${this.state.nights[0]}${this.state.nights[0] === this.state.nights[1] ? '' : '-' + this.state.nights[1]} night${(this.state.nights[1] > 1 ? 's' : '')}`

            } else {
              return 'Anytime'
            }

          // default value
          } else {
            return ''
          }
        })
      )
    }
  }

  renderMenuItems() {
    const { classes } = this.props;

    return(
      dates.map((item, index) => {
        return(
          <Fragment key={item['value']}>
            <MenuItem
              key={item['value']}
              value={item['value']}
              selected={this.state.dates_type === item['value']}
              className={this.state.dates_type === item['value'] ? 'selected' : ''}
              onClick={this.onDateTypeChange.bind(this, item)}
            >
              {item['name']}
              {item['icon'] && <Icon  className="notranslate">{item['icon']}</Icon>}
            </MenuItem>
            {this.state.dates_type === item['value'] && item['value'] !== 'weekends' && item['visible_calendar'] !== true && <div style={{display: 'block', backgroundColor: '#0000000a', padding: '10px 20px', borderRadius: 25, marginTop: 5, fontSize: 14}}>
            <Typography
              // className={classes.label}
              id="label"
              style={{fontSize: 13, fontWeight: 600, marginBottom: 5}}
            >
              Stay for <b>{this.state.nights[0]}{this.state.nights[0] === this.state.nights[1] ? '' : '-' + this.state.nights[1]} night{(this.state.nights[1] > 1 ? 's' : '')}</b>
            </Typography>
            <Slider
              value={this.state.nights}
              min={1}
              max={30}
              aria-labelledby="label"
              onChange={this.handleNightsChange}
              classes={{
                track: classes.track,
                thumb: classes.thumb
              }} />
          </div>}
        </Fragment>
        )
      })
    )
  }

  render() {
    const { classes } = this.props;
    const { open, visible_calendar } = this.state;

    return(
      <div role="tab" className={classes.root} style={{float: (this.props.align || 'none')}}>
        <Button
          buttonRef={node => { this.anchorEl = node; }}
          aria-owns={open ? 'menu-list-grow' : null}
          aria-haspopup="true"
          name="Filter: Dates"
          onClick={!this.props.readonly ? this.handleToggle : () => {}}
          className={open ? classes.selected : classes.select}
          style={{padding: '7px 15px', display: 'block', width: '100%'}}
        >
          <div className={classes.iconLabel} style={this.props.compact ? {float: 'left', marginTop: 1} : {}}><Icon className={`material-icons-outlined notranslate ${classes.icon}`}>calendar_today</Icon>{!this.props.compact && ' Dates'}</div>
          <div style={{maxWidth: (this.props.compact ? 100 : 'auto'), paddingLeft: (this.props.compact ? 22 : 0), color: (this.props.compact ? '#333' : '#828282'), whiteSpace: 'nowrap', fontSize: 13}}>
            {this.renderButtonLabel(this)}
          </div>
        </Button>
        <Popper open={open}
          anchorEl={this.anchorEl}
          className={classes.popper}
          style={(this.state.dates_type === 'specific' ? {height: 572} : {})}
          placement="bottom-start"
          disablePortal={false}
          modifiers={{
            flip: {
              enabled: true,
            },
            preventOverflow: {
              enabled: true,
              boundariesElement: 'scrollParent',
            },
            arrow: {
              enabled: false,
              element: this.anchorEl,
            },
          }}>
          <ClickAwayListener onClickAway={this.handleClose}>
            <Box>
              <DayPickerRangeControllerWrapper
                minimumNights={0}
                onDatesChange={this.onDatesChange.bind(this)}
                calendarDisabled={!visible_calendar}
                initialStartDate={this.state.start_date}
                initialEndDate={this.state.end_date}
                renderCalendarInfo={() => (
                  <div style={{ padding: '10px 21px', color: '#484848' }}>
                    {this.renderMenuItems()}
                  </div>
                )}
              />
              <List className={classes.list}>
                <Divider className={classes.divider}/>
                <ListItem style={{minHeight: 50}}>
                  <ListItemText primary=""/>
                  <ListItemSecondaryAction>
                    <Button variant="contained" color="primary" size="medium" className={classes.buttonBook} onClick={this.handleClose}>
                      Apply
                    </Button>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Box>
          </ClickAwayListener>
        </Popper>
      </div>
    )
  }
}

const styles = theme => ({
  popper: {
    top: '4px !important',
    // left: '1px !important',
    // textAlign: 'left',
    // position: 'fixed !important',
    zIndex: 100,
    // boxShadow: '0px 5px 5px -3px rgba(0,0,0,0.2), 0px 8px 10px 1px rgba(0,0,0,0.14), 0px 3px 14px 2px rgba(0,0,0,0.12)',
    // boxShadow: '0 10px 20px 0 rgb(0 0 0 / 5%)',
    // border: '1px solid #f5f5f5',
    border: '1px solid #f5f5f5',
    borderRadius: 25,
    boxShadow: '0px 10px 20px 0 rgb(0 0 0 / 5%)',
    backgroundColor: '#fff',
  },
  root: {
    fontSize: 12,
    fontWeight: 500,
    color: 'rgba(0, 0, 0, 0.87)',
    display: 'block',
    position: 'relative',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    lineHeight: '1.4em',
    marginRight: 10,
    marginTop: 0,
    textAlign: 'left',
    overflow: 'hidden',
  },
  select: {
    color: '#828282',
    fontWeight: 600,
    // backgroundColor: '#eee',
    fontSize: 12,
    padding: '10px 15px 10px',
    borderRadius: 25,
    // border: '1px solid #eee',
    // height: 40,
    textTransform: 'none !important',
    textAlign: 'left',
    minWidth: 150,
    maxWidth: '100%',

    '&:focus': {
      borderRadius: 25,
    }
  },
  selected: {
    color: '#333',
    fontWeight: 600,
    fontSize: 12,
    backgroundColor: 'rgba(0, 0, 0, 0.04)',
    padding: '10px 15px 10px',
    // border: '1px solid #eee',
    // height: 40,
    borderRadius: 25,
    textTransform: 'none !important',
    textAlign: 'left',
    minWidth: 150,
    maxWidth: '100%',

    '&:focus': {
      // backgroundColor: '#5ad076',
      borderRadius: 25,
    },
    '&~svg': {
      color: '#fff',
    },
    '&:hover': {
      // backgroundColor: '#5ad076',
      borderRadius: 25,
      color: '#333',
    },
  },
  iconLabel: {
    color: '#333',
    fontWeight: 700,
    marginBottom: 2,
  },
  icon: {
    marginRight: 5,
    fontSize: 15,
    fontWeight: 600,
    marginTop: -3,
    padding: '0px !important',
    verticalAlign: 'middle',
    color: '#02c39a',
  },
  iconArrow: {
    margin: '0 0 0 3px',
    fontSize: 18,
    padding: '0px !important',
  },
  thumb: {
    backgroundColor: '#02c39a',
    width: 20,
    height: 20,
    marginTop: '-9px !important',
  },
  track: {
    backgroundColor: '#02c39a'
  },
  buttonBook: {
    float: 'right',
    color: '#fff',
    margin: '5px 0px 0px 0px',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    fontSize: 13,

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  divider: {
    backgroundColor: '#f2f2f2',
    color: '#000',
    marginTop: 0,
  },
  list: {
    paddingTop: 0,
    marginTop: -10,
  },
});

export default withStyles(styles)(DatesFilter);