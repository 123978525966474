import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import {
  Grid,
  withStyles,
  Card,
  CardContent,
  Typography,
  Paper,
} from '@material-ui/core';
import { startCase } from 'lodash'
import AppTopBar from '../../components/AppTopBar.js'
import Helmet from 'react-helmet'
import { config } from '../../config.js'
import Cookies from 'universal-cookie'

const cookies = new Cookies();
const API = config.api.TRAVEL_URL + '/users/unsubscribe'

class Unsubscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      trips: [],
      error: false,
    }
  }

  handleHomeRedirect = () => {
    this.setState({
    }, this.props.history.push('/'))
  }

  componentDidMount() {
    this.unsubscribe(this.props);
  }

  unsubscribe(props) {
    window.gtag('event', 'unsubscribe', {group: 'Discover Weekly'});

    fetch(API, {
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${cookies.get('jwt')}`,
      },
      dataType: 'json',
      body: JSON.stringify({
        token: props.token
      })
    })
    .then(response => response.json())
    .catch((err) => this.setState({ error: true }))

  }

  render() {
    const { classes } = this.props;

    return (
      <Fragment>
        <Helmet>
          <title>{`Explore ${startCase(this.state.group === 'history' ? 'Historical Towns' : this.state.group)} ${this.props.address && this.props.addressType === 'country' ? 'in ' + startCase(this.props.address) : ''}`}</title>
        </Helmet>
        <AppTopBar
            onLocationChanged={this.props.onLocationChanged}
            onLocationCleared={this.props.onLocationCleared}
            onChange={this.props.onFilterChange}
            onSignin={this.props.onSignin}
            user={this.props.user}
            address={startCase(this.props.address)}
            tabSelected={''}
        />

        <div className="destinations explore">
          <div>
            <Paper className={classes.headlinePaper} style={{marginBottom: -20, paddingBottom: 52}}>
              <h2 className={classes.headline}>
                <br/><br/><br/>
                <strong className="main-title" style={{fontWeight: 800}}>
                  Discover weekly
                  <br/>
                </strong>
                <small className={classes.subtitle}>
                  Explore new travel destinations every Monday. Picked just for you.
                </small>
              </h2>
            </Paper>
          </div>

          <div className="destinations-container ">
            <Grid item xs={12} sm={12} md={12} className={classes.gridFilters}>

              <Card className={classes.root}>
                <CardContent>
                  <Typography gutterBottom variant="h6" component="h3" style={{fontWeight: 700}}>
                    You've been unsubscribed
                    <br />
                  </Typography>
                  <Typography className={classes.title} color="textSecondary" variant="body2" component="p" style={{fontWeight: 500}}>
                    You won't be receiving discover weekly notifications anymore.
                  </Typography>
                </CardContent>
              </Card>

            </Grid>
          </div>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  gridFilters: {
    textAlign: 'left',
    fontWeight: 400,
    maxWidth: 1130,
    margin: '0px auto',
  },
  link: {
    padding: 5,
    display: 'block',
  },
  headlinePaper: {
    padding: '0px 0px 40px 0px',
    margin: '-100px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    paddingBottom: '96px',
    backgroundColor: '#fafafa',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 600,
    textAlign: 'left',
    marginTop: '30px',
    padding: '0px 20px',

    [theme.breakpoints.down('sm')]: {
      marginTop: 0,
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#828282',
    display: 'block',
    marginTop: 10,
  },
  featureMedia: {
    maxWidth: 180,
    float: 'right',
    margin: '-10px 0px -27px 0px',

    [theme.breakpoints.down('xs')]: {
      maxWidth: 150,
      margin: -10,
    },
  },
  root: {
    marginTop: "50px",
    textAlign: 'center',
    color: '#02c39a',
    maxWidth: 600,
    margin: '50px auto 0px auto',
    boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
  }
})

export default withStyles(styles)(withRouter(Unsubscribe));