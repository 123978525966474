import React, { Component, Fragment } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Button,
  Grid,
  Paper,
  TextareaAutosize,
  Typography,
} from '@material-ui/core'
import moment from 'moment'


class TripBookingBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      opened_feedback: false,
      feedback_message: '',
      submitting_feedback: false,
    };
  }

  render() {
    const { classes, itinerary, travelers, start_date, end_date, nights, currency, isRefundable } = this.props
    const { opened_feedback, feedback_message, submitting_feedback } = this.state
    const travel_price = (itinerary && itinerary.filter((i) => i.category === 'travel').reduce((acc, current) => acc + current.booking_total_price, 0)) || 0
    const hotel_price = (itinerary && itinerary.filter((i) => i.category === 'accommodation').reduce((acc, current) => acc + current.booking_total_price, 0)) || 0

    return (
      <Fragment>
        {(!opened_feedback && (travel_price > 0 || hotel_price > 0) ?
          <Grid container alignItems={'center'} justify={'center'} style={{maxWidth: 1130, margin: 'auto auto 20px auto', left: 'auto'}}>
            <Grid item xs={12} md={12} style={{ margin: 'auto auto auto auto'}}>
              <Paper className={`${classes.paper}`} elevation={0}>
                <Grid container>
                  <Grid item xs={12}>
                    <div style={{display: 'block', position: 'relative', float: 'none'}}>
                        <Typography className={classes.section} style={{fontSize: 14, border: 'none', float: 'left', display: 'block'}}>
                          <Fragment>{start_date !== end_date ? moment(start_date).format('MMM D - ') : moment(start_date).format('MMM ')} {moment(start_date).format('MMM') === moment(end_date).format('MMM') ? moment(end_date).format('D') : moment(end_date).format('MMM D')}</Fragment> <br/>
                          <small style={{fontWeight: 500, color: '#333'}}>
                            {nights ? nights + 1 : 1}-day{nights > 0 ? 's' : ''} {hotel_price > 0 ? 'stay' : ''} {travel_price && hotel_price ? '&' : ''} {travel_price > 0 ? 'travel' : ''}</small>
                        </Typography>

                        <Typography className={classes.section} style={{fontSize: 14, textAlign: 'right', border: 'none', float: 'right', display: 'block', position: 'absolute', right: 0}}>
                          {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: currency || 'USD'}).format(Math.ceil(((travel_price || 0) + (hotel_price || 0)) / (travelers || 1)))} <br/>
                          <small style={{fontWeight: 500, color: '#333'}}> per person</small>
                        </Typography>
                    </div>
                  </Grid>

                  {travelers > 1 && <Grid item xs={12}>
                    <Typography className={classes.sectionBooking} style={{textAlign: 'right', float: 'right', display: 'block', }}>
                      {new Intl.NumberFormat([], {style: 'currency', minimumFractionDigits: 0, maximumFractionDigits: 0, currency: currency || 'USD'}).format(Math.ceil(((travel_price || 0) + (hotel_price || 0))))} <br/>
                    </Typography>
                    <Typography className={classes.sectionBooking}>
                      Total for {travelers} travelers
                    </Typography>
                  </Grid>}
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        :
          <Fragment></Fragment>
        )}
        {isRefundable && <Grid container alignItems={'center'} justify={'center'} style={{maxWidth: 1130, margin: '20px auto 20px auto', left: 'auto'}}>
            <Grid item xs={12} md={12} style={{ margin: 'auto auto auto auto'}}>
              <Paper className={``} elevation={0}>
                <Grid container>
                  <Grid item xs={12}>
                    {!opened_feedback ? <div style={{display: 'block', position: 'relative', float: 'none', textAlign: 'center', fontWeight: 500, fontSize: '14px'}}>
                        Not satisfied with travel plan ? <a style={{color: '#333', textDecoration: 'underline', marginLeft: '5px', cursor: 'pointer'}} onClick={() => this.setState({opened_feedback: true, feedback_message: ''})}>Get a refund</a>
                    </div>
                    :
                    <div className={classes.feedback}>
                        <div style={{margin: '0px 0px 10px 0px', fontWeight: 600}}>What didn't you like about the travel plan ?</div>
                        <TextareaAutosize
                          autoFocus={true}
                          id="feedback_message"
                          placeholder=""
                          variant="outlined"
                          name="feedback_message"
                          rows={2}
                          value={feedback_message}
                          onChange={e => this.setState({feedback_message: e.target.value})}
                          className={classes.textfieldArea}
                        />
                        <br/>
                        <Button variant="contained" color="primary" size="small" disableElevation className={classes.buttonCancel} onClick={() => this.setState({opened_feedback: false, feedback_message: ''})} style={{marginRight: '10px'}}>
                          Cancel
                        </Button>
                        <Button disabled={!feedback_message || submitting_feedback} variant="contained" color="primary" size="small" disableElevation className={classes.buttonBook} onClick={() => {this.setState({submitting_feedback: true}); this.props.onRefund(feedback_message)}} >
                          {submitting_feedback ? 'Refunding...' : 'Refund the plan'}
                        </Button>
                    </div>}
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>}
      </Fragment>
    )
  }
}

const styles = theme => ({
  header: {
    padding: '0px !important',
  },
  section: {
    fontSize: 18.5,
    fontWeight: 700,
    lineHeight: '18px',
    padding: '15px 20px',

    '& small': {
      color: '#999',
      fontSize: '80%',
    }
  },
  sectionPrice: {
    fontSize: 13,
    fontWeight: 800,
    textAlign: 'right',
    padding: '18px 15px',

    '& small': {
      fontSize: '80%',
      fontWeight: 500,
      color: '#777',
    }
  },
  sectionBooking: {
    fontSize: 13,
    fontWeight: 800,
    lineHeight: '18px',
    padding: '15px 20px 15px 20px',
    borderTop: '1px solid rgba(215, 235, 217, 1)',
    color: 'rgba(95, 143, 99, 0.89)',

    '& small': {
      color: '#999',
      fontSize: '80%',
    },
  },
  skeleton: {
    borderRadius: 10,
  },
  paper: {
    backgroundColor: '#c6e8c940',
    boxShadow: 'none'
  },
  feedback: {
    display: 'block',
    position: 'relative',
    float: 'none',
    textAlign: 'center',
    margin: '0px',
    backgroundColor: '#f7f7f7',
    padding: '10px',
    borderRadius: '15px'
  },
  buttonBook: {
    color: '#fff',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '7px 20px',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  buttonCancel: {
    backgroundColor: '#eee',
    textTransform: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: '7px 20px',

    '&:hover': {
      backgroundColor: '#ccc',
      boxShadow: 'none',
    }
  },
  textfieldArea: {
    width: '100%',
    textAlign: 'left',
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: '2px 0px 0px 0px',
    fontSize: 14,
    fontWeight: 600,
    padding: '11px 5px',
    border: 'none',
    fontFamily: 'inherit',
    minHeight: 20,
    maxWidth: '98%',
    minWidth: '98%',

    '&:focus': {
      outline: 0,
    },
    '& fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&:hover fieldset': {
      borderColor: 'transparent !important',
      borderWidth: 1,
      borderRadius: 25,
      paddingLeft: 30,
    },
    '&::placeholder': {
      color: '#999',
    },
  },
})

export default withRouter(withStyles(styles)(TripBookingBox))
