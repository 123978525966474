import React, { Component, Fragment } from 'react'
import Helmet from 'react-helmet'
import { withRouter } from 'react-router-dom'
import { config } from '../config'
import {
  Icon,
  IconButton,
  Grid,
  Paper,
  LinearProgress,
} from '@material-ui/core'
import {
  Alert,
  AlertTitle,
} from '@material-ui/lab'
import { withStyles } from '@material-ui/core/styles'
import { startCase } from 'lodash'
import AppTopBar from '../components/AppTopBar'
import PlannerOccasion from './planner/PlannerOccasion'
import PlannerQuiz from './planner/PlannerQuiz'
import PlannerPricing from './planner/PlannerPricing'
import PlannerTravel from './planner/PlannerTravel'
import PlannerCreateAccount from './planner/PlannerCreateAccount'
import PlannerUpgrade from './planner/PlannerUpgrade'
import ReactPixel from 'react-facebook-pixel'
import Cookies from 'universal-cookie'
import { OCCASIONS } from '../utils/lists'

const cookies = new Cookies();
const API = config.api.TRAVEL_URL


class Planner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      occasion: props.occasion || 'trip',
      account_plan: 'free',
      preferences: null,
      travel: null,
      contact: props.user && props.user.email ? props.user : null,
      isSubmitting: false,
      isRefreshedUser: false,

      signinDialogOpened: false,
      signinDialogForce: false,
      signinDialogType: 'signup',
      signinDialogTitle: null,
      signinDialogSubtitle: null,

      error: null,
    }
  }

  componentDidUpdate(prevProps) {
    if (JSON.stringify(prevProps.user) !== JSON.stringify(this.props.user)) {
      this.setState({
        contact: this.props.user && this.props.user.email ? this.props.user : null,
        error: null,
      })
    }
    if (this.props.step === 'occasion' && !this.state.isRefreshedUser) {
      this.setState({isRefreshedUser: true}, this.props.onRefreshUser)
    }
  }

  onUpdated = (value) => {
    // submit request to generate a trip
    const user_jwt_token = cookies.get('jwt')

    if (this.props.step === 'dates' && value.travel) {
      this.setState(value, this.props.history.push('/planner/travel_style'))

    } else if (this.props.step === 'travel_style' && value.preferences) {
      // console.log(JSON.stringify({
      //   travel_preference_attributes: {
      //     template_type: this.state.occasion,
      //     template_response: Object.assign({}, ...value.preferences),
      //   }
      // }))

      // if (this.props.user.is_registered && user_jwt_token !== undefined) {
      //   this.setState(value, this.submitRequest)
      // } else {
      this.setState(value, this.props.history.push('/planner/contact'))
      // }

    } else if (this.props.step === 'contact' && value.contact) {

      if (this.props.user.is_registered && user_jwt_token !== undefined) {
        this.setState(value, this.submitRequest)
      } else {
        window.gtag('event', 'failed_request_trip')
        ReactPixel.track('failed_request_trip')
      }

    } else {
      this.setState(value, this.props.history.push('/planner/dates'))
    }
  }

  // savePreferences = (user, travel_preference) => {
  //   const user_jwt_token = cookies.get('jwt')

  //   if (user.is_registered && user_jwt_token !== undefined) {
  //     // Update user preferences
  //     fetch(config.api.TRAVEL_URL + '/users/me', {
  //       method: 'PUT',
  //       headers: {
  //         'Accept': 'application/json',
  //         'Content-Type': 'application/json',
  //         'Authorization': `Bearer ${user_jwt_token}`,
  //       },
  //       dataType: 'json',
  //       // We convert the React state to JSON and send it as the POST body
  //       body: JSON.stringify({
  //         travel_preference: travel_preference,
  //         currency: (cookies.get('curr') ? cookies.get('curr') : 'USD'),
  //       })
  //     })
  //   }
  // }

  openSigninModal = () => {
    this.setState({
      signinDialogOpened: true,
      signinDialogForce: true,
      signinDialogTitle: 'Success!',
      signinDialogSubtitle: "Sign in to your account"
    })
  }

  handleSignin = (user) => {
    // save travel preference when user is new
    // if (!user.travel_preference && this.state.preferences) {
    //   this.savePreferences(user, this.state.preferences)
    //   user.travel_preference = this.state.preferences
    // }

    this.props.onSignin(user)
  }

  submitRequest = () => {
    this.setState({isSubmitting: true})
    const user_jwt_token = cookies.get('jwt')

    const travel = this.state.travel || {}
    const dates = travel.dates || []

    const from = (travel.from != null && travel.from.location) ? [travel.from.location] : travel.from.flat().filter(f => f.location).map(f => f.location)
    const to = (travel.to != null && travel.to.location) ? [{...travel.to.location, dates: travel.dates[0]}] : travel.to.flat().filter(f => f.location).map((f, i) => { return {...f.location, dates: travel.dates[i]} })

    if (user_jwt_token !== undefined) {
      // window.gtag('event', 'generate_trip')
      window.gtag('event', 'purchase', {
        value: OCCASIONS.filter(f => f.value === this.state.occasion)[0].costs,
        currency: "USD",
        items: [{
          item_name: this.state.occasion,
          item_category: 'planning',
          price: OCCASIONS.filter(f => f.value === this.state.occasion)[0].costs,
        }]
      });
      // ReactPixel.track('generate_trip')
      ReactPixel.track('Purchase', {
        currency: "USD",
        value: OCCASIONS.filter(f => f.value === this.state.occasion)[0].costs
      })

      fetch(config.api.TRAVEL_URL + '/ai/planner/pay', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user_jwt_token}`,
        },
        dataType: 'json',
        // We convert the React state to JSON and send it as the POST body
        body: JSON.stringify({
          trip: {
            travel_preference_attributes: {
              template_type: this.state.occasion,
              template_response: Object.assign({}, ...this.state.preferences),
            },
            search_param_attributes: {
              ...travel.travelers,
              ...{from: from, to: (to || {}), budget: travel.budget, currency: travel.currency},
              ...{
                dates_type: dates.dates_type,
                dates_start: dates.start_date,
                dates_end: dates.end_date,
                dates_nights: dates.nights,
              }
            },
            // account_plan: this.state.account_plan,
          }
        })
      })
      .then(response => response.json())
      .then(data => {
        this.setState({isSubmitting: false})

        if (data.redirect_to) {
          this.setState({error: null})

          window.location.replace(data.redirect_to);
          return null;
        }

        if (data.trip_id) {
          this.setState({error: null})
          this.props.history.replace({pathname: '/trip/to/' + data.destination_slug + '/' + data.trip_id, state: {backButtonPath: '/planner'}})
        }

        if (data.errors) {
          this.setState({error: data.errors})
        }
      })

    } else {
      this.openSigninModal()
    }
  }

  progress = () => {
    switch(this.props.step) {
      case 'dates':
        return 40
      case 'travel_style':
        return 60
      case 'contact':
        return 80
      case 'pricing':
        return 100
      default:
        return 20
    }
  }

  title = () => {
    switch(this.props.step) {
      case 'travel_style':
        return 'What do you prefer ?'
      case 'dates':
        return 'Where to ?'
      case 'pricing':
        return 'Choose a plan'
      case 'contact':
        return 'Sign up or Login'
      default:
        return 'What might I plan for you ?'
    }
  }

  subtitle = () => {
    switch(this.props.step) {
      case 'travel_style':
        return 'Tell us about your travel style'
      case 'dates':
        return 'Specify dates and travel preferences'
      case 'contact':
        return 'Get started, one-click sign in or sign up'
      case 'pricing':
        return 'All plans come with 7-day money back guarantee'
      default:
        return 'Choose what kind of trip would you like to plan, AI travel assistant will help you with the rest'
    }
  }

  emptyPlan = (user) => {
    return (user && user.subscription && user.subscription.has_remaining) && !user.subscription.has_remaining.trips
  }

  render() {
    const { classes } = this.props

    return (
      <Fragment>
         <Helmet>
          <title>Plan a trip: {this.title()}</title>
        </Helmet>
        <AppTopBar
          hideMenu={true}
          hideLogo={false}
          hideCurrency={true}
          hideBottomMenu={true}
          showBackButton={true}
          showCloseButton={this.props.user && this.props.user.is_registered ? true : false}
          onLocationChanged={this.props.onLocationChanged}
          onLocationCleared={this.props.onLocationCleared}
          onCurrencyChange={this.handleReloadResults}
          onChange={this.props.onFilterChange}
          onSigninModalChanged={this.handleSigninChange}
          onSignin={this.handleSignin}
          user={this.props.user}
          address={startCase(this.props.address)}
          tabSelected={this.props.tabSelected || 'planner'}
          openedSigninDialog={this.state.signinDialogOpened}
          signinDialogType={this.state.signinDialogType !== null && this.state.signinDialogType}
          signinDialogForce={this.state.signinDialogForce}
          signinDialogTitle={this.state.signinDialogTitle}
          signinDialogSubtitle={this.state.signinDialogSubtitle}
        />

        <div className="destinations explore">
          <div>
            <LinearProgress variant="determinate" value={this.progress()} style={{height: 4, marginTop: 36}}/>
            {/* <ToggleButtonGroup size="small" style={{margin: 'auto'}}>
              <ToggleButton value="planning" disableRipple selected={false} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} >AI Assistant</ToggleButton>
              <ToggleButton value="upcoming" disableRipple selected={true} classes={{root: classes.buttonOutlined, selected: classes.buttonOutlinedSelected}} >Planner</ToggleButton>
            </ToggleButtonGroup> */}

            <Paper square className={classes.headlinePaper} style={{marginBottom: 0, paddingBottom: 0}}>
              <h2 className={classes.headline} style={{textAlign: 'center'}}>
                <strong className="main-title" style={{fontWeight: 800}}>{this.title()}<br/></strong>
                <small className={classes.subtitle}>
                  {this.subtitle()}
                </small>
              </h2>
            </Paper>
          </div>

          <Grid container spacing={2} className={classes.gridFilters} justify="center">
            {this.state.error && <Grid item xs={12} md={8} className={classes.subtitle}>
                <Alert severity="error" style={{textAlign: 'left', borderRadius: 15, fontWeight: 500}}
                  action={
                    <IconButton
                      aria-label="close"
                      color="inherit"
                      size="small"
                      onClick={() => { this.setState({error: null}) }}
                    >
                      <Icon className="notranslate">close</Icon>
                    </IconButton>
                  }>
                  <AlertTitle style={{fontWeight: 700, fontSize: 15}}>{(this.state.error && this.state.error.title) || 'Oops. Unable to request a trip'}</AlertTitle>
                  <div>{this.state.error && this.state.error.message.map(s => <Fragment>{s}<br/></Fragment>)}</div>
                  {/* <div>Contact us at <a href="mailto:hi@travelities.com">hi@travelities.com</a></div> */}
                </Alert>
            </Grid>}


            {(() => {
              // if (this.props.step !== 'occasion' && this.props.user && this.emptyPlan(this.props.user)) {
              //   return this.props.history.replace('/planner')
              // } else if (this.props.step === 'occasion' && this.props.user && this.emptyPlan(this.props.user)) {
              //   return <PlannerUpgrade classes={this.props.classes}/>
              // } else {
                switch(this.props.step) {
                  case 'travel_style':
                    return <PlannerQuiz preferences={this.state.preferences} onUpdated={this.onUpdated} classes={this.props.classes}/>
                  case 'dates':
                    return <PlannerTravel user={this.props.user} travel={this.state.travel} onUpdated={this.onUpdated} multiple={['tour', 'road_trip'].includes(this.state.occasion)} classes={this.props.classes}/>
                  case 'contact':
                    return <PlannerCreateAccount user={this.props.user} contact={this.state.contact} onUpdated={this.onUpdated} isSubmitting={this.state.isSubmitting} onSignin={this.handleSignin} classes={this.props.classes}/>
                  case 'pricing':
                    return <PlannerPricing account_plan={this.state.account_plan} onUpdated={this.onUpdated} isSubmitting={this.state.isSubmitting} classes={this.props.classes}/>
                  case 'occasion':
                    return <PlannerOccasion user={this.props.user} occasion={this.state.occasion} onUpdated={this.onUpdated} classes={this.props.classes}/>
                  default:
                    return <PlannerOccasion user={this.props.user} occasion={this.state.occasion} onUpdated={this.onUpdated} classes={this.props.classes}/>
                }
              // }
            })()}
          </Grid>
        </div>
      </Fragment>
    )
  }
}

const styles = theme => ({
  h3: {
    fontWeight: 700,
    fontSize: 18,
    margin: '0px 5px 15px 5px',
  },
  gridFilters: {
    textAlign: 'left',
    maxWidth: 1142,
    margin: '0px auto 0px auto',
    width: 'calc(100% - 20px)',
    overflow: 'hidden',

    [theme.breakpoints.down('xs')]: {
      width: '100%',
      padding: '0px 10px',
    },
  },
  headlinePaper: {
    padding: '0px 0px 0px 0px',
    margin: '0px auto',
    color: '#333',
    // textAlign: 'left',
    width: '100%',
    position: 'relative',
    boxShadow: 'none',
    height: '100%',
    // minHeight: '60vh',
    paddingBottom: '96px',
    backgroundColor: 'transparent',
    // background: 'linear-gradient(180deg, rgb(26 83 53 / 9%) 0%, rgb(205 214 208 / 77%) 100%)',
  },
  headline: {
    maxWidth: 1119,
    // width: '100%',
    display: 'block',
    position: 'relative',
    margin: '0px auto',
    fontSize: 28,
    fontWeight: 400,
    textAlign: 'left',
    marginTop: '0px',
    padding: '35px 20px 20px 20px',

    [theme.breakpoints.down('xs')]: {
      fontSize: 25,
      padding: '35px 20px 0px 20px',
    },
  },
  subtitle: {
    fontWeight: 500,
    fontSize: '50%',
    color: '#777',
    display: 'block',
    marginBottom: 10,
    marginTop: 10,
  },
  listItemIcon: {
    color: '#02c39a',
  },
  listItemText: {
    fontSize: 15,
    fontWeight: 600,
    color: '#000',
    maxWidth: '78%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  listItemTextSecondary: {
    fontSize: 14,
    fontWeight: 600,
    maxWidth: '78%',
    display: 'block',
    overflow: 'hidden',
    // whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    color: '#444',
  },
  listItemTextPrice: {
    fontSize: 15,
    fontWeight: 600,
    maxWidth: '70%',
    display: 'block',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    cursor: 'pointer',
    // top: '24px',

    '& .material-icons': {
      color: '#02c39a',
      fontWeight: 300,
      verticalAlign: 'middle',
      margin: '0px 0px 0px 15px',
      fontSize: '18px',
    }
  },
  listItemSelected: {
    borderRadius: 15,
    border: '1px solid #02c39a',
    marginBottom: 5,
    boxShadow: "0 0px 10px 0 rgb(0 0 0 / 5%);",

    '&:hover': {
      backgroundColor: '#07c3991a',
    },
  },
  listItem: {
    borderRadius: 15,
    border: '1px solid transparent',
    marginBottom: 5,
    backgroundColor: '#fff',

    '&:hover': {
      backgroundColor: '#07c3991a',
    },
  },
  buttonBook: {
    // float: 'right',
    color: '#fff',
    margin: '10px auto',
    backgroundColor: '#02c39a',
    textTransform: 'none',
    boxShadow: 'none',
    borderRadius: 25,
    fontWeight: 800,
    padding: 15,
    width: '100%',
    maxWidth: '600px',
    display:'block',

    '&:hover': {
      backgroundColor: 'rgb(1, 136, 107)',
      boxShadow: 'none',
    }
  },
  bottomBar: {
    margin: 'auto',
    display: 'block',
    position: 'fixed',
    bottom: 0,
    width: '100%',
    backgroundColor: '#fff',
    boxShadow: 'rgba(0, 0, 0, 0.05) 0px -10px 10px -10px',
  },
  buttonOutlined: {
    // color: '#1bb746',
    float: 'left',
    // marginLeft: 10,
    padding: '10px',
    borderRadius: 25,
    fontWeight: 500,
    border: '1px solid #f5f5f5',
    color: '#333',
    marginTop: 6,
    minWidth: 110,
    backgroundColor: '#f5f5f5',

    '& .material-icons': {
      marginRight: 5,
      color: '#777',
      fontWeight: 500,
      padding: 0,
    },

    '&:hover': {
      backgroundColor: '#f2f2f2',
      border: '1px solid transparent',
    },

    '&:not(:first-child)': {
      marginLeft: 0,
    },

    '&:hover .material-icons': {
      color: '#333',
    },
  },
  buttonOutlinedSelected: {
    backgroundColor: '#f5f5f5 !important',
    fontWeight: 800,
    color: '#333 !important',
    padding: '5px 7px',
    minWidth: 110,

    '& .material-icons': {
      color: '#02c39a !important',
    },

    '&:hover': {
      color: '#333',
    },

    '&:hover .material-icons': {
      color: '#333',
    },

    '& > .MuiToggleButton-label': {
      borderRadius: 25,
      backgroundColor: '#fff',
      padding: '4px 10px',
      boxShadow: '0px 3px 3px -2px rgb(0 0 0 / 5%), 0px 3px 4px 0px rgb(0 0 0 / 5%), 0px 1px 8px 0px rgb(0 0 0 / 5%)',
    },
  },
  priceGuarantee: {
    fontWeight: 800,
    fontSize: '14px !important',
    // fontSize: '12px',
    color: '#02c39a',
    display: 'block',
    margin: '0px 0px 30px 0px',
  }
})

export default withRouter(withStyles(styles)(Planner))
